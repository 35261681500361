import { MultipleLanguageFilter, SingleLanguageFilter } from "@/components/filters/LanguageFilters";
import { Button } from "@/components/ui/button";
import { IdName } from "@/model/common";
import { CircleXIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

interface AnalysisFilterProps {
  resetFilters: (fullReset: boolean) => void;
  sourceLanguages: IdName[] | null;
  updateLanguageCodes: (
    sourceLanguageCode: string | null | undefined,
    targetLanguageCodes: string[] | [] | undefined
  ) => void;
  setTargets: (sourceLanguageCode: string | null) => void;
  sourceLanguageCode: string | null;
  targetLanguages: IdName[] | null;
  targetLanguageCodes: string[] | null;
}

export function AnalysisFilter({
  resetFilters,
  sourceLanguages,
  setTargets,
  sourceLanguageCode,
  targetLanguages,
  targetLanguageCodes,
  updateLanguageCodes,
}: AnalysisFilterProps) {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.analysis" });

  const handleSourceLanguageChange = (code: string | null) => {
    updateLanguageCodes(code, undefined);
    setTargets(code);
  };

  const handleTargetLanguageChange = (codes: string[] | []) => {
    updateLanguageCodes(undefined, codes);
  };

  return (
    <div className="flex gap-4">
      <form className="w-full">
        <div className="my-3 flex space-x-2">
          <SingleLanguageFilter
            selectedLanguageCode={sourceLanguageCode}
            onChange={handleSourceLanguageChange}
            isShowIcon={false}
            toggleSelection
            testId="sourceLanguage"
            title={t("sourceLanguage")}
            options={sourceLanguages ?? []}
          />
          <MultipleLanguageFilter
            selectedLanguageCode={targetLanguageCodes ?? []}
            onChange={handleTargetLanguageChange}
            isShowIcon={false}
            testId="targetLanguages"
            title={t("targetLanguages")}
            options={targetLanguages ?? []}
          />
          {sourceLanguageCode && (
            <Button
              size="sm"
              variant="outline"
              className="border-0 bg-transparent p-1"
              type="button"
              onClick={() => resetFilters(false)}
            >
              <CircleXIcon className="opacity-15" />
            </Button>
          )}
        </div>
      </form>
    </div>
  );
}
