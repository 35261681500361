import { flexRender, Header, HeaderGroup, noop } from "@tanstack/react-table";
import { TableHead, TableHeader, TableRow } from "../../ui/table";
import { Button } from "../../ui/button";
import { ArrowDownIcon, ArrowUpIcon, EqualIcon } from "lucide-react";
import { horizontalListSortingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable";
import { cn } from "@/utils/ui";
import { CSS } from "@dnd-kit/utilities";

interface DatatableHeaderProps<TData> {
  headerGroups: HeaderGroup<TData>[];
  columnOrder: string[];
}

export function DatatableHeader<TData>({ headerGroups, columnOrder }: DatatableHeaderProps<TData>) {
  return (
    <TableHeader className="bg-muted/50 text-sm font-medium text-muted-foreground">
      <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
        {headerGroups.map((headerGroup) => (
          <TableRow key={headerGroup.id} className="hover:bg-transparent">
            {headerGroup.headers.map((header) => {
              return header.id === "select" ? (
                <DatatableSimpleHead key={header.id} header={header} />
              ) : (
                <DatatableSortableHead key={header.id} header={header} />
              );
            })}
          </TableRow>
        ))}
      </SortableContext>
    </TableHeader>
  );
}

interface DatatableHeadProps<TData> {
  header: Header<TData, unknown>;
}

function DatatableSimpleHead<TData>({ header }: DatatableHeadProps<TData>) {
  return (
    <TableHead>
      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
    </TableHead>
  );
}

function DatatableSortableHead<TData>({ header }: DatatableHeadProps<TData>) {
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({ id: header.column.id });
  const isCanSort = header.column.getCanSort();
  const sortDirection = header.column.getIsSorted();
  const style = { transform: CSS.Translate.toString(transform) };

  return (
    <TableHead
      key={header.id}
      colSpan={header.colSpan}
      className={cn(
        "relative",
        "transition-transform",
        "whitespace-nowrap",
        `w-[${header.column.getSize()}]`,
        isDragging ? "opacity-80" : "opacity-100",
        isDragging ? "z-10" : "z-0",
        header.column.columnDef.meta?.position === "right" ? "text-right" : ""
      )}
      style={style}
      ref={setNodeRef}
    >
      <div className="flex items-center justify-between">
        {header.isPlaceholder ? null : (
          <Button
            type="button"
            variant="none"
            onClick={() => (isCanSort ? header.column.toggleSorting(sortDirection === "asc") : noop())}
          >
            <div className="truncate">{flexRender(header.column.columnDef.header, header.getContext())}</div>
            {sortDirection ? (
              <>
                {sortDirection === "asc" && <ArrowUpIcon className="ml-2 size-4 shrink-0" />}
                {sortDirection === "desc" && <ArrowDownIcon className="ml-2 size-4 shrink-0" />}
              </>
            ) : null}
          </Button>
        )}
        <EqualIcon {...attributes} {...listeners} className="ml-2 size-4 shrink-0" />
      </div>
    </TableHead>
  );
}
