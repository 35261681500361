import { EmptyDataPlaceholder } from "@/components/EmptyPlaceholder";
import { ContactSelector } from "@/components/contacts/ContactSelector";
import { AccessorType } from "@/model/request.typing";
import { useAddAccessorMutation, useRemoveAccessorMutation } from "@/query/request.query";
import { useEffect, useRef } from "react";
import { AccessorItem } from "./AccessorItem";

interface AccessorsSectionProps {
  requestId: string;
  entityType: string;
  divisionId: number;
  accessors?: AccessorType[];
}

export function AccessorsSection({ requestId, entityType, divisionId, accessors = [] }: AccessorsSectionProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { add } = useAddAccessorMutation(requestId, entityType);
  const { mutate: remove } = useRemoveAccessorMutation(requestId, entityType);
  const hasAccessors = accessors.length > 0;

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [accessors]);

  if (!divisionId) {
    return (
      <div className="flex max-w-md flex-col gap-4" data-test="accessors">
        <EmptyDataPlaceholder />
      </div>
    );
  }

  return (
    <div className="flex max-w-md flex-col gap-4" data-test="accessors">
      <div className={`mr-3 flex ${hasAccessors ? "flex-row-reverse" : "flex-row"}`} data-test="accessors-header">
        <ContactSelector
          divisionId={divisionId?.toString()}
          filterContactsIds={accessors.map((accessor) => accessor.id)}
          selectedContacts={[]}
          onChange={add}
          data-test="accessors-contact-selector"
        />
      </div>
      {hasAccessors ? (
        <div className="max-h-[200px] overflow-y-auto" ref={scrollRef} data-test="accessors-list-container">
          <div className="mr-3 flex flex-col gap-2" data-test="accessors-list">
            {accessors.map((accessor) => (
              <AccessorItem
                key={accessor.id}
                name={`${accessor.name}`}
                email={accessor.description ?? ""}
                isPending={accessor.isPending}
                onRemove={accessor.isPending ? undefined : () => remove({ accessor })}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
