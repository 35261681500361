import { Column } from "@tanstack/react-table";
import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { ChevronDownIcon, RotateCcw, Settings2 } from "lucide-react";
import { useTranslation } from "react-i18next";

interface DatatableColumnVisibilityDropdownProps<TData> {
  columns: Column<TData, unknown>[];
  onResetDefaultView?: () => void;
}

export function DatatableColumnVisibilityDropdown<TData>({
  columns,
  onResetDefaultView,
}: DatatableColumnVisibilityDropdownProps<TData>) {
  const { t } = useTranslation("translation");

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="max-md:w-full" data-test="datatable-column-visibility-dropdown-trigger">
          <Settings2 className="mr-2 size-4" />
          {t("data-table.view-columns")} <ChevronDownIcon className="ml-2 size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem
          data-test="datatable-column-visibility-dropdown-reset-view-button"
          disabled={!onResetDefaultView}
          onClick={
            onResetDefaultView
              ? (e) => {
                  e.preventDefault();
                  onResetDefaultView();
                }
              : undefined
          }
        >
          <RotateCcw className="mr-2 size-4" />
          <span>{t("data-table.resetDefaultView")}</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        {columns
          .filter((column) => column.getCanHide())
          .map((column) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onSelect={(event) => event.preventDefault()} // prevent closing the dropdown
                onCheckedChange={(value) => column.toggleVisibility(value)}
              >
                {typeof column.columnDef.header === "string" ? column.columnDef.header : column.id}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
