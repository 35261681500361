import { HomePageHero } from "@/components/HomePageHero";
import { HomePageTiles } from "@/components/HomePageTiles";
import { PageShell } from "@/components/shell/PageShell";
import { useAuthUserStore } from "@/store/useAuthUserStore";

export function HomePage() {
  const user = useAuthUserStore((store) => store.user);
  return (
    <PageShell data-test="home-page">
      <HomePageHero user={user} />
      <HomePageTiles user={user} />
    </PageShell>
  );
}
