import { DownloadOptions } from "model/mt.typing";
import { useGetOcrResults, useOcrParagraphs } from "query/mt/machinetranslation.query";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LanguageLoader } from "../LanguageLoader";
import { OcrDownloadButton } from "./OcrDownloadButton";
import { OcrFile } from "./OcrFile";
import { OcrParagraphs } from "./OcrParagraphs";
import { OcrMachineTranslationFormType } from "../../useOcrMachineTranslationForm";

interface OcrDisplayProps {
  fileId: string;
  form: UseFormReturn<OcrMachineTranslationFormType>;
  handleDownloadFiles: (downloadType: DownloadOptions) => Promise<boolean>;
  setShowOcrDownload: (show: boolean) => void;
  showOcrDownload: boolean;
  isOcrPreprocessed: boolean;
}

export const OcrDisplay = (props: OcrDisplayProps) => {
  const { t } = useTranslation();
  const { fileId, form, handleDownloadFiles, setShowOcrDownload, showOcrDownload, isOcrPreprocessed } = props;
  const { sourceParagraphs } = useOcrParagraphs(fileId);
  const { ocrResults, lastOcrResult, status, fetchLastOcrResult } = useGetOcrResults(fileId);

  useEffect(() => {
    if (lastOcrResult) {
      setShowOcrDownload(true);
    } else {
      setShowOcrDownload(false);
    }
  }, [lastOcrResult, setShowOcrDownload]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isOcrPreprocessed && !((ocrResults && ocrResults.status === "finished") || status === "error"))
        fetchLastOcrResult();
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [isOcrPreprocessed, fetchLastOcrResult, ocrResults, status]);

  const renderParagraphs = () => {
    if (!sourceParagraphs)
      return (
        <div className="flex-1">
          <div className="mb-5 flex w-full items-end justify-between border-b-2 pb-1 text-xl">
            {t("mt.file.sourceText")}
            <div className="h-9" />
          </div>
          <LanguageLoader />
        </div>
      );

    return (
      <OcrParagraphs
        form={form}
        handleDownloadFiles={handleDownloadFiles}
        showOcrDownload={showOcrDownload}
        lastOcrResult={lastOcrResult}
        isOcrPreprocessed={isOcrPreprocessed}
      />
    );
  };

  const renderTargetFile = () => {
    if (!isOcrPreprocessed) return null;

    if (!lastOcrResult || lastOcrResult.status != "finished") {
      return (
        <div className="flex-1 items-start">
          <div className="mb-5 flex w-full items-end justify-between border-b-2 pb-1 text-xl">
            {t("mt.file.targetFile")}
            <div className="h-9" />
          </div>
          <LanguageLoader />
        </div>
      );
    }

    return (
      <div className="flex-1">
        <div className="mb-5 flex w-full items-end justify-between border-b-2 pb-1 text-xl">
          <div>{t("mt.file.targetFile")}</div>
          {showOcrDownload ? (
            <OcrDownloadButton type="target" onDownload={() => handleDownloadFiles("target")} />
          ) : (
            <div className="h-9" />
          )}
        </div>
        <OcrFile file={lastOcrResult} fileType="target" data-test="mt-ocr-file-target" />
      </div>
    );
  };

  return (
    <>
      {renderParagraphs()}
      {renderTargetFile()}
    </>
  );
};
