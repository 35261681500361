import { useMemo } from "react";

interface Pagination {
  totalCount: number;
  pageSize: number;
  currentPage: number;
}

export const forwardDots = ">>";
export const backwardDots = "<<";
const numberOfDots = 2;
const minPageNumberShown = 3; // 3 elements other than first page and last page
const countOfTotalPageNumber = 1 /* first page */ + 1 /* last page */ + minPageNumberShown; // 5 elements
const paginationElementCount = numberOfDots + countOfTotalPageNumber;

export const generatePaginationRange = ({ totalCount, pageSize, currentPage }: Pagination) => {
  // generate range
  const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  // total element / page size
  const totalPageCount = Math.ceil(totalCount / pageSize);

  // number of page that we want show is more than the total page count. (there is enough room to show all the page numbers)
  // we just generate all the page from 1 to totalPage count.
  if (paginationElementCount >= totalPageCount) {
    return range(1, totalPageCount);
  }
  // if we are here, it means that we have more pages than we can show.
  // based on the current page we need to find where to put the dots to keep the max of element shown below paginationElementCount (7 elements)

  const firstPageIndex = 1;
  const lastPageIndex = totalPageCount;

  // sibling indexes are page indexes just before and just after current page.
  const leftSiblingIndex = Math.max(currentPage - 1, firstPageIndex); // prevent -1 page we default to one
  const rightSiblingIndex = Math.min(currentPage + 1, lastPageIndex); // preventing to go beyond max. page number.

  const shouldShowLeftDots = leftSiblingIndex >= minPageNumberShown; // when the current page index is far from start page we need the 3 dots because we cant display all of them
  const shouldShowRightDots = rightSiblingIndex <= totalPageCount - minPageNumberShown; // when the current page index is far from the last page we need the 3 dots because we cant display all of them

  // we know that we need to insert the dots, now it's the matter where to put them.

  // not needed on the left generate all the pag number from 1 to total page number [1,2,3,4,5]
  if (!shouldShowLeftDots && shouldShowRightDots) {
    const leftRange = range(1, countOfTotalPageNumber); // generate from 1 to number of offset
    return [...leftRange, forwardDots, totalPageCount];
  }

  // not needed for dots on the right generate all the page number from 1 to total page number [1,2,3,4,5]
  if (shouldShowLeftDots && !shouldShowRightDots) {
    const rightRange = range(totalPageCount - countOfTotalPageNumber + 1, totalPageCount);
    return [firstPageIndex, backwardDots, ...rightRange];
  }

  // we need to generate both dots [1,..2,3,4..,5]
  const middleRange = range(leftSiblingIndex, rightSiblingIndex);
  return [firstPageIndex, backwardDots, ...middleRange, forwardDots, lastPageIndex];
};

export const usePagination = ({ totalCount, pageSize, currentPage }: Pagination) => {
  return useMemo(
    () => generatePaginationRange({ totalCount, pageSize, currentPage }),
    [totalCount, pageSize, currentPage]
  );
};

export function usePaginationSelector({ totalCount, pageSize }: Omit<Pagination, "currentPage">) {
  return useMemo(() => Math.ceil(totalCount / pageSize), [totalCount, pageSize]);
}
