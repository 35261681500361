import { Button } from "@/components/ui/button";
import { cn } from "@/utils/ui";
import { Loader2Icon, XIcon } from "lucide-react";

interface AccessorItemProps {
  name: string;
  email: string;
  isPending?: boolean;
  onRemove?: () => void;
}

export function AccessorItem({ name, email, isPending, onRemove }: AccessorItemProps) {
  const hasActions = onRemove !== undefined;
  return (
    <div
      data-test="accessor-item"
      className={cn(
        "flex items-center justify-between gap-2 rounded-md border px-2 py-1 text-sm",
        isPending && "border-muted bg-muted/10"
      )}
    >
      <div className="flex flex-col" data-test="accessor-info">
        <span className="text-secondary-foreground" data-test="accessor-name">
          {name}
        </span>
        <span className="text-xs text-muted-foreground" data-test="accessor-email">
          {email}
        </span>
      </div>
      <div className="flex size-8 items-center justify-center">
        {hasActions ? (
          <Button
            variant="ghost"
            size="icon"
            onClick={onRemove}
            className="size-8"
            disabled={isPending}
            data-test="accessor-remove-button"
          >
            {isPending ? (
              <Loader2Icon className="size-4 animate-spin" data-test="accessor-loading-icon" />
            ) : (
              <XIcon className="size-4" data-test="accessor-remove-icon" />
            )}
          </Button>
        ) : isPending ? (
          <Loader2Icon className="size-4 animate-spin" data-test="accessor-loading-icon" />
        ) : null}
      </div>
    </div>
  );
}
