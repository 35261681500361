import { useAuthUserStore } from "@/store/useAuthUserStore";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserPreferences, requestLogout, requestUser, requestXSRFToken, updateUserPreferences } from "api/user.api";
import { AxiosError } from "axios";
import { User, UserPreferences, UserProfileFormModel } from "model/user.typing";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { redirectToGoodbye } from "utils/url";
import { useRequestsSearchTextActions } from "../pages/requests/requestsSearchStore";

const useUser = () => {
  const setUser = useAuthUserStore((store) => store.setUser);
  return { setUser };
};

export const useAuthenticatedUser = () => {
  const { setUser } = useUser();
  const { data, isLoading, error } = useQuery<User, AxiosError>({
    queryKey: ["user"],
    queryFn: ({ signal }) => requestUser(signal),
    staleTime: 1000 * 10, // 10 seconds
  });

  useEffect(() => {
    if (isLoading) return;
    if (data) {
      setUser(data);
      localStorage.setItem("user", JSON.stringify(data));
    } else {
      localStorage.removeItem("user");
    }
  }, [data, isLoading, setUser]);

  return { user: data, isLoading, error };
};

export const useLogoutMutation = (options?: { isTimeout?: boolean; returnUrl?: string }) => {
  const queryClient = useQueryClient();
  const { reset } = useRequestsSearchTextActions();

  return useMutation<void, AxiosError>({
    mutationFn: requestLogout,
    onSettled: async () => {
      queryClient.removeQueries(); // remove all queries
      reset();
      redirectToGoodbye(options ?? {});
    },
  });
};

export const useXSRFTokenQuery = () => {
  const { isLoading } = useQuery<unknown, AxiosError>({
    queryKey: ["xsrft"],
    queryFn: async ({ signal }) => await requestXSRFToken(signal),
    retry: 2,
    staleTime: Infinity, // never invalidate
  });
  return { isLoading };
};

export const useUserPreferencesQuery = () => {
  return useQuery<UserPreferences, AxiosError>({
    queryKey: ["user", "preferences"],
    queryFn: async () => {
      return await getUserPreferences();
    },
  });
};

export const useUpdateUserPreferencesMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, UserProfileFormModel>({
    mutationFn: (formValues: UserProfileFormModel) => updateUserPreferences(formValues),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["user"] }); // refresh user
      queryClient.invalidateQueries({ queryKey: ["requests"] }); // refresh requests
      queryClient.invalidateQueries({ queryKey: ["mtConfig"] }); // refresh MT config
      toast.success(t("profile.preferencesUpdated"), { id: "profile-preferences-updated" });
    },
    onError: () => {
      toast.error(t("profile.preferencesUpdateFailed"), { id: "profile-preferences-update-failed" });
    },
  });
};
