import { LucideIcon } from "lucide-react";
import { PropsWithChildren, ReactNode } from "react";

interface Props {
  heading: string | ReactNode;
  description?: string;
  icon?: LucideIcon;
}

export function PageHeaderShell({ heading, description, icon: Icon, children }: PropsWithChildren<Props>) {
  return (
    <div data-test="page-header" className="mb-6 mr-2 flex flex-wrap items-center justify-between gap-4 px-2">
      <div className="grid gap-1">
        <div className="flex flex-nowrap items-center gap-2">
          {Icon ? <Icon data-test="page-header-icon" className="size-6 shrink-0 md:size-8" /> : null}
          <h2 data-test="page-header-title" className="text-2xl font-semibold leading-7">
            {heading}
          </h2>
        </div>
        {description ? (
          <p className="text-sm font-normal leading-tight text-muted-foreground" data-test="page-header-description">
            {description}
          </p>
        ) : null}
      </div>
      {children}
    </div>
  );
}
