import type { IdName } from "@/model/common";
import type { ProjectCostPerLanguagesType, TmLeveragePerLanguagesType } from "@/model/report.typing";
import type {
  AnalysisStats,
  ProjectAnalysisType,
  RequestDetailType,
  StackedBarType,
  TaskAnalysisType,
} from "@/model/request.typing";
import { useLanguagesQuery, useProjectAnalysisQuery } from "@/query/request.query";
import type { TFunction } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const filterProjects = <T>(
  projects: T[] | null,
  sourceLanguageCode: string | null,
  targetLanguageCodes: string[] | null,
  sourceKey: keyof T,
  targetKey: keyof T
): T[] | null => {
  if (!projects || !sourceLanguageCode) return null;
  return projects.filter(
    (project) =>
      project[sourceKey] === sourceLanguageCode &&
      (!targetLanguageCodes ||
        targetLanguageCodes.length === 0 ||
        targetLanguageCodes.includes(project[targetKey] as unknown as string))
  );
};

const calculateAnalysisStats = (tasks: TaskAnalysisType[], operation: string): AnalysisStats => ({
  goodEvaluationScore: tasks.reduce((sum, task) => sum + task.goodEvaluationScore, 0) / tasks.length,
  badEvaluationScore: tasks.reduce((sum, task) => sum + task.badEvaluationScore, 0) / tasks.length,
  tmEvaluationScore: tasks.reduce((sum, task) => sum + task.tmEvaluationScore, 0) / tasks.length,
  goodWordCount: tasks.reduce((sum, task) => sum + task.goodWordCount, 0),
  badWordCount: tasks.reduce((sum, task) => sum + task.badWordCount, 0),
  tmWordCount: tasks.reduce((sum, task) => sum + task.tmWordCount, 0),
  tempoProjectCode: tasks[0]?.tempoProjectCode ?? "",
  operation,
});

const calculateAnalysisResult = (
  apeAnalysis: AnalysisStats | null,
  mtAnalysis: AnalysisStats | null
): "increase" | "decrease" | "equal" | "incomplete" => {
  if (!apeAnalysis || !mtAnalysis) return "incomplete";
  if (apeAnalysis.goodEvaluationScore > mtAnalysis.goodEvaluationScore) {
    return "increase";
  }
  if (apeAnalysis.goodEvaluationScore < mtAnalysis.goodEvaluationScore) {
    return "decrease";
  }
  return "equal";
};

const useRequestAnalysis = (
  project: RequestDetailType,
  projectTmData: TmLeveragePerLanguagesType[] | null,
  projectCostData: ProjectCostPerLanguagesType[] | null
) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "requests.details.analysis",
  });
  const { languages } = useLanguagesQuery();
  const { data: analysis, isLoading } = useProjectAnalysisQuery(project?.projectCode);
  const [sourceLanguages, setSourceLanguages] = useState<IdName[]>([]);
  const [targetLanguages, setTargetLanguages] = useState<IdName[]>([]);
  const [sourceLanguageCode, setSourceLanguageCode] = useState<string | null>(null);
  const [targetLanguageCodes, setTargetLanguageCodes] = useState<string[] | null>([]);

  const filteredProjectCostData = useMemo(
    () =>
      filterProjects<ProjectCostPerLanguagesType>(
        projectCostData,
        sourceLanguageCode,
        targetLanguageCodes,
        "sourceLanguageCode",
        "targetLanguageCode"
      ),
    [projectCostData, sourceLanguageCode, targetLanguageCodes]
  );

  const filteredTmLeverageData = useMemo(
    () =>
      filterProjects<TmLeveragePerLanguagesType>(
        projectTmData,
        sourceLanguageCode,
        targetLanguageCodes,
        "sourceCode",
        "targetCode"
      ),
    [projectTmData, sourceLanguageCode, targetLanguageCodes]
  );

  const filteredTasks = useMemo(
    () =>
      analysis?.analysisTasks.filter(
        (task: TaskAnalysisType) =>
          task.sourceLanguage === sourceLanguageCode &&
          (!targetLanguageCodes?.length || targetLanguageCodes.includes(task.targetLanguage)) &&
          ["ExecuteMtqe", "ExecuteApe"].includes(task.operation)
      ),
    [analysis?.analysisTasks, sourceLanguageCode, targetLanguageCodes]
  );

  const filteredAnalysis = useMemo(() => {
    const mtTasks = filteredTasks?.filter((task) => task.operation === "ExecuteMtqe") ?? [];
    const apeTasks = filteredTasks?.filter((task) => task.operation === "ExecuteApe") ?? [];
    const mtAnalysis =
      mtTasks.length > 0 ? calculateAnalysisStats(mtTasks, "ExecuteMtqe") : (analysis?.mtAnalysis ?? null);
    const apeAnalysis =
      apeTasks.length > 0 ? calculateAnalysisStats(apeTasks, "ExecuteApe") : (analysis?.apeAnalysis ?? null);
    return {
      ...analysis,
      mtAnalysis,
      apeAnalysis,
      analysisTasks: filteredTasks,
      analysisResult: calculateAnalysisResult(apeAnalysis, mtAnalysis),
    };
  }, [analysis, filteredTasks]);

  const isFilterSet = !!sourceLanguageCode;

  const resetFilters = useCallback((fullReset: boolean) => {
    if (fullReset) setSourceLanguages([]);
    setSourceLanguageCode(null);
    setTargetLanguages([]);
    setTargetLanguageCodes([]);
  }, []);

  const setTargets = useCallback(
    (sourceLanguageCode: string | null) => {
      const projectSource = project?.languages?.find((lang) => lang.source.id === sourceLanguageCode);
      const targets = projectSource?.targets ?? [];
      const targetIds = targets.map((target) => target.id);
      setTargetLanguages(targets);
      setTargetLanguageCodes(targetIds);
    },
    [project?.languages]
  );

  const updateLanguageCodes = useCallback(
    (sourceLanguageCode: string | null | undefined, targetLanguageCodes: string[] | [] | undefined) => {
      if (sourceLanguageCode !== undefined) setSourceLanguageCode(sourceLanguageCode);
      if (targetLanguageCodes !== undefined) setTargetLanguageCodes(targetLanguageCodes);
    },
    []
  );

  // Always reset filter for each project change
  useEffect(() => {
    setSourceLanguages(languages?.filter((lang) => project?.languages?.some((l) => l.source.id === lang.id)) ?? []);
  }, [languages, project]);

  // When the source is set, set the correct targets
  useEffect(() => {
    if (sourceLanguages.length === 1) {
      updateLanguageCodes(sourceLanguages[0].id, undefined);
      setTargets(sourceLanguages[0].id);
    }
  }, [updateLanguageCodes, setTargets, sourceLanguages]);

  const getHeaderDetails = (analysisResults: string | null) => {
    const title = t(analysisResults === "increase" ? "qualityImprovements" : "qualityAnalysis");
    const subTitle = t(analysisResults === "increase" ? "qualityImprovementsMsg" : "qualityAnalysisMsg");
    return [title, subTitle];
  };

  const generateChartData = (analysisData: ProjectAnalysisType, t: TFunction) => {
    const data: StackedBarType[] = [];
    if (analysisData.tmWordCount > 0) {
      data.push({
        value: analysisData.tmWordCount,
        name: t("translationMemory"),
        color: "#61A3FF",
      });
    }
    if (analysisData.goodWordCount > 0) {
      data.push({
        value: analysisData.goodWordCount,
        name: t("highQuality"),
        color: "#67E3F9",
      });
    }
    if (analysisData.badWordCount > 0) {
      data.push({
        value: analysisData.badWordCount,
        name: t("humanReviewNeeded"),
        color: "#FDE272",
      });
    }
    return data;
  };

  const generateTaskChartData = (taskData: TaskAnalysisType, t: TFunction) => {
    const data: StackedBarType[] = [];
    if (taskData.tmWordCount > 0) {
      data.push({
        value: taskData.tmWordCount,
        name: t("translationMemory"),
        color: "#61A3FF",
      });
    }
    if (taskData.goodWordCount > 0) {
      data.push({
        value: taskData.goodWordCount,
        name: t("highQuality"),
        color: "#67E3F9",
      });
    }
    if (taskData.badWordCount > 0) {
      data.push({
        value: taskData.badWordCount,
        name: t("humanReviewNeeded"),
        color: "#FDE272",
      });
    }
    return data;
  };

  return {
    projectCode: project?.projectCode,
    // If filter is set and filtered data is not null, return filtered data, otherwise return original data
    analysis: isFilterSet ? (filteredAnalysis ?? analysis) : analysis,
    projectTmData: isFilterSet ? (filteredTmLeverageData ?? projectTmData) : projectTmData,
    projectCostData: isFilterSet ? (filteredProjectCostData ?? projectCostData) : projectCostData,
    resetFilters,
    getHeaderDetails,
    isLoading,
    sourceLanguages,
    sourceLanguageCode,
    setTargets,
    targetLanguages,
    targetLanguageCodes,
    updateLanguageCodes,
    generateChartData,
    generateTaskChartData,
  };
};

export default useRequestAnalysis;
