import PortalTooltip from "@/components/PortalTooltip";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardTitle } from "@/components/ui/card";
import { AnalysisType } from "@/model/request.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { sanitizeHtml } from "@/utils/text";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { ArrowDownIcon, ArrowUpIcon, CircleHelpIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

export function AnalysisMetricCards({ analysis }: { analysis: AnalysisType }) {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.analysis" });
  const formatPercentage = useAuthUserStore((store) => store.formatPercentage);

  const metrics = [
    {
      titleKey: "translationMemoryTitle",
      helpKey: "tmHelp",
      score: analysis.apeAnalysis.tmEvaluationScore,
      before: analysis.mtAnalysis.tmWordCount,
      after: analysis.apeAnalysis.tmWordCount,
      result: analysis.analysisResult,
      displayWordsLabel: true,
    },
    {
      titleKey: "aiTranslationTitle",
      helpKey: "aiTranslationHelp",
      score: analysis.apeAnalysis.goodEvaluationScore,
      before: analysis.mtAnalysis.goodWordCount,
      after: analysis.apeAnalysis.goodWordCount,
      result: analysis.analysisResult,
      displayWordsLabel: true,
    },
    {
      titleKey: "humanReviewNeededTitle",
      helpKey: "humanReviewNeededHelp",
      score: analysis.apeAnalysis.badEvaluationScore,
      before: analysis.mtAnalysis.badWordCount,
      after: analysis.apeAnalysis.badWordCount,
      result: analysis.analysisResult,
      displayWordsLabel: true,
    },
  ];

  return (
    <div className="grid auto-rows-fr gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {metrics.map((metric, index) => (
        <MetricCard
          key={index}
          title={t(metric.titleKey)}
          help={t(metric.helpKey)}
          score={metric.score}
          before={metric.before}
          after={metric.after}
          result={metric.result}
          displayWordsLabel={metric.displayWordsLabel}
          formatPercentage={formatPercentage}
        />
      ))}
    </div>
  );
}

function MetricCard({
  title,
  help,
  score,
  before,
  after,
  result,
  displayWordsLabel,
  formatPercentage,
}: {
  title: string;
  help: string;
  score: number;
  before: number;
  after: number;
  result: string;
  displayWordsLabel: boolean;
  formatPercentage: (value: number, showDecimals: boolean) => string;
}) {
  return (
    <Card className="min-w-[200px] flex-1 rounded-3xl border-0 p-6">
      <AnalysisCardTitle title={title} help={help} />
      <CardContent className="p-0 py-2 pr-2 font-semibold">
        <div className="text-3xl">
          {formatPercentage(score, false)}
          {displayWordsLabel && <span className="ml-2 whitespace-nowrap text-sm font-normal">of words</span>}
        </div>
        <AnalysisScorePill result={result} before={before} after={after} />
      </CardContent>
    </Card>
  );
}

function AnalysisCardTitle({ title, help }: { title: string; help: string }) {
  return (
    <CardTitle className="flex justify-between text-base">
      <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(title) }} />
      <TooltipProvider delayDuration={0}>
        <PortalTooltip text={help}>
          <CircleHelpIcon className="ml-2 mt-1 shrink-0 cursor-pointer text-secondary-foreground opacity-25" />
        </PortalTooltip>
      </TooltipProvider>
    </CardTitle>
  );
}

function AnalysisScorePill({ result, before, after }: { result: string; before: number | null; after: number | null }) {
  const formatPercentage = useAuthUserStore((store) => store.formatPercentage);
  if (result !== "increase" || !before || !after || before === after) return null;
  const isIncrease = before < after;

  const icon = isIncrease ? (
    <ArrowUpIcon className="mr-1 size-4 text-green-500" />
  ) : (
    <ArrowDownIcon className="mr-1 size-4 text-yellow-500" />
  );

  return (
    <div className="mt-3 hidden h-6">
      <Badge variant={isIncrease ? "increase" : "decrease"} className="text-xs">
        {icon}
        {formatPercentage((after - before) / before, false)}
        <span className="ml-1 font-normal">{isIncrease ? "increase" : "reduction"}</span>
      </Badge>
    </div>
  );
}
