import {
  ChartGroupToggleState,
  ChartSettingsType,
  CostReportChartNamesType,
  ProjectCostFiltersType,
  TmLeverageChartNamesType,
  TmLeverageFiltersType,
} from "@/model/report.typing";

import { SortingState } from "@tanstack/react-table";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface CommonReportState<F> {
  sortBy: SortingState;
  tab: "charts" | "table";
  filters: F;
  actions: {
    updateFilters: (newFilters: Partial<F>) => void;
    updateSortBy: (newSortBy: SortingState) => void;
    updateTabView: (newTab: "charts" | "table") => void;
  };
}

interface CostReportState extends CommonReportState<ProjectCostFiltersType> {
  perQuarterChartView: "perQyarterDefault" | "perQyarterYoy";
  chartSettings: Record<CostReportChartNamesType, ChartSettingsType>;
  actions: CommonReportState<ProjectCostFiltersType>["actions"] & {
    updatePerQuarterChartView(value: "perQyarterDefault" | "perQyarterYoy"): void;
    saveChartSettings: (
      chartType: keyof Record<CostReportChartNamesType, ChartSettingsType>,
      value: Partial<ChartSettingsType>
    ) => void;
  };
}

interface TmLeverageReportState extends CommonReportState<TmLeverageFiltersType> {
  chartGrouptToggleState: Record<TmLeverageChartNamesType, ChartGroupToggleState>;
  chartSettings: Record<TmLeverageChartNamesType, ChartSettingsType>;
  actions: CommonReportState<TmLeverageFiltersType>["actions"] & {
    updateChartViewFilter(chartType: TmLeverageChartNamesType, value: Partial<ChartGroupToggleState>): void;
    saveChartSettings: (
      chartType: keyof Record<TmLeverageChartNamesType, ChartSettingsType>,
      value: Partial<ChartSettingsType>
    ) => void;
  };
}

export const useCostReportStore = create(
  persist<CostReportState>(
    (set) => ({
      filters: {
        searchText: "",
        clientNameIds: [],
        ownersIds: [],
        currencyId: null,
        sourceLanguageIds: [],
        targetLanguageIds: [],
        divisionNames: [],
        includeOpenProjects: false,
      },
      sortBy: [{ id: "clientName", desc: false }],
      tab: "charts",
      chartSettings: {
        "per-target-language": { displayBrush: false, displayGrid: false },
        "per-month": { displayBrush: false, displayGrid: false },
        "per-requestor": { displayBrush: false, displayGrid: false },
        "per-quarter": { displayBrush: false, displayGrid: false },
      },
      perQuarterChartView: "perQyarterDefault",
      actions: {
        updatePerQuarterChartView: (value: "perQyarterDefault" | "perQyarterYoy") => {
          set(() => ({ perQuarterChartView: value }));
        },
        updateSortBy: (newSortBy: SortingState) => {
          set(() => ({ sortBy: newSortBy }));
        },
        updateTabView: (newTab: "charts" | "table") => {
          set(() => ({ tab: newTab }));
        },
        updateFilters: (newFilters: Partial<ProjectCostFiltersType>) => {
          set((state) => {
            const filters = { ...state.filters, ...newFilters };
            if (newFilters.deliveryDate?.from && !state.filters.deliveryDate?.from) {
              filters.includeOpenProjects = false;
            }
            return { filters };
          });
        },
        saveChartSettings: (chartType: keyof CostReportState["chartSettings"], value: Partial<ChartSettingsType>) => {
          set((state) => {
            return {
              chartSettings: {
                ...state.chartSettings,
                [chartType]: { ...state.chartSettings[chartType], ...value },
              },
            };
          });
        },
      },
    }),
    {
      name: "", //check ReportsLayout.tsx
      version: 1,
      skipHydration: true,
      partialize: (state) => {
        // removes actions from the serialized state
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { actions, ...rest } = state;
        return { ...rest } as CostReportState;
      },
    }
  )
);

const initialChartViewFilter: ChartGroupToggleState = {
  xTranslated: true,
  repsAnd100: true,
  repetitions: true,
  m100: true,
  m95_99: true,
  m85_94: true,
  m75_84: true,
  m50_74: true,
  noMatch: true,
  words: true,
  fuzzy: true,
};

export const useTmLeverageReportStore = create(
  persist<TmLeverageReportState>(
    (set) => ({
      chartSettings: {
        "per-target-language": { displayBrush: false, displayGrid: false },
        "per-month": { displayBrush: false, displayGrid: false },
        "per-month-percentage": { displayBrush: false, displayGrid: false },
        "per-month-words": { displayBrush: false, displayGrid: false },
      },
      filters: {
        searchText: "",
        clientNameIds: [],
        ownersIds: [],
        sourceLanguageIds: [],
        targetLanguageIds: [],
        divisionNames: [],
      },
      sortBy: [{ id: "clientName", desc: false }],
      tab: "charts",
      chartGrouptToggleState: {
        "per-target-language": initialChartViewFilter,
        "per-month-percentage": initialChartViewFilter,
        "per-month-words": initialChartViewFilter,
      },
      actions: {
        updateSortBy: (newSortBy: SortingState) => {
          set(() => ({ sortBy: newSortBy }));
        },
        updateTabView: (newTab: "charts" | "table") => {
          set(() => ({ tab: newTab }));
        },
        updateFilters: (newFilters: Partial<TmLeverageFiltersType>) => {
          set((state) => {
            const filters = { ...state.filters, ...newFilters };
            return { filters };
          });
        },
        updateChartViewFilter(
          chartType: keyof TmLeverageReportState["chartGrouptToggleState"],
          value: Partial<TmLeverageReportState["chartGrouptToggleState"]>
        ) {
          set((state) => {
            return {
              chartGrouptToggleState: {
                ...state.chartGrouptToggleState,
                [chartType]: { ...state.chartGrouptToggleState[chartType], ...value },
              },
            };
          });
        },
        saveChartSettings: (
          chartType: keyof TmLeverageReportState["chartSettings"],
          value: Partial<ChartSettingsType>
        ) => {
          set((state) => {
            return {
              chartSettings: {
                ...state.chartSettings,
                [chartType]: { ...state.chartSettings[chartType], ...value },
              },
            };
          });
        },
      },
    }),
    {
      name: "", //check ReportsLayout.tsx
      version: 1,
      skipHydration: true,
      partialize: (state) => {
        // removes actions from the serialized state
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { actions, ...rest } = state;
        return { ...rest } as TmLeverageReportState;
      },
    }
  )
);

// cost report hooks
export const useCostReportSortBy = () => useCostReportStore((state) => state.sortBy);
export const useCostReportTabView = () => useCostReportStore((state) => state.tab);
export const useCostReportFilters = () => useCostReportStore((state) => state.filters);
export const useCostReportChartSettings = (chartType: CostReportChartNamesType) =>
  useCostReportStore((state) => state.chartSettings[chartType]);
export const useCostReportChartView = () => useCostReportStore((state) => state.perQuarterChartView);
export const useCostReportActions = () => useCostReportStore((state) => state.actions);

// tm leverage hooks
export const useTmLeverageSortBy = () => useTmLeverageReportStore((state) => state.sortBy);
export const useTmLeverageTabView = () => useTmLeverageReportStore((state) => state.tab);
export const useTmLeverageStoredFilters = () => useTmLeverageReportStore((state) => state.filters);
export const useTmLeverageChartSettings = (chartType: TmLeverageChartNamesType) =>
  useTmLeverageReportStore((state) => state.chartSettings[chartType]);
export const useTmLeverageActions = () => useTmLeverageReportStore((state) => state.actions);
export const useTmLeverageChartViewFilter = () => useTmLeverageReportStore((state) => state.chartGrouptToggleState);
