import { useQueryClient } from "@tanstack/react-query";
import { ButtonIcon } from "components/button/ButtonIcon";
import { Button } from "components/ui/button";
import { Form } from "components/ui/form";
import { t } from "i18next";
import { ArrowLeft, ArrowLeftRight } from "lucide-react";
import { DownloadOptions, FileToTranslate, LanguageOption, MachineFileTranslationResult } from "model/mt.typing";
import {
  useMachineTranslationConfig,
  useTranslationFiles,
  useUploadOcrFileMutation,
} from "query/mt/machinetranslation.query";
import { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { Navigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { FileSourceControls } from "../components/file/FileSourceControls";
import { FileTargetControls } from "../components/file/FileTargetControls";
import { FileUploadStatus } from "../components/file/FileUploadStatus";
import { FileUploads } from "../components/file/FileUploads";
import { FilesTable } from "../components/file/FilesTable";
import { OcrProcessor } from "../components/ocr/OcrProcessor";
import useFileMachineTranslation from "../useFileMachineTranslation";
import { FileMachineTranslationFormType, useFileMachineTranslationForm } from "../useFileMachineTranslationForm";
import { useFileUploads } from "../useFileUploads";

function FileMachineTranslation() {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const form = useFileMachineTranslationForm();
  const [selectedFile, setSelectedFile] = useState<FileToTranslate | null>(null);
  const [ocrFile, setOcrFile] = useState<FileToTranslate | null>(null);
  const [clientKey, setClientKey] = useState<string>("");
  const [mtProviderType, setMtProviderType] = useState<number>(0);
  const [targetLanguages, setTargetLanguages] = useState<LanguageOption[]>([]);
  const { error, isLoading, fileSourceLanguages, configuration } = useMachineTranslationConfig();
  const { translations, getFileTranslations } = useTranslationFiles();
  const { setTargets, swapLanguages, handleDownload, handleDelete, handleDeleteSelected } = useFileMachineTranslation(
    form,
    configuration,
    setTargetLanguages
  );

  const { uploadOcrTranslation, isUploadingOcr, ocrFileResult, resetOcrFileResult } = useUploadOcrFileMutation(
    clientKey,
    ocrFile
  );

  const { addFile } = useFileUploads();

  useEffect(() => {
    if (ocrFile) {
      uploadOcrTranslation();
    }
  }, [ocrFile, uploadOcrTranslation]);

  useEffect(() => {
    const downloadFileAsync = async (downloadFile: string, isOcr: boolean) => {
      return await handleDownload(downloadFile, "target", isOcr);
    };

    const downloadFile = searchParams.get("d");
    const isOcr = searchParams.get("ocr");
    if (downloadFile) {
      searchParams.delete("d");
      downloadFileAsync(downloadFile, isOcr === "true").then((success) => {
        if (success) toast.success(t("mt.file.downloadSuccessMultiple"));
        else toast.warning(t("mt.file.downloadTargetIssue"));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOcrUpload = (file: FileToTranslate | null) => {
    queryClient.removeQueries({ queryKey: ["ocrParagraphResults"], exact: true });
    setOcrFile(file);
  };

  const handleReturnToFiles = () => {
    resetOcrFileResult();
    getFileTranslations();
  };

  function handleDownloadFiles(downloadType: DownloadOptions): Promise<boolean> {
    if (ocrFileResult?.fileId) return handleDownload(ocrFileResult?.fileId, downloadType, true);
    return Promise.resolve(false);
  }

  const onSubmit: SubmitHandler<FileMachineTranslationFormType> = (values: FileMachineTranslationFormType) => {
    if (!selectedFile) return;
    if (addFile(selectedFile, clientKey, mtProviderType, values.sourceLanguage, values.targetLanguage))
      setSelectedFile(null);
    else toast.warning(t("mt.file.uploadingSameFile"));
  };

  useEffect(() => {
    if (configuration && configuration.length > 0) {
      setClientKey(configuration[0].clientKey);
      setMtProviderType(configuration[0].mtProviderType);
      getFileTranslations();
    }
  }, [configuration, getFileTranslations]);

  const renderLanguageSelection = () => {
    if (selectedFile !== null)
      return (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
            <div className="mt-4">
              <div className="md:flex-wrap lg:flex">
                <div className="min-w-80 flex-1">
                  <FileSourceControls sourceLanguages={fileSourceLanguages} form={form} setTargets={setTargets} />
                </div>
                <div className="mx-2 text-center">
                  <ButtonIcon
                    icon={ArrowLeftRight}
                    className="size-10"
                    disabled={!form.formState.isValid}
                    onClick={() => swapLanguages(form.getValues("sourceLanguage"), form.getValues("targetLanguage"))}
                  />
                </div>
                <div className="min-w-80 flex-1">
                  <FileTargetControls targetLanguages={targetLanguages} form={form} />
                </div>
              </div>
              <div className="mt-4 text-center">
                <Button
                  disabled={!form.formState.isValid}
                  variant="default"
                  data-test="mt-file-translate-btn"
                  className="w-full"
                >
                  {t("mt.file.translate")}
                </Button>
              </div>
            </div>
          </form>
        </Form>
      );
  };

  // Redirect to error page if GET MT config failed
  if (error != null) {
    return <Navigate to={"/error/500"} state={{ message: `mt.file.${error.response?.data}` }} replace={false} />;
  }

  return !ocrFileResult ? (
    <div className="flex flex-col gap-2">
      <div className="md:flex-wrap lg:flex">
        <div className="flex w-full flex-col items-start rounded-md border p-4 sm:items-center">
          <FileUploadStatus />
          <FileUploads
            config={configuration?.[0]}
            isLoading={isLoading || isUploadingOcr}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            handleOcrUpload={handleOcrUpload}
          />
          {renderLanguageSelection()}
        </div>
      </div>
      {translations && translations.length > 0 ? (
        <div className="flex w-full flex-col items-start rounded-md border p-4 sm:items-center">
          <FilesTable
            files={translations?.filter(
              (t: MachineFileTranslationResult) =>
                !t.ocrId || (t.ocrId && t.mtStatus === "finished" && t.statusCode == 200)
            )}
            handleDownload={handleDownload}
            handleDelete={handleDelete}
            handleDeleteSelected={handleDeleteSelected}
            refetch={getFileTranslations}
          />
        </div>
      ) : null}
    </div>
  ) : (
    <div className="md:flex-wrap lg:flex">
      <div className="flex w-full flex-col items-start rounded-md border p-4 sm:items-center">
        <div className="my-2 flex w-full justify-between px-4">
          <Button variant="outline" onClick={handleReturnToFiles} data-test="mt-ocr-return-files">
            <ArrowLeft className="mr-2" />
            {t("mt.file.returnToFiles")}
          </Button>
        </div>
        <OcrProcessor
          file={ocrFileResult}
          resetOcrFileResult={resetOcrFileResult}
          handleDownloadFiles={handleDownloadFiles}
        />
      </div>
    </div>
  );
}

export default FileMachineTranslation;
