import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { SidebarMenuButton, SidebarMenuItem } from "@/components/ui/sidebar";
import { IdName } from "@/model/common";
import { useDivisionQuery } from "@/query/request.query";
import { cn } from "@/utils/ui";
import { ChevronDownIcon, ChevronRightIcon, Loader2Icon, PlusIcon } from "lucide-react";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

interface Props {
  buttonType: "nav" | "hero";
}
export function NewRequestButton({ buttonType }: Props) {
  const { data: divisions = [], isLoading } = useDivisionQuery();

  const navigate = useNavigate();
  const handleOnSelect = useCallback(
    (division: IdName) => {
      const path = `/client/requests/create/${division.id}`;
      navigate(path, { replace: true, state: { skipBlock: true } }); // skipBlock is used to skip the blocker in the useBlocker hook
    },
    [navigate]
  );

  if (isLoading) {
    return <LoadingButton buttonType={buttonType} />;
  }
  if (divisions.length === 0) {
    return null;
  }
  if (divisions.length === 1) {
    return (
      <ButtonTrigger
        buttonType={buttonType}
        isMultiDivision={false}
        isActive={false}
        onClick={() => handleOnSelect(divisions[0])}
      />
    );
  }
  return <MultiDivisionButtonPopover buttonType={buttonType} divisions={divisions} onSelect={handleOnSelect} />;
}
export function LoadingButton({ buttonType }: Props) {
  const isSidebar = buttonType === "nav";
  const { t } = useTranslation();
  if (isSidebar) {
    return (
      <SidebarMenuItem>
        <SidebarMenuButton
          disabled={true}
          className={cn(
            "h-11 rounded-xl px-4 py-1 font-medium active:shadow-inner data-[active=true]:shadow-inner [&>svg]:hover:text-sidebar-primary-foreground [&>svg]:data-[active=true]:text-sidebar-primary-foreground"
          )}
        >
          <Loader2Icon className="mr-1 size-4 animate-spin" />
          <span>{t("common.loading")}</span>
        </SidebarMenuButton>
      </SidebarMenuItem>
    );
  }
  return (
    <Button type="button" disabled={true}>
      <Loader2Icon className="mr-1 size-4 animate-spin" />
      <span>{t("common.loading")}</span>
    </Button>
  );
}

export const ButtonTrigger = forwardRef<
  HTMLButtonElement,
  Props & { isMultiDivision: boolean; isActive: boolean; onClick?: () => void }
>(({ buttonType, isMultiDivision, isActive, onClick }, ref) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isActivePath = location.pathname.includes("/client/requests/create");

  if (buttonType === "nav") {
    return (
      <SidebarMenuItem>
        <SidebarMenuButton
          ref={ref}
          className={cn(
            "h-11 rounded-xl px-4 py-1 font-medium active:shadow-inner data-[active=true]:shadow-inner [&>svg]:hover:text-sidebar-primary-foreground [&>svg]:data-[active=true]:text-sidebar-primary-foreground"
          )}
          isActive={isActivePath || isActive}
          tooltip={t("requests.create.new")}
          data-test="button-newrequest"
          onClick={onClick}
        >
          <PlusIcon />
          {isMultiDivision ? (
            <span className="flex w-full items-center justify-between">
              <span>{t("requests.create.new")}</span>
              <ChevronRightIcon className="float-right size-4 active:text-sidebar-primary-foreground" />
            </span>
          ) : (
            <span>{t("requests.create.new")}</span>
          )}
        </SidebarMenuButton>
      </SidebarMenuItem>
    );
  }

  return (
    <Button ref={ref} type="button" data-test="request-create-button" data-active={isActivePath} onClick={onClick}>
      <PlusIcon className="mr-1 size-4" />
      <span>{t("requests.create.new")}</span>
      {isMultiDivision ? <ChevronDownIcon className="ml-1 size-4" /> : null}
    </Button>
  );
});

export function MultiDivisionButtonPopover({
  buttonType,
  divisions,
  onSelect,
}: Props & { divisions: IdName[]; onSelect: (division: IdName) => void }) {
  const [isOpened, setIsOpened] = useState(false);
  const isSidebar = buttonType === "nav";
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setIsOpened(false);
    };
  }, []);

  const handleSelect = useCallback(
    (option: IdName) => {
      setIsOpened(false);
      // Use requestAnimationFrame to ensure state updates complete before navigation
      requestAnimationFrame(() => {
        onSelect(option);
      });
    },
    [onSelect]
  );

  return (
    <Popover open={isOpened} onOpenChange={setIsOpened}>
      <PopoverTrigger className="group/popover text-sidebar-primary-foreground" asChild>
        <ButtonTrigger buttonType={buttonType} isMultiDivision={true} isActive={isOpened} />
      </PopoverTrigger>
      <PopoverContent
        side={isSidebar ? "right" : "bottom"}
        data-test={"combobox-newrequest-content"}
        className="p-1"
        align="start"
      >
        <Command>
          <CommandList>
            <CommandEmpty data-test={"combobox-newrequest-empty"}>{t("facets.not-found")}</CommandEmpty>
            <CommandGroup data-test={"combobox-newrequest-options"}>
              {divisions.map((option) => {
                return (
                  <CommandItem
                    data-test={`options-option-${option.id}`}
                    key={option.id}
                    className="cursor-pointer aria-selected:bg-sidebar-accent aria-selected:text-sidebar-accent-foreground"
                    onSelect={() => handleSelect(option)}
                  >
                    <span>{option.name}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
