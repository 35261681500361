import { cn } from "@/utils/ui";
import { horizontalListSortingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Cell, flexRender, Row } from "@tanstack/react-table";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
import { TableBody, TableCell, TableRow } from "../../ui/table";

interface DatatableBodyProps<TData> {
  rows: Row<TData>[];
  activeRowId?: string;
  columnOrder: string[];
  onRowClick?: (row: TData) => void;
}

export function DatatableBody<TData>({ rows, activeRowId, columnOrder, onRowClick }: DatatableBodyProps<TData>) {
  return (
    <TableBody className="w-full min-w-full flex-1">
      {rows.length ? (
        rows.map((row) => (
          <DatatableRow
            key={row.id}
            row={row}
            isActiveRow={!!activeRowId && activeRowId.toString() === (row.original as { id: string })?.id?.toString?.()}
            columnOrder={columnOrder}
            onRowClick={onRowClick}
          />
        ))
      ) : (
        <DatatableNoData columnsNumber={columnOrder.length} />
      )}
    </TableBody>
  );
}

interface DatatableRowProps<TData> {
  row: Row<TData>;
  isActiveRow: boolean;
  columnOrder: string[];
  onRowClick?: (row: TData) => void;
}

function DatatableRow<TData>({ row, isActiveRow, columnOrder, onRowClick }: DatatableRowProps<TData>) {
  return (
    <TableRow
      data-state={row.getIsSelected() && "selected"}
      className={clsx({
        "cursor-pointer": !!onRowClick,
        "bg-primary text-secondary hover:bg-primary hover:opacity-80": isActiveRow,
      })}
      onClick={() => {
        onRowClick?.(row.original);
      }}
    >
      {row.getVisibleCells().map((cell) => (
        <SortableContext key={cell.id} items={columnOrder} strategy={horizontalListSortingStrategy}>
          <DatatableCell cell={cell} />
        </SortableContext>
      ))}
    </TableRow>
  );
}

interface DatatableCellProps<TData> {
  cell: Cell<TData, unknown>;
}

function DatatableCell<TData>({ cell }: DatatableCellProps<TData>) {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });
  const style = { transform: CSS.Translate.toString(transform) };

  return (
    <TableCell
      className={cn(
        "transition-transform",
        "whitespace-nowrap",
        `w-[${cell.column.getSize()}]`,
        isDragging ? "opacity-80" : "opacity-100",
        isDragging ? "z-10" : "z-0",
        cell.column.columnDef.meta?.position === "right" ? "text-right" : ""
      )}
      style={style}
      ref={setNodeRef}
    >
      <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
    </TableCell>
  );
}

interface DatatableNoDataProps {
  columnsNumber: number;
}

function DatatableNoData({ columnsNumber }: DatatableNoDataProps) {
  const { t } = useTranslation("translation");

  return (
    <TableRow>
      <TableCell colSpan={columnsNumber} className="h-24 text-center">
        {t("data-table.no-result")}
      </TableCell>
    </TableRow>
  );
}
