import { Button } from "@/components/ui/button";
import { DownloadIcon, Loader2Icon } from "lucide-react";
import { useTranslation } from "react-i18next";

export function DownloadExcelReportButton({
  isLoading,
  disabled,
  onDownload,
}: {
  isLoading: boolean;
  disabled: boolean;
  onDownload: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Button
      data-test="download-excel-report"
      title={t("reports.download")}
      disabled={disabled}
      onClick={() => onDownload()}
    >
      <div className="flex flex-nowrap items-center gap-2">
        {isLoading ? (
          <Loader2Icon data-test="loader-icon" className="size-5 animate-spin" />
        ) : (
          <DownloadIcon data-test="download-icon" className="size-5" />
        )}
        <span>{t("reports.download")}</span>
      </div>
    </Button>
  );
}
