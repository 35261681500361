import { ButtonIcon } from "@/components/button/ButtonIcon";
import { TextareaAutoSize } from "@/components/input/TextArea";
import { FormControl, FormField, FormItem, FormMessage } from "components/ui/form";
import { ClipboardCheck, PlusCircle, RefreshCwIcon, XCircle } from "lucide-react";
import { LanguageOption, MachineTranslationResult } from "model/mt.typing";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LanguageLoader } from "../LanguageLoader";
import { LanguageSelector } from "../LanguageSelector";
import useWorklist from "../../useWorklist";
import useGlossary from "./glossary/useGlossary";
import { toast } from "sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import PortalTooltip from "@/components/PortalTooltip";
import { TextMachineTranslationFormType } from "../../useTextMachineTranslationForm";

interface TextTargetControlsProps {
  isTranslating: boolean;
  targetLanguages: LanguageOption[] | null;
  form: UseFormReturn<TextMachineTranslationFormType>;
  clearText: (areaType: string) => void;
  translationResults: MachineTranslationResult | undefined;
  processTranslation: () => void;
}

export const TextTargetControls = ({
  form,
  targetLanguages,
  clearText,
  isTranslating,
  translationResults,
  processTranslation,
}: TextTargetControlsProps) => {
  const { t } = useTranslation();

  const { terminologyConfiguration, terminologyStoredSettings, updateTerminologyStoredSettings } = useGlossary();

  const copyToClipboard = (value: string | undefined) => {
    if (value) {
      navigator.clipboard.writeText(value).then();
      toast.success(t("mt.copiedToClipboard"));
    }
  };

  const { addItem } = useWorklist();

  const handleRefresh = () => {
    updateTerminologyStoredSettings({ ...terminologyStoredSettings, terminologyChanged: false });
    processTranslation();
  };

  return (
    <div className="mb-6 min-h-80 w-full">
      <FormField
        control={form.control}
        data-test="mt-text-target-languages"
        name="targetLanguage"
        render={({ field }) => (
          <FormItem className="flex w-full flex-col">
            <LanguageSelector
              languages={targetLanguages}
              placeholder={t("mt.selectLanguage")}
              field={field}
              onSelect={(language: LanguageOption) => {
                if (language?.code != form.getValues("targetLanguage")?.code) {
                  // Whenever the language changes, clear the previous translation
                  clearText("");
                  form.setValue("targetLanguage", language);
                  localStorage.setItem("mtLastTarget", JSON.stringify(language));
                } else {
                  // Clear the form element and reset the targets
                  form.setValue("targetLanguage", undefined);
                }
              }}
            />
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="targetText"
        render={({ field }) => (
          <FormItem className="flex size-full flex-col pt-4">
            <FormControl>
              {!isTranslating ? (
                <div className="relative">
                  {field.value && (
                    <TooltipProvider delayDuration={0}>
                      <PortalTooltip text={t("mt.clearTargetText")} side="left">
                        <ButtonIcon
                          data-test="mt-text-clearTargetText"
                          icon={XCircle}
                          onClick={() => clearText("targetText")}
                          className="absolute right-2 top-2 animate-in fade-in slide-in-from-right-2 slide-in-from-top-2"
                        />
                      </PortalTooltip>
                      <PortalTooltip text={t("mt.copyToClipboard")} side="left">
                        <ButtonIcon
                          data-test="mt-text-copyTargetTextToClipboard"
                          className="absolute right-2 top-12"
                          icon={ClipboardCheck}
                          onClick={() => copyToClipboard(field.value)}
                        />
                      </PortalTooltip>
                      <PortalTooltip text={t("mt.addToWorklist")} side="left">
                        <ButtonIcon
                          data-test="mt-text-addToWorklist"
                          className="absolute right-2 top-[74px]"
                          icon={PlusCircle}
                          onClick={() => translationResults && addItem(translationResults)}
                        />
                      </PortalTooltip>
                      {terminologyConfiguration.terminologyAvailable &&
                        terminologyStoredSettings.terminologyChanged && (
                          <PortalTooltip text={t("mt.refreshTranslation")} side="left">
                            <ButtonIcon
                              data-test="mt-text-refreshTranslation"
                              className="absolute right-2 top-[98px]"
                              icon={RefreshCwIcon}
                              onClick={() => handleRefresh()}
                            />
                          </PortalTooltip>
                        )}
                    </TooltipProvider>
                  )}
                  <TextareaAutoSize
                    {...field}
                    value={field.value}
                    data-test="mt-target-text"
                    dir="auto"
                    onChange={(e) => {
                      form.setValue("targetText", e.target.value);
                    }}
                  />
                </div>
              ) : (
                <LanguageLoader />
              )}
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
