import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DownloadOptions } from "@/model/mt.typing";
import { DownloadIcon, Loader2Icon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

type OptionType = "ocr" | "vanilla" | "universal" | "ocrAbbyy";

interface DropdownOption {
  type: DownloadOptions;
  text: string;
  optionType: OptionType;
}

interface FileActionsDownloadProps {
  isOcr: boolean;
  isRecentActivity: boolean;
  engineId: number;
  onDownload: (type: DownloadOptions) => Promise<boolean>;
}

export function FileActionsDownload({ isOcr, engineId, onDownload, isRecentActivity }: FileActionsDownloadProps) {
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const isAbbyy = engineId === 2;

  const dropdownItems: DropdownOption[] = [
    {
      type: "source",
      text: t("mt.file.sourceFile"),
      optionType: "universal",
    },
    {
      type: "target",
      text: t("mt.file.targetFile"),
      optionType: "universal",
    },
    {
      type: "both",
      text: t("mt.file.both"),
      optionType: "vanilla",
    },
    {
      type: "sourceText",
      text: t("mt.file.sourceText"),
      optionType: "ocr",
    },
    {
      type: "targetText",
      text: t("mt.file.targetText"),
      optionType: "ocr",
    },
    {
      type: "all",
      text: t("mt.file.all"),
      optionType: "ocr",
    },
    {
      type: "source",
      text: t("mt.file.originalFile"),
      optionType: "ocrAbbyy",
    },
    {
      type: "original",
      text: t("mt.file.sourceFile"),
      optionType: "ocrAbbyy",
    },
    {
      type: "target",
      text: t("mt.file.targetFile"),
      optionType: "ocrAbbyy",
    },
    {
      type: "all",
      text: t("mt.file.all"),
      optionType: "ocrAbbyy",
    },
  ];

  const handleDownloadFile = async (type: DownloadOptions) => {
    setIsDownloading(true);
    try {
      await onDownload(type);
      toast.success(
        type === "both" || type === "all" ? t("mt.file.downloadSuccessMultiple") : t("mt.file.downloadSuccess")
      );
    } catch {
      toast.error(t("mt.file.downloadError"));
    } finally {
      setIsDownloading(false);
    }
  };

  const getDropdownItems = () => {
    if (isAbbyy) {
      return dropdownItems.filter((x) => x.optionType === "ocrAbbyy");
    }
    if (isOcr) {
      return dropdownItems.filter((x) => x.optionType !== "vanilla" && x.optionType !== "ocrAbbyy");
    }
    return dropdownItems.filter((x) => x.optionType !== "ocr" && x.optionType !== "ocrAbbyy");
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button disabled={isDownloading} variant={!isRecentActivity ? "outline" : "icon"} size={"icon"}>
          {isDownloading ? <Loader2Icon className="animate-spin" /> : <DownloadIcon className="size-5" />}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {getDropdownItems().map((item) => (
          <DropdownMenuItem
            key={item.type}
            onClick={(e) => {
              e.stopPropagation();
              handleDownloadFile(item.type);
            }}
          >
            {item.text}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
