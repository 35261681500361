import { cn } from "@/utils/ui";
import { LucideIcon, MinusIcon } from "lucide-react";

type EmptyDataPlaceholderProps = React.HTMLAttributes<HTMLSpanElement> & {
  iconSize?: number;
};

export function EmptyDataPlaceholder({ className, iconSize = 24, ...props }: EmptyDataPlaceholderProps) {
  return (
    <span data-test="empty-placeholder" className={cn("opacity-10", className)} {...props}>
      <MinusIcon width={iconSize} height={iconSize} />
    </span>
  );
}

type EmptyPlaceholderProps = React.HTMLAttributes<HTMLDivElement>;

export function EmptyPlaceholder({ className, children, ...props }: EmptyPlaceholderProps) {
  return (
    <div
      data-test="empty-placeholder"
      className={cn(
        "flex min-h-[200px] flex-col items-center justify-center rounded-md border border-dashed p-8 text-center animate-in fade-in-50",
        className
      )}
      {...props}
    >
      <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">{children}</div>
    </div>
  );
}

interface EmptyPlaceholderIconProps extends Partial<React.SVGProps<SVGSVGElement>> {
  icon: LucideIcon;
}

EmptyPlaceholder.Icon = function EmptyPlaceHolderIcon({ icon: Icon, className, ...props }: EmptyPlaceholderIconProps) {
  if (!Icon) {
    return null;
  }

  return (
    <div className="flex size-20 items-center justify-center rounded-full bg-muted">
      <Icon data-test="empty-placeholder-icon" className={cn("h-10 w-10", className)} {...props} />
    </div>
  );
};

type EmptyPlacholderTitleProps = React.HTMLAttributes<HTMLHeadingElement>;

EmptyPlaceholder.Title = function EmptyPlaceholderTitle({ className, ...props }: EmptyPlacholderTitleProps) {
  return <h2 data-test="empty-placeholder-title" className={cn("mt-6 text-xl font-semibold", className)} {...props} />;
};

type EmptyPlacholderDescriptionProps = React.HTMLAttributes<HTMLParagraphElement>;

EmptyPlaceholder.Description = function EmptyPlaceholderDescription({
  className,
  ...props
}: EmptyPlacholderDescriptionProps) {
  return (
    <p
      data-test="empty-placeholder-description"
      className={cn("mb-8 mt-2 text-center text-sm font-normal leading-6 text-muted-foreground", className)}
      {...props}
    />
  );
};
