import { Combobox } from "@/components/combobox/Combobox";
import { ComboboxSingleSelection } from "@/components/combobox/ComboboxSingleSelection";
import { DEFAULT_SORT_FIELD_CONFIG, SortControl } from "@/components/sorting/SortControl";
import { useSorting } from "@/components/sorting/useSorting";
import { IdName } from "@/model/common";
import { LanguageSortByType } from "@/model/request.typing";
import { LanguageInfoDisplay } from "@/pages/reports/common/LanguageInfoDisplay";
import { LanguagesIcon } from "lucide-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface LanguageFilterProps {
  placeholder?: string;
  inError?: boolean;
  hideCount?: boolean;
  enableSorting?: boolean;
  customValueLabel?: string;
  isShowIcon?: boolean;
  testId: string;
  title: string;
  options: IdName[];
  formatCount?: (count: number) => string;
}

interface SingleLanguageFilterProps extends LanguageFilterProps {
  toggleSelection?: boolean; // set to true to allow deselecting the selected language
  selectedLanguageCode: string | null | undefined;
  onChange: (selectedLanguageCode: string | null) => void;
}
interface MultipleLanguageFilterProps extends LanguageFilterProps {
  selectedLanguageCode: string[];
  onChange: (selectedLanguageCodes: string[]) => void;
}

export function SingleLanguageFilter({
  inError,
  testId,
  title,
  selectedLanguageCode,
  customValueLabel,
  placeholder,
  options,
  isShowIcon = true,
  toggleSelection = false,
  enableSorting = false,
  hideCount,
  formatCount,
  onChange,
}: SingleLanguageFilterProps) {
  const languageOptions = useMapToLanguage(enableSorting, options);
  return (
    <ComboboxSingleSelection<IdName, LanguageSortByType>
      testId={testId}
      inError={inError}
      icon={isShowIcon ? LanguagesIcon : null}
      title={title}
      hideCount={hideCount}
      customValueLabel={customValueLabel}
      placeholder={placeholder}
      options={languageOptions}
      selectedId={selectedLanguageCode}
      onChange={(value) => {
        if (toggleSelection) {
          onChange(value?.id ?? null);
        } else if (value?.id) {
          onChange(value.id);
        }
      }}
      formatCount={formatCount}
      renderSelected={(option) => (
        <LanguageInfoDisplay className="text-sm font-normal" code={option.id} name={option.name} />
      )}
      renderOption={(option) => <LanguageInfoDisplay code={option.id} name={option.name} />}
      sortOptions={
        enableSorting
          ? {
              defaultSort: { field: "frequency", direction: "desc" },
              renderSortControl: () => <LanguageFilterSortHeader testId={testId} />,
            }
          : undefined
      }
    />
  );
}

export function MultipleLanguageFilter({
  testId,
  inError,
  title,
  options,
  customValueLabel,
  selectedLanguageCode = [],
  isShowIcon = true,
  enableSorting = false,
  hideCount,
  placeholder,
  formatCount,
  onChange,
}: MultipleLanguageFilterProps) {
  const languageOptions = useMapToLanguage(enableSorting, options);

  return (
    <Combobox<IdName, LanguageSortByType>
      testId={testId}
      inError={inError}
      icon={isShowIcon ? LanguagesIcon : null}
      title={title}
      hideCount={hideCount}
      options={languageOptions}
      placeholder={placeholder}
      customValueLabel={customValueLabel}
      selectedIds={selectedLanguageCode}
      onChange={(values) => onChange(values.map((value) => value.id))}
      formatCount={formatCount}
      renderSelected={(option) => (
        <LanguageInfoDisplay className="text-sm font-normal" code={option.id} name={option.name} />
      )}
      renderOption={(option) => <LanguageInfoDisplay code={option.id} name={option.name} />}
      sortOptions={
        enableSorting
          ? {
              defaultSort: { field: "frequency", direction: "desc" },
              renderSortControl: () => <LanguageFilterSortHeader testId={testId} />,
            }
          : undefined
      }
    />
  );
}

const useMapToLanguage = (enableSorting: boolean, options: IdName[]) => {
  return useMemo(() => {
    if (!enableSorting) return options;
    const hasFrequency = options.some((option) => "frequency" in option);
    const hasCount = options.some((option) => option.count !== undefined);
    if (!hasFrequency && hasCount) {
      return options.map((option) => ({ ...option, frequency: option.count ?? 0, qeReady: false }));
    }
    return options;
  }, [enableSorting, options]);
};

export const LanguageFilterSortHeader = ({ testId }: { testId: string }) => {
  const { t } = useTranslation();
  const { sortState, updateSort } = useSorting<IdName, LanguageSortByType>({
    id: testId,
    defaultSort: { field: "frequency", direction: "desc" },
  });
  return (
    <div className="flex w-full flex-row items-center gap-2 px-2">
      <div className="flex-1">
        <span data-test={`combobox-${testId}-sort-name`} className="text-xs font-semibold leading-4">
          {t("common.selector.languages.sorted")}
          {!sortState?.field ? "" : t(`common.selector.languages.${sortState?.field}`)}
        </span>
      </div>

      <SortControl
        testId={testId}
        currentSort={sortState!}
        onSortChange={updateSort}
        fields={[DEFAULT_SORT_FIELD_CONFIG.frequency, DEFAULT_SORT_FIELD_CONFIG.name]}
      />
    </div>
  );
};
