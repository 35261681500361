import { useDateFormatter } from "@/hooks/useDate";
import type { ProjectCostPerLanguagesType } from "@/model/report.typing";
import { maxBy, minBy } from "lodash";
import { useTranslation } from "react-i18next";
import { useCostReportFilters } from "../reportStore";

export function useChartDescription(
  reportType: string,
  canIncludeOpenProjects: boolean,
  pricePerPairLanguages: ProjectCostPerLanguagesType[]
) {
  const { t } = useTranslation();
  const { formatDate } = useDateFormatter();
  const filters = useCostReportFilters();

  const fromDate = filters.deliveryDate?.from ?? minBy(pricePerPairLanguages, "deliveryDate")?.deliveryDate;
  const toDate = filters.deliveryDate?.to ?? maxBy(pricePerPairLanguages, "deliveryDate")?.deliveryDate;

  const description = filters.deliveryDate?.to
    ? t("reports.charts.description.completed-between", { from: formatDate(fromDate), to: formatDate(toDate) })
    : !canIncludeOpenProjects || !filters.includeOpenProjects
      ? t("reports.charts.description.completed-since", { from: formatDate(fromDate) })
      : t("reports.charts.description.projects-since", { from: formatDate(fromDate) });

  const title = t(`reports.charts.${reportType}`, {
    currency: filters.currencyId,
  });

  return { description, title };
}
