import { useQueryClient } from "@tanstack/react-query";
import { Alert, AlertDescription } from "components/ui/alert";
import { Progress } from "components/ui/progress";
import type { DownloadOptions } from "model/mt.typing";
import { useTranslationFile } from "query/mt/machinetranslation.query";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import useFileActions from "../../useFileActions";
import { FileActionsDelete } from "./FileActionsDelete";
import { FileActionsDownload } from "./FileActionsDownload";

interface FileStatusProps {
  id: string;
  status: string;
  code: number | null;
  uploadDate: Date;
  fileKey: string;
  fileName: string;
  isOcr: boolean;
  isRecentActivity: boolean;
  engineId: number;
  handleDownload: (fileKey: string, type: DownloadOptions, isOcr: boolean) => Promise<boolean>;
  handleDelete: (id: string) => Promise<boolean>;
}

export const FileActions = (props: FileStatusProps) => {
  const { t } = useTranslation();
  const {
    id,
    status,
    code,
    uploadDate,
    fileKey,
    fileName,
    handleDownload,
    handleDelete,
    isOcr,
    engineId,
    isRecentActivity,
  } = props;
  const [isError, setIsError] = useState<boolean>(false);

  const { checkForError, refreshNeeded } = useFileActions(code, uploadDate);
  const { getFileTranslation } = useTranslationFile(fileKey);

  const checkFileStatus = useCallback(() => {
    if (status) {
      setIsError(checkForError(status));
    }
  }, [checkForError, status]);

  useEffect(() => {
    checkFileStatus();
  }, [checkFileStatus]);

  const queryClient = useQueryClient();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isOcr && refreshNeeded(status)) {
        getFileTranslation().then((response) => {
          if (response.data?.mtStatus === "finished") {
            queryClient.invalidateQueries({
              queryKey: ["translationFiles"],
            });

            toast.success(t("mt.file.fileReady", { filename: response.data?.filename }), {
              style: {
                maxWidth: 640,
              },
            });
          }
        });
      } else {
        clearInterval(intervalId);
      }
    }, 10 * 1000); // 10 seconds in milliseconds

    return () => clearInterval(intervalId);
  }, [fileKey, refreshNeeded, t, queryClient, fileName, getFileTranslation, status, isOcr]);

  if (isError)
    return (
      <Alert variant="warning" className="max-w-fit py-1">
        <AlertDescription>{t("mt.uhOh")}</AlertDescription>
      </Alert>
    );

  if (isRecentActivity) {
    return (
      <div className="flex items-center gap-1">
        <FileActionsDownload
          isRecentActivity={isRecentActivity}
          isOcr={isOcr}
          engineId={engineId}
          onDownload={(type) => handleDownload(fileKey, type, isOcr)}
        />
      </div>
    );
  }

  switch (status) {
    case "new":
      return <Progress isInfinite={true} className="max-w-xs" />;
    case "analyzed":
    case "finished":
      return (
        <div className="flex items-center gap-1">
          <FileActionsDownload
            isRecentActivity={isRecentActivity}
            isOcr={isOcr}
            engineId={engineId}
            onDownload={(type) => handleDownload(fileKey, type, isOcr)}
          />
          <FileActionsDelete
            onDelete={() => handleDelete(id)}
            onSuccess={() => queryClient.invalidateQueries({ queryKey: ["translationFiles"] })}
          />
        </div>
      );
    default:
      return null;
  }
};
