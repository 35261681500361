import errorDa from "./da_DK/error.json";
import translationDa from "./da_DK/translation.json";
import errorDe from "./de_DE/error.json";
import translationDe from "./de_DE/translation.json";
import errorEn from "./en_US/error.json";
import translationEn from "./en_US/translation.json";
import errorEs from "./es_ES/error.json";
import translationEs from "./es_ES/translation.json";
import errorFi from "./fi_FI/error.json";
import translationFi from "./fi_FI/translation.json";
import errorFr from "./fr_FR/error.json";
import translationFr from "./fr_FR/translation.json";
import errorIt from "./it_IT/error.json";
import translationIt from "./it_IT/translation.json";
import errorJa from "./ja_JP/error.json";
import translationJa from "./ja_JP/translation.json";
import errorKo from "./ko_KR/error.json";
import translationKo from "./ko_KR/translation.json";
import errorNb from "./nb_NO/error.json";
import translationNb from "./nb_NO/translation.json";
import errorNl from "./nl_NL/error.json";
import translationNl from "./nl_NL/translation.json";
import errorPt from "./pt_PT/error.json";
import translationPt from "./pt_PT/translation.json";
import errorSv from "./sv_SE/error.json";
import translationSv from "./sv_SE/translation.json";
import errorZhCn from "./zh_CN/error.json";
import translationZhCn from "./zh_CN/translation.json";
import errorZhTw from "./zh_TW/error.json";
import translationZhTw from "./zh_TW/translation.json";

export const languages = {
  "en-US": { translation: translationEn, error: errorEn },
  "fr-FR": { translation: translationFr, error: errorFr },
  "de-DE": { translation: translationDe, error: errorDe },
  "da-DK": { translation: translationDa, error: errorDa },
  "es-ES": { translation: translationEs, error: errorEs },
  "fi-FI": { translation: translationFi, error: errorFi },
  "it-IT": { translation: translationIt, error: errorIt },
  "ja-JP": { translation: translationJa, error: errorJa },
  "ko-KR": { translation: translationKo, error: errorKo },
  "nl-NL": { translation: translationNl, error: errorNl },
  "nb-NO": { translation: translationNb, error: errorNb },
  "pt-BR": { translation: translationPt, error: errorPt },
  "pt-PT": { translation: translationPt, error: errorPt },
  "sv-SE": { translation: translationSv, error: errorSv },
  "zh-Hant": { translation: translationZhCn, error: errorZhCn },
  "zh-Hans": { translation: translationZhTw, error: errorZhTw },
};
