import { downloadActivity } from "api/machinetranslation.api";
import { t } from "i18next";
import { toast } from "sonner";

function useDownloadActivity() {
  async function downloadActivityReport(startDate: string, endDate: string, isDownloadActivity: boolean) {
    const result = await downloadActivity(startDate, endDate, isDownloadActivity);
    if (result) {
      const link = document.createElement("a");
      link.href = `data:${result.contentType};base64,${result.fileContents}`;
      link.setAttribute("download", result.fileDownloadName);
      document.body.appendChild(link);
      link.click();
    } else toast.error(t("mt.file.downloadError"));
  }

  return { downloadActivityReport };
}

export default useDownloadActivity;
