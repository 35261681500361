import { MultipleLanguageFilter, SingleLanguageFilter } from "@/components/filters/LanguageFilters";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { IdName } from "@/model/common";
import { useMostRecentLanguageQuery } from "@/query/request.query";
import { bytesToSize } from "@/utils/file";
import { sortAlphabeticallyCompareFn } from "@/utils/text";
import { cn } from "@/utils/ui";
import { ChevronDown, ChevronRight, DotIcon, MinusCircleIcon, RefreshCcw } from "lucide-react";
import { useMemo, useState } from "react";
import { FieldErrors, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import UploadFileZone, { useNotifyFileRejected } from "./common/UploadFileZone";
import { useManagedFilesToBeUploaded } from "./fileUpload";
import { NewRequestFormType, SourceFile } from "./useNewRequestForm";
import { DivisionType } from "@/model/request.typing";

interface Props {
  division: DivisionType;
  requestIdentifier: string;
  form: UseFormReturn<NewRequestFormType>;
}

export function UploadFilesStep({ division, requestIdentifier, form }: Props) {
  const { t } = useTranslation("translation");
  const notifyFileRejected = useNotifyFileRejected();
  const sourceOptions = useMostRecentLanguageQuery("source");
  const targetOptions = useMostRecentLanguageQuery("target");

  const { updateFile, removeFiles, fileByGroup, uploadFiles, retryUpload } = useManagedFilesToBeUploaded(
    { divisionId: division.id, requestIdentifier, uploadToS3: division.useAmazonS3UploadWithAntivirus },
    form,
    notifyFileRejected
  );

  const totalFileSize = Object.values(fileByGroup).reduce(
    (acc, files) => acc + files.reduce((acc, file) => acc + file.file.size, 0),
    0
  );
  const errors = Object.entries(form.formState.errors)
    .map(([key, value]) => {
      if (key !== "sourceFiles") {
        // return nothing
        return null;
      }
      if (Array.isArray(value) && value.length > 0) {
        const values = value.filter(Boolean);
        const message =
          values?.[0]?.sourceLanguageCode?.message ?? values?.[0]?.targetLanguageCodes?.message ?? "sourceFiles";
        return message as string;
      } else {
        return key;
      }
    })
    .filter(Boolean);

  return (
    <>
      <div className="flex justify-center p-6 text-2xl font-semibold leading-7">
        {t("requests.create.upload.upload-files-translation")}
      </div>
      <div className="mx-auto w-full max-w-7xl">
        {errors.length > 0 ? (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription className="text-sm font-medium">
              {errors.map((message) => t(`requests.create.errors.${message}`))}
            </AlertDescription>
          </Alert>
        ) : null}
        <UploadFileZone variant="dropzone" totalFileSize={totalFileSize} uploadFiles={uploadFiles} />
        <UploadTable
          fileByGroup={fileByGroup}
          removeFiles={removeFiles}
          sourceLanguages={sourceOptions}
          targetLanguages={targetOptions}
          updateFile={updateFile}
          retryUpload={retryUpload}
          errors={form.formState.errors}
        />
      </div>
    </>
  );
}

function UploadTable({
  fileByGroup,
  sourceLanguages,
  targetLanguages,
  errors,
  updateFile,
  removeFiles,
  retryUpload,
}: {
  fileByGroup: Record<string, SourceFile[]>;
  sourceLanguages: IdName[];
  targetLanguages: IdName[];
  removeFiles: (fileId: string | string[]) => void;
  updateFile: (fileId: string, file: Partial<SourceFile>) => void;
  retryUpload: (fileId: string) => void;
  errors: FieldErrors<NewRequestFormType>;
}) {
  const { t } = useTranslation("translation", { keyPrefix: "requests.create.upload" });
  const groups = Object.keys(fileByGroup).filter((group) => group !== "default");
  const files = fileByGroup["default"] ?? [];

  // all except the default group
  const fileCountInGroups = groups.reduce((acc, group) => acc + fileByGroup[group].length, 0);

  const getGroupStartIndex = (currentIndex: number) => {
    if (currentIndex === 0) return 0;
    return groups.reduce((acc, group, index) => {
      if (index < currentIndex) {
        return acc + fileByGroup[group].length;
      }
      return acc;
    }, 0);
  };

  if (groups.length === 0 && files.length === 0) return null;

  return (
    <>
      <h2 className="py-4 text-lg font-semibold">{t("assignLanguages")}</h2>

      <div className="rounded-lg border dark:border-border">
        <Table className="w-full xl:table-fixed" data-test="table-source-files">
          <TableHeader className="bg-neutral-100 text-sm font-medium text-neutral-600 dark:bg-background dark:text-foreground">
            <TableRow className="hover:bg-transparent">
              <TableHead className="w-3/12">{t("filename")}</TableHead>
              <TableHead className="w-4/12">{t("sourceLanguage.title")}</TableHead>
              <TableHead className="w-4/12">{t("targetLanguages.title")}</TableHead>
              <TableHead />
            </TableRow>
          </TableHeader>

          <TableBody>
            {groups.map((group, index) => {
              const groupStartIndex = getGroupStartIndex(index);
              return (
                <GroupRow
                  key={group}
                  group={group}
                  startIndex={groupStartIndex}
                  sourceFiles={fileByGroup[group]}
                  sourceLanguages={sourceLanguages}
                  targetLanguages={targetLanguages}
                  removeFiles={removeFiles}
                  updateFile={updateFile}
                  retryUpload={retryUpload}
                  errors={errors}
                />
              );
            })}
            {files.map((file, index) => (
              <FileRow
                key={index}
                index={fileCountInGroups + index}
                file={file}
                sourceLanguages={sourceLanguages}
                targetLanguages={targetLanguages}
                removeFile={removeFiles}
                updateFile={updateFile}
                retryUpload={retryUpload}
                errors={errors}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
}

function GroupRow({
  startIndex,
  group,
  sourceFiles,
  sourceLanguages,
  targetLanguages,
  removeFiles,
  updateFile,
  retryUpload,
  errors,
}: {
  startIndex: number;
  group: string;
  sourceFiles: SourceFile[];
  sourceLanguages: IdName[];
  targetLanguages: IdName[];
  removeFiles: (fileId: string | string[]) => void;
  updateFile: (fileId: string, file: Partial<SourceFile>) => void;
  retryUpload: (fileId: string) => void;
  errors: FieldErrors<NewRequestFormType>;
}) {
  const expandIfError = useMemo(() => {
    const sourceFileErrors = (errors?.sourceFiles ?? []) as FieldErrors<SourceFile>[];
    if (!Array.isArray(sourceFileErrors)) return false;

    const errorMessageIndexes = sourceFileErrors
      .map((error, index) => {
        return error ? index : null;
      })
      .filter(Boolean) as number[];
    const result = errorMessageIndexes.some((index) => {
      return index >= startIndex && index < startIndex + sourceFiles.length;
    });
    return result;
  }, [errors, sourceFiles, startIndex]);

  const [expanded, setExpanded] = useState(expandIfError);
  const { t } = useTranslation("translation", { keyPrefix: "requests.create.upload" });

  const sameTargetLanguages = sourceFiles.every((file) => {
    return (
      file.targetLanguageCodes?.sort(sortAlphabeticallyCompareFn).join() ===
      sourceFiles[0].targetLanguageCodes?.sort(sortAlphabeticallyCompareFn).join()
    );
  });

  const sameSourceLanguages = sourceFiles.every(
    (file) => file.sourceLanguageCode === sourceFiles[0].sourceLanguageCode
  );

  const canRemoveFiles = sourceFiles.every((file) => file.status !== "planned");

  return (
    <>
      <TableRow
        className={cn("group", {
          "bg-neutral-100 text-neutral-600 dark:bg-background dark:text-foreground": expanded,
        })}
      >
        <TableCell>
          <div className="flex flex-row justify-normal gap-2">
            {expanded ? (
              <ChevronDown
                role="button"
                className="size-4 shrink-0 grow-0 cursor-pointer"
                onClick={() => setExpanded(!expanded)}
              />
            ) : (
              <ChevronRight
                role="button"
                className="size-4 shrink-0 grow-0 cursor-pointer"
                onClick={() => setExpanded(!expanded)}
              />
            )}

            <div className="flex flex-1 flex-col items-start justify-center">
              <button
                type="button"
                className="cursor-pointer select-none font-medium hover:underline"
                onClick={() => setExpanded(!expanded)}
              >
                {group}
              </button>
              <div className="flex select-none flex-nowrap items-center gap-1 text-xs text-muted-foreground">
                <span className="whitespace-nowrap">
                  {sourceFiles.length} {t("files")}
                </span>
                <DotIcon className="size-4" />
                <span className="whitespace-nowrap">
                  {bytesToSize(sourceFiles.reduce((acc, file) => acc + file.file.size, 0))}
                </span>
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <SingleLanguageFilter
            selectedLanguageCode={sameSourceLanguages ? sourceFiles[0].sourceLanguageCode : null}
            customValueLabel={sameSourceLanguages ? undefined : t("customSelection")}
            placeholder={t("sourceLanguage.placeholder")}
            onChange={(sourceLanguageCode) => {
              sourceFiles.forEach((file) => {
                updateFile(file.key, { sourceLanguageCode });
              });
            }}
            enableSorting={true}
            isShowIcon={false}
            testId={"sourceLanguage"}
            title={t("sourceLanguage.label")}
            options={sourceLanguages}
          />
        </TableCell>
        <TableCell>
          <MultipleLanguageFilter
            selectedLanguageCode={sameTargetLanguages ? sourceFiles[0].targetLanguageCodes : []}
            customValueLabel={sameTargetLanguages ? undefined : t("customSelection")}
            placeholder={t("targetLanguages.placeholder")}
            onChange={(targetLanguageCodes) => {
              sourceFiles.forEach((file) => {
                updateFile(file.key, { targetLanguageCodes });
              });
            }}
            enableSorting={true}
            isShowIcon={false}
            testId={"targetLanguages"}
            title={t("targetLanguages.label")}
            options={targetLanguages}
          />
        </TableCell>
        <TableCell>
          <Button
            type="button"
            variant="link"
            size="icon"
            className={cn("invisible size-5", { "group-hover:visible": canRemoveFiles })}
            onClick={() => removeFiles(sourceFiles.map((file) => file.key))}
          >
            <MinusCircleIcon className="size-full shrink-0 text-neutral-400 dark:text-muted-foreground" />
          </Button>
        </TableCell>
      </TableRow>

      {sourceFiles.map((file, fileIndex) => (
        <FileRow
          key={fileIndex}
          file={file}
          index={startIndex + fileIndex}
          errors={errors}
          status={expanded ? "expanded" : "collapsed"}
          sourceLanguages={sourceLanguages}
          targetLanguages={targetLanguages}
          removeFile={removeFiles}
          updateFile={updateFile}
          retryUpload={retryUpload}
        />
      ))}
    </>
  );
}

function FileRow({
  index,
  errors,
  file,
  status = "none",
  sourceLanguages,
  targetLanguages,
  removeFile,
  updateFile,
  retryUpload,
}: {
  index: number;
  status?: "expanded" | "collapsed" | "none";
  file: SourceFile;
  sourceLanguages: IdName[];
  targetLanguages: IdName[];
  errors: FieldErrors<NewRequestFormType>;
  removeFile: (fileId: string) => void;
  updateFile: (fileId: string, file: Partial<SourceFile>) => void;
  retryUpload: (fileId: string) => void;
}) {
  const { t } = useTranslation("translation", { keyPrefix: "requests.create.upload" });

  const isOpen = status === "expanded";
  const isVisible = status === "none" || isOpen;
  const hasSourceLanguageError = errors?.sourceFiles?.[index]?.sourceLanguageCode?.message;
  const hasTargetLanguageError = errors?.sourceFiles?.[index]?.targetLanguageCodes?.message;

  return (
    <TableRow
      data-state={isVisible ? "open" : "closed"}
      className={cn(
        "group transition-all data-[state=closed]:hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
      )}
    >
      <TableCell>
        <div
          className={cn("flex flex-1 flex-col", {
            "ml-4 border-l-2 border-neutral-300": isOpen,
          })}
        >
          <span className={cn("truncate font-medium", { "ml-2": isOpen })} title={file.file.name}>
            {file.file.name}
          </span>

          <div className={cn("flex flex-nowrap items-center gap-1 text-xs text-muted-foreground", { "ml-2": isOpen })}>
            <span className="whitespace-nowrap">{bytesToSize(file.file.size)}</span>
            {["failed", "canceled", "waiting", "planned", "uploading"].includes(file.status) && (
              <DotIcon className="size-4" />
            )}
            <div className="whitespace-nowrap">
              {file.status === "uploading" && (
                <span data-test={`upload-dialog-file-${file.file.name}-progress`}>{file.progression}%</span>
              )}
              {["failed", "canceled", "waiting", "planned"].includes(file.status) && (
                <div
                  data-test={`upload-dialog-file-${file.file.name}-status`}
                  className={cn(
                    "flex items-center space-x-2 text-xs",
                    ["waiting", "planned"].includes(file.status)
                      ? "text-orange-500"
                      : "text-red-600 dark:text-red-400/90"
                  )}
                >
                  {t(`status.${file.status === "planned" ? "waiting" : file.status}`)}
                </div>
              )}
            </div>
          </div>
          {file.status === "uploading" && (
            <div
              className="h-1 w-full rounded-full bg-gray-200"
              data-test={`upload-dialog-file-${file.file.name}-progressbar`}
            >
              <div
                className="h-1 rounded-full bg-blue-300"
                data-test={`upload-dialog-file-${file.file.name}-progressbar-value`}
                style={{ width: `${file.progression}%` }}
              ></div>
            </div>
          )}
        </div>
      </TableCell>
      <TableCell>
        <SingleLanguageFilter
          isShowIcon={false}
          enableSorting={true}
          inError={!!hasSourceLanguageError}
          selectedLanguageCode={file.sourceLanguageCode}
          placeholder={t("sourceLanguage.placeholder")}
          testId={"sourceLanguage"}
          title={t("sourceLanguage.label")}
          options={sourceLanguages}
          onChange={(sourceLanguageCode) => {
            updateFile(file.key, { sourceLanguageCode });
          }}
        />
      </TableCell>
      <TableCell>
        <MultipleLanguageFilter
          isShowIcon={false}
          enableSorting={true}
          inError={!!hasTargetLanguageError}
          selectedLanguageCode={file.targetLanguageCodes ?? []}
          placeholder={t("targetLanguages.placeholder")}
          title={t("targetLanguages.label")}
          testId={"targetLanguages"}
          options={targetLanguages}
          onChange={(targetLanguageCodes) => {
            updateFile(file.key, { targetLanguageCodes });
          }}
        />
      </TableCell>
      <TableCell className="flex items-center justify-end gap-2">
        {file.status === "failed" && (
          <Button
            type="button"
            variant="link"
            size="icon"
            className="size-5"
            onClick={() => retryUpload(file.id)}
            title={t("common.retry")}
          >
            <RefreshCcw className="size-full shrink-0 text-neutral-400 dark:text-muted-foreground" />
          </Button>
        )}
        <Button
          type="button"
          variant="link"
          size="icon"
          className={cn("group invisible size-5", { "group-hover:visible": file.status !== "planned" })}
          onClick={() => removeFile(file.key)}
        >
          <MinusCircleIcon className="size-full shrink-0 text-neutral-400 dark:text-muted-foreground" />
        </Button>
      </TableCell>
    </TableRow>
  );
}
