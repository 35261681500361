import { IdName } from "@/model/common";
import type {
  AnalysisType,
  AnalysisTypeAPI,
  BaseRequestType,
  ContactTypeAPI,
  CreateRequestTypeAPI,
  CustomField,
  CustomFieldValue,
  CustomFieldValueType,
  DeliveredRequestType,
  DivisionType,
  InProgressRequestType,
  LanguageType,
  LanguageTypeAPI,
  MostRecentAPI,
  MostRecentLanguage,
  NewRequestType,
  ProjectDocument,
  ProjectDocumentAPI,
  QuoteTypeKeyAPI,
  RequestDetailType,
  RequestDetailTypeAPI,
  RequestDivisionTypeAPI,
  RequestDocumentAPI,
  RequestFinancialType,
  RequestFinancialTypeAPI,
  RequestTypeAPI,
  ResponseAPI,
  ServiceType,
  ServiceTypeAPI
} from "@/model/request.typing";
import type { NewRequestFormType, RequestFile } from "@/pages/requests/create/useNewRequestForm";
import { convertToDate } from "@/utils/dates";
import { getProxiedUrl } from "@/utils/url";
import type { AxiosError } from "axios";
import { differenceInSeconds, format } from "date-fns";
import api from "./api";

export const createRequest = async (
  requestForm: NewRequestFormType,
  customFields: CustomField[]
): Promise<number | null | string[]> => {
  const body: CreateRequestTypeAPI = {
    name: requestForm.name as string,
    quoteSubmissionDueDate: requestForm.quoteDueDate?.toISOString(),
    serviceBundleId: requestForm.service?.id,
    quoteRequested: requestForm.requestQuote,
    comments: requestForm.instructions,
    customerForEntityId: requestForm.division.id,
    customerReference: requestForm.invoicingReference as string,
    dueDate: requestForm.deadline?.toISOString(),
    newCustomerRequestIdentifier: { id: requestForm.requestIdentifier },
    referenceFiles: requestForm.referenceFiles.map(({ file }) => ({ fileName: file.name })),
    sourceFiles: requestForm.sourceFiles.map((sourceFile) => ({
      fileName: sourceFile.file.name,
      sourceLanguageIsoCode: sourceFile.sourceLanguageCode as string,
      targetLanguageIsoCodes: sourceFile.targetLanguageCodes,
    })),
    accessors: requestForm.contacts.map((contact) => ({ customerContactId: contact.id })),
    customFields: Object.entries(requestForm.customFields).map(([key, value]) => {
      const customField = customFields.find((field) => field.id.toString() === key.toString());
      const customFieldType = customField?.fieldType ?? "String";
      const mappedValue = parseCustomFieldValue(customFieldType, value);
      const id = Number(key);
      return {
        id,
        customFieldId: id,
        value: mappedValue,
        currentValue: mappedValue,
        fieldType: customFieldType,
      };
    }),
  };
  const { data } = await api.post<ResponseAPI<{ value: number }>>("/api/client/requests", body);

  const error = data.errors && data.errors.length > 0 ? data.errors : null;
  if (error) {
    return error;
  }
  return data.content?.value ?? null;
};

function parseCustomFieldValue(customFieldType: CustomFieldValueType, value: CustomFieldValue): CustomFieldValue {
  if (value === null || value === undefined) {
    return null;
  }

  if (customFieldType === "Date") {
    return format(new Date(value as string), "yyyy-MM-dd");
  }

  if (customFieldType === "String" || customFieldType === "Boolean") {
    return value;
  }

  if (customFieldType === "Number" || customFieldType === "SingleSelect") {
    return parseInt(value as string);
  }

  if (customFieldType === "MultiSelect") {
    return Array.isArray(value) ? value.map((v) => parseInt(v as unknown as string)) : value;
  }
  return value;
}

export const getPreSignedUrl = async (
  requestIdentifier: string,
  divisionId: string,
  fileNames: string[]
): Promise<Array<{ fileName: string; uploadUrl: string }> | null> => {
  const url = `/api/client/requests/preSignedUrls`;
  const response = await api.post<ResponseAPI<{ value: { results: Array<{ uploadUrl: string; fileName: string }> } }>>(
    url,
    {
      identifier: requestIdentifier,
      customerForEntityId: divisionId,
      fileNames: fileNames,
    }
  );

  return response.data?.content?.value?.results ?? null;
};

export async function getDownloadUrl(entityType: string, id: string, fileName: string): Promise<string | null> {
  const response = await api.post<ResponseAPI<{ value: { downloadUrl: string } }>>(`/api/client/requests/downloadUrl`, {
    id,
    entityType,
    fileName,
  });
  const url = response.data?.content?.value?.downloadUrl ?? null;
  if (!url) {
    return null;
  }
  return getProxiedUrl(url);
}

export async function uploadSingleFile<T extends RequestFile>(
  {
    sourceFile,
    presignedUrl,
  }: {
    sourceFile: T;
    presignedUrl: string;
  },
  onStarting: (sourceFile: T, abort: AbortController) => void,
  onProgress: (sourceFile: T, progress: number, remainingTime?: string) => void,
  onUploaded: (sourceFile: T) => void,
  onFailed: (sourceFile: T, isCancellation: boolean) => void
): Promise<void> {
  try {
    const abort = new AbortController();
    onStarting(sourceFile, abort);
    const startTime = new Date();
    const proxiedUrl = getProxiedUrl(presignedUrl)
    await api.put(proxiedUrl, sourceFile.file.slice(), {
      onUploadProgress: (ev) => {
        const total = ev.total ?? 1;
        const elapsedTime = differenceInSeconds(new Date(), startTime);
        const progress = Math.round((ev.loaded / total) * 100);
        const remainingTime = Math.round((elapsedTime / progress) * (100 - progress)).toString();
        onProgress(sourceFile, progress, remainingTime);
        if (progress === 100) {
          onUploaded(sourceFile);
        }
      },
      timeout: 0,
      signal: abort.signal,
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });
    onUploaded(sourceFile);
  } catch (error) {
    const isCancellation = (error as AxiosError)?.code === "ERR_CANCELED";
    onFailed(sourceFile, isCancellation);
  }
}

export const uploadSingleFileLegacy = async <T extends RequestFile>(
  { divisionId, requestIdentifier, sourceFile }: { requestIdentifier: string; divisionId: string; sourceFile: T },
  onStarting: (sourceFile: T, abort: AbortController) => void,
  onProgress: (sourceFile: T, progress: number, remainingTime?: string) => void,
  onUploaded: (sourceFile: T) => void,
  onFailed: (sourceFile: T, isCancellation: boolean) => void
): Promise<void> => {
  try {
    const abort = new AbortController();
    onStarting(sourceFile, abort);
    const url = `/api/client/requests/uploadFile?divisionId=${divisionId}&requestIdentifier=${requestIdentifier}&fileName=${encodeURIComponent(sourceFile.file.name)}`;
    const formData = new FormData();
    formData.append(sourceFile.file.name, sourceFile.file);
    const startTime = new Date();
    await api.post(url, formData, {
      onUploadProgress: (ev) => {
        const total = ev.total ?? 1;
        const elapsedTime = differenceInSeconds(new Date(), startTime);
        const progress = Math.round((ev.loaded / total) * 100);
        const remainingTime = Math.round((elapsedTime / progress) * (100 - progress)).toString();
        onProgress(sourceFile, progress, remainingTime);
        if (progress === 100) {
          onUploaded(sourceFile);
        }
      },
      timeout: 0,
      signal: abort.signal,
    });
    onUploaded(sourceFile);
  } catch (error) {
    const isCancellation = (error as AxiosError)?.code === "ERR_CANCELED";
    onFailed(sourceFile, isCancellation);
  }
};

export async function fetchRequestIdentifier(divisionId: string, signal: AbortSignal): Promise<string | null> {
  const url = `/api/client/requests/identifier?divisionId=${divisionId}`;
  const response = await api.get<ResponseAPI<{ value: { id: string } }>>(url, { signal });
  const data = response.data?.content?.value?.id;
  return data ?? null;
}

export async function fetchMostRecent(signal: AbortSignal): Promise<MostRecentLanguage> {
  const url = "/api/client/requests/mostRecent";
  const response = await api.get<ResponseAPI<{ value: MostRecentAPI }>>(url, { signal });
  return response.data?.content?.value;
}

export async function fetchServiceBundles(divisionId?: string, signal?: AbortSignal): Promise<ServiceType[]> {
  const url = `/api/client/requests/services${divisionId ? `?customerId=${divisionId}` : ""}`;
  const response = await api.get<ResponseAPI<{ value: ServiceTypeAPI[] }>>(url, {
    signal,
  });
  const data = response.data?.content?.value;
  return data
    ? data.map((service) => ({
      id: service.id.toString(),
      name: service.description,
      iconName: service.iconName,
      description: service.elaborateDescription,
      highlighted: service.highlighted,
      frequency: 0,
    }))
    : [];
}

export async function fetchLanguages(signal: AbortSignal): Promise<LanguageType[]> {
  const url = "/api/client/requests/languages";
  const response = await api.get<ResponseAPI<{ value: LanguageTypeAPI[] }>>(url, {
    signal,
  });
  const data = response.data?.content?.value;
  return data
    ? data.map((language) => ({
      id: language.code.toString(),
      name: language.description,
      frequency: 0,
      qeReady: language.qeReady,
    }))
    : [];
}

export async function fetchRequestDocuments(
  { id, entityType }: { id: string; entityType: string },
  signal: AbortSignal
): Promise<ProjectDocument[]> {
  const url = `/api/client/requests/${entityType}/${id}/documents`;

  const response = await api.get<ResponseAPI<{ value: RequestDocumentAPI | ProjectDocumentAPI }>>(url, { signal });
  const data = response.data?.content?.value;

  if (!data) {
    return [];
  }

  const mapToDefaultFileName = (url: string | null | undefined, fileName: string | null | undefined) => {
    const hasUrl = !!url && url.trim().length > 0;
    const hasFileName = !!fileName && fileName.trim().length > 0;
    if (!hasUrl) return null;
    return hasUrl && hasFileName ? fileName : "no name";
  };

  if ("sourceAndTargetFiles" in data) {
    return data.sourceAndTargetFiles.map((document) => ({
      sourceFile: {
        sourceLanguage: { id: document.sourceLanguage, name: "" },
        id: document.sourceFile.id,
        fileName: mapToDefaultFileName(document.sourceFile.downloadUrl, document.sourceFile.fileName),
        downloadUrl: document.sourceFile.downloadUrl,
      },
      targetFiles: document.targetFiles.map((file) => ({
        targetLanguage: { id: file.targetLanguage, name: "" },
        targetFile: file.targetFile
          ? {
            id: file.targetFile.id,
            fileName: mapToDefaultFileName(file.targetFile.downloadUrl, file.targetFile.fileName),
            downloadUrl: file.targetFile.downloadUrl,
          }
          : null,
      })),
    }));
  }

  if ("details" in data) {
    return data.details.flatMap((document) => {
      return document.sourceFiles.map((sourceFile) => {
        return {
          sourceFile: {
            sourceLanguage: { id: document.sourceLanguage, name: "" },
            id: sourceFile.id,
            fileName: mapToDefaultFileName(sourceFile.downloadUrl, sourceFile.fileName),
            downloadUrl: sourceFile.downloadUrl,
          },
          targetFiles: document.targetLanguages.map((targetLanguage) => ({
            targetLanguage: { id: targetLanguage, name: "" },
            targetFile: null,
          })),
        };
      });
    });
  }

  return [];
}

export async function fetchProjectFinancials(projectId: string, signal: AbortSignal): Promise<RequestFinancialType[]> {
  const url = `/api/client/requests/project/${projectId}/financials`;
  const response = await api.get<
    ResponseAPI<{
      value: {
        details: Record<QuoteTypeKeyAPI, RequestFinancialTypeAPI[]>;
        projectCode: string;
      };
    }>
  >(url, {
    signal,
  });
  const data = response.data?.content?.value?.details;
  if (!data) {
    return [];
  }
  return mapToFinancial(data, response.data?.content?.value.projectCode).sort(
    (a, b) => b.eventDateTime.getTime() - a.eventDateTime.getTime()
  );
}

export async function fetchProjectAnalysis(projectCode: string, signal: AbortSignal): Promise<AnalysisType | null> {
  const url = `/api/client/requests/project/${projectCode}/analysis`;
  const { data } = await api.get<ResponseAPI<{ value: AnalysisTypeAPI }>>(url, {
    signal,
  });
  return data.content?.value ?? null;
}

export async function fetchNewRequests(signal: AbortSignal): Promise<NewRequestType[]> {
  const url = "/api/client/requests/new";
  const { data } = await api.get<ResponseAPI<{ value: { newRequests: RequestTypeAPI[] } }>>(url, { signal });
  return mapToNewRequests(data?.content?.value?.newRequests ?? []);
}

export async function fetchDivisions(signal: AbortSignal): Promise<DivisionType[]> {
  const { data } = await api.get<ResponseAPI<{ value: { customers: RequestDivisionTypeAPI[] } }>>(
    "/api/client/requests/divisions",
    { signal }
  );
  const divisions = data?.content?.value?.customers ?? [];
  return divisions.map((division) => ({
    id: division.id.toString(),
    name: division.name,
    useAmazonS3UploadWithAntivirus: division.useAmazonS3UploadWithAntivirus,
  }));
}

export async function fetchInProgressRequests(signal: AbortSignal): Promise<InProgressRequestType[]> {
  const url = "/api/client/requests/inprogress";
  const { data } = await api.get<ResponseAPI<{ value: { inProgressProjects: RequestTypeAPI[] } }>>(url, { signal });
  return mapToInProgressRequests(data?.content?.value?.inProgressProjects ?? []);
}

export async function fetchDeliveredRequests(signal: AbortSignal): Promise<DeliveredRequestType[]> {
  const url = "/api/client/requests/delivered";
  const { data } = await api.get<ResponseAPI<{ value: { deliveredProjects: RequestTypeAPI[] } }>>(url, { signal });
  return mapToDeliveredRequests(data?.content?.value?.deliveredProjects ?? []);
}

export async function fetchRequestDetail(
  { id, entityType }: { id: string; entityType: string },
  signal: AbortSignal
): Promise<RequestDetailType | null> {
  const url = `/api/client/requests/${entityType}/${id}`;
  const response = await api.get<ResponseAPI<{ value: RequestDetailTypeAPI }>>(url, { signal });
  return mapToRequestDetail(response.data?.content?.value);
}

export async function acceptQuote({ id }: { id: string }): Promise<boolean> {
  const url = `/api/client/requests/project/${id}/acceptQuote`;
  const response = await api.post<ResponseAPI<{ success: boolean }>>(url);
  return response.data?.content?.success ?? false;
}

export async function rejectQuote({ id, comments }: { id: string; comments: string }): Promise<boolean> {
  const url = `/api/client/requests/project/${id}/rejectQuote`;
  const response = await api.post<ResponseAPI<{ success: boolean }>>(url, { comments });
  return response.data?.content?.success ?? false;
}

export async function fetchCustomFields(divisionId: string, signal: AbortSignal): Promise<CustomField[]> {
  const url = `/api/client/requests/customFields/${divisionId}`;
  const { data } = await api.get<ResponseAPI<{ value: { customFields: CustomField[] } }>>(url, {
    signal,
  });

  return (data?.content?.value?.customFields ?? []).map((field) => ({
    ...field,
    customFieldId: field.id,
    isValid: true, // in creation all fetched fields are valid
  }));
}

export async function updateCustomField({
  requestId,
  entityType,
  customFieldId,
  value,
  fieldType,
}: {
  requestId: string;
  entityType: string;
  customFieldId: number;
  value: CustomFieldValue;
  fieldType: CustomFieldValueType;
}): Promise<void> {
  const url = `/api/client/requests/${entityType}/${requestId}/customFields/${customFieldId}`;
  const response = await api.put<ResponseAPI<{ message: string }>>(url, {
    value: parseCustomFieldValue(fieldType, value),
    fieldType,
    customerRequestId: Number(requestId),
    customFieldId: Number(customFieldId),
  });
  if (response.data?.statusCode !== 200) {
    throw new Error(response.data?.content?.message ?? "Failed to update custom field");
  }
}

export async function updatePONumber({
  requestId,
  entityType,
  value,
}: {
  requestId: string;
  entityType: string;
  value: string;
}): Promise<void> {
  const url = `/api/client/requests/${entityType}/${requestId}/poNumber`;
  const response = await api.put<ResponseAPI<{ message: string }>>(url, {
    value,
  });
  if (response.data?.statusCode !== 200) {
    throw new Error(response.data?.content?.message ?? "Failed to update PO number");
  }
}
export async function addAccessor({
  id,
  entityType,
  customerContactId,
}: {
  id: string;
  entityType: string;
  customerContactId: string;
}): Promise<void> {
  const url = `/api/client/requests/${entityType}/${id}/accessors`;
  const response = await api.post<ResponseAPI<{ message: string }>>(url, {
    customerContactId,
  });
  if (response.data?.statusCode !== 200) {
    throw new Error(response.data?.content?.message ?? "Failed to add accessor");
  }
}

export async function removeAccessor({
  id,
  entityType,
  accessorId,
}: {
  id: string;
  entityType: string;
  accessorId: string;
}): Promise<void> {
  const url = `/api/client/requests/${entityType}/${id}/accessors/${accessorId}`;
  const response = await api.delete<ResponseAPI<{ message: string }>>(url);
  if (response.data?.statusCode !== 200) {
    throw new Error(response.data?.content?.message ?? "Failed to remove accessor");
  }
}

/***
 * Maps the API response to the model types
 * @param request the request to map
 * @param isProjectDelivered true if the project is considered delivered.
 * True only then the call of this function comes from fetchDeliveredRequests (URL: /api/client/requests/delivered)
 * it's a workaround because API does not return delivery status for delivered projects.
 */
function mapToBaseRequest(request: RequestTypeAPI, isProjectDelivered: boolean): BaseRequestType {
  return {
    id: request.id,
    name: request.requestName ?? request.projectName,
    customerName: request.customerName,
    customerContactName: request.customerContactName,
    service: request.serviceBundleId
      ? {
        id: request.serviceBundleId.toString(),
        name: "",
        description: null,
        iconName: null,
        highlighted: null,
        frequency: 0,
      }
      : null,
    status: mapToStatuses(request.requestStatus ?? request.projectStatus, request.deliveryStatus, isProjectDelivered),
    createdOn: request.requestDate,
    projectCode: request.projectCode ?? null,
    invoicingReference: request.invoicingReference,
    explicitAI: request.explicitAI,
    apeOperationsStatus: request.apeOperationsStatus,
    type: request.projectName ? "project" : "request",
  };
}

function mapToStatuses(status: string, deliveryStatus: "None" | "Partial" | "Full", isDelivered: boolean): string {
  if (status === "Created") {
    return "draft";
  }
  if (status === "Submitted" || status === "DownloadingFiles" || status === "ProjectGenerated") {
    return "submitted";
  }
  if (status === "Cancelled") {
    return "cancelled";
  }
  if (status === "Initialized") {
    return "quote-preparation";
  }
  if (status === "QuoteSubmitted") {
    return "quote-ready";
  }
  if (status === "Rejected") {
    return "rejected";
  }
  if (status === "Archived") {
    return "files-archived";
  }
  if (status === "InExecution" || status === "ReadyForInvoice" || status === "Invoiced" || status === "Credited") {
    if (deliveryStatus === "Full" || isDelivered) {
      return "delivery-complete";
    }
    if (deliveryStatus === "Partial") {
      return "delivery-partial";
    }
    if (!deliveryStatus || deliveryStatus === "None") {
      return "in-progress";
    }
  }
  if (status === "Accepted") {
    return "in-progress";
  }
  return status;
}

function mapToNewRequests(requests: RequestTypeAPI[]): NewRequestType[] {
  return requests.map((request) => {
    const type = request.projectStatus !== null ? "project" : "request";
    return {
      ...mapToBaseRequest(request, false),
      id: request.id,
      type,
      quoteSubmissionDueDate: request.quoteSubmissionDueDate,
      deadline: request.deadline,
      directProject: request.directProject,
      quoteRequested: request.directProject ?? false,
      total: request.total ?? null,
      currency: request.currencyCode,
    };
  });
}

function mapToInProgressRequests(requests: RequestTypeAPI[]): InProgressRequestType[] {
  return requests.map((request) => {
    return {
      ...mapToBaseRequest(request, false),
      deadline: request.deadline,
      dueOn: request.deadline,
      total: request.total ?? 0,
      currency: request.currencyCode,
    };
  });
}

function mapToDeliveredRequests(requests: RequestTypeAPI[]): DeliveredRequestType[] {
  return requests.map((request) => {
    return {
      ...mapToBaseRequest(request, true),
      deliveredOn: request.deliveryDate,
      total: request.total ?? 0,
      currency: request.currencyCode,
      type: "project",
    };
  });
}

function mapToRequestDetail(request: RequestDetailTypeAPI): RequestDetailType | null {
  if (!request) {
    return null;
  }
  return {
    id: request.id,
    type: request.projectName ? "project" : "request",
    name: request.customerRequestName ?? request.projectName ?? "",
    status: mapToStatuses(request.requestStatus ?? request.projectStatus, request.deliveryStatus, false),
    customerName: request.customerName,
    customerContactName: request.customerContactName,
    projectManager: request.projectManager1,
    projectCode: request.projectCode,
    service: request.serviceBundleId
      ? {
        id: request.serviceBundleId.toString(),
        name: "",
        description: null,
        iconName: null,
        highlighted: null,
        frequency: 0,
      }
      : null,
    quoteSubmissionDueDate: convertToDate(request.quoteSubmissionDueDate),
    quoteRequested: request.quoteRequested,
    referenceMaterials: request.referenceMaterials.map((material) => ({
      name: material.description,
      id: material.id.toString(),
    })),
    deliveredOn: convertToDate(request.deliveryDate),
    comments: request.comments,
    customField1: request.customField1,
    customField2: request.customField2,
    customField3: request.customField3,
    customField4: request.customField4,
    deadline: convertToDate(request.deadline),
    currency: request.currencyCode,
    total: request.total,
    requestDate: convertToDate(request.requestDate) as Date,
    languages: request.languages.map((language) => ({
      source: { id: language.sourceLanguage, name: "" },
      targets: language.targetLanguages.map((target) => ({
        id: target,
        name: "",
      })),
    })),
    invoicingReference: request.invoicingReference,
    explicitAI: request.explicitAI,
    apeOperationsStatus: request.apeOperationsStatus,
    customFields: request.customFields,
    accessors: request.accessors.map((accessor) => ({
      id: accessor.customerContactId.toString(),
      name: `${accessor.contactFirstName} ${accessor.contactLastName}`,
      description: accessor.contactEmail,
      roles: accessor.roles,
    })),
    divisionId: request.customerForEntityId,
  };
}

function mapToFinancial(
  data: Record<QuoteTypeKeyAPI, RequestFinancialTypeAPI[]>,
  projectCode: string
): RequestFinancialType[] {
  const mapFinancialQuoteType = (value: number | null) => {
    switch (value) {
      case 0:
        return "quoteType.none";
      case 1:
        return "quoteType.quote";
      case 2:
        return "quoteType.costEstimate";
      case 3:
        return "quoteType.costConfirmation";
      case 4:
        return "quoteType.deliveryNote";
      default:
        return "quoteType.none";
    }
  };

  const result: RequestFinancialType[] = [];

  for (const key in data) {
    const events = data[key as QuoteTypeKeyAPI];
    if (Array.isArray(events) && events.length > 0) {
      const [mostRecentEvent, ...historyEvents] = events;
      const uniqueObject: RequestFinancialType = {
        ...mostRecentEvent,
        projectCode,
        eventDateTime: convertToDate(mostRecentEvent.eventDateTime) as Date,
        event: mostRecentEvent.event.toLowerCase(),
        quoteType: mapFinancialQuoteType(mostRecentEvent.quoteType),
        history:
          events.length > 1
            ? historyEvents.map((quoteEvent) => ({
              ...quoteEvent,
              projectCode,
              event: quoteEvent.event.toLowerCase(),
              quoteType: mapFinancialQuoteType(quoteEvent.quoteType),
              eventDateTime: convertToDate(quoteEvent.eventDateTime) as Date,
            }))
            : [],
      };

      result.push(uniqueObject);
    }
  }

  return result;
}

export async function searchContacts(divisionId: string, searchTerm: string, signal: AbortSignal): Promise<IdName[]> {
  const url = `/api/client/requests/${divisionId}/search?searchTerm=${encodeURIComponent(searchTerm)}`;
  const { data } = await api.get<ResponseAPI<{ value: { contacts: Array<ContactTypeAPI> } }>>(url, { signal });

  return (data?.content?.value?.contacts ?? []).map((contact) => ({
    id: contact.contactId.toString(),
    name: `${contact.firstName} ${contact.lastName}`,
    description: contact.email,
  }));
}
