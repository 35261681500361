import { RequestDetailType } from "@/model/request.typing";
import { useTranslation } from "react-i18next";
import { ArrowRightIcon, ChevronDownIcon, ChevronUpIcon, HelpCircleIcon } from "lucide-react";
import { useLanguagesQuery } from "@/query/request.query";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import PortalTooltip from "@/components/PortalTooltip";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { AnimatePresence, motion } from "framer-motion";

export function AnalysisUnsupported({ project }: { project: RequestDetailType }) {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.analysis" });
  const [isOpen, setIsOpen] = useState(false);
  const { languages } = useLanguagesQuery();
  const unsupportedLanguages = languages?.filter(lang => !lang.qeReady);

  // Check for unsupported languages in the project
  const hasUnsupported = project.languages.some(lang =>
    unsupportedLanguages?.some(unsupportedLang => unsupportedLang.id === lang.source.id) ||
    lang.targets.some(target => unsupportedLanguages?.some(unsupportedLang => unsupportedLang.id === target.id))
  );

  if (!hasUnsupported) return null;

  return (
    <>
      <div className="mt-12 flex w-full items-start space-x-3">
        <TooltipProvider delayDuration={0}>
          <PortalTooltip text={t("unsupportedHelp")}>
            <HelpCircleIcon className="size-5 text-secondary-foreground opacity-25" />
          </PortalTooltip>
        </TooltipProvider>
        <span className="text-sm font-semibold">{t("unsupportedLanguages")}</span>
        <Button
          variant="link"
          className="m-0 flex h-auto cursor-pointer items-center p-0 text-sm font-semibold text-primary no-underline hover:no-underline"
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {t(isOpen ? "less" : "more", { keyPrefix: "common" })}
          {isOpen ? <ChevronUpIcon className="ml-1 size-4" /> : <ChevronDownIcon className="ml-1 size-4" />}
        </Button>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.5 }}
            style={{ overflow: "hidden" }}
          >
            <div className="ml-8 mt-2">
              <span className="grid grid-cols-1">
                <div className="mb-1 text-sm">{t("unsupportedMsg")}</div>
                {project.languages.flatMap(lang =>
                  lang.targets
                    .filter(target => unsupportedLanguages?.some(unsupportedLang => unsupportedLang.id === lang.source.id || unsupportedLang.id === target.id))
                    .map(target => (
                      <div key={`${lang.source.id}-${target.id}`} className="mb-1 flex items-center gap-2 text-sm">
                        <span>{lang.source.name} {lang.source.id}</span>
                        <ArrowRightIcon size={16} className="shrink-0" />
                        <span>{target.name} {target.id}</span>
                      </div>
                    ))
                )}
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}