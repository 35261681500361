import { MachineOcrTranslationResult, OcrFileResult } from "model/mt.typing";
import { getBaseUrl } from "utils/url";

interface OcrFileProps {
  file: OcrFileResult | MachineOcrTranslationResult;
  fileType: string;
}

export const OcrFile = (props: OcrFileProps) => {
  const { file, fileType } = props;
  const portalServerUrl = getBaseUrl();
  return (
    <div className="flex-1 items-center justify-center" data-test={`mt-ocr-file-${fileType}`}>
      {file && !file.isPdf ? (
        <img src={`${portalServerUrl}/api/client/translate/file/${file.fileId}?type=${fileType}`} className="m-auto" />
      ) : (
        <embed
          src={`${portalServerUrl}/api/client/translate/file/${file.fileId}?type=${fileType}`}
          type="application/pdf"
          className="size-full min-h-[960px]"
        />
      )}
    </div>
  );
};
