import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { useDateFormatter } from "@/hooks/useDate";
import {
  CostReportChartNames,
  type PerLanguageBarItemType,
  type ProjectCostPerLanguagesType,
} from "@/model/report.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { endOfMonth, formatDate, isValid, startOfMonth } from "date-fns";
import { chain, sumBy } from "lodash";
import { useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { Bar, BarChart, Brush, CartesianGrid, Rectangle, Tooltip, XAxis, YAxis } from "recharts";
import { ChartCard } from "../common/ChartCard";
import { ChartEmptyPlaceholder } from "../common/ChartEmptyPlaceholder";
import { ChartCardSkeleton } from "../common/ChartSkeleton";
import { ChartTooltipCursor } from "../common/ChartTooltipCursor";
import { useChartBrushIndexes } from "../common/useChartBrushIndexes";
import { useCostReportActions, useCostReportChartSettings } from "../reportStore";
import { useChartDescription } from "./useChartDescription";

export function SpendPerMonthPage() {
  const { pricePerPairLanguages, isLoading } =
    useOutletContext<{
      isLoading: boolean;
      pricePerPairLanguages: ProjectCostPerLanguagesType[];
    }>() ?? {};

  if (isLoading) return <ChartCardSkeleton />;
  if (pricePerPairLanguages === undefined) return null;

  return <SpendPerMonthChart pricePerPairLanguages={pricePerPairLanguages} isFocusView={true} />;
}

export function SpendPerMonthChart({
  pricePerPairLanguages,
  height = 600,
  isFocusView = false,
}: {
  pricePerPairLanguages: ProjectCostPerLanguagesType[];
  height?: number;
  isFocusView?: boolean;
}) {
  const formatNumber = useAuthUserStore((store) => store.formatNumber);
  const { formatDateCustom } = useDateFormatter();
  const { updateFilters } = useCostReportActions();
  const { title, description } = useChartDescription(CostReportChartNames.perMonth, true, pricePerPairLanguages);

  const { saveChartSettings } = useCostReportActions();
  const chartSettings = useCostReportChartSettings(CostReportChartNames.perMonth);

  const [brushStartEndIndex, setBrushStartEndIndex] = useChartBrushIndexes();

  const pricePerMonth = useMemo(() => {
    const groupedData = chain(pricePerPairLanguages)
      .unionBy("projectId")
      .filter((data) => data.deliveryDate !== null)
      .groupBy((data) => {
        const date = new Date(data.deliveryDate as Date);
        return `${date.getMonth() + 1}/${date.getFullYear()}`;
      })
      .map((group) => {
        const currencyCode = group[0].currencyCode;
        const price = sumBy(group, "price");
        return { monthYear: group[0].deliveryDate, currencyCode, price };
      })
      .orderBy("monthYear")
      .map((value) => ({
        ...value,
        brushTickName: formatDateCustom(value.monthYear, "MMM yy"),
      }))
      .value();

    return groupedData;
  }, [pricePerPairLanguages, formatDateCustom]);

  const handleOnMonthClick = (monthYear: Date) => {
    if (!monthYear) return;
    const from = startOfMonth(monthYear);
    const to = endOfMonth(monthYear);
    if (!from || !to) return;
    if (!isValid(from) || !isValid(to)) return;
    updateFilters({ deliveryDate: { from, to } });
  };

  if (pricePerPairLanguages.length === 0) {
    return <ChartEmptyPlaceholder />;
  }

  return (
    <ChartCard
      title={title}
      height={height}
      isFocusView={isFocusView}
      description={description}
      link={CostReportChartNames.perMonth}
      chartSettings={chartSettings}
      saveChartSettings={saveChartSettings}
    >
      {({ displayGrid, displayBrush }) => (
        <BarChart data={pricePerMonth} margin={{ top: 20, left: 40, right: 25, bottom: 0 }}>
          {displayGrid ? <CartesianGrid strokeDasharray="3 3" /> : null}
          {displayBrush ? (
            <Brush
              height={20}
              travellerWidth={5}
              onChange={setBrushStartEndIndex}
              startIndex={brushStartEndIndex.startIndex}
              endIndex={brushStartEndIndex.endIndex}
              className="text-sm"
              fill="#cbd5e1"
              stroke="#374151"
              dataKey="brushTickName"
            />
          ) : null}
          <XAxis
            dataKey="monthYear"
            className="text-xs font-medium"
            tickFormatter={(value) => {
              return formatDate(value, "MMM yy");
            }}
            angle={320}
            minTickGap={15}
            height={50}
            dy={18}
            dx={-18}
          />
          <YAxis tickFormatter={(value) => formatNumber(value)} />
          <Tooltip
            cursor={<ChartTooltipCursor variant={"indigo"} />}
            content={({ payload }) => {
              if (!payload?.[0]?.payload) return null;
              const data = payload[0].payload;
              return (
                <Card>
                  <CardHeader className="px-2 py-1 text-base">
                    <CardTitle className="flex flex-col gap-1 text-base">
                      <span>{formatDate(data.monthYear, "MMM yy")}</span>
                      <span className="text-base font-medium">{formatNumber(data.price, data.currencyCode)}</span>
                    </CardTitle>
                  </CardHeader>
                </Card>
              );
            }}
          />
          <Bar
            dataKey="price"
            className="cursor-pointer fill-indigo-400 stroke-indigo-500 dark:fill-indigo-700 dark:stroke-indigo-800"
            activeBar={<Rectangle className="fill-indigo-600 stroke-indigo-700" />}
            onClick={(data: PerLanguageBarItemType) => handleOnMonthClick(data.monthYear)}
          />
        </BarChart>
      )}
    </ChartCard>
  );
}
