import { App } from "@/routes/App";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { displayWarning } from "utils/consoleWarning";
import { loadAppcuesScripts } from "utils/appcues";
import "./i18n/config";
import "./index.css";
import "./branding.css";

// Appcue js files will be loaded
loadAppcuesScripts();

if (import.meta.env.PROD) {
  displayWarning();
}

createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>
);
