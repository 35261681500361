import { SortDirection, SortState } from "@/components/sorting/sort.typings";
import { Select, SelectContent, SelectItem, SelectTrigger } from "@/components/ui/select";
import { cn } from "@/utils/ui";
import {
  ArrowDownAzIcon,
  ArrowDownIcon,
  ArrowDownWideNarrowIcon,
  ArrowUpAzIcon,
  ArrowUpIcon,
  ArrowUpWideNarrowIcon,
} from "lucide-react";
import { useTranslation } from "react-i18next";

export const DEFAULT_SORT_FIELD_CONFIG = {
  frequency: {
    value: "frequency" as const,
    label: "common.selector.languages.frequency",
    icon: {
      asc: <ArrowUpWideNarrowIcon className="size-4 shrink-0" />,
      desc: <ArrowDownWideNarrowIcon className="size-4 shrink-0" />,
    },
  },
  name: {
    value: "name" as const,
    label: "common.selector.languages.name",
    icon: {
      asc: <ArrowDownAzIcon className="size-4 shrink-0" />,
      desc: <ArrowUpAzIcon className="size-4 shrink-0" />,
    },
  },
};

export interface SortControlProps<Field extends string> {
  testId: string;
  currentSort: SortState<Field>;
  onSortChange: (newSort: SortState<Field>) => void;
  fields: Array<{
    value: Field;
    label: string;
    icon?: {
      asc: React.ReactNode;
      desc: React.ReactNode;
    };
  }>;
  mode?: "icon" | "button";
  className?: string;
}

export function SortControl<Field extends string>({
  testId,
  currentSort,
  onSortChange,
  fields,
  mode = "icon",
  className,
}: SortControlProps<Field>) {
  const { t } = useTranslation();
  const currentField = fields.find((f) => f.value === currentSort.field);

  const defaultIcons = {
    asc: <ArrowUpIcon className="size-4 shrink-0" />,
    desc: <ArrowDownIcon className="size-4 shrink-0" />,
  };

  return (
    <Select
      data-test={`sort-control-${testId}`}
      value={`${currentSort.field}_${currentSort.direction}`}
      onValueChange={(value: string) => {
        const [field, direction] = value.split("_") as [Field, SortDirection];
        onSortChange({ field, direction });
      }}
    >
      <SelectTrigger
        autoFocus={false}
        tabIndex={-1}
        withChevron={false}
        data-test={`sort-control-${testId}-trigger`}
        data-direction={currentSort.direction}
        data-field={currentSort.field}
        className={cn(
          "group w-fit",
          { "focus:ring-0 dark:text-muted-foreground": mode === "button" },
          { "border-0 p-0 text-neutral-500 focus:ring-0 dark:text-muted-foreground": mode === "icon" },
          className
        )}
      >
        <div className={mode === "button" ? "inline-flex flex-nowrap items-center gap-2" : ""}>
          {currentField?.icon?.[currentSort.direction] ?? defaultIcons[currentSort.direction]}
          {mode === "button" && currentField?.label ? t(currentField?.label) : null}
        </div>
      </SelectTrigger>
      <SelectContent>
        {fields.map((field) => (
          <SelectItem
            key={field.value}
            value={`${field.value}_${currentSort.direction === "asc" ? "desc" : "asc"}`}
            data-test={`sort-control-${testId}-item-${field.value}`}
            className="pl-2"
          >
            <div className="flex items-center gap-2">
              {field.icon?.[currentSort.direction === "asc" ? "desc" : "asc"] ??
                defaultIcons[currentSort.direction === "asc" ? "desc" : "asc"]}
              {t(field.label)}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
