import { EmptyPlaceholder } from "@/components/EmptyPlaceholder";
import { MENU_ICONS } from "@/components/Icons";
import { DataTable } from "@/components/datatable/DataTable";
import { useMatchedParam } from "@/hooks/useMatchedParam";
import {
  AllRequestType,
  BaseRequestType,
  DeliveredRequestType,
  InProgressRequestType,
  NewRequestType,
  RequestListType,
} from "@/model/request.typing";
import {
  useAllRequestsQuery,
  useDeliveredRequestsQuery,
  useInProgressRequestsQuery,
  useNewRequestsQuery,
  useTableDownloadMutation,
} from "@/query/request.query";
import { ColumnDef, ColumnSort } from "@tanstack/react-table";
import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router";
import { RequestListFilters } from "./list/components/RequestListFilters";
import { useRequestListColumns } from "./list/hooks/useRequestListColumns";
import { useRequestsSearchText, useRequestsSearchTextActions } from "./requestsSearchStore";

export function NewRequestsContent() {
  const { data, isLoading } = useNewRequestsQuery();
  if (isColumnEmpty(isLoading, data)) return <EmptyColumnPlaceholder />;
  return <RequestListTable type="new" isLoading={isLoading} data={data} defaultColumnVisibility={{ total: false }} />;
}

export function InProgressRequestsContent() {
  const { data, isLoading } = useInProgressRequestsQuery();
  if (isColumnEmpty(isLoading, data)) return <EmptyColumnPlaceholder />;
  return <RequestListTable type="in-progress" isLoading={isLoading} data={data} />;
}

export function DeliveredRequestsContent() {
  const { data, isLoading } = useDeliveredRequestsQuery();
  if (isColumnEmpty(isLoading, data)) return <EmptyColumnPlaceholder />;
  return <RequestListTable type="delivered" isLoading={isLoading} data={data} />;
}

export const AllRequestsContent = () => {
  const { data, isLoading } = useAllRequestsQuery();
  if (isColumnEmpty(isLoading, data)) return <EmptyColumnPlaceholder />;
  return <RequestListTable type="all" isLoading={isLoading} data={data} />;
};

export type RequestTableDataType =
  | NewRequestType
  | InProgressRequestType
  | DeliveredRequestType
  | AllRequestType
  | BaseRequestType;

interface RequestListTableProps<T extends RequestTableDataType> {
  type: RequestListType;
  isLoading: boolean;
  data: Array<T>;
  defaultColumnVisibility?: Record<string, boolean>;
}

function RequestListTable<T extends RequestTableDataType>({
  type,
  isLoading,
  data,
  defaultColumnVisibility,
}: RequestListTableProps<T>) {
  const { updateSearchText } = useRequestsSearchTextActions();
  const searchText = useRequestsSearchText();
  const activeRowId = useMatchedParam("id");
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<ColumnSort[]>([{ id: "createdOn", desc: true }]);
  const columns = useRequestListColumns<T>(type);
  const uniqueName = `requests-table.${type}`;
  const { mutateAsync: fileDownload, isPending } = useTableDownloadMutation(type);

  return (
    <div data-test={uniqueName}>
      <DataTable<T>
        uniqueName={uniqueName}
        isLoading={isLoading}
        showViews={true}
        showSearch={true}
        defaultPageSize={10}
        columns={columns}
        data={data}
        searchTerm={searchText}
        onSearch={updateSearchText}
        sortBy={sortBy}
        onSortBy={(sortBy) => setSortBy(sortBy)}
        facetedFilters={(table, displayedFilters) => (
          <RequestListFilters data={data} table={table} displayedFilters={displayedFilters} />
        )}
        activeRowId={activeRowId}
        onRowClick={(row) => {
          const entityType = (row as NewRequestType)?.type === "request" ? "request" : "project";
          navigate(`details/${entityType}/${row.id}`);
        }}
        downloadAction={{
          isDownloading: isPending,
          onDownload: fileDownload,
          processRecord: (record: Record<string, unknown>, row: T, columnDef: ColumnDef<T, unknown>) => {
            if (columnDef.id === "total") {
              const currency = (row as InProgressRequestType | DeliveredRequestType).currency;
              record[t("requests.table.currency")] = currency ?? "-";
            }

            return record;
          },
        }}
        hasDetailsDrawer={true}
        defaultColumnVisibility={defaultColumnVisibility}
      />
      <Outlet />
    </div>
  );
}

function EmptyColumnPlaceholder() {
  const { t } = useTranslation();
  return (
    <EmptyPlaceholder>
      <EmptyPlaceholder.Icon icon={MENU_ICONS.requests} />
      <EmptyPlaceholder.Title>{t("requests.home.empty.title")}</EmptyPlaceholder.Title>
      <EmptyPlaceholder.Description>{t("requests.home.empty.description")}</EmptyPlaceholder.Description>
    </EmptyPlaceholder>
  );
}

function isColumnEmpty(isLoading: boolean, data: unknown[]): boolean {
  return !isLoading && data.length === 0;
}
