import { TruncatedText } from "@/components/TruncatedText";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useDateFormatter } from "@/hooks/useDate";
import type { RequestFinancialType, RequestFinancialTypeHistory } from "@/model/request.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { DotIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { DownloadLinkIcon } from "../components/DownloadLink";

export function HistoryDialog({
  open,
  data,
  projectId,
  entityType,
  onClose,
}: {
  open: boolean;
  data: RequestFinancialType | null;
  projectId: string;
  entityType: string;
  onClose: () => void;
}) {
  const { t } = useTranslation("translation", {
    keyPrefix: "requests.details.financials",
  });
  if (!data) return null;
  return (
    <Dialog
      data-test="history-dialog"
      open={open}
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
    >
      <DialogContent data-test="history-dialog-content" className="sm:max-w-6xl">
        <DialogHeader>
          <DialogTitle data-test="history-dialog-title" autoFocus tabIndex={0}>
            {t("historyDialog.title")}
          </DialogTitle>
          <DialogDescription data-test="history-dialog-description" className="flex items-center gap-1">
            <span data-test="history-dialog-project">{data.projectCode}</span>
            <DotIcon className="size-6 shrink-0" />
            <span data-test="history-dialog-quote">{t(data.quoteType)}</span>
          </DialogDescription>
        </DialogHeader>
        <div className="max-h-[400px] overflow-scroll"></div>
        <HistoryTimeLine data={data} projectId={projectId} entityType={entityType} />
        <DialogFooter className="sm:justify-end">
          <DialogClose asChild>
            <Button type="button" variant="secondary" data-test="history-close-action">
              {t("historyDialog.close")}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function HistoryTimeLine({
  data,
  projectId,
  entityType,
}: {
  data: RequestFinancialType;
  projectId: string;
  entityType: string;
}) {
  const { t } = useTranslation("translation", {
    keyPrefix: "requests.details.financials.quoteEvents",
  });

  const { formatDateTime } = useDateFormatter();
  const formatNumber = useAuthUserStore((store) => store.formatNumber);

  const groupedEvents = [data, ...data.history].reduce(
    (acc, event) => {
      // grouped by quoteVersion
      const key = event.quoteVersion;

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(event);
      return acc;
    },
    {} as Record<string, RequestFinancialTypeHistory[]>
  );
  const eventKeys = Object.keys(groupedEvents);

  return (
    <div className="relative m-6" data-test="history-timeline">
      {/* Vertical Line */}
      <div className="absolute left-1/4 top-0 h-full w-[2px] bg-border" />

      <div className="space-y-8">
        {/* eventKeys are reversed to display the latest versions first */}
        {eventKeys.reverse().map((key, index) => {
          return (
            <div className="relative flex items-start" key={index}>
              {/* Date on the Left */}
              <div className="mt-1.5 flex w-1/4 flex-col items-end gap-0 pr-4 text-sm text-muted-foreground">
                <div data-test="history-timeline-date" className="text-nowrap font-semibold">
                  {t("version", {
                    version: groupedEvents[key][0].quoteVersion,
                  })}
                </div>
              </div>

              {/* Timeline Indicator */}
              <div className="relative right-[0.16rem] mt-2">
                <div className="z-10 size-[16px] shrink-0 rounded-full border-2 border-border bg-primary" />
              </div>
              {/* Content on the Right */}
              <div className="w-3/4 pl-4">
                {/* groupedEvents are sorted chronologically by the backend, we reverse it to display the latest events first */}
                {groupedEvents[key].reverse().map((event, index) => (
                  <div key={index}>
                    {/* Timeline Indicator */}
                    {index !== 0 ? (
                      <div className="relative right-[2.2rem] top-6">
                        <div className="z-10 size-[16px] shrink-0 rounded-full border-2 border-border bg-primary" />
                      </div>
                    ) : null}
                    <div className="w-full rounded border border-border p-2 sm:w-3/4">
                      <div className="flex items-center justify-between border-b border-border pb-2">
                        <div className="text-sm font-semibold" data-test="history-timeline-event">
                          {t(event.event)}
                        </div>

                        {/* Add price and currency information */}
                        <div className="flex items-center gap-2">
                          {event.total !== undefined && event.total !== null && event.currencyCode && (
                            <>
                              <div className="text-sm font-medium" data-test="history-timeline-price">
                                {formatNumber(event.total, event.currencyCode)}
                              </div>
                              <DotIcon className="size-6 shrink-0 text-muted-foreground" />
                            </>
                          )}
                          <DownloadLinkIcon
                            entityType={entityType}
                            fileName={event.quoteFileName as string}
                            id={projectId}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-4 px-2 py-4 text-xs">
                        <div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:justify-between">
                          <EventActor actor={event.actor} />
                          <div data-test="history-timeline-full-date">{formatDateTime(event.eventDateTime)}</div>
                        </div>
                        <TruncatedText
                          text={event.comment}
                          maxChars={50}
                          className="ml-2 border-l-4 border-l-muted px-2 text-secondary-foreground"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function EventActor({ actor }: { actor: RequestFinancialTypeHistory["actor"] }) {
  if (actor === null || (!actor.firstName && !actor.lastName)) {
    return <div />;
  }
  return (
    <div className="flex items-center gap-1">
      <div className="font-medium">
        {actor.email ? (
          <a
            data-test="history-timeline-actor"
            href={`mailto:${actor.email}`}
            className="cursor-pointer hover:underline"
          >
            {actor.firstName} {actor.lastName}
          </a>
        ) : (
          <span data-test="history-timeline-actor">
            {actor.firstName} {actor.lastName}
          </span>
        )}
      </div>
    </div>
  );
}
