import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "components/ui/button";
import { AnimatePresence, motion } from "framer-motion";
import { FileSpreadsheet, Minimize2, Trash2 } from "lucide-react";
import useWorklist from "pages/machinetranslation/useWorklist";
import { useTranslation } from "react-i18next";
import { SavedTranslation } from "./SavedTranslation";
import { FloatingLabelInput } from "@/components/input/FloatingInput";

interface SavedTextTranslationsProps {
  active: boolean;
  setActive: (value: string) => void;
}

export const SavedTextTranslations = (props: SavedTextTranslationsProps) => {
  const { t } = useTranslation();
  const { active, setActive } = props;

  const { worklistItems, filteredItems, filter, changeFilter, clearItems, copyToClipboard, exportToExcel, removeItem } =
    useWorklist();

  if (!worklistItems || worklistItems.length === 0 || !active) return null;

  const translations = filter !== "" ? filteredItems : worklistItems;

  const renderTranslations = () => {
    return translations.length === 0 ? (
      <Alert className="mt-2" variant="warning">
        <AlertDescription>{t("mt.noMatches")}</AlertDescription>
      </Alert>
    ) : (
      translations.map((t) => (
        <SavedTranslation key={t.id} translation={t} deleteTranslation={removeItem} copyToClipboard={copyToClipboard} />
      ))
    );
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: 0, opacity: 0 }}
        animate={{ x: -8, opacity: 1 }}
        exit={{ x: 0, opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
      >
        <div
          className="my-2 ml-4 min-w-[480px] rounded-md border p-4 sm:items-center lg:max-w-96"
          data-test="mt-worklist"
        >
          <div className="mb-2 flex items-center justify-between gap-2">
            <h2 className="text-xl font-medium">{t("mt.worklist")}</h2>
            <div className="flex items-center gap-1">
              <Button
                data-test="mt-clear-worklist"
                type="button"
                variant="outline"
                className="mr-2 h-8 align-middle text-xs"
                size="sm"
                onClick={() => clearItems()}
              >
                <Trash2 className="me-2 size-4" />
                {t("mt.clear")}
              </Button>
              <Button
                disabled={translations.length === 0}
                type="button"
                data-test="mt-export-worklist"
                variant="outline"
                className="mr-2 h-8 align-middle text-xs"
                size="sm"
                onClick={() => exportToExcel()}
              >
                <FileSpreadsheet className="me-2 size-4" />
                {t("mt.export")}
              </Button>
              <Button
                type="button"
                variant="outline"
                className="h-8 align-middle text-xs"
                size="sm"
                onClick={() => setActive("")}
              >
                <Minimize2 className="size-4" />
              </Button>
            </div>
          </div>

          <FloatingLabelInput
            type="search"
            label={t("common.search.label")}
            className="my-2 h-8"
            value={filter}
            data-test={"mt-worklist-search"}
            onChange={(e) => {
              changeFilter(e.target.value);
            }}
          />

          <Alert variant="info">
            <AlertDescription>{t("mt.workListInstructions")}</AlertDescription>
          </Alert>

          <div>{renderTranslations()}</div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
