import { RowData } from "@tanstack/react-table";
import { deleteTranslationFile, deleteTranslationFiles, downloadTranslationFile } from "api/machinetranslation.api";
import { t } from "i18next";
import {
  DownloadOptions,
  LanguageOption,
  MachineFileTranslationResult,
  MachineTranslationConfig,
} from "model/mt.typing";
import { useCallback } from "react";
import { UseFormReturn } from "react-hook-form";
import { toast } from "sonner";
import { FileMachineTranslationFormType } from "./useFileMachineTranslationForm";

function useFileMachineTranslation(
  form: UseFormReturn<FileMachineTranslationFormType>,
  configuration: MachineTranslationConfig[] | undefined,
  setTargetLanguages: (text: LanguageOption[]) => void
) {
  const setTargets = useCallback(
    (source: LanguageOption | null) => {
      const targets: LanguageOption[] = [];
      if (configuration && source) {
        let targetLanguages = configuration[0].languages.find(
          (x) => x.sourceLanguage.languageCode === source.code
        )?.targetLanguages;

        if (configuration[0].bannedLanguages && configuration[0].bannedLanguages.length > 0) {
          const sourceMatch = configuration[0].bannedLanguages.find(
            (x) => x.sourceLanguage.languageCode === source.code
          );
          if (sourceMatch)
            targetLanguages = targetLanguages?.filter(
              (x) => !sourceMatch.targetLanguages.map((x) => x.languageCode).some((y) => y === x.languageCode)
            );
        }

        targetLanguages?.map((l) =>
          targets.push({
            label: l.languageName,
            code: l.languageCode,
            isDefaultDialect: l.isDefaultDialect,
            mappedCode: l.mappedLanguageCode,
            supportsTerminology: l.supportsTerminology,
          })
        );
      }
      setTargetLanguages(targets);
      const lastItem = localStorage.getItem("mtLastTarget");
      if (targets?.length === 1) form.setValue("targetLanguage", targets[0], { shouldValidate: true });
      else if (lastItem) {
        // Attempt to set to last selected target
        const targetOption: LanguageOption = JSON.parse(lastItem);
        if (
          targets.some(
            (t) => t.code == targetOption.code && targetOption.code != form.getValues("sourceLanguage")?.code
          )
        ) {
          form.setValue("targetLanguage", targetOption, { shouldValidate: true });
          toast.success(t("mt.targetLastSelection"));
        } else {
          form.resetField("targetLanguage");
        }
      } else {
        form.resetField("targetLanguage");
      }
    },
    [configuration, form, setTargetLanguages]
  );

  const swapLanguages = useCallback(
    (source: LanguageOption | undefined, target: LanguageOption | undefined) => {
      if (configuration && source && target) {
        if (configuration[0].languages.find((x) => x.sourceLanguage.languageCode === target.code) !== undefined) {
          const targets: LanguageOption[] = [];
          const targetLanguages = configuration[0].languages.find(
            (x) => x.sourceLanguage.languageCode === target.code
          )?.targetLanguages;
          targetLanguages?.map((l) =>
            targets.push({
              label: l.languageName,
              code: l.languageCode,
              isDefaultDialect: l.isDefaultDialect,
              mappedCode: l.mappedLanguageCode,
              supportsTerminology: l.supportsTerminology,
            })
          );
          setTargetLanguages(targets);

          if (targets.find((x) => x.code === source.code) !== undefined) form.setValue("targetLanguage", source);
          else form.resetField("targetLanguage");

          form.setValue("sourceLanguage", target);
        } else {
          form.resetField("sourceLanguage");
          form.resetField("targetLanguage");
        }
      }
    },
    [configuration, form, setTargetLanguages]
  );

  const handleDownload = async (fileKey: string, type: DownloadOptions, isOcr: boolean): Promise<boolean> => {
    return await downloadTranslationFile(fileKey, type, isOcr);
  };

  const handleDelete = async (id: string) => {
    const result = await deleteTranslationFile(id);
    return result.success;
  };

  const handleDeleteSelected = async (data: RowData[]) => {
    const result = await deleteTranslationFiles(data.map((row) => (row as MachineFileTranslationResult).id).join(","));
    return result.success;
  };

  return { setTargets, swapLanguages, handleDownload, handleDelete, handleDeleteSelected };
}

export default useFileMachineTranslation;
