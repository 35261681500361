import {
  AutoDetectResults,
  DownloadOptions,
  FileToTranslate,
  LanguageOption,
  MachineFileTranslationDeleteResult,
  MachineFileTranslationDownload,
  MachineFileTranslationResult,
  MachineOcrTranslationResult,
  MachineTranslationConfig,
  MachineTranslationResult,
  OcrFileResult,
  OcrParagraphResult,
  OcrParagraphsResult,
  Terminology,
  TerminologyPayload,
  TerminologyUploadResult,
  TermPayload,
} from "model/mt.typing";
import { FileWithPath } from "react-dropzone";
import api, { requestDownloadFile } from "./api";

export const getConfigs = async (): Promise<MachineTranslationConfig[]> => {
  const response = await api.get<MachineTranslationConfig[]>(`/api/client/translate/config`);
  return response.data;
};

export const autoDetect = async (text: string | null): Promise<AutoDetectResults> => {
  const response = await api.get<AutoDetectResults>(`/api/client/translate/autodetect?source=${text}`);
  return response.data;
};

export const translate = async (
  collectionId: string,
  mtProviderType: number,
  sourceLanguageCode: LanguageOption | undefined,
  targetLanguageCode: LanguageOption | undefined,
  sourceText: string | undefined,
  isTerminologyEnabled: boolean,
  selectedTerminologyId: string | null
): Promise<MachineTranslationResult> => {
  const response = await api.post<MachineTranslationResult>("/api/client/translate", {
    from: sourceLanguageCode,
    to: targetLanguageCode,
    source: sourceText,
    collectionId: collectionId,
    mtProviderType: mtProviderType,
    version: 1,
    terminologyId: isTerminologyEnabled ? selectedTerminologyId : null,
  });
  return response.data;
};

export const upload = async (
  clientKey: string,
  mtProviderType: number,
  sourceLanguageCode: LanguageOption | undefined,
  targetLanguageCode: LanguageOption | undefined,
  file: FileToTranslate | null
): Promise<MachineOcrTranslationResult> => {
  const form = new FormData();
  form.set("ClientKey", clientKey);
  form.set("MTProviderType", mtProviderType.toString());
  if (sourceLanguageCode) {
    form.set("SourceLanguage", sourceLanguageCode.mappedCode);
  }
  if (targetLanguageCode) {
    form.set("TargetLanguage", targetLanguageCode.mappedCode);
  }
  if (file) {
    form.append("file", file.file);
  }
  const requestConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: 120000, // Two minutes processing time
  };

  const response = await api.post<MachineOcrTranslationResult>("/api/client/translate/upload", form, requestConfig);
  return response.data;
};

export const uploadOcr = async (clientKey: string, file: FileToTranslate | null): Promise<OcrFileResult> => {
  const form = new FormData();
  form.set("ClientKey", clientKey);
  if (file) {
    form.append("file", file.file);
  }

  const requestConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: 120000, // Two minutes processing time
  };

  const response = await api.post<OcrFileResult>("/api/client/translate/uploadocr", form, requestConfig);
  return response.data;
};

export const getTranslations = async (): Promise<MachineFileTranslationResult[]> => {
  const response = await api.get<MachineFileTranslationResult[]>(`/api/client/translate/translations`);
  return response.data;
};

export const getTranslation = async (id: string): Promise<MachineFileTranslationResult> => {
  const response = await api.get<MachineFileTranslationResult>(`/api/client/translate/translation/${id}`);
  return response.data;
};

export const downloadTranslationFile = async (
  id: string,
  type: DownloadOptions,
  isOcr: boolean
): Promise<boolean> => {
  try {
    await requestDownloadFile(`/api/client/translate/download?id=${id}&type=${type}&ocr=${isOcr}`, null);
    return true;
  }
  catch (error) {
    console.log(error);
    return false;
  }
};

export const downloadActivity = async (
  startDate: string,
  endDate: string,
  isDownloadActivity: boolean
): Promise<MachineFileTranslationDownload> => {
  const response = await api.get<MachineFileTranslationDownload>(
    `/api/client/translate/downloadActivity?startDate=${startDate}&endDate=${endDate}&isDownloadActivity=${isDownloadActivity}`
  );
  return response.data;
};

export const getOcrParagraphs = async (id: string): Promise<OcrParagraphsResult> => {
  const response = await api.get<OcrParagraphsResult>(`/api/client/translate/paragraphs/${id}`, {
    timeout: 120000, // Two minutes processing time
  });
  return response.data;
};

export const getOcrResults = async (id: string): Promise<MachineOcrTranslationResult> => {
  const response = await api.get<MachineOcrTranslationResult>(`/api/client/translate/ocr/${id}`, {
    timeout: 120000, // Two minutes processing time
  });
  return response.data;
};

export const deleteTranslationFile = async (id: string): Promise<MachineFileTranslationDeleteResult> => {
  const response = await api.get<MachineFileTranslationDeleteResult>(`/api/client/translate/delete?id=${id}`);
  return response.data;
};

export const deleteTranslationFiles = async (ids: string): Promise<MachineFileTranslationDeleteResult> => {
  const response = await api.get<MachineFileTranslationDeleteResult>(`/api/client/translate/deleteMany?ids=${ids}`);
  return response.data;
};

export const processOcr = async (
  collectionId: string,
  mtProviderType: number,
  fileId: string,
  sourceLanguageCode: LanguageOption | undefined,
  targetLanguageCode: LanguageOption | undefined,
  paragraphs: OcrParagraphResult[] | undefined,
  isTerminologyEnabled: boolean,
  terminologyId: string | null
): Promise<MachineOcrTranslationResult> => {
  const response = await api.post<MachineOcrTranslationResult>("/api/client/translate/processocr", {
    collectionId: collectionId,
    mtProviderType: mtProviderType,
    fileId: fileId,
    from: sourceLanguageCode,
    to: targetLanguageCode,
    paragraphs: paragraphs,
    terminologyId: isTerminologyEnabled ? terminologyId : null,
  });
  return response.data;
};

export const confirmDisclaimer = async (value: boolean): Promise<boolean> => {
  const response = await api.get<boolean>(`/api/client/translate/confirm?value=${value ? "yes" : "no"}`);
  return response.data;
};

export const getTerminologyList = async (customerId: string): Promise<Terminology[]> => {
  const response = await api.get<Terminology[]>(`/api/client/translate/terminologyList?customerId=${customerId}`);
  return response.data;
};

export const getTerminology = async (customerId: string, terminologyId: string): Promise<Terminology> => {
  const response = await api.get<Terminology>(
    `/api/client/translate/terminology?customerId=${customerId}&terminologyId=${terminologyId}`
  );
  return response.data;
};

export const updateTerminology = async (
  customerId: string,
  terminologyId: string,
  values: TerminologyPayload
): Promise<Terminology> => {
  const response = await api.post<Terminology>(`/api/client/translate/updateTerminology`, {
    customerId: customerId,
    terminologyId: terminologyId,
    name: values.name,
  });
  return response.data;
};

export const addTerminology = async (customerId: string, values: TerminologyPayload): Promise<Terminology> => {
  const response = await api.post<Terminology>(`/api/client/translate/addTerminology`, {
    customerId: customerId,
    name: values.name,
  });
  return response.data;
};

export const deleteTerminology = async (customerId: string, terminologyId: string): Promise<boolean> => {
  const response = await api.get<boolean>(
    `/api/client/translate/deleteTerminology?customerId=${customerId}&terminologyId=${terminologyId}`
  );
  return response.data;
};

export const addTerm = async (
  customerId: string,
  instanceId: string,
  terminologyId: string,
  values: TermPayload
): Promise<Terminology> => {
  const response = await api.post<Terminology>(`/api/client/translate/addTerm`, {
    customerId: customerId,
    instanceId: instanceId,
    terminologyId: terminologyId,
    source: values.source,
    target: values.target,
    sourceLanguageCode: values.sourceLanguageCode,
    targetLanguageCode: values.targetLanguageCode,
  });
  return response.data;
};

export const deleteTerm = async (
  customerId: string,
  instanceId: string,
  terminologyId: string,
  termId: string
): Promise<{ success: boolean }> => {
  const response = await api.post<{ success: boolean }>(`/api/client/translate/deleteTerm`, {
    customerId: customerId,
    instanceId: instanceId,
    terminologyId: terminologyId,
    termId: termId,
  });
  return response.data;
};

export const updateTerm = async (
  customerId: string,
  instanceId: string,
  terminologyId: string,
  termId: string,
  values: TermPayload
): Promise<Terminology> => {
  const response = await api.post<Terminology>(`/api/client/translate/updateTerm`, {
    customerId: customerId,
    instanceId: instanceId,
    terminologyId: terminologyId,
    termId: termId,
    source: values.source,
    target: values.target,
    sourceLanguageCode: values.sourceLanguageCode,
    targetLanguageCode: values.targetLanguageCode,
  });
  return response.data;
};

export const uploadGlossary = async (
  customerId: string,
  instanceId: string,
  terminologyId: string,
  sourceLanguageCode: string,
  targetLanguageCode: string,
  file: FileWithPath
): Promise<TerminologyUploadResult> => {
  const form = new FormData();
  form.set("CustomerId", customerId);
  form.set("InstanceId", instanceId);
  form.set("TerminologyId", terminologyId);
  form.set("SourceLanguageCode", sourceLanguageCode);
  form.set("TargetLanguageCode", targetLanguageCode);
  if (file) {
    form.append("file", file);
  }

  const requestConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: 120000, // Two minutes processing time
  };

  const response = await api.post<TerminologyUploadResult>("/api/client/translate/uploadglossary", form, requestConfig);
  return response.data;
};
