import { CustomField } from "@/model/request.typing";
import { useUpdateCustomFieldMutation } from "@/query/request.query";
import { InlineEditableField } from "./common/InlineEditableField";

interface Props {
  field: CustomField;
  requestId: string;
  entityType: string;
}

export function InlineEditableCustomField({ field, requestId, entityType }: Props) {
  const { mutateAsync, isPending } = useUpdateCustomFieldMutation(requestId, entityType);
  return <InlineEditableField field={field} isPending={isPending} onSave={mutateAsync} />;
}
