import { useDateFormatter } from "@/hooks/useDate";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { addMonths } from "date-fns";
import { t } from "i18next";
import { CalendarIcon, Loader2Icon } from "lucide-react";
import { useMachineTranslationConfig } from "query/mt/machinetranslation.query";
import { useState } from "react";
import type { DateRange } from "react-day-picker";
import { cn } from "utils/ui";
import useDownloadActivity from "./useDownloadActivity";

const DownloadActivity = () => {
  const { configuration } = useMachineTranslationConfig();
  const { dateLocale, formatDateCustom } = useDateFormatter();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [date, setDate] = useState<DateRange | undefined>({
    from: addMonths(new Date(), -1),
    to: undefined,
  });

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  const { downloadActivityReport } = useDownloadActivity();

  function handleDownload() {
    if (date?.from !== undefined && date.to !== undefined && configuration) {
      setIsProcessing(true);
      downloadActivityReport(
        formatDateCustom(date.from, "yyyy-MM-dd"),
        formatDateCustom(date.to, "yyyy-MM-dd"),
        configuration[0].isDownloadActivity
      ).then(() => {
        setIsProcessing(false);
        setIsVisible(false);
      });
    }
  }

  function handleSetDate(dateRange: DateRange | undefined) {
    setDate(dateRange);
    if (dateRange?.from !== undefined && dateRange?.to !== undefined) {
      setIsCalendarOpen(false);
    }
  }

  return (
    <Dialog open={isVisible} onOpenChange={setIsVisible}>
      <DialogTrigger asChild>
        <Button>{t("mt.downloadActivity")}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("mt.downloadActivity")}</DialogTitle>
          <DialogDescription>{t("mt.chooseDateRange")}</DialogDescription>
        </DialogHeader>
        <Popover open={isCalendarOpen} modal={true}>
          <PopoverTrigger asChild>
            <Button
              disabled={isProcessing}
              id="date"
              variant={"outline"}
              className={cn("w-full justify-center text-center font-normal", !date && "text-muted-foreground")}
              onClick={() => setIsCalendarOpen(!isCalendarOpen)}
            >
              <CalendarIcon className="mr-2 size-4" />
              {date?.from ? (
                date.to ? (
                  <>
                    {formatDateCustom(date.from, "LLL dd, y")} - {formatDateCustom(date.to, "LLL dd, y")}
                  </>
                ) : (
                  formatDateCustom(date.from, "LLL dd, y")
                )
              ) : (
                <span>{t("mt.pickDate")}</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              autoFocus={true}
              mode="range"
              defaultMonth={date?.from}
              selected={date}
              onSelect={handleSetDate}
              numberOfMonths={2}
              locale={dateLocale}
            />
          </PopoverContent>
        </Popover>
        <DialogFooter className="justify-end">
          <DialogClose asChild>
            <Button disabled={isProcessing} variant="secondary">
              {t("common.modal.close")}
            </Button>
          </DialogClose>
          <Button
            disabled={isProcessing || date?.from === undefined || date?.to === undefined || configuration == null}
            onClick={() => handleDownload()}
          >
            {isProcessing ? <Loader2Icon className="mr-2 animate-spin" /> : null}
            {t("mt.downloadActivity")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadActivity;
