import { useDateFormatter } from "@/hooks/useDate";
import type { QueryObserverResult } from "@tanstack/react-query";
import type { ColumnDef, RowData } from "@tanstack/react-table";
import type { AxiosError } from "axios";
import { DataTable } from "components/datatable/DataTable";
import { type UrlParamsType, useUrlParams } from "hooks/useUrlParams";
import { t } from "i18next";
import type { DownloadOptions, MachineFileTranslationResult } from "model/mt.typing";
import { useMemo } from "react";
import { toast } from "sonner";
import { FileActions } from "./FileActions";

interface FilesTableProps {
  files: MachineFileTranslationResult[];
  handleDownload: (fileKey: string, type: DownloadOptions, isOcr: boolean) => Promise<boolean>;
  handleDelete: (id: string) => Promise<boolean>;
  handleDeleteSelected: (rows: RowData[]) => Promise<boolean>;
  refetch: () => Promise<QueryObserverResult<MachineFileTranslationResult[], AxiosError<unknown>>>;
}

export const FilesTable = ({ files, refetch, handleDownload, handleDelete, handleDeleteSelected }: FilesTableProps) => {
  const { formatDate } = useDateFormatter();

  const {
    queryParams: { searchTerm, sortBy },
    setQueryParams,
  } = useUrlParams({
    localStorageKey: "mt_file_filters",
    defaultValues: {
      searchTerm: "",
      sortBy: [{ id: "translateDate", desc: true }],
    } as UrlParamsType,
  });

  const handleDeletion = async (rows: RowData[]): Promise<boolean> => {
    const success = await handleDeleteSelected(rows);
    if (success) {
      return refetch().then(() => {
        toast.success(t("mt.file.deleteSuccess"));
        return true;
      });
    }
    toast.error(t("mt.file.deleteFailed"));
    return false;
  };

  const columns = useMemo<ColumnDef<MachineFileTranslationResult>[]>(
    () => [
      {
        id: "translateDate",
        header: t("mt.translateDate"),
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        accessorFn: (translation) => translation.translateDate,
        cell(props) {
          return formatDate(props.row.original.translateDate);
        },
      },
      {
        id: "sourceFile",
        header: t("mt.sourceFile"),
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        accessorFn: (translation) => translation.filename,
        cell(props) {
          return props.row.original.filename;
        },
      },
      {
        id: "fromLanguage",
        header: t("mt.from"),
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        accessorFn: (translation) => translation.fromLanguage,
        cell(props) {
          return props.row.original.fromLanguage;
        },
      },
      {
        id: "toLanguage",
        header: t("mt.to"),
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        accessorFn: (translation) => translation.toLanguage,
        cell(props) {
          return props.row.original.toLanguage;
        },
      },
      {
        id: "isOcr",
        header: t("mt.type"),
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        accessorFn: (translation) => translation.isOcr,
        cell(props) {
          return props.row.original.isOcr ? (
            <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
              OCR
            </span>
          ) : (
            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              FILE
            </span>
          );
        },
      },
      {
        id: "actions",
        header: t("mt.actions"),
        enableSorting: false,
        enableHiding: false,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        enableResizing: false,
        accessorFn: (translation) => translation,
        cell(props) {
          return (
            <FileActions
              id={props.row.original.id}
              status={props.row.original.mtStatus}
              uploadDate={props.row.original.translateDate}
              code={props.row.original.statusCode}
              fileKey={props.row.original.fileId}
              fileName={props.row.original.filename}
              handleDownload={handleDownload}
              handleDelete={handleDelete}
              isOcr={props.row.original.isOcr}
              isRecentActivity={false}
              engineId={props.row.original.engineId}
            />
          );
        },
      },
    ],
    [handleDownload, handleDelete, formatDate]
  );

  return (
    <DataTable
      data-test="mt-files-table"
      uniqueName="mt-files"
      data={files ?? []}
      sortBy={sortBy}
      columns={columns}
      onSortBy={(sortBy) => setQueryParams({ sortBy })}
      searchTerm={searchTerm}
      onSearch={(searchTerm) => setQueryParams({ searchTerm })}
      defaultPageSize={20}
      showViews={false}
      onDeleteSelected={handleDeletion}
      isWithSelectColumn={true}
    />
  );
};
