import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SidebarTrigger, useSidebar } from "@/components/ui/sidebar";
import { UserAvatar } from "@/components/UserAvatar";
import useTheme, { ThemeType } from "@/hooks/useTheme";
import { useLogoutMutation } from "@/query/user.query";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { redirectToUserProfile } from "@/utils/url";
import { clsx } from "clsx";
import { LaptopIcon, LogOutIcon, MoonIcon, Settings, SunMediumIcon, SunMoon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const THEMES = Object.entries({ light: SunMediumIcon, dark: MoonIcon, system: LaptopIcon });

export function AppHeader() {
  const user = useAuthUserStore((store) => store.user);
  const navigate = useNavigate();
  const { theme, updateTheme } = useTheme();
  const { t } = useTranslation("translation", { keyPrefix: "header" });
  const { mutate: doLogout } = useLogoutMutation();
  const { isMobile } = useSidebar();
  const isDefaultSite = user?.siteTag === "default";

  return (
    <header className="sticky top-0 z-30 flex h-16 w-full justify-between bg-sidebar px-4 py-3 text-sidebar-foreground backdrop-blur">
      <div>{isMobile && <SidebarTrigger className="size-12" />}</div>
      <div className="flex items-center gap-4">
        <div
          data-test="appcues-placeholder-one"
          id="appcues-placeholder-one"
          className="flex h-12 min-w-4 items-center"
        ></div>
        {user && (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <UserAvatar user={user} className="size-10" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="rounded-xl p-2" collisionPadding={15}>
              <div className="flex gap-3 p-2">
                <UserAvatar user={user} className="size-14" />
                <div className="flex flex-col justify-center">
                  <div className="font-semibold leading-tight">
                    {user.firstName} {user.lastName}
                  </div>
                  <div className="text-sm leading-tight text-muted-foreground">{user.email}</div>
                </div>
              </div>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer focus:bg-sidebar-accent focus:text-sidebar-accent-foreground"
                onClick={() => (user.isVNext ? navigate("/profile") : redirectToUserProfile())}
              >
                <Settings className="mr-2 size-4 text-sidebar-foreground" />
                <span>{t("user-menu.user-settings")}</span>
              </DropdownMenuItem>
              {isDefaultSite && (
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger className="cursor-pointer focus:bg-sidebar-accent focus:text-sidebar-accent-foreground data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground">
                    <SunMoon className="mr-2 size-4 text-sidebar-foreground" />
                    <span>{t("user-menu.appearance")}</span>
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent className="p-2">
                      {THEMES.map(([key, Icon]) => (
                        <DropdownMenuItem
                          key={key}
                          className={clsx(
                            "cursor-pointer focus:bg-sidebar-accent focus:text-sidebar-accent-foreground",
                            {
                              "bg-sidebar-accent": key === theme,
                            }
                          )}
                          onClick={() => updateTheme(key as ThemeType)}
                        >
                          <Icon className="mr-2 size-4 text-sidebar-foreground" />
                          <span>{t(`theme.${key}`)}</span>
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              )}
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer focus:bg-sidebar-accent focus:text-sidebar-accent-foreground"
                onClick={() => doLogout()}
              >
                <LogOutIcon className="mr-2 size-4 text-sidebar-foreground" />
                <span>{t("user-menu.logout")}</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    </header>
  );
}
