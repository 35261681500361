import { ComboboxOption } from "@/components/combobox/common/ComboboxOption";
import { ComboboxTrigger } from "@/components/combobox/common/ComboboxTrigger";
import { MultiSelectionProps } from "@/components/combobox/common/combobox.typings";
import { useCombobox } from "@/components/combobox/common/useCombobox";
import { useComboboxItems } from "@/components/combobox/common/useComboboxItems";
import { IdName } from "@/model/common";
import { Badge } from "components/ui/badge";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "components/ui/command";
import { Popover, PopoverContent } from "components/ui/popover";
import { CheckIcon } from "lucide-react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

export function Combobox<T extends IdName, SortField extends string>({
  placeholder,
  title,
  options,
  selectedIds = [],
  icon,
  className,
  onChange,
  customValueLabel,
  testId = "",
  hideCount = false,
  inError = false,
  formatCount,
  renderSelected,
  renderOption,
  sortOptions,
  triggerSize,
}: MultiSelectionProps<T, SortField>) {
  const { t } = useTranslation();
  const { setSearchValue, listRef, sortItems, renderSortControl, isSortingEnabled } = useCombobox<T, SortField>({
    testId,
    sorting: sortOptions,
  });

  const { selected, unselected } = useComboboxItems<T>(options, selectedIds, sortItems);
  const isShouldDisplayPlaceholder = !!placeholder && selected.length === 0 && !customValueLabel;

  const handleSelectAll = useCallback(() => {
    onChange([...options]);
  }, [onChange, options]);

  const handleClearAll = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const handleOptionSelect = useCallback(
    (option: T) => {
      onChange([...selected, option]);
    },
    [onChange, selected]
  );

  const handleOptionDeselect = useCallback(
    (optionId: T["id"]) => {
      onChange(selected.filter((v) => v.id !== optionId));
    },
    [onChange, selected]
  );

  return (
    <Popover>
      <ComboboxTrigger
        testId={testId}
        title={title}
        placeholder={placeholder}
        icon={icon}
        inError={inError}
        customValueLabel={customValueLabel}
        selected={selected}
        renderSelected={renderSelected}
        isShouldDisplayPlaceholder={isShouldDisplayPlaceholder}
        size={triggerSize}
      />
      <PopoverContent
        data-test={`combobox-${testId}-content`}
        className={cn("w-[var(--radix-popover-trigger-width)] min-w-[400px] p-0", className)}
        align="start"
      >
        <Command>
          <CommandInput data-test={`combobox-${testId}-input`} placeholder={title} onValueChange={setSearchValue} />
          <CommandList ref={listRef}>
            {isSortingEnabled && renderSortControl?.()}
            {isSortingEnabled && selected.length > 0 && <CommandSeparator />}
            <CommandEmpty data-test={`combobox-${testId}-empty`}>{t("translation:facets.not-found")}</CommandEmpty>
            {selected.length > 0 && (
              <CommandGroup data-test={`combobox-${testId}-selected-options`}>
                {selected.map((option) => (
                  <ComboboxOption
                    key={option.id}
                    option={option}
                    isSelected
                    hideCount={hideCount}
                    testId={testId}
                    onSelect={() => handleOptionDeselect(option.id)}
                    renderOption={renderOption}
                    formatCount={formatCount}
                  />
                ))}
              </CommandGroup>
            )}
            {unselected.length > 0 && <CommandSeparator />}
            {unselected.map((option) => (
              <ComboboxOption
                key={option.id}
                option={option}
                hideCount={hideCount}
                testId={testId}
                onSelect={() => handleOptionSelect(option)}
                renderOption={renderOption}
                formatCount={formatCount}
              />
            ))}
          </CommandList>
          <CommandSeparator />
          {selected.length === 0 && options.length > 2 && (
            <CommandGroup>
              <CommandItem data-test={`combobox-${testId}-select-all-action`} onSelect={handleSelectAll}>
                <div
                  className={cn(
                    "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-neutral-400 dark:border-neutral-600 [&_svg]:invisible"
                  )}
                >
                  <CheckIcon data-test={`combobox-${testId}-option-icon`} className={cn("h-4 w-4")} />
                </div>
                <div className="flex items-center gap-2">
                  {t("common.selector.languages.selectAll")}
                  <Badge variant="muted" className="rounded-sm px-1 font-normal">
                    {options.length}
                  </Badge>
                </div>
              </CommandItem>
            </CommandGroup>
          )}
          {selected.length > 0 && (
            <CommandGroup>
              <CommandItem data-test={`combobox-${testId}-clear-action`} onSelect={handleClearAll}>
                <div
                  className={cn(
                    "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                    "bg-primary text-primary-foreground"
                  )}
                >
                  <CheckIcon data-test={`select-${testId}-selected-option-icon`} className={cn("h-4 w-4")} />
                </div>
                {t("common.selector.languages.deselectAll")}
              </CommandItem>
            </CommandGroup>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
}
