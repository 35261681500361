import { Button } from "@/components/ui/button";
import { FormControl, FormDescription, FormField, FormItem } from "@/components/ui/form";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { bytesToSize } from "@/utils/file";
import { cn } from "@/utils/ui";
import { DotIcon, MinusCircleIcon, RefreshCcw } from "lucide-react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import UploadFileZone from "./common/UploadFileZone";
import { useReferenceFilesToBeUploaded } from "./fileUpload";
import { NewRequestFormType } from "./useNewRequestForm";

const MAX_FILE_SIZE = 2_000_000_000; // 2 GB

interface Props {
  requestIdentifier: string;
  divisionId: string;
  uploadToS3: boolean;
  form: UseFormReturn<NewRequestFormType>;
}
export function UploadReferenceFiles({ requestIdentifier, divisionId, form, uploadToS3 }: Props) {
  const { t } = useTranslation();
  const { removeFile, uploadFiles, retryUpload, referenceFiles } = useReferenceFilesToBeUploaded(
    { requestIdentifier, divisionId, uploadToS3 },
    form
  );
  const totalFileSize = Object.values(referenceFiles).reduce((acc, files) => acc + files.file.size, 0);

  return (
    <div className="w-full flex-1">
      <FormField
        control={form.control}
        name="instructions"
        render={() => (
          <FormItem>
            <FormControl>
              <UploadFileZone
                variant="input"
                placeholder={t("requests.create.upload.upload-files-reference")}
                totalFileSize={totalFileSize}
                uploadFiles={uploadFiles}
              />
            </FormControl>
            <FormDescription>
              {t("requests.create.upload.upload-message-condition", { size: bytesToSize(MAX_FILE_SIZE) })}
            </FormDescription>
          </FormItem>
        )}
      />
      <Table data-test="table-reference-files">
        <TableBody>
          {referenceFiles.map((file, index) => (
            <TableRow key={index} className={"group"}>
              <TableCell className="max-w-80 p-0">
                <div className={cn("ml-4 flex flex-1 flex-col border-l-2 border-neutral-300")}>
                  <span className={cn("ml-2 truncate font-medium")} title={file.file.name}>
                    {file.file.name}
                  </span>
                  <div className={cn("ml-2 flex flex-nowrap items-center gap-1 text-xs text-muted-foreground")}>
                    <span className="whitespace-nowrap">{bytesToSize(file.file.size)}</span>
                    {["failed", "canceled", "waiting", "planned", "uploading"].includes(file.status) && (
                      <DotIcon className="size-4" />
                    )}
                    <div className="whitespace-nowrap">
                      {file.status === "uploading" && (
                        <span data-test={`upload-dialog-file-${file.file.name}-progress`}>{file.progression}%</span>
                      )}
                      {["failed", "canceled", "waiting", "planned"].includes(file.status) && (
                        <div
                          data-test={`upload-dialog-file-${file.file.name}-status`}
                          className={cn(
                            "flex items-center space-x-2 text-xs",
                            ["waiting", "planned"].includes(file.status)
                              ? "text-orange-500"
                              : "text-red-600 dark:text-red-400/90"
                          )}
                        >
                          {t(`requests.create.upload.status.${file.status === "planned" ? "waiting" : file.status}`)}
                        </div>
                      )}
                    </div>
                  </div>
                  {file.status === "uploading" && (
                    <div
                      className="mx-2 h-1 w-full rounded-full bg-gray-200"
                      data-test={`upload-dialog-file-${file.file.name}-progressbar`}
                    >
                      <div
                        className="h-1 rounded-full bg-blue-300"
                        data-test={`upload-dialog-file-${file.file.name}-progressbar-value`}
                        style={{ width: `${file.progression}%` }}
                      ></div>
                    </div>
                  )}
                </div>
              </TableCell>

              <TableCell className="text-end">
                <div className="flex justify-end gap-2">
                  {file.status === "failed" && (
                    <Button
                      type="button"
                      variant="link"
                      size="icon"
                      className="size-5"
                      onClick={() => retryUpload(file.id)}
                      title={t("common.retry")}
                    >
                      <RefreshCcw className="size-full shrink-0 text-neutral-400 dark:text-muted-foreground" />
                    </Button>
                  )}
                  <Button
                    type="button"
                    variant="link"
                    size="icon"
                    className={cn("group invisible size-5", { "group-hover:visible": file.status !== "planned" })}
                    onClick={() => removeFile(file.id)}
                  >
                    <MinusCircleIcon className="size-full shrink-0 text-neutral-400 dark:text-muted-foreground" />
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
