import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { TmLeverageChartNames, TmLeveragePerLanguagesType } from "@/model/report.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { chain, sumBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Bar, BarChart, Brush, CartesianGrid, Rectangle, Tooltip, XAxis, YAxis } from "recharts";
import { ChartCard } from "../common/ChartCard";
import { ChartEmptyPlaceholder } from "../common/ChartEmptyPlaceholder";
import { ChartCardSkeleton } from "../common/ChartSkeleton";
import { ChartTooltipCursor } from "../common/ChartTooltipCursor";
import { useChartBrushIndexes } from "../common/useChartBrushIndexes";
import { useTmLeverageActions, useTmLeverageChartSettings, useTmLeverageChartViewFilter } from "../reportStore";
import { ChartCategoryInfo, ChartFilter } from "./common/ChartComponents";
import { applyFuzzyNoMatchCalculation } from "./useTmLeverageFilter";

export function TmLeveragePerMonthWordsPage() {
  const { filteredData, isLoading } =
    useOutletContext<{ isLoading: boolean; filteredData: TmLeveragePerLanguagesType[] }>() ?? {};

  if (isLoading) return <ChartCardSkeleton />;
  if (filteredData === undefined) return null;

  return <TmLeveragePerMonthWordsChart data={filteredData} isFocusView={true} height={600} />;
}

export function TmLeveragePerMonthWordsChart({
  data,
  height = 600,
  isFocusView,
}: {
  height?: number;
  data: TmLeveragePerLanguagesType[];
  isFocusView: boolean;
}) {
  const { t } = useTranslation();
  const formatNumber = useAuthUserStore((state) => state.formatNumber);
  const formatDate = useAuthUserStore((state) => state.formatDate);
  const chartViewSates = useTmLeverageChartViewFilter();
  const chartViewSate = chartViewSates[TmLeverageChartNames.perMonthWords];
  const { saveChartSettings } = useTmLeverageActions();
  const chartSettings = useTmLeverageChartSettings(TmLeverageChartNames.perMonthWords);

  const [brushStartEndIndex, setBrushStartEndIndex] = useChartBrushIndexes();

  const perMonthData = useMemo(
    () =>
      chain(applyFuzzyNoMatchCalculation(TmLeverageChartNames.perMonthWords, data))
        .groupBy((data) => {
          const date = new Date(data.deliveryDate);
          return `${date.getMonth() + 1}/${date.getFullYear()}`;
        })
        .map((items) => {
          return {
            monthYear: items[0].deliveryDate,
            xTranslated: sumBy(items, "xTranslated"),
            repetitions: sumBy(items, "repetitions"),
            m100: sumBy(items, "m100"),
            m95_99: sumBy(items, "m95_99"),
            m85_94: sumBy(items, "m85_94"),
            m75_84: sumBy(items, "m75_84"),
            m50_74: sumBy(items, "m50_74"),
            noMatch: sumBy(items, "noMatch"),
            words: sumBy(items, "words"),
          };
        })
        .orderBy("monthYear")
        .map((value) => ({ ...value, brushTickName: formatDate(value.monthYear, { dateFormat: "MMM yy" }) }))
        .value(),
    [data, formatDate]
  );

  if (perMonthData.length === 0) {
    return <ChartEmptyPlaceholder />;
  }

  return (
    <div>
      <ChartCard
        height={height}
        title={t("reports.tmleverage.charts.per-month-words")}
        link={TmLeverageChartNames.perMonthWords}
        isFocusView={isFocusView}
        chartSettings={chartSettings}
        saveChartSettings={saveChartSettings}
        renderBeforeChart={() => <ChartFilter chartType={TmLeverageChartNames.perMonthWords} />}
      >
        {({ displayGrid, displayBrush }) => {
          return (
            <BarChart
              data={perMonthData}
              margin={{
                top: 20,
                left: 40,
                right: 25,
                bottom: 10,
              }}
            >
              {displayGrid ? <CartesianGrid strokeDasharray="3 3" /> : null}
              {displayBrush ? (
                <Brush
                  height={20}
                  travellerWidth={5}
                  onChange={setBrushStartEndIndex}
                  startIndex={brushStartEndIndex.startIndex}
                  endIndex={brushStartEndIndex.endIndex}
                  className="text-sm"
                  fill="#cbd5e1"
                  stroke="#374151"
                  dataKey="brushTickName"
                />
              ) : null}
              <XAxis
                dataKey={"monthYear"}
                className="text-xs font-medium text-secondary-foreground"
                tickFormatter={(value) => {
                  return formatDate(value, { dateFormat: "MMM yy" });
                }}
                angle={320}
                minTickGap={15}
                height={50}
                dy={18}
                dx={-18}
              />
              <YAxis tickFormatter={(value) => formatNumber(value)} />
              <Tooltip
                cursor={<ChartTooltipCursor variant={"blue"} />}
                content={({ payload }) => {
                  if (!payload?.[0]?.payload) return null;
                  const data = payload[0].payload;
                  return (
                    <ChartToolTip
                      data={data}
                      formatNumber={formatNumber}
                      formatDate={(date) => formatDate(date, { dateFormat: "MMMM yyyy" })}
                    />
                  );
                }}
              />
              {chartViewSate.xTranslated ? (
                <Bar
                  stackId="a"
                  dataKey="xTranslated"
                  className="fill-blue-400 stroke-blue-500 dark:fill-blue-700 dark:stroke-blue-800"
                  activeBar={<Rectangle className="fill-blue-600 stroke-blue-700" />}
                />
              ) : null}
              {chartViewSate.repetitions ? (
                <Bar
                  dataKey="repetitions"
                  stackId={"a"}
                  className="fill-cyan-400 stroke-cyan-500 dark:fill-cyan-700 dark:stroke-cyan-800"
                  activeBar={<Rectangle className="fill-cyan-600 stroke-cyan-700" />}
                />
              ) : null}

              {chartViewSate.m50_74 ? (
                <Bar
                  dataKey="m50_74"
                  stackId={"a"}
                  className="fill-amber-400 stroke-amber-500 dark:fill-amber-700 dark:stroke-amber-800"
                  activeBar={<Rectangle className="fill-amber-600 stroke-amber-700" />}
                />
              ) : null}
              {chartViewSate.m75_84 ? (
                <Bar
                  dataKey="m75_84"
                  stackId={"a"}
                  className="fill-blue-400 stroke-blue-500 dark:fill-blue-700 dark:stroke-blue-800"
                  activeBar={<Rectangle className="fill-blue-600 stroke-blue-700" />}
                />
              ) : null}
              {chartViewSate.m85_94 ? (
                <Bar
                  dataKey="m85_94"
                  stackId={"a"}
                  className="fill-rose-400 stroke-rose-500 dark:fill-rose-700 dark:stroke-rose-800"
                  activeBar={<Rectangle className="fill-rose-600 stroke-rose-700" />}
                />
              ) : null}
              {chartViewSate.m95_99 ? (
                <Bar
                  dataKey="m95_99"
                  stackId={"a"}
                  className="fill-lime-400 stroke-lime-500 dark:fill-lime-700 dark:stroke-lime-800"
                  activeBar={<Rectangle className="fill-lime-600 stroke-lime-700" />}
                />
              ) : null}
              {chartViewSate.m100 ? (
                <Bar
                  dataKey="m100"
                  stackId={"a"}
                  className="fill-stone-400 stroke-stone-500 dark:fill-stone-700 dark:stroke-stone-800"
                  activeBar={<Rectangle className="fill-stone-600 stroke-stone-700" />}
                />
              ) : null}

              {chartViewSate.noMatch ? (
                <Bar
                  dataKey="noMatch"
                  stackId={"a"}
                  className="fill-violet-400 stroke-violet-500 dark:fill-violet-700 dark:stroke-violet-800"
                  activeBar={<Rectangle className="fill-violet-600 stroke-violet-700" />}
                />
              ) : null}
            </BarChart>
          );
        }}
      </ChartCard>
    </div>
  );
}

function ChartToolTip({
  data,
  formatDate,
  formatNumber,
}: {
  data: TmLeveragePerLanguagesType & { monthYear: Date };
  formatDate: (date: Date) => string;
  formatNumber: (value: number) => string;
}) {
  return (
    <Card>
      <CardHeader className="px-2 py-1 text-base">
        <CardTitle className="flex flex-col gap-1 text-base">
          <span>{formatDate(data.monthYear)}</span>
          <div className="grid grid-cols-[min-content_auto] gap-2">
            <ChartCategoryInfo prefix={"noMatch"}></ChartCategoryInfo>
            <span className="text-right">{formatNumber(data.noMatch)}</span>

            <ChartCategoryInfo prefix={"m100"}></ChartCategoryInfo>
            <span className="text-right">{formatNumber(data.m100)}</span>

            <ChartCategoryInfo prefix={"m95_99"}></ChartCategoryInfo>
            <span className="text-right">{formatNumber(data.m95_99)}</span>

            <ChartCategoryInfo prefix={"m85_94"}></ChartCategoryInfo>
            <span className="text-right">{formatNumber(data.m85_94)}</span>

            <ChartCategoryInfo prefix={"m75_84"}></ChartCategoryInfo>
            <span className="text-right">{formatNumber(data.m75_84)}</span>

            <ChartCategoryInfo prefix={"m50_74"}></ChartCategoryInfo>
            <span className="text-right">{formatNumber(data.m50_74)}</span>

            <ChartCategoryInfo prefix={"repetitions"}></ChartCategoryInfo>
            <span className="text-right">{formatNumber(data.repetitions)}</span>

            <ChartCategoryInfo prefix={"xTranslated"}></ChartCategoryInfo>
            <span className="text-right">{formatNumber(data.xTranslated)}</span>
            <div className="col-span-2">
              <Separator />
            </div>
            <ChartCategoryInfo prefix={"words"}></ChartCategoryInfo>
            <span className="text-right">{formatNumber(data.words)}</span>
          </div>
        </CardTitle>
      </CardHeader>
    </Card>
  );
}
