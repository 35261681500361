import NotAllowedPage from "@/pages/errors/NotAllowedPage";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { extractAuthorizedPaths, sanitizePath } from "@/utils/url";
import { useMemo } from "react";
import { Outlet, useLocation } from "react-router";

export function AuthorizeRoute() {
  const location = useLocation();
  const userModules = useAuthUserStore((store) => store?.user?.modules);
  const userReportModules = useAuthUserStore((store) => store?.user?.reportModules);

  // user authorized paths
  const authorizedPaths = useMemo(
    () => extractAuthorizedPaths(userModules, userReportModules),
    [userModules, userReportModules]
  );

  const currentPath = sanitizePath(location.pathname); // current path

  const isHomePath = currentPath === "/client"; // home path always authorized

  const isAuthorizedPath = !!authorizedPaths.find(
    (authorizedPath) => authorizedPath === currentPath || currentPath.startsWith(authorizedPath)
  );

  if (isHomePath || isAuthorizedPath) {
    return <Outlet />;
  }

  return <NotAllowedPage hideFooter={true} hideGoBackAction={true} />;
}
