import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { requestRefreshToken } from "./user.api";

const api = axios.create({
  timeout: 30000,
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

// Response interceptor to log VNextResponseModel errors
api.interceptors.response.use((response: AxiosResponse) => {
  if (
    response.data?.statusCode != 200 &&
    response.data?.errors &&
    Array.isArray(response.data.errors) &&
    response.data.errors.length > 0
  ) {
    console.warn("API returned errors:", response.data.errors);
  }
  return response;
});

// Instantiate the interceptor
createAuthRefreshInterceptor(api, requestRefreshToken);

export async function requestDownloadFile(
  url: string,
  body: unknown | null,
  defaultFileName?: string | ((fileName: string) => string)
): Promise<void> {
  let href: string | undefined;
  let link: HTMLAnchorElement | undefined;

  try {
    const config: AxiosRequestConfig = { responseType: "blob", timeout: 0 }; // no timeout for blob
    const response = body ? await api.post(url, body, config) : await api.get(url, config);

    const initialFileName = getDownloadedFileNameFromResponse(
      response.headers["content-disposition"],
      typeof defaultFileName === "string" ? defaultFileName : undefined
    );

    const fileName = typeof defaultFileName === "function" ? defaultFileName(initialFileName) : initialFileName;

    const blob = new Blob([response.data]);
    href = window.URL.createObjectURL(blob);
    link = document.createElement("a");

    link.href = href;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  } finally {
    // Cleanup
    if (link && link.parentNode) {
      document.body.removeChild(link);
    }
    if (href) {
      window.URL.revokeObjectURL(href);
    }
  }
}

function decodeFileName(encodedFileName: string): string {
  return encodedFileName.includes("%25")
    ? decodeURIComponent(decodeURIComponent(encodedFileName))
    : decodeURIComponent(encodedFileName);
}

export function getDownloadedFileNameFromResponse(disposition: string, defaultFileName = "file"): string {
  if (!disposition || !disposition.includes("attachment")) {
    return defaultFileName;
  }

  // Try UTF-8 filename first
  const utf8FilenameRegex = /filename\*=UTF-8''([^;]+)/i;
  const utf8FilenameMatch = utf8FilenameRegex.exec(disposition);
  if (utf8FilenameMatch?.[1]) {
    return decodeFileName(utf8FilenameMatch[1]);
  }

  // Fall back to standard filename
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const filenameMatch = filenameRegex.exec(disposition);
  if (filenameMatch?.[1]) {
    const cleanFileName = filenameMatch[1].replace(/['"]/g, "");
    return decodeFileName(cleanFileName);
  }

  return defaultFileName;
}

export default api;
