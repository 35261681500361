import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { bytesToSize, excludedFileChars, excludedFileExtensions, excludedFileTypes } from "@/utils/file";
import { UploadCloudIcon } from "lucide-react";
import { useCallback } from "react";
import { FileError, FileRejection, useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "sonner";

const MAX_FILE_SIZE = 2_000_000_000; // 2 GB
const MAX_FILES = 50; //maximun number of files that can be uploaded

export const useNotifyFileRejected = () => {
  const { t } = useTranslation("translation", { keyPrefix: "requests.create.upload" });

  const notifyFileRejected = useCallback(
    ({ name, error, meta }: { name: string; error: string; meta: string }) => {
      toast.error(t("errors.file-rejected"), {
        dismissible: true,
        description: (
          <Alert variant="default" className="border-0">
            <AlertTitle>{name}</AlertTitle>
            <AlertDescription>
              <div>{t(`errors.${error}`, { meta })}</div>
            </AlertDescription>
          </Alert>
        ),
      });
    },
    [t]
  );

  return notifyFileRejected;
};

const validateFile = (file: File, totalFileSize: number): FileError | null => {
  if (totalFileSize > MAX_FILE_SIZE) {
    return { code: "files-size-limit", message: "files-size-limit" };
  }

  const invalidExtResponse = (ext: string) => {
    return { code: "file-invalid-ext", message: "file-invalid-ext", meta: ext.toUpperCase() };
  };

  if (excludedFileTypes.includes(file.type)) {
    return invalidExtResponse(file.type);
  }

  if (file.name) {
    const noExtResponse = { code: "file-no-ext", message: "file-no-ext" };
    if (!file.name.includes(".")) return noExtResponse;
    const extension = file.name.split(".").pop();
    if (!extension) return noExtResponse;
    if (excludedFileExtensions.includes(extension)) return invalidExtResponse(extension);

    const invalidChars = excludedFileChars.filter((c) => file.name.includes(c));
    if (invalidChars.length > 0) {
      const invalidNameResponse = (char: string) => {
        return {
          code: "file-invalid-chars",
          message: "file-invalid-chars",
          meta: char,
        };
      };
      return invalidNameResponse(invalidChars.join(","));
    }
  }
  return null;
};

interface BaseProps {
  totalFileSize: number;
  uploadFiles: (files: File[]) => void;
}

interface InputProps extends BaseProps {
  variant: "input";
  placeholder: string; // Mandatory if variant is "input"
}
interface DropzoneProps extends BaseProps {
  variant: "dropzone";
}

type Props = InputProps | DropzoneProps;

const UploadFileZone = ({ variant, totalFileSize, uploadFiles, ...rest }: Props) => {
  const { t } = useTranslation("translation");
  const notifyFileRejected = useNotifyFileRejected();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropRejected: (rejected: FileRejection[]) => {
      const errors = rejected.map(({ file, errors }) => {
        const errorWithMeta = errors as Array<FileError & { meta: string }>;
        return { name: file.name, error: errorWithMeta[0].code, meta: errorWithMeta[0].meta };
      });
      errors.forEach(({ name, error, meta }) => notifyFileRejected({ name, error, meta }));
    },
    onDropAccepted: (accepted: File[]) => uploadFiles(accepted),
    validator: (file) => validateFile(file, totalFileSize),
    multiple: true,
    maxSize: MAX_FILE_SIZE,
    minSize: 1,
    maxFiles: MAX_FILES,
  });

  if (variant === "input") {
    const label = "placeholder" in rest ? rest.placeholder : t("requests.create.upload.upload-message-drop");
    return (
      <div
        className="flex h-10 flex-row flex-nowrap items-center gap-2 rounded-md border border-neutral-300 p-1 outline-none focus:border-2 focus-visible:border-ribbon dark:border-border dark:focus-visible:border-ribbon"
        {...getRootProps()}
      >
        <input {...getInputProps()} data-test="support-file-upload" />
        <Button
          type="button"
          autoFocus={false}
          tabIndex={-1}
          variant="secondary"
          size="default"
          className="m-0 h-8 p-3"
        >
          {t("requests.create.upload.chooseFiles")}
        </Button>

        {isDragActive ? (
          <p className="text-sm">{t("requests.create.upload.upload-message-active")}</p>
        ) : (
          totalFileSize === 0 && <p className="text-sm text-neutral-500 dark:text-muted-foreground">{label}</p>
        )}
      </div>
    );
  }

  return (
    <div className="mb-4 flex items-center justify-center">
      <div
        className="flex w-full cursor-pointer flex-col items-center justify-center rounded-xl border-2 border-dotted border-neutral-400 bg-neutral-100 p-3 dark:border-border dark:bg-muted"
        {...getRootProps()}
      >
        <input {...getInputProps()} data-test="source-file-upload" />
        <div className="flex flex-col items-center justify-center gap-1">
          <UploadCloudIcon className="size-9 text-ribbon" />

          <div className="text-center text-lg">
            {isDragActive ? (
              <p className="font-semibold">{t("requests.create.upload.upload-message-active")}</p>
            ) : (
              <span>
                <span className="mr-1 font-semibold">{t("requests.create.upload.upload-message-drop")}</span>
                <Trans
                  i18nKey="requests.create.upload.upload-message-click"
                  components={{ s: <span className="cursor-pointer underline" /> }}
                />{" "}
              </span>
            )}
          </div>
          <p className="text-base text-neutral-600 dark:text-muted-foreground">
            {t("requests.create.upload.upload-message-condition", { size: bytesToSize(MAX_FILE_SIZE) })}
          </p>

          {totalFileSize > 0 ? (
            <p className="text-base text-neutral-600 dark:text-muted-foreground">
              {t("requests.create.upload.upload-message-current-size", { size: bytesToSize(totalFileSize) })}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UploadFileZone;
