import type { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { FloatingLabelInput } from "./input/FloatingInput";

interface SearchInputProps {
  term: string | null;
  onSearch: (term: string | null) => void;
  className?: string;
  label?: string;
}

export function SearchInput({ term = "", className, onSearch, label = "common.search.label" }: SearchInputProps) {
  const { t } = useTranslation("translation");

  const handleOnSearch = (fromTerm: string) => {
    onSearch(fromTerm.trim() === "" ? null : fromTerm);
  };

  return (
    <div className="min-w-[250px] flex-1">
      <FloatingLabelInput
        type="search"
        name="pp-search"
        data-test="data-table-search"
        value={term ?? ""}
        className={className}
        autoComplete="one-time-code"
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnSearch(e.target.value)}
        label={t(label)}
      />
    </div>
  );
}
