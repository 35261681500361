import { Alert } from "@/components/ui/alert";
import { TerminologyUploadResult } from "@/model/mt.typing";
import { Button } from "components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "components/ui/dialog";
import { t } from "i18next";

interface GlossaryResultsProps {
  glossaryResult: TerminologyUploadResult | null;
  setGlossaryResult: (value: TerminologyUploadResult | null) => void;
}

export const GlossaryResults = (props: GlossaryResultsProps) => {
  const { glossaryResult, setGlossaryResult } = props;
  if (glossaryResult === null) return null;

  return (
    <Dialog open={true}>
      <DialogContent showClose={false}>
        <DialogHeader>
          <DialogTitle>{t("mt.glossary.importResults")}</DialogTitle>
        </DialogHeader>
        <div className="space-y-2 text-sm">
          <Alert className="grid" variant={glossaryResult.success ? "info" : "warning"}>
            <span className="text-lg">
              {glossaryResult.success
                ? t("mt.glossary.uploadDialog.uploadSuccess")
                : t("mt.glossary.uploadDialog.uploadFailure")}
            </span>
            {glossaryResult.resultData && glossaryResult.resultData.addedCount !== 0 ? (
              <span className="mt-2 text-sm">
                {t("mt.glossary.uploadDialog.addedItems", {
                  addedCount: glossaryResult.resultData.addedCount,
                  glossaryName: glossaryResult.terminology?.name,
                  totalCount: glossaryResult.terminology?.terminologyItems.length,
                })}
              </span>
            ) : null}
            {glossaryResult.issues.surplusItems !== 0 ? (
              <span className="mt-2 text-sm">
                {t("mt.glossary.uploadDialog.surplusItems", { surplusCount: glossaryResult.issues.surplusItems })}
              </span>
            ) : null}
            {glossaryResult.issues.duplicateItems !== 0 ? (
              <span className="mt-2 text-sm">
                {t("mt.glossary.uploadDialog.duplicateItems", { duplicateCount: glossaryResult.issues.duplicateItems })}
              </span>
            ) : null}
            {!glossaryResult.issues.isDocumentOk ? (
              <span className="mt-2 text-sm">{t("mt.glossary.uploadDialog.documentFormatIssue")}</span>
            ) : null}
            {glossaryResult.issues.isDocumentMaxItems ? (
              <span className="mt-2 text-sm">{t("mt.glossary.uploadDialog.uploadRule5")}</span>
            ) : null}
            {!glossaryResult.issues.isApiOk ? (
              <span className="mt-2 text-sm">{t("mt.glossary.uploadDialog.apiAddIssue")}</span>
            ) : null}
          </Alert>
        </div>
        <DialogFooter className="grid justify-items-end">
          <Button autoFocus variant="outline" onClick={() => setGlossaryResult(null)}>
            {t("mt.ok")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
