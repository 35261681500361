import type { SortingState } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo } from "react";
import { ParamKeyValuePair, useSearchParams } from "react-router";
import { getLocalStorageValue, setLocalStorageValue } from "utils/storage";

type SortType = SortingState;

export interface UrlParamsType {
  sortBy: SortingState;
  searchTerm: string;
}

function getQueryParam(searchParams: URLSearchParams, key: string, defaultValue: string): string {
  const value = searchParams.get(key);
  if (value && value.trim().length > 0) {
    return value;
  }
  return defaultValue;
}

function parseSortBy(sortBy: string | null): SortType {
  if (typeof sortBy !== "string" || sortBy.trim().length === 0) {
    return [];
  }
  const parsedValues = (sortBy ?? "").split("_").filter((value) => value && value.length > 0);
  if (parsedValues.length === 0) {
    return [];
  }
  const [columnId = "", direction = "asc"] = parsedValues;
  return [{ id: columnId, desc: direction === "desc" }] as SortType;
}

function sortToString(sortingState: SortingState): string {
  if (sortingState.length > 0) {
    const { id, desc } = sortingState[0];
    return `${id}_${desc ? "desc" : "asc"}`;
  }
  return "";
}

function loadFromUrlParams<P extends UrlParamsType>(searchParams: URLSearchParams, defaultValues: P): P {
  const searchTerm = getQueryParam(searchParams, "searchTerm", defaultValues.searchTerm);
  const sortBy = parseSortBy(getQueryParam(searchParams, "sortBy", ""));
  return { searchTerm, sortBy: sortBy.length > 0 ? sortBy : [] } as P;
}

export function useUrlParams<P extends UrlParamsType = UrlParamsType>(options: {
  localStorageKey: string;
  defaultValues: P;
  checkIsFilterActive?: (params: P) => boolean;
}) {
  const { localStorageKey, defaultValues } = options;
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // if no params, check local storage, then fallback to default values
    if (Array.from(searchParams).length === 0) {
      const fromLocalStorage = getLocalStorageValue<string>(localStorageKey);
      const values = fromLocalStorage
        ? loadFromUrlParams(new URLSearchParams(fromLocalStorage), defaultValues)
        : defaultValues;

      const newParamsValue = Object.entries(values)
        .filter(([, value]) => value !== null)
        .map(([key, value]) => {
          const formattedValue = key === "sortBy" ? sortToString(value) : value;
          return [key, formattedValue] as ParamKeyValuePair;
        });
      setSearchParams(newParamsValue, { replace: true });
      if (localStorageKey) {
        setLocalStorageValue(localStorageKey, new URLSearchParams(newParamsValue).toString());
      }
    }
  }, [searchParams, setSearchParams, localStorageKey, defaultValues]);

  const params = useMemo(() => {
    return loadFromUrlParams(searchParams, defaultValues);
  }, [defaultValues, searchParams]);

  const setParams = useCallback(
    (newParams: Partial<P>) => {
      if (newParams.searchTerm !== undefined) {
        if (newParams.searchTerm) {
          searchParams.set("searchTerm", newParams.searchTerm.toString());
        } else {
          searchParams.delete("searchTerm");
        }
      }

      if (newParams.sortBy !== undefined) {
        // sortBy should always be defaulted
        searchParams.set(
          "sortBy",
          newParams.sortBy.length > 0 ? sortToString(newParams.sortBy) : sortToString(defaultValues.sortBy)
        );
      }

      if (localStorageKey) {
        setLocalStorageValue(localStorageKey, searchParams.toString());
      }
      setSearchParams(searchParams, { preventScrollReset: true });
    },
    [searchParams, localStorageKey, setSearchParams, defaultValues.sortBy]
  );

  return {
    queryParams: { ...params, searchParams },
    setQueryParams: setParams,
  };
}
