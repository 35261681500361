import Loader from "components/Loader";

function FullPageLoader() {
  return (
    <main data-test="full-page-loader" className="grid h-full grid-rows-1 items-center justify-center">
      <div>
        <Loader
          data-test="full-page-loader-content"
          isShown={true}
          displayText={false}
          variant="xl"
          className="m-0 mb-6"
        />
      </div>
    </main>
  );
}

export default FullPageLoader;
