export function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
  // Check if arrays are the same reference
  if (arr1 === arr2) {
    return true;
  }

  // Check if arrays are the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Loop through the arrays to compare elements
  for (let i = 0; i < arr1.length; i++) {
    // If the elements are arrays themselves, call arraysAreEqual recursively
    if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
      if (!arraysAreEqual(arr1[i] as unknown[], arr2[i] as unknown[])) {
        return false;
      }
    }
    // Otherwise, compare the elements directly
    else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // If all checks pass, arrays are equal
  return true;
}
