import ErrorLayoutPage from "routes/layout/ErrorLayoutPage";
import { useTranslation } from "react-i18next";

function NotAllowedPage(options?: { hideFooter?: boolean; hideGoBackAction?: boolean }) {
  const { t } = useTranslation("error");

  return (
    <ErrorLayoutPage
      errorCode={401}
      title={t("page.notAllowed.title")}
      description={t("page.notAllowed.description")}
      options={options}
    />
  );
}

export default NotAllowedPage;
