import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { experimental_createPersister, type AsyncStorage } from "@tanstack/react-query-persist-client";
import { createStore, del, get, set, type UseStore } from "idb-keyval";

function newIdbStorage(idbStore: UseStore): AsyncStorage {
  return {
    getItem: async (key) => await get(key, idbStore),
    setItem: async (key, value) => await set(key, value, idbStore),
    removeItem: async (key) => await del(key, idbStore),
  };
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      staleTime: 1000 * 60 * 5, // 5 minutes
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      persister: experimental_createPersister({
        filters: {
          predicate(query) {
            return query.meta?.persist === true;
          },
        },
        storage: newIdbStorage(createStore("acolad_portal_db", "cached_queries")),
        maxAge: 1000 * 60 * 60 * 4, // 4 hours
      }),
    },
  },
});

function ReactQueryProvider({ children }: { children: React.ReactNode }) {
  const isDisableReactQueryDevtools = Boolean(import.meta.env.AP_DISABLE_REACT_QUERY_DEBUG) === true;
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {isDisableReactQueryDevtools ? null : (
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" client={queryClient} />
      )}
    </QueryClientProvider>
  );
}

export { queryClient, ReactQueryProvider };
