import { Combobox } from "@/components/combobox/Combobox";
import { ComboboxSingleSelection } from "@/components/combobox/ComboboxSingleSelection";
import { DatePicker } from "@/components/DatePicker";
import { FloatingLabelInput } from "@/components/input/FloatingInput";
import { Checkbox } from "@/components/ui/checkbox";
import { FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Label } from "@/components/ui/label";
import { CustomField, CustomFieldOption, CustomFieldValue } from "@/model/request.typing";
import { cn } from "@/utils/ui";
import { sortBy } from "lodash";
import { AlertTriangleIcon } from "lucide-react";
import { Control, ControllerFieldState, ControllerRenderProps, FieldValues, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";

export function CustomFieldFormField<T extends FieldValues>({
  isCreate,
  control,
  fieldConfig,
  invalidOptions = [],
}: {
  isCreate: boolean;
  fieldConfig: CustomField;
  control: Control<T>;
  invalidOptions?: CustomFieldOption[];
}) {
  const required = fieldConfig.isMandatory ? "*" : "";
  const label = isCreate ? `${fieldConfig.label} ${required}`.trim() : fieldConfig.label;
  return (
    <FormField
      control={control}
      name={`customFields.${fieldConfig.customFieldId.toString()}` as Path<T>}
      render={({ field, fieldState }) => (
        <CustomFieldRenderer
          field={field}
          fieldState={fieldState}
          fieldConfig={fieldConfig}
          label={label}
          invalidOptions={invalidOptions}
        />
      )}
    />
  );
}

function InvalidOptionsWarning({
  invalidOptions,
  fieldType,
}: {
  invalidOptions: CustomFieldOption[];
  fieldType: CustomField["fieldType"];
}) {
  const { t } = useTranslation();

  if (invalidOptions.length === 0) return null;

  // Only show for select fields
  if (fieldType !== "MultiSelect" && fieldType !== "SingleSelect") return null;

  return (
    <span
      className="flex items-start gap-2 rounded-md bg-amber-50 p-2 text-xs dark:bg-amber-950/30"
      role="alert"
      aria-live="polite"
    >
      <AlertTriangleIcon className="size-4 shrink-0 text-amber-500/80" />
      <span className="text-amber-700 dark:text-amber-400">
        {t(
          fieldType === "MultiSelect"
            ? "requests.details.customFields.warnings.invalidOptions"
            : "requests.details.customFields.warnings.invalidOption",
          {
            count: invalidOptions.length,
            options: invalidOptions.map((o) => o.optionText).join(", "),
            option: invalidOptions[0].optionText,
          }
        )}
      </span>
    </span>
  );
}

function CustomFieldRenderer<T extends FieldValues>({
  fieldConfig,
  field,
  fieldState,
  label,
  className,
  invalidOptions = [],
}: {
  fieldConfig: CustomField;
  field: ControllerRenderProps<T, Path<T>>;
  fieldState: ControllerFieldState;
  label: string;
  className?: string;
  invalidOptions?: CustomFieldOption[];
}) {
  const hasError = !!fieldState.error?.message;

  let input = (
    <div className={className}>
      <FloatingLabelInput
        {...field}
        label={label}
        required={fieldConfig.isMandatory}
        hasError={!!fieldState.error?.message}
      />
    </div>
  );

  switch (fieldConfig.fieldType) {
    case "MultiSelect": {
      const { options, selectedIds } = keepOnlyValidOrSelectedOptions(fieldConfig.options, field.value);
      input = (
        <div className="w-fit">
          <Combobox
            options={options}
            selectedIds={selectedIds}
            onChange={(value) => {
              field.onChange(value.map((v) => v.id));
              field.onBlur();
            }}
            title={label}
          />
        </div>
      );
      break;
    }
    case "SingleSelect": {
      const { options, selectedIds } = keepOnlyValidOrSelectedOptions(fieldConfig.options, field.value);
      input = (
        <div className="w-fit">
          <ComboboxSingleSelection
            options={options}
            selectedId={selectedIds[0]}
            onChange={(value) => {
              field.onChange(value?.id ?? null);
              field.onBlur();
            }}
            title={label}
          />
        </div>
      );
      break;
    }
    case "Date":
      input = (
        <div className="w-fit">
          <DatePicker
            label={label}
            value={field.value}
            hasError={!!fieldState.error?.message}
            canDeleteValue={!fieldConfig.isMandatory}
            onChange={(value) => {
              field.onChange(value ?? null);
              field.onBlur();
            }}
          />
        </div>
      );
      break;

    case "Boolean":
      input = (
        <div className="mt-2 flex items-center gap-2">
          <Checkbox
            {...field}
            id={`customField-${fieldConfig.customFieldId.toString()}`}
            data-test="input-request-name"
            checked={field.value === true}
            onCheckedChange={(checked) => {
              field.onChange(!!checked);
              field.onBlur();
            }}
            className={cn({
              "border-red-400 dark:border-red-400/90": hasError,
            })}
          />
          <Label htmlFor={`customField-${fieldConfig.customFieldId.toString()}`}>{label}</Label>
        </div>
      );
      break;
    case "Number":
      input = (
        <FloatingLabelInput
          {...field}
          type="number"
          min={fieldConfig.minValue ?? undefined}
          max={fieldConfig.maxValue ?? undefined}
          label={label}
          required={fieldConfig.isMandatory}
          hasError={!!fieldState.error?.message}
          onChange={(e) => {
            field.onChange(e.target.value?.trim() === "" ? undefined : e.target.value);
          }}
        />
      );
      break;
    case "String":
      input = (
        <FloatingLabelInput
          {...field}
          type="text"
          label={label}
          required={fieldConfig.isMandatory}
          hasError={!!fieldState.error?.message}
        />
      );
      break;
    default:
      break;
  }

  return (
    <FormItem className="grid grid-cols-[100%_0%]">
      <FormControl>{input}</FormControl>
      <FormDescription>
        <InvalidOptionsWarning invalidOptions={invalidOptions} fieldType={fieldConfig.fieldType} />
      </FormDescription>
      <FormMessage />
    </FormItem>
  );
}

function keepOnlyValidOrSelectedOptions(options: CustomFieldOption[], currentValue: CustomFieldValue) {
  if (options.length === 0) {
    return { options: [], selectedIds: [] };
  }

  const selectedIds = new Set(
    currentValue == null ? [] : Array.isArray(currentValue) ? currentValue.map(String) : [String(currentValue)]
  );

  // Single filter pass
  const filteredOptions = options.filter((option) => option.isValid || selectedIds.has(String(option.id)));

  return {
    options: sortBy(filteredOptions, "displayOrder").map((option) => ({
      id: option.id.toString(),
      name: option.optionText,
      isDisabled: !option.isValid,
    })),
    selectedIds: Array.from(selectedIds),
  };
}
