import { FileToTranslate, LanguageOption } from "model/mt.typing";
import { useUploadTranslationFileMutation } from "query/mt/machinetranslation.query";
import { t } from "i18next";
import { useCallback, useEffect } from "react";
import { toast } from "sonner";
import { create } from "zustand";

interface FileUpload {
  id: string;
  file: FileToTranslate;
  clientKey: string;
  mtProviderType: number;
  sourceLanguage: LanguageOption | undefined;
  targetLanguage: LanguageOption | undefined;
  status: string;
}

interface FileUploadStoreState {
  files: FileUpload[];
  addFile: (item: FileUpload) => void;
  removeFile: (id: string) => void;
  updateStatus: (id: string, status: string) => void;
}

export const useFileUploadStore = create<FileUploadStoreState>((set) => ({
  files: [],
  addFile: (item: FileUpload) => {
    set((state) => ({ files: [...state.files, item] }));
  },
  updateStatus: (id: string, status: string) => {
    set((state) => ({
      files: state.files.map((file) => (file.id === id ? ({ ...file, status: status } as FileUpload) : file)),
    }));
  },
  removeFile: (id: string) => {
    set((state) => ({ files: state.files.filter((x) => x.id !== id) }));
  },
}));

export const useFileUploads = () => {
  const addFileToQueue = useFileUploadStore((state) => state.addFile);
  const files = useFileUploadStore((state) => state.files);
  const updateFileStatus = useFileUploadStore((state) => state.updateStatus);
  const removeFile = useFileUploadStore((state) => state.removeFile);

  const onUploadSettled = (file: FileToTranslate, isSuccessful: boolean, errorMessage?: string) => {
    removeFile(file.name);
    let message = "";
    if (!isSuccessful) {
      message = errorMessage ? t(`mt.file.${errorMessage}`) : t("mt.file.uploadFailed", { filename: file.name });
    } else {
      message = t("mt.file.uploadSuccess", { filename: file.name });
    }
    toast.success(message, {
      style: { maxWidth: 640 },
    });
  };

  const { mutate: uploadFile } = useUploadTranslationFileMutation(onUploadSettled);

  // Monitor "files" list to see if we have something to upload
  useEffect(() => {
    const filesToUpload = files.filter((x) => x.status === "toUpload");
    // Check if are not uploading anything currently
    if (filesToUpload.length > 0 && !filesToUpload.some((x) => x.status === "uploading")) {
      const fileToUpload = filesToUpload[0];
      updateFileStatus(fileToUpload.id, "uploading");
      uploadFile({
        currentFile: fileToUpload.file,
        clientKey: fileToUpload.clientKey,
        mtProviderType: fileToUpload.mtProviderType,
        sourceLanguage: fileToUpload.sourceLanguage,
        targetLanguage: fileToUpload.targetLanguage,
      });
    }
  }, [files, updateFileStatus, uploadFile]);

  const addFile = useCallback(
    (
      file: FileToTranslate,
      clientKey: string,
      mtProviderType: number,
      sourceLanguage?: LanguageOption,
      targetLanguage?: LanguageOption
    ) => {
      if (files.some((x) => x.id === file.name)) return false;
      else {
        addFileToQueue({
          id: file.name,
          file,
          clientKey,
          mtProviderType,
          sourceLanguage,
          targetLanguage,
          status: "toUpload",
        });
        return true;
      }
    },
    [files, addFileToQueue]
  );

  return { addFile };
};
