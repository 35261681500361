import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useDateFormatter, useDateOperations, useTimeFormat } from "@/hooks/useDate";
import { cn } from "@/utils/ui";
import { Calendar as CalendarIcon, ClockIcon, XIcon } from "lucide-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonIcon } from "./button/ButtonIcon";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Separator } from "./ui/separator";
import { Toggle } from "./ui/toggle";

interface DatePickerProps {
  value?: Date;
  className?: string;
  hasError?: boolean;
  onChange: (date: Date | undefined) => void;
  toDate?: Date | undefined;
  fromDate?: Date | undefined;
  label: string;
  timePicker?: {
    defaultTime?: { hour?: number; minute?: 0 | 15 | 30 | 45 };
    enable?: boolean;
  };
  canDeleteValue?: boolean;
}

export const DatePicker = ({
  value,
  onChange,
  className,
  hasError,
  fromDate,
  toDate,
  label,
  timePicker = { enable: false },
  canDeleteValue = true,
}: DatePickerProps) => {
  const [open, setOpen] = useState(false);
  const { dateLocale, formatDate, formatDateTime } = useDateFormatter();
  const { convertDate, timezone } = useDateOperations();
  const hidden = [{ before: fromDate }, { after: toDate }].filter(
    (matcher): matcher is { before: Date } | { after: Date } => {
      if (!matcher.before && !matcher.after) return false;
      return true;
    }
  );

  const handleDateSelect = (selected: Date | undefined) => {
    if (!timePicker.enable) {
      if (selected) {
        const newDate = convertDate(selected);
        onChange(newDate);
      } else {
        onChange(undefined);
      }
      setOpen(false);
      return;
    }

    // If time picker is enabled, we need to set the time of the date
    if (selected) {
      const newDate = convertDate(selected);
      if (value) {
        newDate.setHours(value.getHours());
        newDate.setMinutes(value.getMinutes());
      } else {
        // Set default hour when first selecting a date
        newDate.setHours(timePicker.defaultTime?.hour ?? 0);
        newDate.setMinutes(timePicker.defaultTime?.minute ?? 0);
      }
      onChange(newDate);
    } else {
      onChange(undefined);
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            type="button"
            variant={"outline"}
            data-test="button-date-picker"
            className={cn(
              "group w-full justify-start pr-0 text-left font-normal focus-visible:ring-0 focus-visible:ring-offset-0",
              !value && "text-sm font-normal",
              hasError
                ? "border-red-300 text-red-900 placeholder:text-red-300 focus:border-red-500 focus:ring-red-500"
                : "focus:border-2 focus-visible:border-ribbon focus-visible:border-opacity-100",
              className,
              value && canDeleteValue ? "pr-8" : "pr-2"
            )}
          >
            <span className="mr-2 text-sm font-normal">{label}</span>
            {value ? (
              <>
                <Separator orientation="vertical" className="mr-2 h-4" />
                <span className="mr-2" data-test="date-picker-value">
                  {timePicker.enable ? formatDateTime(value) : formatDate(value)}
                </span>
              </>
            ) : (
              <CalendarIcon className="mx-4 size-4 text-muted-foreground" />
            )}
          </Button>
          {value && canDeleteValue && (
            <div className="relative -left-7 flex flex-row items-center gap-2">
              <ButtonIcon
                type="button"
                className="z-10 size-4"
                icon={XIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(undefined);
                }}
              />
            </div>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" data-test="popover-date-picker">
        <Calendar
          mode="single"
          captionLayout="dropdown"
          locale={dateLocale}
          timeZone={timezone}
          selected={value}
          defaultMonth={value}
          startMonth={fromDate}
          endMonth={toDate}
          autoFocus={true}
          disabled={hidden}
          onSelect={(value) => handleDateSelect(value)}
        />
        {timePicker.enable ? <TimePicker date={value} onChange={onChange} setOpen={setOpen} /> : null}
      </PopoverContent>
    </Popover>
  );
};

export function TimePicker({
  date,
  onChange,
  setOpen,
}: {
  date: Date | undefined;
  onChange: (date: Date) => void;
  setOpen: (value: boolean) => void;
}) {
  const { t } = useTranslation("translation");
  const { time, is24Hour, hours, minutes, onTimeChange } = useTime(date, onChange);

  return (
    <div className="flex w-full flex-col gap-3 px-4 py-2" data-test="time-picker">
      <div className="flex w-full flex-row items-center justify-center gap-2">
        <div>
          <ClockIcon className="size-5 text-muted-foreground" />
        </div>
        <div className="flex flex-row flex-nowrap items-center gap-1">
          <Select onValueChange={(hour) => onTimeChange("hour", hour)} value={time.hour}>
            <SelectTrigger className="w-[70px]" data-test="time-picker-hour">
              <SelectValue placeholder="Hour" />
            </SelectTrigger>
            <SelectContent>
              {hours.map((hour) => (
                <SelectItem key={hour.value} value={hour.value}>
                  {hour.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select onValueChange={(minute) => onTimeChange("minute", minute)} value={time.minute}>
            <SelectTrigger className="w-[70px]" data-test="time-picker-minute">
              <SelectValue placeholder="Min" />
            </SelectTrigger>
            <SelectContent>
              {minutes.map((minute) => (
                <SelectItem key={minute.value} value={minute.value}>
                  {minute.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        {!is24Hour && (
          <div className="flex flex-row flex-nowrap items-center">
            <Toggle
              variant="outline"
              className="rounded-r-none border-r-0"
              pressed={time.period === "AM"}
              onPressedChange={() => onTimeChange("period", "AM")}
              data-test="time-picker-period-am"
            >
              AM
            </Toggle>
            <Toggle
              variant="outline"
              className="rounded-l-none border-l-0"
              pressed={time.period === "PM"}
              onPressedChange={() => onTimeChange("period", "PM")}
              data-test="time-picker-period-pm"
            >
              PM
            </Toggle>
          </div>
        )}
      </div>
      <Separator orientation="horizontal" className="h-px" />
      <div className="flex justify-end">
        <Button type="button" onClick={() => setOpen(false)}>
          {t("common.calendar.close")}
        </Button>
      </div>
    </div>
  );
}

export const useTime = (date: Date | undefined, onChange: (date: Date) => void) => {
  const { is24Hour } = useTimeFormat();
  const { now, convertDate } = useDateOperations();
  const currentDate = date ?? now();

  // Generate hours based on format
  const hours = useMemo(
    () =>
      is24Hour
        ? Array.from({ length: 24 }, (_, i) => ({
            value: String(i),
            label: String(i).padStart(2, "0"),
          }))
        : [
            { value: "12", label: "12" },
            ...Array.from({ length: 11 }, (_, i) => ({
              value: String(i + 1),
              label: String(i + 1).padStart(2, "0"),
            })),
          ],
    [is24Hour]
  );

  // Fixed set of minutes at 15-minute intervals
  const minutes = useMemo(
    () =>
      [0, 15, 30, 45].map((i) => ({
        value: String(i),
        label: String(i).padStart(2, "0"),
      })),
    []
  );

  // Format current hour based on time format
  const hour = useMemo(() => {
    const currentHour = currentDate.getHours();
    return String(is24Hour ? currentHour : currentHour === 0 ? 12 : currentHour > 12 ? currentHour - 12 : currentHour);
  }, [currentDate, is24Hour]);

  const minute = useMemo(() => {
    const currentMinute = currentDate.getMinutes();
    if (minutes.some((minute) => minute.value === String(currentMinute))) {
      return String(currentMinute);
    }
    return minutes[0].value;
  }, [currentDate, minutes]);

  const period = useMemo(() => {
    const currentHour = currentDate.getHours();
    return currentHour >= 12 ? "PM" : "AM";
  }, [currentDate]);

  const onTimeChange = (type: "hour" | "minute" | "period", value: string) => {
    const newDate = convertDate(currentDate);
    const currentHour = newDate.getHours();

    switch (type) {
      case "hour": {
        const numValue = parseInt(value, 10);
        if (is24Hour) {
          newDate.setHours(numValue);
        } else {
          const isPM = currentHour >= 12;
          const newHour = numValue === 12 ? (isPM ? 12 : 0) : isPM ? numValue + 12 : numValue;
          newDate.setHours(newHour);
        }
        break;
      }
      case "minute": {
        newDate.setMinutes(parseInt(value, 10));
        break;
      }
      case "period": {
        const baseHour = currentHour % 12;
        newDate.setHours(value === "PM" ? baseHour + 12 : baseHour);
        break;
      }
    }

    onChange(newDate);
  };

  return {
    time: { hour, minute, period },
    is24Hour,
    hours,
    minutes,
    onTimeChange,
  };
};
