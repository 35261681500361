import { cva } from "class-variance-authority";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

// JG: I don't love this solution, LoaderIcon had issues after build
// To address, I'm passing in custom styles, instead of classes
// https://github.com/timolins/react-hot-toast/issues/189
interface Props {
  isShown: boolean;
  displayText?: boolean;
  className?: string;
  variant: "default" | "sm" | "md" | "lg" | "xl";
}

const loaderVariants = cva("animate-spin stroke-muted-foreground stroke-1", {
  variants: {
    variant: {
      default: "size-2",
      sm: "size-6",
      md: "size-10",
      lg: "size-24 sm:size-36",
      xl: "size-48",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const Loader = ({ isShown, className = "mt-12", displayText, variant }: Props) => {
  const { t } = useTranslation();

  if (!isShown) {
    return null;
  }
  return (
    <div data-test="loader-container" className="flex items-center justify-center space-x-4">
      <Loader2 data-test="loader-icon" className={cn(loaderVariants({ variant }), className)} />
      {displayText == null ||
        (displayText && (
          <span data-test="loader-message" className="text-xl font-bold">
            {t("common.loading")}
          </span>
        ))}
    </div>
  );
};

export default Loader;
