import { EmptyDataPlaceholder } from "@/components/EmptyPlaceholder";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { ServiceType } from "@/model/request.typing";
import { clsx } from "clsx";

interface Props {
  service: ServiceType | null | undefined;
  className?: string;
}

export function ServiceDescriptor({ service, className = "" }: Props) {
  if (!service?.name || service.name.trim() === "") {
    return <EmptyDataPlaceholder className={className} />;
  }

  if (service.description) {
    return (
      <HoverCard openDelay={0} closeDelay={0}>
        <HoverCardTrigger className={clsx("underline hover:cursor-pointer", className)} data-test="service-name">
          {service.name}
        </HoverCardTrigger>
        <HoverCardContent className="max-w-md whitespace-normal text-center">{service.description}</HoverCardContent>
      </HoverCard>
    );
  }

  return <span className={className}>{service.name}</span>;
}
