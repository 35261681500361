import { AlignLeftIcon } from "lucide-react";
import { TooltipProvider } from "components/ui/tooltip";
import PortalTooltip from "@/components/PortalTooltip";
import { t } from "i18next";

interface OcrParagraphProps {
  paragraph?: string;
  pageNumber: number;
  paragraphNumber: number;
  updateParagraph?: (pageNumber: number, paragraphNumber: number, paragraph: string | null) => void;
}

export const OcrParagraph = (props: OcrParagraphProps) => {
  const { paragraph, pageNumber, paragraphNumber, updateParagraph } = props;

  return (
    <div className="flex">
      <div
        contentEditable={true}
        spellCheck={false}
        className="mb-2 mr-2 min-h-8 break-all rounded border border-border p-2 align-middle text-sm focus-visible:ring-transparent"
        onBlur={(e) => updateParagraph?.(pageNumber, paragraphNumber, e.currentTarget.textContent)}
        suppressContentEditableWarning={true}
      >
        {paragraph}
      </div>
      <TooltipProvider delayDuration={250}>
        <PortalTooltip text={t("mt.file.pageWithParagraph", { page: pageNumber + 1, paragraph: paragraphNumber + 1 })}>
          <AlignLeftIcon className="mb-2 mr-2 size-5 shrink-0 cursor-pointer opacity-20 hover:opacity-75" />
        </PortalTooltip>
      </TooltipProvider>
    </div>
  );
};
