import { TZDate } from "@date-fns/tz";
import { type Locale, format as formatDateFn, isValid } from "date-fns";

export function formatDate(
  date: Date | null | undefined | string,
  settings: {
    locale: Locale;
    dateFormat: string;
    timeSettings: {
      defaultTimeFormat: string;
      timeFormat: string | null;
      timeZoneShortName: string;
      includeTime: boolean;
      includeTimeZone: "show" | "hide" | "auto";
    };
  }
) {
  const valueDate = typeof date === "string" ? new Date(date) : (date as Date);
  if (!valueDate || !isValid(valueDate)) {
    return "";
  }

  const { includeTime, defaultTimeFormat, includeTimeZone, timeFormat, timeZoneShortName } = settings.timeSettings;

  if (date && (!!timeFormat || includeTime)) {
    const localDateTime =
      typeof date === "string" ? new TZDate(date, timeZoneShortName) : new TZDate(date, timeZoneShortName);

    const isShowTimeZone =
      includeTimeZone === "show" ||
      (includeTimeZone === "auto" && new Date().getTimezoneOffset() !== localDateTime.getTimezoneOffset());

    const formatString = timeFormat
      ? `${settings.dateFormat} ${timeFormat}`
      : `${settings.dateFormat} ${defaultTimeFormat.replace("tt", "aa")}`; // add default time format and convert .net time to date-fns time

    if ((isShowTimeZone && !!timeZoneShortName) || formatString.includes("O")) {
      const dateTimeFormat = formatString.includes("O") ? formatString : `${formatString} O`;
      //time is displayed with timezone
      return formatDateFn(localDateTime, dateTimeFormat, {
        locale: settings.locale,
      }).replace(
        /(GMT\+0)|(GMT)/, // replace GMT+0 or GMT with UTC
        "UTC"
      );
    }

    // time is displayed without timezone
    return formatDateFn(localDateTime, formatString, {
      locale: settings.locale,
    });
  }
  // only date is displayed
  return formatDateFn(valueDate, settings.dateFormat, {
    locale: settings.locale,
  });
}

export function formatNumber(
  number: unknown,
  options: {
    language: string;
    numberLocale: string;
    currency?: string;
    currencyPosition?: number;
    currencyDecimalDigits?: number;
  }
) {
  // test if number is a number and it's not NaN, null or undefined
  if (typeof number !== "number" || Number.isNaN(number) || number === null || number === undefined) {
    return "";
  }

  let symbol = null;
  if (options.currency) {
    const currencyFormatter = new Intl.NumberFormat(options.language, {
      style: "currency",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      currency: options.currency.toUpperCase(),
    });
    const currencyString = currencyFormatter.format(0);
    symbol = currencyString.replace(/[\d\s.,]/g, "").trim();
  }

  const numberFormatter = new Intl.NumberFormat(options.numberLocale, {
    minimumFractionDigits: options.currencyDecimalDigits,
  });

  const formattedNumber = numberFormatter.format(number);

  if (symbol) {
    // put the currency in the right position
    switch (options.currencyPosition) {
      case 1:
        return `${formattedNumber}${symbol}`;
      case 2:
        return `${symbol} ${formattedNumber}`;
      case 3:
        return `${formattedNumber} ${symbol}`;
      default:
        return `${symbol}${formattedNumber}`; // 0 is the default
    }
  }

  return numberFormatter.format(number);
}

export function formatPercentage(
  number: unknown,
  options: { language: string; numberLocale: string; fractionDigits?: number }
) {
  const formattedNumber = number === null || number === 0 || Number.isNaN(Number(number)) ? 0 : Number(number);

  const percentageFormatter = new Intl.NumberFormat(options.language, {
    style: "percent",
    minimumFractionDigits: options.fractionDigits ?? 0,
    maximumFractionDigits: options.fractionDigits ?? 2,
  });

  return percentageFormatter.format(formattedNumber);
}
