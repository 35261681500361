export function setLocalStorageValue(key: string, value: string | object) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(new Event("storage"));
  } catch (err) {
    console.error(err);
  }
}

export function getLocalStorageValue<T extends string | object>(key: string): T | null {
  try {
    const value = localStorage.getItem(key);
    return value ? (JSON.parse(value) as T) : null;
  } catch (err) {
    console.error(err);
    return null;
  }
}
