import { cn } from "@/utils/ui";
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  type InputHTMLAttributes,
  type PropsWithoutRef,
  forwardRef,
} from "react";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "placeholder"> {
  hasError?: boolean;
}

const FloatingInput = forwardRef<HTMLInputElement, InputProps>(({ className, hasError, ...props }, ref) => {
  return (
    <Input
      placeholder=" "
      className={cn(
        "peer",
        "focus-visible:ring-0 focus-visible:ring-offset-0",
        { "focus:border-2 focus-visible:border-ribbon": !hasError },
        className
      )}
      hasError={hasError}
      ref={ref}
      {...props}
    />
  );
});
FloatingInput.displayName = "FloatingInput";

const FloatingLabel = forwardRef<
  ElementRef<typeof Label>,
  ComponentPropsWithoutRef<typeof Label> & { hasError?: boolean }
>(({ className, hasError, ...props }, ref) => {
  return (
    <Label
      className={cn(
        "pointer-events-none absolute start-1 top-2 z-10 origin-[0] -translate-y-4 scale-90 bg-background px-2 text-sm font-normal text-foreground duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-muted-foreground peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-90 peer-focus:px-2 peer-focus:text-ribbon rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4",
        { "text-red-600 peer-focus:text-red-600 dark:text-red-400/90": hasError },
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
FloatingLabel.displayName = "FloatingLabel";

type FloatingLabelInputProps = InputProps & {
  label?: string;
  hasError?: boolean;
};
const FloatingLabelInput = forwardRef<ElementRef<typeof FloatingInput>, PropsWithoutRef<FloatingLabelInputProps>>(
  ({ id, label, hasError, ...props }, ref) => {
    return (
      <div className="relative">
        <FloatingInput ref={ref} id={id} hasError={hasError} {...props} />
        <FloatingLabel htmlFor={id} hasError={hasError}>
          {label}
        </FloatingLabel>
      </div>
    );
  }
);

FloatingLabelInput.displayName = "FloatingLabelInput";

export { FloatingLabelInput };
