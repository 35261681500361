export function objectsAreEqual<T>(obj1: T, obj2: T): boolean {
  // Check if both objects are the same reference
  if (obj1 === obj2) {
    return true;
  }

  // Check if either is not an object or is null
  if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if objects have the same number of keys
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Compare each key and its corresponding value
  for (const key of keys1) {
    // Check if key exists in the second object
    if (!keys2.includes(key)) {
      return false;
    }

    // Compare values
    const value1 = obj1[key as keyof T];
    const value2 = obj2[key as keyof T];

    // If the values are objects, recurse; if not, compare directly
    if (typeof value1 === "object" && typeof value2 === "object") {
      if (!objectsAreEqual(value1, value2)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
}
