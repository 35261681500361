import { EmptyPlaceholder } from "@/components/EmptyPlaceholder";
import { MENU_ICONS } from "@/components/Icons";
import { CardLink } from "@/components/Links";
import { PageHeaderShell } from "@/components/shell/PageHeaderShell";
import { PageShell } from "@/components/shell/PageShell";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { useTranslation } from "react-i18next";

export function ReportsPage() {
  const { t } = useTranslation();
  const reportTiles = useAuthUserStore((store) => store.user?.reportModules);

  return (
    <PageShell data-test="report-page">
      <PageHeaderShell data-test="report-page-header" icon={MENU_ICONS.reports} heading={t("reports.home.reports")} />
      {reportTiles?.length ? (
        <div data-test="report-page-cards" className="grid grid-cols-1 gap-2 lg:grid-cols-2 2xl:grid-cols-3">
          {reportTiles.map((tile) => (
            <CardLink
              key={tile.id}
              link={tile.link}
              icon={tile.iconName}
              title={tile.name}
              description={tile.description}
            />
          ))}
        </div>
      ) : (
        <EmptyPlaceholder>
          <EmptyPlaceholder.Icon icon={MENU_ICONS.reports} />
          <EmptyPlaceholder.Title>{t("reports.home.empty.title")}</EmptyPlaceholder.Title>
          <EmptyPlaceholder.Description>{t("reports.home.empty.description")}</EmptyPlaceholder.Description>
        </EmptyPlaceholder>
      )}
    </PageShell>
  );
}
