import AppVersionDetails from "@/components/AppVersionDetails";
import { getIcon } from "@/components/Links";
import AcoladPortalLogo from "@/components/svgs/Logo";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarSeparator,
  SidebarTrigger,
  useSidebar,
} from "@/components/ui/sidebar";
import { UserModule } from "@/model/user.typing";
import { NewRequestButton } from "@/pages/requests/common/NewRequestButton";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { sanitizeString } from "@/utils/text";
import { cn } from "@/utils/ui";
import { getBaseUrl, getClassicPath, isVnextPath, redirectToPortal, sanitizePath, VNEXT_BASE_PATH } from "@/utils/url";
import { LucideIcon } from "lucide-react";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

export function AppSidebar() {
  const modules = useAuthUserStore((state) => state.user?.modules);
  return (
    <Sidebar collapsible="icon" className="border-none">
      <AppSidebarHeader />
      <AppSidebarContent modules={modules} />
      <AppSidebarFooter />
    </Sidebar>
  );
}

function AppSidebarHeader() {
  const navigate = useNavigate();
  const user = useAuthUserStore((store) => store.user);
  const { state } = useSidebar();
  const isDefaultSite = user?.siteTag === "default";

  return (
    <SidebarHeader className="mb-4 h-16 justify-center" data-test="home-logo">
      <div className="cursor-pointer" onClick={() => (!user?.isVNext ? redirectToPortal() : navigate("/"))}>
        {isDefaultSite || !user?.logoUrl || user.logoUrl.trim().length === 0 ? (
          <AcoladPortalLogo size={state === "collapsed" ? "small" : "normal"} />
        ) : (
          <img alt="logo" src={`${getBaseUrl()}${user.logoUrl}`} />
        )}
      </div>
    </SidebarHeader>
  );
}

interface AppSidebarContentProps {
  modules?: UserModule[];
}
function AppSidebarContent({ modules }: AppSidebarContentProps) {
  const hasRequestsModule = hasRequestModule(modules);
  return (
    <SidebarContent className="p-2">
      <SidebarMenu>
        {hasRequestsModule ? <NewRequestButton buttonType="nav" /> : null}
        {modules?.map((module, index) => {
          return (
            <SidebarNavLink
              key={index}
              moduleName={module.name}
              moduleLink={module.link}
              moduleIconName={module.iconName}
            />
          );
        })}
      </SidebarMenu>
    </SidebarContent>
  );
}

function AppSidebarFooter() {
  const { isMobile } = useSidebar();
  return (
    <SidebarFooter>
      <SidebarSeparator />
      <SidebarMenu>
        <SidebarMenuItem className="mx-auto mt-4">
          <AppVersionDetails />
        </SidebarMenuItem>
      </SidebarMenu>
      {!isMobile && (
        <div className="my-4 flex justify-end">
          <SidebarTrigger className="size-12 hover:bg-sidebar-accent focus-visible:ring-2 focus-visible:ring-sidebar-ring [&>svg]:size-5" />
        </div>
      )}
    </SidebarFooter>
  );
}

interface SidebarNavLinkProps {
  moduleName: string;
  moduleLink: string;
  moduleIconName: string | LucideIcon;
}
function SidebarNavLink({ moduleName, moduleLink, moduleIconName }: SidebarNavLinkProps) {
  const { isVnext, path } = getLinkInfo(moduleLink);
  const location = useLocation();
  const isActive = location.pathname.includes(moduleLink.replace("/app", ""));
  let isCreateRequestPage = false;
  if (moduleName === "Requests" && location.pathname.includes("/client/requests/create")) {
    isCreateRequestPage = true;
  }
  return (
    <SidebarMenuItem>
      <SidebarMenuButton
        className={cn(
          "h-11 rounded-xl px-4 py-1 font-medium active:shadow-inner data-[active=true]:shadow-inner [&>svg]:hover:text-sidebar-primary-foreground [&>svg]:data-[active=true]:text-sidebar-primary-foreground"
        )}
        data-test={`menu-item-${sanitizeString(moduleName, true)}`}
        isActive={isActive && !isCreateRequestPage}
        tooltip={moduleName}
        asChild
      >
        <NavLink to={path} relative={"route"} reloadDocument={!isVnext} end={path === "/client"}>
          {getIcon({ isVnext, icon: moduleIconName, link: moduleLink })}
          <span className="flex gap-2">
            <span>{moduleName}</span>
          </span>
        </NavLink>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
}

function getLinkInfo(pathTo: string) {
  const path = sanitizePath(pathTo);
  const isVnext = isVnextPath(path);
  const portalServerUrl = getBaseUrl();
  return {
    isVnext: isVnext,
    path: isVnext ? path.replace(VNEXT_BASE_PATH, "") : getClassicPath({ portalServerUrl, path }),
  };
}

function hasRequestModule(modules: UserModule[] | undefined) {
  return modules?.some((module) => module.link === "/app/client/requests");
}
