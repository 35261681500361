import { requestDownloadFile } from "@/api/api";
import {
  acceptQuote,
  addAccessor,
  createRequest,
  fetchCustomFields,
  fetchDeliveredRequests,
  fetchDivisions,
  fetchInProgressRequests,
  fetchLanguages,
  fetchMostRecent,
  fetchNewRequests,
  fetchProjectAnalysis,
  fetchProjectFinancials,
  fetchRequestDetail,
  fetchRequestDocuments,
  fetchRequestIdentifier,
  fetchServiceBundles,
  getDownloadUrl,
  rejectQuote,
  removeAccessor,
  searchContacts,
  updateCustomField,
  updatePONumber,
} from "@/api/request.api";
import { useDateOperations } from "@/hooks/useDate";
import { IdName } from "@/model/common";
import type {
  AccessorType,
  AllRequestType,
  AnalysisType,
  BaseRequestType,
  CustomField,
  CustomFieldValue,
  CustomFieldValueType,
  DeliveredRequestType,
  DivisionType,
  InProgressRequestType,
  LanguageType,
  MostRecentLanguage,
  NewRequestType,
  ProjectDocument,
  RequestDetailType,
  RequestFinancialType,
  ServiceType,
} from "@/model/request.typing";
import type { NewRequestFormType } from "@/pages/requests/create/useNewRequestForm";
import { convertAPIObjectDatesToUserTimezone } from "@/utils/dates";
import { queryOptions, useMutation, useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { concat, findIndex, orderBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

export const useLanguagesQuery = () => {
  const {
    data = [],
    error,
    isLoading,
  } = useQuery<LanguageType[], AxiosError>({
    queryKey: ["requests", "languages"],
    queryFn: async ({ signal }) => {
      return await fetchLanguages(signal);
    },
  });
  return { languages: data, error, isLoading };
};

const mostRecentQueryOptions = queryOptions<MostRecentLanguage, AxiosError>({
  queryKey: ["requests", "mostRecent"],
  queryFn: ({ signal }) => fetchMostRecent(signal),
});

export const useMostRecentLanguageQuery = (languageType: "source" | "target" = "source") => {
  const { languages } = useLanguagesQuery();
  const { data: mostRecent } = useQuery(mostRecentQueryOptions);

  return useMemo(() => {
    const recentLanguages = mostRecent?.[`${languageType}Languages`] ?? {};

    if (Object.keys(recentLanguages).length === 0) {
      return languages;
    }

    return languages.map(
      (lang): LanguageType => ({
        ...lang,
        frequency: recentLanguages[lang.id] ?? 0,
      })
    );
  }, [mostRecent, languageType, languages]);
};

export const useServiceBundleQuery = (divisionId?: string) => {
  const useServiceBundleQueryOptions = queryOptions<ServiceType[], AxiosError>({
    queryKey: ["requests", "service-bundles", divisionId],
    queryFn: ({ signal }) => fetchServiceBundles(divisionId, signal),
  });

  return useQueries({
    queries: [useServiceBundleQueryOptions, mostRecentQueryOptions],
    combine: (results) => {
      const [{ data: services = [] }, { data: mostRecent }] = results;
      if (!mostRecent?.serviceBundles) return services;
      return orderBy(
        services.map((service) => ({ ...service, frequency: mostRecent.serviceBundles[service.id] ?? 0 })),
        [
          "highlighted", // First, sort by highlighted
          "frequency", // Then, sort by most used
          (lang: IdName) => findIndex(services, { id: lang.id }), // Finally, sort by ID
        ],
        ["desc", "desc", "asc"] // Sort order: highlighted first, most used next, and ascending ID
      );
    },
  });
};

export const useRequestDocumentsQuery = (id: string, entityType: string) => {
  const { languages } = useLanguagesQuery();
  const { data = [], isLoading } = useQuery<ProjectDocument[], AxiosError>({
    queryKey: ["requests", "documents", entityType, id],
    queryFn: ({ signal }) => fetchRequestDocuments({ id, entityType }, signal),
  });
  const result = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map((document) => {
      return {
        ...document,
        sourceFile: {
          ...document.sourceFile,
          sourceLanguage:
            languages.find((language) => language.id === document.sourceFile.sourceLanguage.id) ??
            document.sourceFile.sourceLanguage,
        },
        targetFiles: document.targetFiles.map((target) => {
          const targetLanguage =
            languages?.find((language) => language.id === target.targetLanguage.id) ?? target.targetLanguage;
          return { ...target, targetLanguage };
        }),
      } as ProjectDocument;
    });
  }, [data, languages]);

  return { data: result, isLoading };
};

export const useProjectFinancialsQuery = (projectId?: string | undefined) => {
  return useQuery<RequestFinancialType[], AxiosError>({
    queryKey: ["requests", "financials", projectId],
    queryFn: async ({ signal }) => {
      if (!projectId) {
        return [];
      }
      return await fetchProjectFinancials(projectId, signal);
    },
    staleTime: 1000 * 30, // 30 seconds
    enabled: !!projectId,
  });
};

export const useProjectAnalysisQuery = (projectCode?: string | null | undefined) => {
  return useQuery<AnalysisType | null, AxiosError>({
    queryKey: ["requests", "analysis", projectCode],
    queryFn: async ({ signal }) => {
      if (!projectCode) return null;
      return await fetchProjectAnalysis(projectCode, signal);
    },
    staleTime: 1000 * 30, // 30 seconds
    enabled: !!projectCode,
  });
};

/*
 * // GCE-3679: filter canceled/rejected requests by default and only show them in the all tab
 */
export const useNewRequestsQuery = (
  params: { filterCanceledRejected: boolean } = {
    filterCanceledRejected: true,
  }
) => {
  const { filterCanceledRejected } = params;
  const services = useServiceBundleQuery();
  const { timezone } = useDateOperations();
  const { data, isLoading } = useQuery<NewRequestType[], AxiosError>({
    queryKey: ["requests", "new"],
    queryFn: ({ signal }) => fetchNewRequests(signal),
    staleTime: 1000 * 30, // 30 seconds
  });

  const result = useMemo(
    () =>
      convertAPIObjectDatesToUserTimezone(
        mapToService(data, services).filter((request) =>
          filterCanceledRejected ? ["cancelled", "rejected"].includes(request.status) === false : true
        ),
        ["createdOn", "quoteSubmissionDueDate", "deadline"],
        timezone
      ),
    [data, filterCanceledRejected, services, timezone]
  );

  return { data: result, isLoading };
};

export const useInProgressRequestsQuery = () => {
  const services = useServiceBundleQuery();
  const { timezone } = useDateOperations();
  const { data, isLoading } = useQuery<InProgressRequestType[], AxiosError>({
    queryKey: ["requests", "in-progress"],
    queryFn: ({ signal }) => fetchInProgressRequests(signal),
    staleTime: 1000 * 30, // 30 seconds
  });

  const result = useMemo(
    () => convertAPIObjectDatesToUserTimezone(mapToService(data, services), ["createdOn", "deadline"], timezone),
    [data, services, timezone]
  );

  return { data: result, isLoading };
};

export const useDeliveredRequestsQuery = () => {
  const services = useServiceBundleQuery();
  const { timezone } = useDateOperations();
  const { data, isLoading } = useQuery<DeliveredRequestType[], AxiosError>({
    queryKey: ["requests", "delivered"],
    queryFn: ({ signal }) => fetchDeliveredRequests(signal),
    staleTime: 1000 * 30, // 30 seconds
  });
  const result = useMemo(
    () => convertAPIObjectDatesToUserTimezone(mapToService(data, services), ["createdOn", "deliveredOn"], timezone),
    [data, services, timezone]
  );
  return { data: result, isLoading };
};

export const useAllRequestsQuery = () => {
  const { data: newRequests, isLoading: isLoadingNewRequests } = useNewRequestsQuery({ filterCanceledRejected: false }); // include filter for canceled and rejected in all requests
  const { data: inProgressRequests, isLoading: isLoadingInProgressRequests } = useInProgressRequestsQuery();
  const { data: deliveredRequests, isLoading: isLoadingDeliveredRequests } = useDeliveredRequestsQuery();

  const allRequests = useMemo(
    () => concat<AllRequestType>(deliveredRequests, inProgressRequests, newRequests),
    [newRequests, inProgressRequests, deliveredRequests]
  );

  return {
    data: allRequests,
    isLoading: isLoadingNewRequests || isLoadingInProgressRequests || isLoadingDeliveredRequests,
  };
};

export const useRequestDetailQuery = (id: string, entityType: string, type: string) => {
  const { languages } = useLanguagesQuery();
  const services = useServiceBundleQuery();
  const queryClient = useQueryClient();

  const cachedData = useMemo(() => {
    const values = queryClient.getQueryData(["requests", type]);
    if (Array.isArray(values)) {
      return values.find((value) => value.id?.toString() === id);
    }
  }, [queryClient, type, id]);

  const { data, isLoading } = useQuery<RequestDetailType | null, AxiosError>({
    queryKey: ["requests", "detail", id, entityType],
    queryFn: ({ signal }) => fetchRequestDetail({ id, entityType }, signal),
    placeholderData: cachedData,
    staleTime: 1000 * 30, // 30 seconds
  });

  const result = useMemo(() => {
    if (!data) {
      return null;
    }

    const updatedLanguages = (data.languages ?? []).map((language) => ({
      ...language,
      source: languages.find((lang) => lang.id === language.source.id) ?? language.source,
      targets: language.targets.map((target) => languages?.find((lang) => lang.id === target.id) ?? target),
    }));

    const service = data.service?.id ? services.find((service) => service.id === data.service?.id) : null;

    return {
      ...data,
      service,
      languages: updatedLanguages,
    };
  }, [data, languages, services]);

  return { data: result, isLoading };
};

function mapToService<T extends BaseRequestType>(data: T[] | undefined | null, services: ServiceType[]): T[] {
  if (!data) {
    return [];
  }
  return data.map((request) => {
    const service = services.find((service) => service.id === request.service?.id) ?? request.service;
    return {
      ...request,
      service,
    };
  });
}

export const requestIdentifierQuery = (divisionId: string) =>
  queryOptions<string | null, AxiosError>({
    queryKey: ["requests", "identifier", divisionId],
    queryFn: ({ signal }) => fetchRequestIdentifier(divisionId, signal),
    retry: 1,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const divisionQuery = queryOptions<DivisionType[], AxiosError>({
  queryKey: ["requests", "divisions"],
  queryFn: ({ signal }) => fetchDivisions(signal),
});

export const useDivisionQuery = () => {
  return useQuery<DivisionType[], AxiosError>(divisionQuery);
};

export const useFileDownloadMutation = (id: string, entityType: string, fileName: string) => {
  const { t } = useTranslation();
  return useMutation<void, AxiosError>({
    mutationKey: ["requests", "fileDownload", id, entityType, fileName],
    mutationFn: async () => {
      const downloadUrl = await getDownloadUrl(entityType, id, fileName);
      if (!downloadUrl) {
        throw new Error("Download URL not found");
      }
      return await requestDownloadFile(downloadUrl, null, fileName);
    },
    onError: (error) => {
      toast.error(t("common.errors.failed-download"));
      console.error("Error downloading file", error);
    },
  });
};

export const useTableDownloadMutation = (type: string) => {
  const { t } = useTranslation();
  return useMutation<void, AxiosError, Record<string, unknown>[]>({
    mutationKey: ["requests", "tableDownload", type],
    mutationFn: (rows) =>
      requestDownloadFile(`/api/client/requests/getReport/${type}`, {
        records: JSON.stringify(rows),
      }),
    onError: (error) => {
      toast.error(t("common.errors.failed-download"));
      console.error("Error downloading file", error);
    },
  });
};

export const useAcceptQuoteMutation = (projectId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation<boolean, AxiosError>({
    mutationFn: () => acceptQuote({ id: projectId }),
    onSuccess: (isSuccessful) => {
      if (isSuccessful) {
        toast(t("requests.details.financials.acceptSuccess"));
        queryClient.invalidateQueries({
          queryKey: ["requests", "financials", projectId],
        });
      } else {
        toast(t("requests.details.financials.acceptFailure"), {
          id: "acceptQuote",
        });
      }
    },
    onError: () => {
      toast(t("requests.details.financials.acceptFailure"), {
        id: "acceptQuote",
      });
    },
  });
};

export const useRejectQuoteMutation = (projectId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation<boolean, AxiosError, { comments: string }>({
    mutationFn: ({ comments }) => rejectQuote({ id: projectId, comments }),
    onSuccess: (isSuccessful) => {
      if (isSuccessful) {
        toast(t("requests.details.financials.rejectSuccess"));
        queryClient.invalidateQueries({
          queryKey: ["requests", "financials", projectId],
        });
      } else {
        toast(t("requests.details.financials.rejectFailure"), {
          id: "rejectQuote",
        });
      }
    },
    onError: () => {
      toast(t("requests.details.financials.rejectFailure"), {
        id: "rejectQuote",
      });
    },
  });
};

export const useNewRequestMutation = (onSuccess?: (requestId: number | null) => void) => {
  const queryClient = useQueryClient();
  const { data, isPending, mutate, reset } = useMutation<
    number | null | string[],
    AxiosError,
    { form: NewRequestFormType; customFields: CustomField[] }
  >({
    mutationKey: ["requests", "acceptQuote"],
    mutationFn: async ({ form, customFields }) => await createRequest(form, customFields),
    onSuccess: (requestId) => {
      if (typeof requestId === "number" && requestId > 0) {
        // invalidate only the new requests query or in-progress requests query (in case of auto-accept project)
        queryClient.invalidateQueries({ queryKey: ["requests", "new"] });
        queryClient.invalidateQueries({
          queryKey: ["requests", "in-progress"],
        });
        onSuccess?.(requestId);
      }
    },
  });

  const serverErrors = Array.isArray(data) && data.every((element) => typeof element === "string") ? data : null;

  const createdRequestId = typeof data === "number" ? data : typeof data === "string" ? parseInt(data) : null;

  return { mutate, serverErrors, isPending, createdRequestId, reset };
};

export const useCustomFieldsQuery = (divisionId: string) => {
  return useQuery<CustomField[], AxiosError>({
    queryKey: ["requests", "customFields", divisionId],
    queryFn: ({ signal }) => fetchCustomFields(divisionId, signal),
    staleTime: 1000 * 30, // 30 seconds
  });
};


export const useUpdateCustomFieldMutation = (requestId: string, entityType: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation<
    void,
    AxiosError,
    { customFieldId: number; value: CustomFieldValue; fieldType: CustomFieldValueType }
  >({
    mutationFn: ({ customFieldId, value, fieldType }) =>
      updateCustomField({ requestId, entityType, customFieldId, value, fieldType }),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ["requests", "detail", requestId, entityType] });
      // Snapshot the previous value.
      const previousQueryData = queryClient.getQueryData<RequestDetailType>(["requests", "detail", requestId, entityType]);
      // Optimistically update to the new value.
      queryClient.setQueryData<RequestDetailType>(["requests", "detail", requestId, entityType], (oldData) => {
        if (!oldData) return oldData;
        // Update the specific custom field with the new value.
        const updatedCustomFields = (oldData.customFields ?? []).map((field) => {
          if (field.customFieldId === variables.customFieldId) {
            return { ...field, currentValue: variables.value };
          }
          return field;
        });
        return { ...oldData, customFields: updatedCustomFields };
      });
      // Return a context with the previous data for rollback if needed.
      return { previousQueryData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["requests", "detail", requestId, entityType] });
      toast.success(t("requests.details.customFields.updateSuccess"));
    },
    onError: () => {
      toast.error(t("requests.details.customFields.updateError"));
    },
  });
};

export const useUpdatePONumberMutation = (requestId: string, entityType: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<void, AxiosError, { value: string }>({
    mutationFn: ({ value }) => updatePONumber({ requestId, entityType, value }),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ["requests", "detail", requestId, entityType] });
      // Snapshot the previous value.
      const previousQueryData = queryClient.getQueryData<RequestDetailType>(["requests", "detail", requestId, entityType]);
      // Optimistically update to the new value.
      queryClient.setQueryData<RequestDetailType>(["requests", "detail", requestId, entityType], (oldData) => {
        if (!oldData) return oldData;
        return { ...oldData, invoicingReference: variables.value };
      });
      // Return a context with the previous data for rollback if needed.
      return { previousQueryData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["requests"] });
      toast.success(t("requests.details.fields.poNumber.updateSuccess"));
    },
    onError: () => {
      toast.error(t("requests.details.fields.poNumber.updateError"));
    },
  });
};

export const useSearchContactsQuery = (divisionId: string, searchTerm: string) => {
  const cleanSearchTerm = searchTerm.trim();
  const { data, isLoading, isFetching } = useQuery<IdName[], AxiosError>({
    queryKey: ["contacts", "search", divisionId, cleanSearchTerm],
    queryFn: ({ signal }) => searchContacts(divisionId, cleanSearchTerm, signal),
    enabled: !!divisionId && !!cleanSearchTerm && cleanSearchTerm.length >= 2,
    staleTime: 1000 * 60, // Cache for 1 minute
  });

  return {
    data: data ?? [],
    isLoading: isLoading || isFetching
  };
};

export const useAddAccessorMutation = (id: string, entityType: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate } = useMutation<void, AxiosError, { accessor: AccessorType }>({
    mutationFn: ({ accessor }) => addAccessor({ id, entityType, customerContactId: accessor.id }),
    onSuccess: (_, { accessor }) => {
      queryClient.invalidateQueries({ queryKey: ["requests", "detail", id, entityType] });
      toast.success(t("requests.details.accessors.addSuccess", { name: accessor.name }));
    },
    onError: (_, { accessor }) => {
      toast.error(t("requests.details.accessors.addError", { name: accessor.name }));
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ["requests", "detail", id, entityType] });
      const previousQueryData = queryClient.getQueryData<RequestDetailType>(["requests", "detail", id, entityType]);
      queryClient.setQueryData<RequestDetailType>(["requests", "detail", id, entityType], (oldData) => {
        if (!oldData) return oldData;
        return { ...oldData, accessors: [...(oldData.accessors ?? []), { ...variables.accessor, isPending: true, canBeRemoved: false }] };
      });
      return { previousQueryData };
    },
    onSettled: (_, error, { accessor }) => {
      queryClient.invalidateQueries({ queryKey: ["requests", "detail", id, entityType] });

      const isSuccess = !error;
      if (isSuccess) {
        queryClient.setQueryData<RequestDetailType>(["requests", "detail", id, entityType], (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            accessors: oldData.accessors.map(a =>
              a.id === accessor.id ? { ...a, isPending: false, canBeRemoved: true } : a
            )
          };
        });
      } else {
        queryClient.setQueryData<RequestDetailType>(["requests", "detail", id, entityType], (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            accessors: oldData.accessors.filter(a => a.id !== accessor.id)
          };
        });
      }
    }
  });
  const add = (accessors: IdName[]) => {
    accessors.forEach((accessor) => mutate({ accessor: { id: accessor.id, name: accessor.name, description: accessor.description, roles: [] } }));
  };

  return { add };
};

export const useRemoveAccessorMutation = (id: string, entityType: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<void, AxiosError, { accessor: AccessorType }>({
    mutationKey: ["requests", "removeAccessor", id, entityType],
    mutationFn: ({ accessor }) => removeAccessor({ id, entityType, accessorId: accessor.id }),
    onSuccess: (_, { accessor }) => {
      queryClient.invalidateQueries({ queryKey: ["requests", "detail", id, entityType] });
      toast.success(t("requests.details.accessors.removeSuccess", { name: accessor.name }));
    },
    onError: (_, { accessor }) => {
      toast.error(t("requests.details.accessors.removeError", { name: accessor.name }));
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ["requests", "detail", id, entityType] });
      const previousQueryData = queryClient.getQueryData<RequestDetailType>(["requests", "detail", id, entityType]);
      queryClient.setQueryData<RequestDetailType>(["requests", "detail", id, entityType], (oldData) => {
        if (!oldData) return oldData;
        return { ...oldData, accessors: oldData.accessors.map(a => a.id === variables.accessor.id ? { ...a, isPending: true } : a) };
      });
      return { previousQueryData };
    },
    onSettled: (_, error, { accessor }) => {
      queryClient.invalidateQueries({ queryKey: ["requests", "detail", id, entityType] });

      const isSuccess = !error;
      if (isSuccess) {
        queryClient.setQueryData<RequestDetailType>(["requests", "detail", id, entityType], (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            accessors: oldData.accessors.filter(a => a.id !== accessor.id)
          };
        });
      } else {
        queryClient.setQueryData<RequestDetailType>(["requests", "detail", id, entityType], (oldData) => {
          if (!oldData) return oldData;
          return { ...oldData, accessors: oldData.accessors.map(a => a.id === accessor.id ? { ...a, isPending: false } : a) };
        });
      }
    }
  });
};
