import { useAuthUserStore } from "@/store/useAuthUserStore";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useCostReportStore, useTmLeverageReportStore } from "./reportStore";

function setReportsPersistanceKey(userId: string) {
  useCostReportStore.persist.setOptions({
    name: `${userId}-cost-filters`,
  });
  useTmLeverageReportStore.persist.setOptions({
    name: `${userId}-tm-leverage-filters`,
  });
  useCostReportStore.persist.rehydrate();
  useTmLeverageReportStore.persist.rehydrate();
}

export const ReportsLayout = () => {
  const userId = useAuthUserStore((store) => store.user?.id) as string;
  useEffect(() => {
    setReportsPersistanceKey(userId);
  }, [userId]);
  return <Outlet />;
};
