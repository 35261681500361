import type { CustomField, CustomFieldValue } from "@/model/request.typing";
import { t } from "i18next";
import { z } from "zod";

export const createCustomFieldSchema = (field: CustomField) => {
  let valueSchema: z.ZodType<CustomFieldValue>;

  switch (field.fieldType) {
    case "Number":
      valueSchema = z.union([z.number(), z.string()])
        .nullable()
        .transform((val) => {
          if (val === null || val === "" || val === undefined) return undefined;
          const num = typeof val === "string" ? Number(val) : val;
          return isNaN(num) ? undefined : num;
        })
        .optional()
        .refine((val) => {
          if (val !== undefined && isNaN(Number(val))) return false;
          return true;
        }, t("common.validation.customFields.invalidNumber"))
        .refine((val) => {
          if (field.minValue && val !== null && val !== undefined && val < field.minValue) return false;
          return true;
        }, t("common.validation.customFields.minValue", { value: field.minValue }))
        .refine((val) => {
          if (field.maxValue && val !== null && val !== undefined && val > field.maxValue) return false;
          return true;
        }, t("common.validation.customFields.maxValue", { value: field.maxValue }));
      break;
    case "Date":
      valueSchema = z.date().optional().nullable()
        .refine((val) => {
          return val === null || val === undefined || !isNaN(val.getTime());
        }, t("common.validation.customFields.invalidDate"))
        .refine((val) => {
          return field.isMandatory ? val !== null && val !== undefined : true;
        }, t("common.validation.customFields.dateRequired"));
      break;
    case "Boolean":
      valueSchema = z.boolean().optional().nullable().default(null)
        .refine((val) => {
          return field.isMandatory ? val !== null && val !== undefined : true;
        }, t("common.validation.customFields.required"));
      break;
    case "SingleSelect":
      valueSchema = z.union([z.number(), z.coerce.number()]).optional().nullable().default(null)
        .refine((val) => {
          return field.isMandatory ? val !== null && val !== undefined : true;
        }, t("common.validation.customFields.selectSingleRequired"));
      break;
    case "MultiSelect":
      valueSchema = z.array(z.union([z.number(), z.coerce.number()])).optional().nullable().default([])
        .refine((val) => {
          if (field.isMandatory && Array.isArray(val) && val.length === 0) return false;
          return true;
        }, t("common.validation.customFields.selectMultiRequired"));
      break;
    case "String":
    default:
      valueSchema = z.string().optional().nullable().default(null).transform((val) => {
        if (typeof val === "string" && val.trim() === "") return null;
        return val;
      })
        .refine((val) => {
          if (field.maxValue && val && val.length > field.maxValue) return false;
          return true;
        }, t("common.validation.customFields.maxLength", { length: field.maxValue }));
  }

  if (field.isMandatory) {
    valueSchema = valueSchema.refine((val) => val !== null && val !== undefined, t("common.validation.customFields.required"));
  }

  return valueSchema;
};

/**
 * Creates a schema for validating a record of custom fields
 * @param fields Array of custom field configurations
 * @returns Zod schema for validating custom fields record
 */
export const createCustomFieldsRecordSchema = (fields: CustomField[]) => {
  return z.record(z.string(), z.any()).superRefine((data, ctx) => {
    fields.forEach((field) => {
      const fieldSchema = createCustomFieldSchema(field);
      const value = data[field.customFieldId];

      try {
        fieldSchema.parse(value);
      } catch (error) {
        if (error instanceof z.ZodError) {
          error.errors.forEach((err) => {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: err.message,
              path: [field.customFieldId.toString()],
            });
          });
        }
      }
    });
  });
};

export type CustomFieldsRecord = z.infer<ReturnType<typeof createCustomFieldsRecordSchema>>;
