import FullPageLoader from "@/pages/FullPageLoader";
import { useAuthenticatedUser } from "@/query/user.query";
import { redirectToLogin } from "@/utils/url";
import { useEffect, useRef } from "react";
import { Navigate, Outlet } from "react-router-dom";

export function AuthenticateUserRoute() {
  const isFirstRenderRef = useRef<boolean>(true);
  const { user, isLoading } = useAuthenticatedUser();

  useEffect(() => {
    if (isLoading) return;
    isFirstRenderRef.current = false;
  }, [isLoading]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (!user) {
    // redirect to login page only on first render
    if (isFirstRenderRef.current) {
      redirectToLogin(location.pathname);
      return null;
    }
    // redirect to 401 page if user is not authenticated
    return <Navigate to={"/error/401"} replace={false} />;
  }

  // continue to the next route if user is authenticated
  return <Outlet />;
}
