import type { Row } from "@tanstack/react-table";

/**
 * Used to filter the data in the datatable
 * add it to the ColumnDef as "filterFn: filterStingInclude"
 * @param row the datatable row
 * @param id the id of the column
 * @param value the value to filter
 * @returns true if the value is included in the row
 */
export function filterStingInclude<TData>(row: Row<TData>, id: string, value: unknown) {
  if (!Array.isArray(value)) {
    return true;
  }

  return value.includes(row.getValue(id)?.toString());
}
