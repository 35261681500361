import useTheme from "hooks/useTheme";
import { useEffect, useRef } from "react";

/**
 * On load will detect theme and sync it with local storage and switch document classes accordingly
 */
function ThemeProvider() {
  const isFirstRenderRef = useRef<boolean>(true);
  const { detectTheme } = useTheme();

  useEffect(() => {
    if (isFirstRenderRef.current) {
      detectTheme(); // trigger theme detection
      isFirstRenderRef.current = false;
    }
  }, [detectTheme, isFirstRenderRef]);

  // no need to render anything
  return null;
}

export default ThemeProvider;
