import { cn } from "@/utils/ui";
import * as ProgressPrimitive from "@radix-ui/react-progress";
import { clsx } from "clsx";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

const Progress = forwardRef<
  ElementRef<typeof ProgressPrimitive.Root>,
  ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & { isInfinite?: boolean }
>(({ className, value, isInfinite, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn("relative h-4 w-full overflow-hidden rounded-full bg-secondary", className)}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className={clsx("size-full flex-1 bg-primary", { "origin-left-right animate-progress": isInfinite })}
      style={{ transform: `translateX(-${100 - (value ?? 0)}%)` }}
    />
  </ProgressPrimitive.Root>
));

Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
