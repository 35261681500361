import { MENU_ICONS } from "components/Icons";
import { PageHeaderShell } from "components/shell/PageHeaderShell";
import { PageShell } from "components/shell/PageShell";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { MachineTranslationRouteParam } from "model/mt.typing";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import DownloadActivity from "./components/DownloadActivity";
import MTConfirmationAlert from "./components/MTConfirmationAlert";
import FileMachineTranslation from "./file/FileMachineTranslation";
import TextMachineTranslation from "./text/TextMachineTranslation";

function MachineTranslation() {
  const { t } = useTranslation();
  const { type = "text" } = useParams<MachineTranslationRouteParam>();

  return (
    <PageShell>
      <PageHeaderShell heading={t("mt.title")} icon={MENU_ICONS.machinetranslation}>
        <DownloadActivity />
      </PageHeaderShell>
      <div>
        <Tabs defaultValue={type}>
          <TabsList className="mx-2 grid max-w-[300px] grid-cols-2">
            <TabsTrigger value="text" asChild>
              <Link to="/client/machinetranslation/text">{t("mt.navigation.text")}</Link>
            </TabsTrigger>
            <TabsTrigger value="file" asChild>
              <Link to="/client/machinetranslation/file">{t("mt.navigation.file")}</Link>
            </TabsTrigger>
          </TabsList>
          <div className="flex h-full flex-col sm:flex-1">
            <TabsContent value="text">
              <TextMachineTranslation />
            </TabsContent>
            <TabsContent value="file">
              <FileMachineTranslation />
            </TabsContent>
          </div>
        </Tabs>
      </div>
      <MTConfirmationAlert />
    </PageShell>
  );
}

export default MachineTranslation;
