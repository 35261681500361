import { IdName } from "model/common";
import { useMemo } from "react";

export interface UseComboboxItemsResult<TData extends IdName> {
  selected: TData[];
  unselected: TData[];
}

export function useComboboxItems<TData extends IdName>(
  options: TData[],
  selectedIds: Array<TData["id"]>,
  sortByFn: (items: TData[]) => TData[]
): UseComboboxItemsResult<TData> {
  return useMemo(
    () => ({
      selected: options.filter((option) => selectedIds.map((id) => id.toString()).includes(option.id.toString())),
      unselected: sortByFn(options.filter((option) => !selectedIds.map((id) => id.toString()).includes(option.id.toString()))),
    }),
    [options, selectedIds, sortByFn]
  );
}
