import { Button } from "@/components/ui/button";
import { t } from "i18next";
import useGlossary from "./useGlossary";
import { ChevronLeft, Loader2Icon, PlusCircle } from "lucide-react";
import { ReactNode, useState } from "react";
import { toast } from "sonner";
import { GlossaryTopBar } from "./components/GlossaryTopBar";
import { useAddTerminologyMutation, useGetTerminologyListQuery } from "@/query/mt/machinetranslation.query";
import { TerminologyPayload } from "@/model/mt.typing";
import { TooltipProvider } from "@/components/ui/tooltip";
import PortalTooltip from "@/components/PortalTooltip";
import { FloatingLabelInput } from "@/components/input/FloatingInput";

interface GlossaryDetailsProps {
  showBack: boolean;
}

interface GlossaryInfoState {
  name: string;
}

export const GlossaryInfo = (props: GlossaryDetailsProps) => {
  const [state, setState] = useState<GlossaryInfoState>({ name: "" });
  const { showBack } = props;
  const { terminologyConfiguration, setView, setCurrentTerminology } = useGlossary();

  const { refetch } = useGetTerminologyListQuery(terminologyConfiguration.customerId);
  const { addNewTerminology, isFetchingAdd } = useAddTerminologyMutation();

  const validate = (): boolean => {
    if (state.name.length > 0) return true;
    return false;
  };

  const handleSubmit = async () => {
    if (validate() && terminologyConfiguration.customerId) {
      const payload: TerminologyPayload = {
        name: state.name,
      };
      const createdTerminology = await addNewTerminology({
        customerId: terminologyConfiguration.customerId,
        payload,
      });
      refetch();
      setCurrentTerminology(createdTerminology);
      setView("editTerms");
    } else {
      toast.warning(t("mt.glossary.glossaryNameEmpty"));
    }
  };

  const actionButtons: ReactNode = (
    <TooltipProvider delayDuration={0}>
      <PortalTooltip text={t("common.back")}>
        <Button
          type="button"
          variant="outline"
          className="h-8 align-middle text-xs"
          size="sm"
          onClick={() => setView("list")}
        >
          <ChevronLeft className="size-4" />
        </Button>
      </PortalTooltip>
    </TooltipProvider>
  );

  return (
    <>
      <GlossaryTopBar title={t("mt.glossary.glossaryDetails")} actionButtons={showBack ? actionButtons : null} />
      <hr className="mb-3 mt-1 h-px border-0 bg-gray-200 dark:bg-gray-700"></hr>
      <div className="space-y-2">
        <div className="items-center">
          <FloatingLabelInput
            label={t("mt.glossary.glossaryName")}
            disabled={isFetchingAdd}
            className="w-full"
            value={state.name}
            onChange={(e) => {
              setState({ ...state, name: e.target.value });
            }}
          ></FloatingLabelInput>
        </div>
        <div className="flex justify-center gap-2">
          <Button disabled={isFetchingAdd} className="mt-2 w-full" variant={"outline"} onClick={() => handleSubmit()}>
            {isFetchingAdd ? (
              <Loader2Icon className="mr-2 size-5 animate-spin" />
            ) : (
              <PlusCircle className="mr-2 opacity-50" />
            )}
            {t("mt.glossary.addGlossary")}
          </Button>
        </div>
      </div>
    </>
  );
};
