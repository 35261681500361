import { EmptyDataPlaceholder } from "@/components/EmptyPlaceholder";
import { MENU_ICONS } from "@/components/Icons";
import { Combobox } from "@/components/combobox/Combobox";
import { DataTable } from "@/components/datatable/DataTable";
import { DateRangePicker } from "@/components/datePicker/DateRangePicker";
import { MultipleLanguageFilter } from "@/components/filters/LanguageFilters";
import { FloatingLabelInput } from "@/components/input/FloatingInput";
import { Card, CardContent } from "@/components/ui/card";
import { useDateFormatter } from "@/hooks/useDate";
import { useSearchParamsString } from "@/hooks/useSearchParamsString";
import type { IdName } from "@/model/common";
import {
  TmLeverageChartNames,
  type TmLeverageChartNamesType,
  type TmLeveragePerLanguagesType,
} from "@/model/report.typing";
import { useTmLeverageData, useTmLeverageExcelDownload } from "@/query/report.query";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import type { ColumnDef } from "@tanstack/react-table";
import { BuildingIcon, LandmarkIcon, UserIcon } from "lucide-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { DownloadExcelReportButton } from "../common/DownloadExcelReportButton";
import { LanguageInfoDisplay } from "../common/LanguageInfoDisplay";
import { ReportLayout } from "../common/ReportLayout";
import { useReportPathName } from "../common/useReportPathName";
import { useTmLeverageActions, useTmLeverageSortBy, useTmLeverageTabView } from "../reportStore";
import { applyFuzzyNoMatchCalculation, useTmLeverageFilter, useTmLeverageFilteredData } from "./useTmLeverageFilter";

const REPORT_PATHS = [
  TmLeverageChartNames.perLanguage,
  TmLeverageChartNames.perMonthPercentage,
  TmLeverageChartNames.perMonthWords,
];

export function TmLeverageReportLayout() {
  const searchParams = useSearchParamsString();
  const { isDashboard, currentReportPathName } = useReportPathName("tmleverage");
  const { tmLeverageData, isLoading } = useTmLeverageData(searchParams);
  const { showClient, filteredData } = useTmLeverageFilteredData(tmLeverageData);
  const { isDownloading, downloadExcelFile } = useTmLeverageExcelDownload();
  const tab = useTmLeverageTabView();
  const { updateTabView } = useTmLeverageActions();

  return (
    <ReportLayout
      i18nKeyPrefix={"tmleverage"} // i18n => reports.tmleverage.*
      icon={MENU_ICONS.tmleverage}
      pathNames={{
        isDashboard,
        currentReportPathName,
        reportsPathName: REPORT_PATHS,
      }}
      renderActions={() => (
        <DownloadExcelReportButton
          isLoading={isDownloading}
          disabled={isDownloading || filteredData.length === 0}
          onDownload={() => downloadExcelFile(filteredData)}
        />
      )}
    >
      <ReportLayout.Filter isLoading={isLoading}>
        <TmLeverageFilter showClient={showClient} tmLeverageData={tmLeverageData} hasSearchParams={!!searchParams} />
      </ReportLayout.Filter>
      <ReportLayout.ViewSwitcher
        isLoading={isLoading}
        tab={tab}
        onTabChange={(tab) => updateTabView(tab)}
        renderTable={() => (
          <TmLeverageTable
            chartType={currentReportPathName as TmLeverageChartNamesType}
            isLoading={isLoading}
            filteredData={filteredData}
          />
        )}
        rendreCharts={() => <Outlet context={{ isLoading, filteredData }} />}
      />
    </ReportLayout>
  );
}

function TmLeverageFilter({
  showClient,
  hasSearchParams,
  tmLeverageData,
}: {
  showClient: boolean;
  hasSearchParams: boolean;
  tmLeverageData: TmLeveragePerLanguagesType[];
}) {
  const { t } = useTranslation("translation", { keyPrefix: "reports.filters" });
  const { options, hasActiveFilters, filters, updateFilters, onReset } = useTmLeverageFilter(
    tmLeverageData,
    hasSearchParams
  );

  if (!hasActiveFilters && tmLeverageData.length === 0) return null;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-wrap items-center gap-2">
        <FloatingLabelInput
          className="max-w-72"
          data-test="tmLeverageSearchTerm"
          type="search"
          label={t("common.search.label", { keyPrefix: "" })}
          value={filters.searchText}
          onChange={(e) => {
            updateFilters({ searchText: e.target.value });
          }}
        />
        {showClient ? (
          <Combobox
            testId="client"
            icon={BuildingIcon}
            title={t("clients")}
            options={options.clientNames}
            selectedIds={filters.clientNameIds ?? []}
            onChange={(values) => updateFilters({ clientNameIds: values.map((v) => v.id.toString()) })}
            hideCount={true}
          />
        ) : null}
        <Combobox
          testId="owner"
          icon={UserIcon}
          title={t("owners")}
          options={options.owners as IdName[]}
          onChange={(values) => updateFilters({ ownersIds: values.map((v) => v.id.toString()) })}
          selectedIds={filters.ownersIds ?? []}
        />
        <Combobox
          testId="division"
          icon={LandmarkIcon}
          title={t("divisions")}
          options={options.divisions as IdName[]}
          onChange={(values) => updateFilters({ divisionNames: values.map((v) => v.name.toString()) })}
          selectedIds={filters.divisionNames ?? []}
        />
        <DateRangePicker
          testId="delivery-date"
          title={t("deliveryDate")}
          range={filters.deliveryDate}
          minMaxDate={options.deliveryDateMinMax}
          onUpdate={(dateRange) => updateFilters({ deliveryDate: dateRange })}
        />

        <ReportLayout.FilterReset isShown={hasActiveFilters} onReset={onReset} />
      </div>
      <div className="flex flex-wrap items-center gap-2">
        <MultipleLanguageFilter
          testId="source-language"
          title={t("sourceLanguage")}
          options={options.sourceLanguages}
          selectedLanguageCode={filters.sourceLanguageIds}
          onChange={(value) => updateFilters({ sourceLanguageIds: value })}
          hideCount={true}
          enableSorting={true}
        />
        <MultipleLanguageFilter
          testId="target-language"
          title={t("targetLanguage")}
          options={options.targetLanguages}
          selectedLanguageCode={filters.targetLanguageIds}
          onChange={(values) => updateFilters({ targetLanguageIds: values })}
          hideCount={true}
          enableSorting={true}
        />
      </div>
    </div>
  );
}

function TmLeverageTable({
  filteredData,
  isLoading,
  chartType,
}: {
  filteredData: TmLeveragePerLanguagesType[];
  isLoading: boolean;
  chartType: TmLeverageChartNamesType | null;
}) {
  const sortBy = useTmLeverageSortBy();
  const { updateSortBy } = useTmLeverageActions();
  const { columns, defaultColumnVisibility } = useTableColumns(chartType);
  const data = useMemo(() => applyFuzzyNoMatchCalculation(chartType, filteredData), [filteredData, chartType]);

  return (
    <Card>
      <CardContent className="mt-4">
        <DataTable
          data-test="tm-leverage-table"
          uniqueName="tm-leverage-reports"
          isLoading={isLoading}
          defaultColumnVisibility={defaultColumnVisibility}
          showViews={true}
          showSearch={false}
          defaultPageSize={10}
          columns={columns}
          data={data}
          sortBy={sortBy}
          onSortBy={updateSortBy}
        />
      </CardContent>
    </Card>
  );
}

const useTableColumns = (chartType: TmLeverageChartNamesType | null) => {
  const { formatDate } = useDateFormatter();
  const formatNumber = useAuthUserStore((store) => store.formatNumber);
  const { t } = useTranslation("translation", {
    keyPrefix: "reports.tmleverage.table",
  });

  return useMemo(() => {
    const allColumns: ColumnDef<TmLeveragePerLanguagesType>[] = [
      {
        id: "projectCode",
        header: t("projectCode"),
        enableSorting: true,
        enableHiding: true,
        accessorFn: (data) => data.projectId,
        cell({ row }) {
          const projectCode = row.original.projectId;
          if (!projectCode) return <EmptyDataPlaceholder />;
          return <div className="whitespace-nowrap">{projectCode}</div>;
        },
      },
      {
        id: "projectId",
        header: t("projectId"),
        enableSorting: true,
        enableHiding: true,
        accessorFn: (data) => data.portalProjectId,
        cell({ row }) {
          const projectId = row.original.portalProjectId;
          if (!projectId) return <EmptyDataPlaceholder />;
          return <div>{projectId}</div>;
        },
      },
      {
        id: "projectName",
        header: t("projectName"),
        enableSorting: true,
        enableHiding: true,
        accessorFn: (data) => data.projectName,
        cell(props) {
          if (!props.row.original.projectName) return <EmptyDataPlaceholder />;
          return (
            <div className="max-w-xs" title={props.row.original.projectName}>
              <p className="truncate">{props.row.original.projectName}</p>
            </div>
          );
        },
      },
      {
        id: "owner",
        header: t("owner"),
        enableSorting: true,
        enableHiding: true,
        accessorFn: (data) => data.owner?.name,
        cell(props) {
          if (!props.row.original.owner) return <EmptyDataPlaceholder />;
          return <div className="flex flex-col gap-1">{props.row.original.owner.name}</div>;
        },
      },
      {
        id: "division",
        header: t("division"),
        enableSorting: true,
        enableHiding: true,
        accessorFn: (data) => data.ecosystemName,
      },
      {
        id: "deliveryDate",
        header: t("deliveryDate"),
        enableSorting: true,
        enableHiding: true,
        accessorFn: (data) => data.deliveryDate,
        cell(props) {
          const date = props.row.original.deliveryDate;
          if (!date) return <EmptyDataPlaceholder />;
          return formatDate(date);
        },
      },
      {
        id: "sourceLanguage",
        header: t("sourceLanguage"),
        enableSorting: true,
        enableHiding: true,
        accessorFn: (data) => data.sourceName,
        cell(props) {
          return <LanguageInfoDisplay code={props.row.original.sourceCode} name={props.row.original.sourceName} />;
        },
      },
      {
        id: "targetLanguage",
        header: t("targetLanguage"),
        enableSorting: true,
        enableHiding: true,
        accessorFn: (data) => data.targetName,
        cell(props) {
          return <LanguageInfoDisplay code={props.row.original.targetCode} name={props.row.original.targetName} />;
        },
      },
      {
        id: "xTranslated",
        header: t("xTranslated"),
        enableSorting: true,
        enableHiding: true,
        meta: { position: "right" },
        accessorFn: (data) => data.xTranslated,
        cell(props) {
          return formatNumber(props.row.original.xTranslated);
        },
      },
      {
        id: "repetitions",
        header: t("repetitions"),
        enableSorting: true,
        enableHiding: true,
        meta: { position: "right" },
        accessorFn: (data) => data.repetitions,
        cell(props) {
          return formatNumber(props.row.original.repetitions);
        },
      },
      {
        id: "repsAnd100",
        header: t("repsAnd100"),
        enableSorting: true,
        enableHiding: true,
        meta: { position: "right" },
        accessorFn: (data) => data.repsAnd100,
        cell(props) {
          return formatNumber(props.row.original.repsAnd100);
        },
      },
      {
        id: "m100",
        header: t("m100"),
        enableSorting: true,
        enableHiding: true,
        meta: { position: "right" },
        accessorFn: (data) => data.m100,
        cell(props) {
          return formatNumber(props.row.original.m100);
        },
      },
      {
        id: "m95_99",
        header: t("m95_99"),
        enableSorting: true,
        enableHiding: true,
        meta: { position: "right" },
        accessorFn: (data) => data.m95_99,
        cell(props) {
          return formatNumber(props.row.original.m95_99);
        },
      },
      {
        id: "m85_94",
        header: t("m85_94"),
        enableSorting: true,
        enableHiding: true,
        meta: { position: "right" },
        accessorFn: (data) => data.m85_94,
        cell(props) {
          return formatNumber(props.row.original.m85_94);
        },
      },
      {
        id: "m75_84",
        header: t("m75_84"),
        enableSorting: true,
        enableHiding: true,
        meta: { position: "right" },
        accessorFn: (data) => data.m75_84,
        cell(props) {
          return formatNumber(props.row.original.m75_84);
        },
      },
      {
        id: "m50_74",
        header: t("m50_74"),
        enableSorting: true,
        enableHiding: true,
        meta: { position: "right" },
        accessorFn: (data) => data.m50_74,
        cell(props) {
          return formatNumber(props.row.original.m50_74);
        },
      },
      {
        id: "fuzzy",
        header: t("fuzzy"),
        enableSorting: true,
        enableHiding: true,
        meta: { position: "right" },
        accessorFn: (data) => data.fuzzy,
        cell(props) {
          return formatNumber(props.row.original.fuzzy);
        },
      },
      {
        id: "noMatch",
        header: t("noMatch"),
        enableSorting: true,
        enableHiding: true,
        meta: { position: "right" },
        accessorFn: (data) => data.noMatch,
        cell(props) {
          return formatNumber(props.row.original.noMatch);
        },
      },
      {
        id: "words",
        header: t("words"),
        enableSorting: true,
        enableHiding: true,
        meta: { position: "right" },
        accessorFn: (data) => data.words,
        cell(props) {
          return formatNumber(props.row.original.words);
        },
      },
    ];

    if (chartType === TmLeverageChartNames.perLanguage) {
      const hideColumns = ["repetitions", "m100", "m95_99", "m85_94", "m75_84", "m50_74"];
      const defaultColumnVisibility = {
        portalProjectId: false,
        projectName: false,
      };
      // filter the columns based on the chart type.
      const columns = allColumns.filter((column) => {
        return !hideColumns.includes(column.id as string);
      });
      return { columns, defaultColumnVisibility };
    }
    if (chartType === TmLeverageChartNames.perMonthPercentage) {
      const hideColumns = ["repetitions", "xTranslated", "m100", "m95_99", "m85_94", "m75_84", "m50_74"];
      const defaultColumnVisibility = {
        portalProjectId: false,
        projectName: false,
      };
      // filter the columns based on the chart type.
      const columns = allColumns.filter((column) => {
        return !hideColumns.includes(column.id as string);
      });
      return { columns, defaultColumnVisibility };
    }
    if (chartType === TmLeverageChartNames.perMonthWords) {
      const hideColumns = ["fuzzy", "repsAnd100"];
      const defaultColumnVisibility = {
        projectId: true,
        projectName: false,
        m100: false,
        m95_99: false,
        m85_94: false,
        m75_84: false,
        m50_74: false,
      };
      // filter the columns based on the chart type.
      const columns = allColumns.filter((column) => {
        return !hideColumns.includes(column.id as string);
      });
      return { columns, defaultColumnVisibility };
    }

    return {
      columns: allColumns.filter((column) => {
        return !["repsAnd100", "fuzzy"].includes(column.id as string);
      }),
      defaultColumnVisibility: {
        portalId: true,
        projectName: false,
        xTranslated: false,
        repetitions: false,
        new: false,
        m100: false,
        m95_99: false,
        m85_94: false,
        m75_84: false,
        m50_74: false,
      },
    };
  }, [t, chartType, formatDate, formatNumber]);
};
