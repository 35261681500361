import { TZDate } from "@date-fns/tz";
import { endOfDay, isAfter, isBefore, isEqual, isValid, isWithinInterval, startOfDay } from "date-fns";
import { type DateRange, isDateRange } from "react-day-picker";

/**
 * Check if a date is within a date range
 * @param currentDate The date to check
 * @param range The date range
 * @returns True if the date is within the range or if the range or the date are undefined
 * @example isDateInRange(new Date('2022-01-01'), { from: new Date('2022-01-01'), to: new Date('2022-01-31') })
 */
export function isDateInRange(currentDate?: Date | string | null | undefined, range?: DateRange): boolean {
  if (!currentDate) return false; // if no date, return false
  if (!range || !isDateRange(range)) return true;
  if (!range.from && !range.to) return true;

  const valueDate = new TZDate(currentDate as Date);
  const fromDate = range.from ? startOfDay(range.from) : null;
  const toDate = range.to ? endOfDay(range.to) : null;

  if (fromDate && toDate) {
    return isWithinInterval(valueDate, { start: fromDate, end: toDate });
  }
  if (fromDate) {
    return isAfter(valueDate, fromDate) || isEqual(valueDate, fromDate);
  }
  if (toDate) {
    return isBefore(valueDate, toDate) || isEqual(valueDate, toDate);
  }
  return true;
}

export function areRangesEqual(range1?: DateRange, range2?: DateRange): boolean {
  const { from: start1, to: end1 } = range1 ?? {};
  const { from: start2, to: end2 } = range2 ?? {};

  const startsAreBothUndefined = !start1 && !start2;
  const endsAreBothUndefined = !end1 && !end2;

  if (startsAreBothUndefined && endsAreBothUndefined) {
    return true;
  }

  const startsEqual = startsAreBothUndefined || (start1 && start2 && isEqual(start1, start2));
  const endsEqual = endsAreBothUndefined || (end1 && end2 && isEqual(end1, end2));

  return !!startsEqual && !!endsEqual;
}

export function isRangeInside(range1: DateRange | undefined, range2: DateRange | undefined): boolean {
  // If either range is undefined, return true
  if (!range1 || !range2) return true;

  // If any required date is missing, return true
  if (!range1.from || !range1.to || !range2.from || !range2.to) return true;

  // Check if range1 is completely inside range2
  return (
    (isAfter(range1.from, range2.from) || isEqual(range1.from, range2.from)) &&
    (isBefore(range1.to, range2.to) || isEqual(range1.to, range2.to))
  );
}

/***
 * This function takes a date string in the format 'YYYY-MM-DD' and returns a Date object.
 * If the date string is invalid, it returns null.
 * API date comes without timezone, so we append 'Z' to the date string to indicate it's in UTC.
 * @param dateString - The date string in the format 'YYYY-MM-DD'
 * @returns A Date object or null
 */
export function convertToDate(dateString: string | null | undefined): Date | null {
  if (!dateString) {
    return null;
  }
  const dateInUserTimezone = new TZDate(dateString);
  if (Number.isNaN(dateInUserTimezone.getTime())) {
    return null;
  }
  return dateInUserTimezone;
}
/**
 * Converts a time string in the format 'HH:mm:ss' to a time zone offset string in the format 'UTC±HH:mm'.
 * If the time string is invalid, it returns null.
 * @param timeString - The time string in the format 'HH:mm:ss'
 * @returns A time zone offset string in the format '±HH:mm' or 'UTC' or null
 */
export function convertToTimeZoneInfo(
  timeString: string | null | undefined
): { shortName: string; minutes: number } | null {
  if (!timeString) {
    return null;
  }
  const timeRegex = /^(-)?(\d{2}):(\d{2}):(\d{2})$/;
  const match = timeString.match(timeRegex);

  if (!match) {
    return null; // Invalid format
  }

  const sign = match[1] ? -1 : 1;
  const hours = match[2];
  const minutes = match[3];

  const hourNum = Number.parseInt(hours, 10);
  const minuteNum = Number.parseInt(minutes, 10);

  if (hourNum < 0 || hourNum > 14 || minuteNum < 0 || minuteNum >= 60) {
    return null; // Invalid time range for a time zone offset
  }

  if (hourNum === 0 && minuteNum === 0) {
    return {
      shortName: "UTC",
      minutes: 0,
    };
  }

  return {
    shortName: `${sign > 0 ? "+" : "-"}${hours}:${minutes}`,
    minutes: sign * (hourNum * 60 + minuteNum),
  };
}

export function is24HourFormat(timeFormat: string): boolean {
  return /[HkK]/.test(timeFormat);
}
export const createDateInTimeZone = (date: Date, timezone: string): TZDate => {
  return new TZDate(date, timezone);
};
export const createNowInTimeZone = (timezone: string): TZDate => {
  return new TZDate(new Date(), timezone);
};

/**
 * Converts API dates to user timezone. This function should be used consistently when receiving dates from the API.
 * @param apiDate - The date from the API, can be string, Date, or null/undefined
 * @param timezone - The user's timezone
 * @returns A Date object in the user's timezone, or null if the input is invalid
 */
export function convertAPIDateToUserTimezone(apiDate: Date | string | null | undefined, timezone: string): Date | null {
  if (!apiDate) {
    return null;
  }
  try {
    const date = typeof apiDate === "string" ? new TZDate(apiDate, timezone) : new TZDate(apiDate, timezone);
    return isValid(date) ? date : null;
  } catch (error) {
    console.error("Error converting API date to user timezone:", error);
    return null;
  }
}

/**
 * Converts an array of objects containing date fields to user timezone
 * @param data - Array of objects containing date fields
 * @param dateFields - Array of field names that contain dates
 * @param timezone - The user's timezone
 * @returns A new array with dates converted to user timezone
 */
export function convertAPIObjectDatesToUserTimezone<T>(data: T[], dateFields: (keyof T)[], timezone: string): T[] {
  return data.map((item) => {
    const convertedItem = { ...item };
    for (const field of dateFields) {
      const value = item[field];
      if (value) {
        const convertedDate = convertAPIDateToUserTimezone(value as string | Date | null | undefined, timezone);
        if (convertedDate !== null) {
          // Use type assertion to bypass strict type checking
          (convertedItem[field] as Date) = convertedDate;
        }
      }
    }
    return convertedItem;
  });
}
