import { DownloadOptions, LanguageOption, OcrFileResult, OcrMachineTranslationFormModel } from "@/model/mt.typing";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "components/ui/button";
import { Form } from "components/ui/form";
import { ArrowRight, Loader2Icon } from "lucide-react";
import {
  useMachineTranslationConfig,
  useOcrParagraphs,
  useProcessOcrMutation,
} from "query/mt/machinetranslation.query";
import { useCallback, useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import useOcrMachineTranslation from "../../useOcrMachineTranslation";
import { useOcrMachineTranslationForm } from "../../useOcrMachineTranslationForm";
import { OcrDisplay } from "./OcrDisplay";
import { OcrDownloadButton } from "./OcrDownloadButton";
import { OcrFile } from "./OcrFile";
import { OcrSourceControls } from "./OcrSourceControls";
import { OcrTargetControls } from "./OcrTargetControls";

interface OcrProcessorProps {
  file: OcrFileResult;
  resetOcrFileResult: () => void;
  handleDownloadFiles: (downloadType: DownloadOptions) => Promise<boolean>;
}

export const OcrProcessor = (props: OcrProcessorProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { file, resetOcrFileResult, handleDownloadFiles } = props;
  const [targetLanguages, setTargetLanguages] = useState<LanguageOption[]>([]);
  const form = useOcrMachineTranslationForm();
  const { sourceParagraphs, paragraphResults, status } = useOcrParagraphs(file.fileId);
  const { sourceLanguages, configuration, collectionId, setCollectionId, mtProviderType, setMtProviderType } =
    useMachineTranslationConfig();
  const { ocr, ocrResults, isProcessingOcr } = useProcessOcrMutation(
    collectionId,
    mtProviderType,
    file,
    form.getValues()
  );
  const { setTargets } = useOcrMachineTranslation(
    form,
    configuration,
    setTargetLanguages,
    setCollectionId,
    setMtProviderType
  );
  const [showOcrDownload, setShowOcrDownload] = useState<boolean>(false);
  const onSubmit: SubmitHandler<OcrMachineTranslationFormModel> = () => {
    ocr();
  };

  useEffect(() => {
    form.reset();
    queryClient.removeQueries({ queryKey: ["getOcrResults"], exact: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, file]);

  const findAutoDetectMatch = useCallback(
    (detectedCode: string) => {
      return (
        sourceLanguages.find(
          (t) =>
            (t.code === detectedCode || t.code.startsWith(detectedCode + "-")) &&
            (t.isDefaultDialect ||
              (!t.isDefaultDialect &&
                sourceLanguages.some((s) => s.code === detectedCode || s.code.startsWith(detectedCode + "-"))))
        ) ?? null
      );
    },
    [sourceLanguages]
  );

  useEffect(() => {
    if (paragraphResults?.autoDetectedResults && sourceLanguages.length > 0) {
      const matchedTarget = findAutoDetectMatch(
        JSON.parse(paragraphResults.autoDetectedResults).detections[0].language
      );
      if (matchedTarget && !form.getValues("sourceLanguage")) {
        toast.success(t("mt.autoDetectedSource", { value: matchedTarget.label }));
        form.setValue("sourceLanguage", matchedTarget);
        setTargets(matchedTarget);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paragraphResults, sourceLanguages, setTargets, findAutoDetectMatch, t]);

  useEffect(() => {
    if (sourceParagraphs) {
      form.setValue("paragraphs", sourceParagraphs);
    }
    if (status === "error") {
      toast.error("Something bad happened while analyzing your file.");
      resetOcrFileResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetOcrFileResult, sourceParagraphs, status]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
        {configuration && paragraphResults?.status == "done" && (
          <>
            {!ocrResults && (
              <div className="w-full space-y-4 p-4">
                <div className="w-full border-b-2 pb-1 text-xl">{t("mt.file.selectLanguages")}</div>
                <div className="flex">
                  <div className="flex-1">
                    <OcrSourceControls form={form} sourceLanguages={sourceLanguages} setTargets={setTargets} />
                  </div>
                  <ArrowRight className="size-10 shrink-0 opacity-20 hover:opacity-75" />
                  <div className="flex-1">
                    <OcrTargetControls form={form} targetLanguages={targetLanguages} />
                  </div>
                </div>
                <div className="flex">
                  <Button type="submit" className="w-full" data-test="mt-ocr-translate-btn">
                    {isProcessingOcr ? <Loader2Icon className="mr-2 animate-spin" /> : null}
                    {!isProcessingOcr ? t("mt.file.translate") : t("mt.file.uploading")}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
        <div className="mb-5 w-full space-y-4 p-4">
          <div className="flex gap-2">
            <div className="flex-1">
              <div className="mb-5 flex w-full items-end justify-between border-b-2 pb-1 text-xl">
                <div>{t("mt.file.sourceFile")}</div>
                {showOcrDownload ? (
                  <OcrDownloadButton type="source" onDownload={() => handleDownloadFiles("source")} />
                ) : (
                  <div className="h-9" />
                )}
              </div>
              <OcrFile file={file} fileType="source" />
            </div>
            <OcrDisplay
              fileId={file.fileId}
              form={form}
              handleDownloadFiles={handleDownloadFiles}
              setShowOcrDownload={setShowOcrDownload}
              showOcrDownload={showOcrDownload}
              isOcrPreprocessed={ocrResults !== undefined}
            />
          </div>
        </div>
      </form>
    </Form>
  );
};
