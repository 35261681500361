import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";

export const OcrMachineTranslationFormSchema = z.object({
  sourceLanguage: z.object(
    {
      label: z.string(),
      code: z.string(),
      mappedCode: z.string(),
      supportsTerminology: z.boolean(),
    },
    { message: t("mt.languageRequired") }
  ),
  targetLanguage: z.object(
    {
      label: z.string(),
      code: z.string(),
      mappedCode: z.string(),
      supportsTerminology: z.boolean(),
    },
    { message: t("mt.languageRequired") }
  ),
  paragraphs: z.array(
    z.object({
      pageNumber: z.number(),
      paragraphNumber: z.number(),
      paragraph: z.string().optional(),
    })
  ),
});

export type OcrMachineTranslationFormType = z.infer<typeof OcrMachineTranslationFormSchema>;

export const useOcrMachineTranslationForm = () => {
  return useForm<OcrMachineTranslationFormType>({
    mode: "onChange",
    resolver: zodResolver(OcrMachineTranslationFormSchema),
  });
};
