import { EmptyPlaceholder } from "@/components/EmptyPlaceholder";
import { MENU_ICONS } from "@/components/Icons";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { Tabs, TabsContent, TabsList, TabsTriggerAlt } from "@/components/ui/tabs";
import {
  useProjectAnalysisQuery,
  useProjectFinancialsQuery,
  useRequestDetailQuery,
  useRequestDocumentsQuery,
} from "@/query/request.query";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { clsx } from "clsx";
import { ExpandIcon, MinimizeIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { AnalysisTabContent } from "./common/AnalysisTabContent";
import { BasicDetailsContent, DetailsTabContent } from "./common/DetailsTabContent";
import { DocumentsTabContent } from "./common/DocumentsTabContent";
import { FinancialsTabContent } from "./common/financials-tab/FinancialsTabContent";
import type { RequestListType } from "@/model/request.typing";

type RequestDetailParams = {
  type: RequestListType;
  entityType: string;
  id: string;
  activeTab?: string;
};

export function RequestDetailPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { entityType, type, id, activeTab = "analysis" } = useParams<RequestDetailParams>();
  const { data, isLoading } = useRequestDetailQuery(id as string, entityType as string, type as string);
  const { data: financialData = [], isLoading: isFinancialLoading } = useProjectFinancialsQuery(id);
  const { isLoading: isAnalysisLoading } = useProjectAnalysisQuery(data?.projectCode);
  const { data: documentData = [], isLoading: isDocumentLoading } = useRequestDocumentsQuery(
    id as string,
    entityType as string
  );
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tab, setTab] = useState(activeTab);
  const heightTopArea = "260px";
  const idRef = useRef<number | undefined>(undefined);

  const showQuoteTab = !isFinancialLoading && financialData.length > 0;
  const showAnalysisTab = entityType === "project"; // Always show analysis tab for projects

  // Analysis tab is always the default, if explicitAI is true
  useEffect(() => {
    // Use the project's id to ensure the tab doesn't change on rerenders unless the id changes
    if (data?.id !== idRef.current) {
      setTab(activeTab === "analysis" && !showAnalysisTab ? "documents" : activeTab);
      idRef.current = data?.id;
    }
  }, [showAnalysisTab, data?.id, activeTab]);

  return (
    <Sheet open={!!id} modal={false}>
      <SheetContent
        aria-describedby={undefined}
        side="right"
        className={clsx(
          isFullScreen
            ? "fixed inset-x-0 top-0 z-40 min-h-full w-full px-0"
            : "top-0 w-full px-0 md:w-5/6 xl:w-4/6 2xl:w-3/6"
        )}
        onButtonClose={() => navigate(`/client/requests/${type}`)}
      >
        <SheetHeader>
          <VisuallyHidden>
            <SheetTitle>{data?.name ?? "Request name"}</SheetTitle>
          </VisuallyHidden>
        </SheetHeader>
        {!data ? (
          isLoading ? (
            <span />
          ) : (
            <EmptyPlaceholder className="mt-12">
              <EmptyPlaceholder.Icon icon={MENU_ICONS.requests} />
              <EmptyPlaceholder.Title>{t("requests.details.empty.title")}</EmptyPlaceholder.Title>
              <EmptyPlaceholder.Description>{t("requests.details.empty.description")}</EmptyPlaceholder.Description>
            </EmptyPlaceholder>
          )
        ) : (
          <>
            <div className="mr-10 flex justify-between pl-8 pr-5">
              <div className="flex-1">
                <h1 title={data.name} className="truncate text-2xl font-medium" data-test="request-name">
                  {data.name}
                </h1>
                {data.status && (
                  <Badge variant="blue" className="whitespace-nowrap rounded-full px-4 py-1" data-test="request-status">
                    {t(`requests.statuses.${data.status}`)}
                  </Badge>
                )}
              </div>
              <Button
                variant="ghost"
                size="sm"
                className="inline-flex gap-1 px-2 opacity-70 hover:opacity-100"
                title={
                  isFullScreen ? t("reports.charts.options.exitFullScreen") : t("reports.charts.options.fullScreen")
                }
                onClick={() => setIsFullScreen(!isFullScreen)}
              >
                {isFullScreen ? <MinimizeIcon className="size-5" /> : <ExpandIcon className="size-5" />}
              </Button>
            </div>
            <BasicDetailsContent data={data} />
            <Tabs value={tab} onValueChange={setTab}>
              <TabsList
                className="min-w-full justify-start gap-4 rounded-none border-b-2 border-gray-300 bg-transparent pl-4 dark:border-gray-600"
                data-test="request-info-tabs"
              >
                {showAnalysisTab && (
                  <TabsTriggerAlt value="analysis">
                    <div className="inline-flex items-center gap-2">{t("requests.details.tabs.analysis")}</div>
                  </TabsTriggerAlt>
                )}
                <TabsTriggerAlt value="documents">
                  <div className="inline-flex items-center gap-2">
                    {t("requests.details.tabs.documents")} {!isDocumentLoading && <Badge>{documentData.length}</Badge>}
                  </div>
                </TabsTriggerAlt>
                {showQuoteTab && (
                  <TabsTriggerAlt value="financials">
                    <div className="inline-flex items-center gap-2">
                      {t("requests.details.tabs.financials")}{" "}
                      {!isFinancialLoading && <Badge>{financialData.length}</Badge>}
                    </div>
                  </TabsTriggerAlt>
                )}
                <TabsTriggerAlt value="details">
                  <div className="inline-flex items-center gap-2">{t("requests.details.tabs.details")}</div>
                </TabsTriggerAlt>
              </TabsList>
              <div className="overflow-scroll bg-muted" style={{ height: `calc(100vh - ${heightTopArea})` }}>
                <TabsContent value="analysis">
                  {showAnalysisTab && (
                    <AnalysisTabContent
                      project={data}
                      entityType={entityType as string}
                      isLoading={isAnalysisLoading}
                    />
                  )}
                </TabsContent>
                <TabsContent value="documents">
                  <DocumentsTabContent
                    isLoading={isDocumentLoading}
                    documentData={documentData}
                    id={id as string}
                    entityType={entityType as string}
                  />
                </TabsContent>
                <TabsContent value="financials">
                  {showQuoteTab && (
                    <FinancialsTabContent
                      financialData={financialData}
                      projectId={data.id.toString()}
                      entityType={entityType as string}
                      requestType={type}
                      requestStatus={data.status}
                    />
                  )} 
                </TabsContent>
                <TabsContent value="details">
                  <DetailsTabContent data={data} />
                </TabsContent>
              </div>
            </Tabs>
          </>
        )}
      </SheetContent>
    </Sheet>
  );
}
