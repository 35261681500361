import { FloatingLabelInput } from "@/components/input/FloatingInput";
import { DEFAULT_SORT_FIELD_CONFIG, SortControl } from "@/components/sorting/SortControl";
import { useSorting } from "@/components/sorting/useSorting";
import { IdName } from "@/model/common";
import { Column, Table } from "@tanstack/react-table";
import { ReactElement } from "react";
import type { DateRange } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { Combobox } from "../../combobox/Combobox";
import { DateRangePicker, type DateRangePickerProps } from "../../datePicker/DateRangePicker";
import { Button } from "../../ui/button";

interface DatatableFilterProps<TData, TValue> {
  testId?: string;
  column?: Column<TData, TValue>;
  title: string;
  options: IdName[];
}

export function DatatableSelectFilter<TData, TValue>({
  testId,
  column,
  title,
  options,
}: DatatableFilterProps<TData, TValue>) {
  const facets = column?.getFacetedUniqueValues();
  const selectedValues = new Set(column?.getFilterValue() as string[]);

  const realOptions = options
    .filter((option) => facets?.has(option.id))
    .map((option) => ({ ...option, count: facets?.get(option.id) }));

  return (
    <Combobox
      testId={testId}
      title={title}
      options={realOptions}
      selectedIds={Array.from(selectedValues)}
      onChange={(values) => {
        const filterValues = values.map((value) => value.id);
        column?.setFilterValue(values.length > 0 ? filterValues : undefined);
      }}
    />
  );
}

export function DatatableDateFilter<TData, TValue>({
  testId,
  column,
  title,
  minMaxDate,
  presetsType,
}: Omit<DatatableFilterProps<TData, TValue>, "options"> & Pick<DateRangePickerProps, "minMaxDate" | "presetsType">) {
  return (
    <DateRangePicker
      testId={testId}
      title={title}
      align="start"
      range={column?.getFilterValue() as DateRange}
      onUpdate={(dateRange) => {
        if (dateRange.to === undefined && dateRange.from) dateRange.to = dateRange.from;
        column?.setFilterValue(dateRange);
      }}
      minMaxDate={minMaxDate}
      presetsType={presetsType}
      showSingleDate={true}
    />
  );
}

export function DatatableTextFilter<TData, TValue>({
  column,
  title,
  testId,
}: {
  column?: Column<TData, TValue>;
  title: string;
  testId?: string;
}) {
  const columnFilterValue = column?.getFilterValue();

  return (
    <FloatingLabelInput
      type="search"
      data-test={testId}
      value={columnFilterValue ? (columnFilterValue as string) : ""}
      onChange={(e) => column?.setFilterValue(e.target.value)}
      label={title}
    />
  );
}

interface DatatableFiltersListDropdownProps<T> {
  table: Table<T>;
  id: string;
  selectedIds: string[];
  onFiltersListChange: (values: IdName[]) => void;
}

export function DatatableFiltersListDropdown<T>({
  table,
  id,
  selectedIds,
  onFiltersListChange,
}: DatatableFiltersListDropdownProps<T>) {
  const { t } = useTranslation("translation");

  const availableFilters = table
    .getAllColumns()
    .filter((c) => c.getCanFilter())
    .map((c) => ({ id: c.id, name: c.columnDef.header as string }));

  return (
    <div className="min-w-[250px]">
      <Combobox<IdName, "name">
        testId={id}
        title={t("data-table.filters")}
        options={availableFilters}
        selectedIds={selectedIds}
        onChange={onFiltersListChange}
        triggerSize="default"
        sortOptions={{
          defaultSort: { field: "name", direction: "asc" },
          renderSortControl: () => <DatatableFiltersListDropdownFilterSortHeader id={id} />,
        }}
      />
    </div>
  );
}

function DatatableFiltersListDropdownFilterSortHeader({ id }: { id: string }) {
  const { t } = useTranslation();
  const { sortState, updateSort } = useSorting<IdName, "name">({
    id: id,
    defaultSort: { field: "name", direction: "asc" },
  });
  return (
    <div className="flex w-full flex-row items-center gap-2 px-2">
      <div className="flex-1">
        <span data-test={`combobox-${id}-sort-name`} className="text-xs font-semibold leading-4">
          {t("common.selector.languages.sorted")}
          {!sortState?.field ? "" : t(`common.selector.languages.${sortState?.field}`)}
        </span>
      </div>

      <SortControl
        testId={id}
        currentSort={sortState!}
        onSortChange={updateSort}
        fields={[DEFAULT_SORT_FIELD_CONFIG.name]}
      />
    </div>
  );
}

interface DatatableFiltersSectionProps {
  filters: ReactElement;
  isFiltered: boolean;
  onResetClick: () => void;
}

export function DatatableFiltersSection({ filters, isFiltered, onResetClick }: DatatableFiltersSectionProps) {
  const { t } = useTranslation("translation");

  return (
    <div className="mb-2 mt-4 flex flex-1 flex-wrap items-center gap-2">
      {filters}
      {isFiltered && (
        <Button data-test="filters-reset" variant="secondary" size="sm" className="print:hidden" onClick={onResetClick}>
          {t("data-table.rest-filters")}
        </Button>
      )}
    </div>
  );
}
