import type { FileToTranslate, LanguageMap, Terminology } from "model/mt.typing";
import type { FileWithPath } from "react-dropzone";

export function mapFileToTranslate(file: FileWithPath): FileToTranslate {
  return {
    arrayBuffer: file.arrayBuffer,
    id: self.crypto.randomUUID(),
    lastModified: file.lastModified,
    name: file.name,
    path: file.path,
    size: file.size,
    slice: file.slice,
    stream: file.stream,
    text: file.text,
    type: file.type,
    webkitRelativePath: file.webkitRelativePath,
    file: file,
  } as FileToTranslate;
}

export function mapCurrencySymbols(formatNumber: string | undefined, currencySymbol: string | undefined) {
  if (!formatNumber || !currencySymbol) {
    return [];
  }
  return [
    { name: `${currencySymbol}${formatNumber}`, value: "0" },
    { name: `${formatNumber}${currencySymbol}`, value: "1" },
    { name: `${currencySymbol} ${formatNumber}`, value: "2" },
    { name: `${formatNumber} ${currencySymbol}`, value: "3" },
  ];
}

export function getLanguageName(code: string, mappedLanguages: LanguageMap[]): string {
  let result = code;

  const mappedLang = mappedLanguages.find((x) => x.code === code);
  if (mappedLang) result = mappedLang.name;

  return result;
}

export function mapTerminologyItemLanguages(mappedLanguages: LanguageMap[], terminology: Terminology): Terminology {
  for (const item of terminology.terminologyItems) {
    item.sourceLanguageName = getLanguageName(item.sourceLanguageCode, mappedLanguages);
    item.targetLanguageName = getLanguageName(item.targetLanguageCode, mappedLanguages);
  }

  return terminology;
}
