import { CostReportChartNames, TmLeverageChartNames } from "@/model/report.typing";
import FullPageLoader from "@/pages/FullPageLoader";
import { HomePage } from "@/pages/HomePage";
import GenericErrorPage from "@/pages/errors/GenericErrorPage";
import NotAllowedPage from "@/pages/errors/NotAllowedPage";
import NotFoundPage from "@/pages/errors/NotFoundPage";
import ServerErrorPage from "@/pages/errors/ServerErrorPage";
import MachineTranslation from "@/pages/machinetranslation/MachineTranslation";
import { UserProfile } from "@/pages/profile/UserProfile";
import { ReportsLayout } from "@/pages/reports/ReportsLayout";
import { ReportsPage } from "@/pages/reports/ReportsPage";
import { CostReportDashboardPage } from "@/pages/reports/cost/CostReportDashboard";
import { CostReportLayout } from "@/pages/reports/cost/CostReportLayout";
import { SpendPerMonthPage } from "@/pages/reports/cost/SpendPerMonthPage";
import { SpendPerQuarterPage } from "@/pages/reports/cost/SpendPerQuarterPage";
import { SpendPerRequestorPage } from "@/pages/reports/cost/SpendPerRequestorPage";
import { SpendPerTargetLanguagePage } from "@/pages/reports/cost/SpendPerTargetLanguagePage";
import { TmLeverageDashboard } from "@/pages/reports/tmLeverage/TmLeverageDashboard";
import { TmLeverageReportLayout } from "@/pages/reports/tmLeverage/TmLeverageLayout";
import { TmLeveragePerLanguagePage } from "@/pages/reports/tmLeverage/TmLeveragePerLanguagePage";
import { TmLeveragePerMonthPercentagePage } from "@/pages/reports/tmLeverage/TmLeveragePerMonthPercentagePage";
import { TmLeveragePerMonthWordsPage } from "@/pages/reports/tmLeverage/TmLeveragePerMonthWordsPage";
import { RequestDetailPage } from "@/pages/requests/RequestDetail";
import { RequestsPage } from "@/pages/requests/RequestsPage";
import { NewRequestPage, loader as newRequestLoader } from "@/pages/requests/create/NewRequestPage";
import { ReactQueryProvider, queryClient } from "@/providers/ReactQueryProvider";
import ThemeProvider from "@/providers/ThemeProvider";
import { useXSRFTokenQuery } from "@/query/user.query";
import { AuthenticateUserRoute } from "@/routes/AuthenticateUserRoute";
import { AuthorizeRoute } from "@/routes/AuthorizeRoute";
import { MainAppLayout } from "@/routes/layout/MainAppLayout";
import { VNEXT_BASE_PATH } from "@/utils/url";
import { Navigate, Outlet, Route, createBrowserRouter, createRoutesFromElements } from "react-router";
import { RouterProvider } from "react-router/dom";

/* Main route of the application, it contains react-query and react-router contexts */
const MainRoute = () => {
  const { isLoading } = useXSRFTokenQuery();
  if (isLoading) {
    return <FullPageLoader />;
  }
  return (
    <>
      <Outlet />
      <ThemeProvider />
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<MainRoute />} hydrateFallbackElement={<FullPageLoader />}>
      {/*App routes, can be only shown when user is authenticated*/}
      <Route element={<AuthenticateUserRoute />}>
        <Route path="/" element={<MainAppLayout />}>
          <Route index element={<Navigate to={"client"} />} />
          {/* Authorize route */}
          <Route element={<AuthorizeRoute />}>
            {/* Client module */}
            <Route path="client" element={<Outlet />}>
              <Route index element={<HomePage />} />
              {/* MT module */}
              <Route path="machinetranslation" element={<Outlet />}>
                <Route index element={<Navigate to="text" />} />
                <Route path=":type" element={<MachineTranslation />} />
              </Route>

              {/* Reports module */}
              <Route path="reports" element={<ReportsLayout />}>
                <Route index element={<ReportsPage />} />
                <Route path="cost" element={<CostReportLayout />}>
                  <Route index element={<CostReportDashboardPage />} />
                  <Route path={CostReportChartNames.perLanguage} element={<SpendPerTargetLanguagePage />} />
                  <Route path={CostReportChartNames.perMonth} element={<SpendPerMonthPage />} />
                  <Route path={CostReportChartNames.perQuarter} element={<SpendPerQuarterPage />} />
                  <Route path={CostReportChartNames.perRequestor} element={<SpendPerRequestorPage />} />
                </Route>
                <Route path="tmleverage" element={<TmLeverageReportLayout />}>
                  <Route index element={<TmLeverageDashboard />} />
                  <Route path={TmLeverageChartNames.perLanguage} element={<TmLeveragePerLanguagePage />} />
                  <Route
                    path={TmLeverageChartNames.perMonthPercentage}
                    element={<TmLeveragePerMonthPercentagePage />}
                  />
                  <Route path={TmLeverageChartNames.perMonthWords} element={<TmLeveragePerMonthWordsPage />} />
                </Route>
              </Route>

              {/* Requests module */}
              <Route path="requests">
                <Route
                  path="create/:divisionId"
                  element={<NewRequestPage />}
                  loader={newRequestLoader(queryClient)}
                  errorElement={<GenericErrorPage />}
                />
                <Route index element={<Navigate to={"new"} />} />
                <Route path=":type" element={<RequestsPage />}>
                  <Route path="details/:entityType/:id/:activeTab?" element={<RequestDetailPage />} />
                </Route>
              </Route>
            </Route>
          </Route>

          {/* User profile */}
          <Route path="profile" element={<UserProfile />} />
        </Route>
      </Route>
      {/*Error routes*/}
      <Route path="/error/401" element={<NotAllowedPage />} />
      <Route path="/error/404" element={<NotFoundPage />} />
      <Route path="/error/500" element={<ServerErrorPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  ),
  { basename: VNEXT_BASE_PATH }
);

export function App() {
  return (
    <ReactQueryProvider>
      <RouterProvider router={router} />
    </ReactQueryProvider>
  );
}

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}
