import { Button } from "@/components/ui/button";
import { DownloadOptions } from "@/model/mt.typing";
import { t } from "i18next";
import { DownloadIcon, Loader2Icon } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

export function OcrDownloadButton({ type, onDownload }: { type: DownloadOptions; onDownload: () => Promise<boolean> }) {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownloadFile = () => {
    setIsDownloading(true);
    onDownload().then((success) => {
      setIsDownloading(false);
      if (success) {
        toast.success(
          type == "both" || type == "all" ? t("mt.file.downloadSuccessMultiple") : t("mt.file.downloadSuccess")
        );
      } else toast.error(t("mt.file.downloadError"));
    });
  };

  return (
    <Button
      disabled={isDownloading}
      variant="outline"
      type="button"
      size="sm"
      className="mr-1"
      data-test={`mt-ocr-download-${type}`}
      onClick={() => handleDownloadFile()}
    >
      {isDownloading ? <Loader2Icon className="size-5 animate-spin" /> : null}
      {!isDownloading ? <DownloadIcon className="size-5" /> : null}
    </Button>
  );
}
