import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/utils/ui";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker, type DayPickerProps } from "react-day-picker";

function Calendar({ className, classNames, showOutsideDays = false, ...props }: DayPickerProps) {
  const defaultStartMonth = new Date(new Date().getFullYear() - 10, 0, 31); // january 31st 10 years ago
  const defaultEndMonth = new Date(new Date().getFullYear() + 5, 11, 31); // december 31st 5 years from now
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      data-test="calendar-daypicker"
      className={cn("p-3", className)}
      classNames={{
        months: "relative flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        month_caption: "flex justify-center select-none pt-1 relative items-center",
        caption_label: "text-sm font-medium select-none aria-hidden:hidden",
        nav: "absolute flex flex-row justify-between left-0 top-[0.15rem] w-full",
        button_previous: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 z-10"
        ),
        button_next: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 z-10"
        ),
        month_grid: "w-full border-collapse space-y-1",
        weekdays: "flex",
        weekday: "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
        week: "flex w-full mt-2 ",
        day: "size-9 text-center text-sm p-0 hover:bg-accent hover:text-accent-foreground hover:opacity-90",
        day_button: cn(buttonVariants({ variant: "none" }), "size-9 p-0 font-normal"),
        range_start: "rounded-l-md",
        range_end: "rounded-r-md",
        selected:
          "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        today: "bg-accent text-accent-foreground",
        outside:
          "rounded-md text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        disabled: "text-muted-foreground opacity-50",
        range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
        hidden: "opacity-30 pointer-events-none",
        dropdown: "text-sm font-medium outline-none bg-background",
        dropdowns: "flex items-center space-x-1",
        ...classNames,
      }}
      components={{
        Chevron: (props) => {
          if (props.orientation === "left") {
            return <ChevronLeft className="size-4" {...props} />;
          }
          return <ChevronRight className="size-4" {...props} />;
        },
      }}
      {...props}
      startMonth={props.startMonth ?? defaultStartMonth}
      endMonth={props.endMonth ?? defaultEndMonth}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
