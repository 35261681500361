import { getPreSignedUrl, uploadSingleFile, uploadSingleFileLegacy } from "@/api/request.api";
import { chunk } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { NewRequestFormType, ReferenceFile, RequestFile, SourceFile } from "./useNewRequestForm";

export const useManagedFilesToBeUploaded = (
  { divisionId, requestIdentifier, uploadToS3 }: { requestIdentifier: string; divisionId: string, uploadToS3: boolean },
  form: UseFormReturn<NewRequestFormType>,
  notifyFileRejected: ({ name, error, meta }: { name: string; error: string; meta: string }) => void
) => {
  const refGroupId = useRef<number>(1);
  const { fields, remove, append, update } = useFieldArray({ control: form.control, name: "sourceFiles" });
  const [allFiles, setAllFiles] = useState<SourceFile[]>(fields);
  const [uploadAttempts, setUploadAttempts] = useState<Set<string>>(new Set());

  const fileByGroup = useMemo(() => {
    return allFiles.reduce(
      (acc, file) => {
        if (!file.groupName) return acc;
        if (file.groupName === "default") {
          if (!acc["default"]) acc["default"] = [];
          acc["default"].push(file);
          return acc;
        }
        if (!acc[file.groupName]) acc[file.groupName] = [];
        acc[file.groupName].push(file);
        return acc;
      },
      {} as Record<string, SourceFile[]>
    );
  }, [allFiles]);

  const filesToUpload = useMemo(() => {
    if (allFiles.length === 0) return [];
    return allFiles.filter((f) => f.status === "waiting");
  }, [allFiles]);

  const uploadFiles = useCallback(
    (acceptedFiles: File[]) => {
      // filter out files that are already in the allFiles list and notify the user
      const { duplicateFiles, newFiles } = acceptedFiles.reduce(
        (acc, file) => {
          const index = allFiles.findIndex((f) => f.file.name === file.name);
          if (index === -1) {
            acc.newFiles.push(file);
          } else {
            acc.duplicateFiles.push(file);
          }
          return acc;
        },
        { duplicateFiles: [], newFiles: [] } as { duplicateFiles: File[]; newFiles: File[] }
      );

      if (duplicateFiles.length > 0) {
        duplicateFiles.forEach((file) => {
          notifyFileRejected({ name: file.name, error: "file-exists", meta: "" });
        });
      }

      if (newFiles.length === 0) return;

      const filesToUpload: SourceFile[] = [];

      if (newFiles.length === 1) {
        const newFile = newFiles[0];
        filesToUpload.push({
          id: newFile.name,
          key: newFile.name,
          file: newFile,
          groupName: "default",
          progression: 0,
          status: "waiting",
          sourceLanguageCode: null,
          targetLanguageCodes: [],
          uploaded: false,
        });
      } else {
        const groupName = `Batch ${refGroupId.current++}`;
        newFiles.forEach((file) => {
          filesToUpload.push({
            id: file.name,
            key: file.name,
            file,
            groupName,
            progression: 0,
            status: "waiting",
            sourceLanguageCode: null,
            targetLanguageCodes: [],
            uploaded: false,
          });
        });
      }
      append(filesToUpload);
      setAllFiles((prev) => {
        const files = [...prev, ...filesToUpload];
        return files;
      });
    },
    [allFiles, append, notifyFileRejected]
  );

  const updateFile = useCallback(
    (fileKey: string, data: Partial<SourceFile>) => {
      setAllFiles((prev) => {
        return prev.map((file, index) => {
          if (file.key === fileKey) {
            const updatedFile = { ...file, ...data, id: fileKey };
            if (data.sourceLanguageCode) {
              form.setValue(`sourceFiles.${index}.sourceLanguageCode`, data.sourceLanguageCode, { shouldDirty: true });
            } else if (data.targetLanguageCodes) {
              form.setValue(`sourceFiles.${index}.targetLanguageCodes`, data.targetLanguageCodes, {
                shouldDirty: true,
              });
            } else if (data.status === "planned") {
              form.setValue(`sourceFiles.${index}.status`, data.status);
            } else if (data.status === "failed" || data.status === "canceled") {
              form.setValue(`sourceFiles.${index}.uploaded`, data.uploaded === true, { shouldDirty: true });
              form.setValue(`sourceFiles.${index}.status`, data.status, { shouldDirty: true });
            } else if (data.status === "uploading") {
              form.setValue(`sourceFiles.${index}.uploaded`, data.uploaded === true, { shouldDirty: true });
              form.setValue(`sourceFiles.${index}.status`, data.status);
              form.setValue(`sourceFiles.${index}.progression`, data.progression ?? 0);
              form.setValue(`sourceFiles.${index}.remainingTime`, data.remainingTime);
              if (data.abortController) {
                form.setValue(`sourceFiles.${index}.abortController`, data.abortController);
              }
            } else if (data.status === "uploaded") {
              form.setValue(`sourceFiles.${index}.uploaded`, data.uploaded === true, { shouldDirty: true });
              form.setValue(`sourceFiles.${index}.status`, data.status, { shouldDirty: true });
              form.setValue(`sourceFiles.${index}.remainingTime`, data.remainingTime);
              form.setValue(`sourceFiles.${index}.abortController`, data.abortController);
              form.setValue(`sourceFiles.${index}.progression`, data.progression ?? 0);
            } else {
              form.setValue(`sourceFiles.${index}`, updatedFile);
            }
            return updatedFile;
          }
          return file;
        });
      });
    },
    // safe to ignore form here because we use only the setValue function
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const removeFiles = useCallback(
    (fileKeys: string | string[]) => {
      const isSingleFileWithGroupName =
        typeof fileKeys === "string" && allFiles.find((f) => f.key === fileKeys)?.groupName !== "default";
      if (!Array.isArray(fileKeys) && !isSingleFileWithGroupName) {
        remove(allFiles.findIndex((f) => f.key === fileKeys));
        setAllFiles((prev) => {
          const fileToCancel = prev.find((f) => f.key === fileKeys);
          if (fileToCancel?.abortController) {
            fileToCancel.abortController.abort();
          }
          return prev.filter((f) => f.key !== fileKeys);
        });
      } else {
        const arrayFileKeys = Array.isArray(fileKeys) ? fileKeys : [fileKeys];
        const fileIndexes = arrayFileKeys.map((key) => allFiles.findIndex((f) => f.key === key));
        remove(fileIndexes);

        const filesToCancel = allFiles.filter((f) => f.abortController && arrayFileKeys.includes(f.key));
        filesToCancel.forEach((file) => {
          if (file.abortController) file.abortController.abort();
        });

        const newAllFiles = allFiles.filter((f) => !arrayFileKeys.includes(f.key));

        // update the group name number of all files
        const groupNames = newAllFiles.reduce((acc, file) => {
          if (file.groupName && file.groupName !== "default") {
            acc.add(file.groupName);
          }
          return acc;
        }, new Set<string>());

        refGroupId.current = 1;
        groupNames.forEach((groupName) => {
          const groupFiles = newAllFiles.filter((f) => f.groupName === groupName);
          if (groupFiles.length === 0) return;
          if (groupFiles.length === 1) {
            groupFiles.forEach((file) => {
              file.groupName = "default";
            });
          } else {
            groupFiles.forEach((file) => {
              file.groupName = `Batch ${refGroupId.current}`;
            });
            refGroupId.current++;
          }
        });

        setAllFiles(newAllFiles);
        // update indexes
        newAllFiles.forEach((file) => {
          const currentIndex = newAllFiles.findIndex((f) => f.key === file.key);
          update(currentIndex, { ...file, groupName: file.groupName });
          // todo update indexes
        });
      }
    },
    [allFiles, update, remove]
  );

  useEffect(() => {
    if (filesToUpload.length === 0) return;

    // Filter out files that have already been attempted
    const newFilesToUpload = filesToUpload.filter((file) => !uploadAttempts.has(file.key));
    if (newFilesToUpload.length === 0) return;

    // Mark these files as attempted
    setUploadAttempts((prev) => {
      const next = new Set(prev);
      newFilesToUpload.forEach((file) => next.add(file.key));
      return next;
    });

    newFilesToUpload.forEach((file) => {
      updateFile(file.key, { status: "planned" });
    });
    batchUpload({ requestIdentifier, divisionId, files: newFilesToUpload, uploadToS3 }, updateFile);
  }, [divisionId, filesToUpload, requestIdentifier, updateFile, uploadAttempts, uploadToS3]);

  useEffect(() => {
    allFiles.forEach((file, index) => {
      const dirtyFields = form.formState.dirtyFields?.sourceFiles?.[index];
      if (dirtyFields) {
        const isSourceLanguageDirty = dirtyFields.sourceLanguageCode === true;
        const isTargetLanguagesDirty = dirtyFields.targetLanguageCodes && dirtyFields.targetLanguageCodes.length > 0;
        if (isSourceLanguageDirty || file.sourceLanguageCode) {
          form.trigger(`sourceFiles.${index}.sourceLanguageCode`);
        }
        if (isTargetLanguagesDirty || (file.targetLanguageCodes && file.targetLanguageCodes.length > 0)) {
          form.trigger(`sourceFiles.${index}.targetLanguageCodes`);
        }
      }
    });
    // safe to ignore form here because we use only the trigger function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFiles]);

  const retryUpload = useCallback(
    (fileId: string) => {
      const fileIndex = allFiles.findIndex((f) => f.id === fileId);
      if (fileIndex !== -1) {
        // Remove from upload attempts to allow retry
        setUploadAttempts((prev) => {
          const next = new Set(prev);
          next.delete(allFiles[fileIndex].key);
          return next;
        });

        // Update file status with the correct type
        const updatedFile: SourceFile = {
          ...allFiles[fileIndex],
          status: "waiting" as const,
          progression: 0,
          uploaded: false,
        };

        setAllFiles((prev) => {
          const next = [...prev];
          next[fileIndex] = updatedFile;
          return next;
        });

        update(fileIndex, updatedFile);
      }
    },
    [allFiles, update, setUploadAttempts]
  );

  return {
    uploadFiles,
    removeFiles,
    updateFile,
    fileByGroup,
    retryUpload,
  };
};

async function batchUpload<T extends RequestFile>(
  { divisionId, requestIdentifier, files, uploadToS3 }: { requestIdentifier: string; divisionId: string; files: T[], uploadToS3: boolean },
  onChange: (fileKey: string, data: Partial<T>) => void
) {
  const onStarting = (file: T, abortController: AbortController) => {
    onChange(file.key, { status: "uploading", progression: 0, uploaded: false, abortController } as Partial<T>);
  };
  const onProgress = (file: T, progress: number, remainingTime?: string) => {
    onChange(file.key, { status: "uploading", progression: progress, remainingTime, uploaded: false } as Partial<T>);
  };
  const onUploaded = (file: T) => {
    onChange(file.key, {
      status: "uploaded",
      progression: 100,
      uploaded: true,
      abortController: undefined,
      remainingTime: "0",
    } as Partial<T>);
  };
  const onFailed = (file: T, isCancellation: boolean) => {
    onChange(file.key, { status: isCancellation ? "canceled" : "failed" } as Partial<T>);
  };

  try {
    // upload to s3
    if (uploadToS3) {
      const presignedUrls = await getPreSignedUrl(requestIdentifier, divisionId, files.map((f) => f.file.name));
      if (!presignedUrls) {
        files.forEach((file) => onFailed(file, false));
        return;
      }
      const chunkedFiles = chunk(files, 2);
      for (const someFiles of chunkedFiles) {
        await Promise.all(
          someFiles.map((sourceFile) => {
            const urlData = presignedUrls.find((url) => {
              return url.fileName === sourceFile.file.name;
            });
            if (!urlData) {
              onFailed(sourceFile, false);
              return Promise.resolve();
            }
            return uploadSingleFile(
              { sourceFile, presignedUrl: urlData.uploadUrl },
              onStarting,
              onProgress,
              onUploaded,
              onFailed
            );
          })
        );
      }
    }
    // upload to legacy
    else {
      const chunkedFiles = chunk(files, 2);
      for (const someFiles of chunkedFiles) {
        // uploading file by file
        await Promise.all(
          someFiles.map((sourceFile) =>
            uploadSingleFileLegacy({ requestIdentifier, divisionId, sourceFile }, onStarting, onProgress, onUploaded, onFailed)
          )
        );
      }
    }

  } catch (error) {
    console.error("Upload failed:", error);
    // Mark all files as failed when there's a network error
    files.forEach((file) => onFailed(file, false));
  }
}

export const useReferenceFilesToBeUploaded = (
  { requestIdentifier, divisionId, uploadToS3 }: { requestIdentifier: string; divisionId: string; uploadToS3: boolean },
  form: UseFormReturn<NewRequestFormType>
) => {
  const { update, remove, append, fields: files } = useFieldArray({ control: form.control, name: "referenceFiles" });
  const [uploadAttempts, setUploadAttempts] = useState<Set<string>>(new Set());

  const uploadFiles = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;
      const filesToUpload: ReferenceFile[] = [];
      acceptedFiles.forEach((file) => {
        const index = files.findIndex((f) => f.key === file.name);
        if (index === -1) {
          filesToUpload.push({
            id: file.name,
            key: file.name,
            file,
            progression: 0,
            status: "waiting",
            uploaded: false,
          });
        }
      });

      if (filesToUpload.length === 0) return;
      append(filesToUpload);
    },
    [append, files]
  );

  useEffect(() => {
    if (files.length === 0) return;
    const filesToUpload = files.filter((f) => f.status === "waiting" && !uploadAttempts.has(f.key));
    if (filesToUpload.length === 0) return;

    setUploadAttempts((prev) => {
      const next = new Set(prev);
      filesToUpload.forEach((f) => next.add(f.key));
      return next;
    });

    batchUpload(
      { requestIdentifier, divisionId, files: filesToUpload, uploadToS3 },
      (fileKey: string, data: Partial<ReferenceFile>) => {
        const fileIndex = files.findIndex((f) => f.key === fileKey);
        if (fileIndex !== -1) {
          update(fileIndex, { ...files[fileIndex], ...data });
        }
      }
    );
  }, [divisionId, requestIdentifier, files, update, uploadAttempts, uploadToS3]);

  const retryUpload = useCallback(
    (fileId: string) => {
      const fileIndex = files.findIndex((f) => f.id === fileId);
      if (fileIndex !== -1) {
        setUploadAttempts((prev) => {
          const next = new Set(prev);
          next.delete(files[fileIndex].key);
          return next;
        });
        update(fileIndex, {
          ...files[fileIndex],
          status: "waiting",
          progression: 0,
          uploaded: false,
        });
      }
    },
    [files, update]
  );

  const removeFile = useCallback(
    (fileId: string) => {
      const fileToRemove = files.find((f) => f.id === fileId);
      if (fileToRemove) {
        setUploadAttempts((prev) => {
          const next = new Set(prev);
          next.delete(fileToRemove.key);
          return next;
        });
      }
      remove(files.findIndex((f) => f.id === fileId));
    },
    [files, remove]
  );

  const referenceFiles = useMemo(() => {
    return files.reduce((acc, file) => {
      acc.push(file);
      return acc;
    }, [] as ReferenceFile[]);
  }, [files]);

  return { uploadFiles, removeFile, referenceFiles, retryUpload };
};

