import { useSidebar } from "@/components/ui/sidebar";
import { clsx } from "clsx";
import { Button } from "components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { backwardDots, forwardDots, usePagination, usePaginationSelector } from "hooks/usePagination";
import { ChevronLeftIcon, ChevronRightIcon, ChevronsLeftIcon, ChevronsRightIcon, EllipsisIcon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

const PAGE_SIZES = [5, 10, 15, 20];

export interface PaginationType {
  pageSize: number;
  pageIndex: number;
}

interface DatatablePaginationProps {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  onPaginationChange: (pagination: PaginationType) => void;
  isShrinkedLeft?: boolean;
}

export function DatatablePagination({
  currentPage,
  totalCount,
  pageSize,
  onPaginationChange,
  isShrinkedLeft = false,
}: DatatablePaginationProps) {
  const { t } = useTranslation("translation", { keyPrefix: "data-table.pagination" });
  const { isMobile } = useSidebar();

  if (totalCount === 0) {
    return null;
  }

  const currentPageCount = currentPage === 1 ? currentPage : (currentPage - 1) * pageSize;
  const totalPageCount = (currentPage - 1) * pageSize + pageSize;

  return (
    <div className="bg-muted/50 px-4 py-3 text-sm font-medium text-muted-foreground">
      <div
        className={clsx("flex flex-1 flex-wrap gap-2", {
          "items-center justify-between": !isShrinkedLeft,
        })}
      >
        <div className="flex flex-wrap items-baseline gap-4">
          <div className="whitespace-nowrap">
            {totalPageCount < totalCount ? (
              <div className="flex flex-nowrap gap-1">
                <span>{t("showing")}</span>
                <span>{currentPageCount}</span>
                <span>{t("to")}</span>
                <span>{totalPageCount}</span>
                <span>{t("of")}</span>
                <span>{totalCount}</span>
                <span>{t("results")}</span>
              </div>
            ) : (
              <span>
                {t("total")} <span>{totalCount}</span>
              </span>
            )}
          </div>
          <Select
            value={pageSize.toString()}
            onValueChange={(value) => onPaginationChange({ pageSize: Number(value), pageIndex: 1 })}
          >
            <SelectTrigger className="w-fit">
              <SelectValue placeholder={t("data-table.pagination.pageSize")} className="mr-4" />
            </SelectTrigger>
            <SelectContent>
              {PAGE_SIZES.map((pz) => (
                <SelectItem key={pz} value={pz.toString()}>
                  {pz} {t("per-page")}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          {isShrinkedLeft || isMobile ? (
            <TablePageSelectorSelect
              totalCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPaginationChange={onPaginationChange}
            />
          ) : (
            <TablePageSelectorNavButtons
              totalCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPaginationChange={onPaginationChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

interface TablePageSelectorProps {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  onPaginationChange: (pagination: PaginationType) => void;
}

function TablePageSelectorNavButtons({
  totalCount,
  pageSize,
  currentPage,
  onPaginationChange,
}: TablePageSelectorProps) {
  const { t } = useTranslation("translation");
  const paginationRange = usePagination({ currentPage, pageSize, totalCount });
  const lastPage = paginationRange?.length > 0 ? Number(paginationRange[paginationRange.length - 1]) : 0;
  const [isForwardHovered, setIsForwardHovered] = useState<boolean>(false);
  const [isBackwardHovered, setIsBackwardHovered] = useState<boolean>(false);

  function resetDotHovers() {
    setIsBackwardHovered(false);
    setIsForwardHovered(false);
  }

  return (
    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm">
      <Button
        type="button"
        size="icon"
        variant="outline"
        onClick={() => {
          resetDotHovers();
          onPaginationChange({ pageIndex: currentPage - 1, pageSize });
        }}
        disabled={currentPage === 1}
        className="rounded-r-none"
      >
        <span className="relative">
          <span className="sr-only">{t("previous")}</span>
        </span>
        <ChevronLeftIcon className="size-5" aria-hidden="true" />
      </Button>

      {paginationRange.map((pageNumber, idx) => {
        if (pageNumber === backwardDots) {
          return (
            <Button
              type="button"
              size="icon"
              variant="outline"
              className="flex items-center rounded-none align-middle"
              key={pageNumber + idx}
              onClick={() => {
                resetDotHovers();
                onPaginationChange({ pageIndex: currentPage - 3, pageSize });
              }}
              onMouseOver={() => setIsBackwardHovered(true)}
              onMouseLeave={() => setIsBackwardHovered(false)}
            >
              {isBackwardHovered ? (
                <ChevronsLeftIcon className="size-4" aria-hidden="true" />
              ) : (
                <EllipsisIcon className="mt-2 size-4 opacity-50" aria-hidden="true" />
              )}
            </Button>
          );
        }
        if (pageNumber === forwardDots) {
          return (
            <Button
              type="button"
              size="icon"
              variant="outline"
              className="flex items-center rounded-none align-bottom"
              key={pageNumber + idx}
              onClick={() => {
                resetDotHovers();
                if (currentPage + 3 <= lastPage) {
                  onPaginationChange({ pageIndex: currentPage + 3, pageSize });
                } else {
                  onPaginationChange({ pageIndex: lastPage, pageSize });
                }
              }}
              onMouseOver={() => setIsForwardHovered(true)}
              onMouseLeave={() => setIsForwardHovered(false)}
            >
              {isForwardHovered ? (
                <ChevronsRightIcon className="size-4" aria-hidden="true" />
              ) : (
                <EllipsisIcon className="mt-2 size-4 opacity-50" aria-hidden="true" />
              )}
            </Button>
          );
        }
        const isActive = (pageNumber as number) === currentPage;
        return (
          <Button
            key={pageNumber}
            type="button"
            size="icon"
            variant="outline"
            onClick={() => {
              resetDotHovers();
              onPaginationChange({ pageIndex: pageNumber as number, pageSize });
            }}
            className={cn("hidden rounded-none sm:block", {
              "bg-accent text-accent-foreground": isActive,
            })}
          >
            {pageNumber.toString()}
          </Button>
        );
      })}
      <Button
        type="button"
        size="icon"
        variant="outline"
        disabled={currentPage === lastPage}
        onClick={() => {
          resetDotHovers();
          onPaginationChange({ pageIndex: currentPage + 1, pageSize });
        }}
        className="rounded-l-none"
      >
        <span className="relative">
          <span className="sr-only">{t("next")}</span>
        </span>
        <ChevronRightIcon className="size-5" aria-hidden="true" />
      </Button>
    </nav>
  );
}

function TablePageSelectorSelect({ totalCount, pageSize, currentPage, onPaginationChange }: TablePageSelectorProps) {
  const { t } = useTranslation("translation", { keyPrefix: "data-table.pagination" });
  const totalPageCount = usePaginationSelector({ totalCount, pageSize });

  function getSelectItems(totalPageCount: number) {
    return Array.from(Array(totalPageCount), (_, i) => {
      return (
        <SelectItem key={i + 1} value={(i + 1).toString()}>
          {i + 1}
        </SelectItem>
      );
    });
  }

  return (
    <Select
      value={currentPage.toString()}
      onValueChange={(value) => onPaginationChange({ pageIndex: +value, pageSize })}
    >
      <SelectTrigger className="w-fit gap-x-2">
        <span>{t("page")}</span>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>{getSelectItems(totalPageCount)}</SelectContent>
    </Select>
  );
}
