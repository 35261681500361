import { User } from "@/model/user.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { downloadAsExcelReport } from "@/utils/excel";

import { t } from "i18next";
import { MachineTranslationResult } from "model/mt.typing";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { create } from "zustand";

interface WorklistStoreState {
  worklistItems: MachineTranslationResult[];
  addWorklistItem: (item: MachineTranslationResult) => void;
  removeWorklistItem: (id: string) => void;
  setWorklistItems: (data: MachineTranslationResult[]) => void;
}

const useStore = create<WorklistStoreState>((set) => ({
  worklistItems: [],
  addWorklistItem: (item: MachineTranslationResult) => {
    set((state) => ({ worklistItems: [...state.worklistItems, item] }));
  },
  removeWorklistItem: (id: string) => {
    set((state) => ({ worklistItems: state.worklistItems.filter((x) => x.id !== id) }));
  },
  setWorklistItems: (data: MachineTranslationResult[]) => {
    set(() => ({ worklistItems: data }));
  },
}));

function useWorklist() {
  const user = useAuthUserStore((state) => state.user as User);
  const [isFetched, setIsFetched] = useState(false);
  const [filter, setFilter] = useState("");
  const [filteredItems, setFilteredItems] = useState<MachineTranslationResult[]>([]);

  const { worklistItems, addWorklistItem, removeWorklistItem, setWorklistItems } = useStore((state) => state);

  useEffect(() => {
    if (!isFetched && user) {
      const savedTranslations = localStorage.getItem(`mtSavedTranslations-${user.id}`);
      if (savedTranslations) {
        setWorklistItems(JSON.parse(savedTranslations));
        setIsFetched(true);
      }
    }
  }, [isFetched, setIsFetched, setWorklistItems, user]);

  const updateLocalStorage = (data: MachineTranslationResult[]) => {
    localStorage.setItem(`mtSavedTranslations-${user.id}`, JSON.stringify(data));
  };

  const addItem = (item: MachineTranslationResult) => {
    if (!worklistItems.includes(item)) {
      addWorklistItem(item);
      updateLocalStorage([...worklistItems, item]);
    }
  };

  const removeItem = (id: string) => {
    removeWorklistItem(id);
    updateLocalStorage(worklistItems.filter((x) => x.id !== id));
    toast.success(t("mt.removedFromWorklist"));
  };

  const clearItems = () => {
    setWorklistItems([]);
    localStorage.removeItem(`mtSavedTranslations-${user.id}`);
    toast.success(t("mt.worklistCleared"));
  };

  const copyToClipboard = (id: string) => {
    const saved = worklistItems.find((t) => t.id === id);
    if (saved) navigator.clipboard.writeText(saved.targetText).then();
    toast.success(t("mt.copiedToClipboard"));
  };

  const exportToExcel = () => {
    const mappedTranlations: object[] = [];
    const itemsToExport = filter !== "" && filteredItems.length > 0 ? filteredItems : worklistItems;

    itemsToExport.forEach((item) => {
      mappedTranlations.push({
        "Source Text": item.sourceText,
        "Target Text": item.targetText,
        "Source Language": item.sourceLanguage.label,
        "Target Language": item.targetLanguage.label,
        "Word Count": item.wordCount,
      });
    });

    const columns = [
      { width: 50, header: "Source Text", key: "Source Text" },
      { width: 50, header: "Target Text", key: "Target Text" },
      { width: 20, header: "Source Language", key: "Source Language" },
      { width: 20, header: "Target Language", key: "Target Language" },
      { width: 15, header: "Word Count", key: "Word Count" },
    ];

    downloadAsExcelReport(mappedTranlations, columns, "Worklist");
  };

  const changeFilter = (value: string) => {
    setFilter(value);
    if (value !== "") {
      const searchText = value.toLowerCase().trim();
      const filteredTranslations = worklistItems.filter((translation) => {
        const sourceText = translation.sourceText.toLowerCase();
        const targetText = translation.targetText.toLowerCase();

        return sourceText.includes(searchText) || targetText.includes(searchText);
      });
      setFilteredItems(filteredTranslations);
    }
  };

  return {
    addItem,
    changeFilter,
    clearItems,
    copyToClipboard,
    exportToExcel,
    filter,
    filteredItems,
    removeItem,
    worklistItems,
  };
}

export default useWorklist;
