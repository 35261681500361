import { useOnMount } from "@/hooks/useOnMount";
import {
  TmLeverageChartNames,
  type TmLeverageChartNamesType,
  type TmLeverageFiltersType,
  type TmLeveragePerLanguagesType,
} from "@/model/report.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { isDateInRange } from "@/utils/dates";
import { chain } from "lodash";
import { useMemo } from "react";
import { useTmLeverageActions, useTmLeverageStoredFilters } from "../reportStore";

export function useTmLeverageFilteredData(tmLeverages: TmLeveragePerLanguagesType[]) {
  const filters = useTmLeverageStoredFilters();
  const user = useAuthUserStore((store) => store.user);
  const showClient = user?.showClient ?? false;

  const filteredData = useMemo(() => {
    return chain(tmLeverages)
      .filter((item) => {
        if (filters.searchText?.length > 0) {
          const trimmedSearchText = filters.searchText.toLowerCase().trim();
          if (
            !item.projectName?.toLowerCase().toString().includes(trimmedSearchText) &&
            !item.projectId?.toLowerCase().toString().includes(trimmedSearchText) &&
            !item.portalProjectId?.toString().includes(trimmedSearchText)
          )
            return false;
        }
        if (
          showClient &&
          isArrayHasValue(filters.clientNameIds) &&
          !filters.clientNameIds.includes(item.client.id.toString())
        ) {
          return false;
        }
        if (
          !!item.owner &&
          isArrayHasValue(filters.ownersIds) &&
          !filters.ownersIds.includes(item.owner.id.toString())
        ) {
          return false;
        }
        if (!isDateInRange(new Date(item.deliveryDate), filters.deliveryDate)) {
          return false;
        }
        if (filters.sourceLanguageIds.length > 0 || filters.targetLanguageIds.length > 0) {
          const sourceLanguageFilter = isArrayHasValue(filters.sourceLanguageIds)
            ? filters.sourceLanguageIds.includes(item.sourceCode)
            : true;
          const targetLanguageFilter = isArrayHasValue(filters.targetLanguageIds)
            ? filters.targetLanguageIds.includes(item.targetCode)
            : true;
          return sourceLanguageFilter && targetLanguageFilter;
        }
        if (isArrayHasValue(filters.divisionNames) && !filters.divisionNames.includes(item.ecosystemName)) {
          return false;
        }
        return true;
      })
      .orderBy("name")
      .value();
  }, [tmLeverages, filters, showClient]);

  return {
    showClient,
    filteredData,
  };
}

export function applyFuzzyNoMatchCalculation(
  chartType: TmLeverageChartNamesType | null,
  tmLeverages: TmLeveragePerLanguagesType[]
) {
  if (chartType === TmLeverageChartNames.perLanguage && tmLeverages.length > 0) {
    return tmLeverages.map((language) => {
      const fuzzy = language.m95_99 + language.m85_94 + language.m75_84 + language.m50_74; // SUM=95_99_match_quantity + 85_94_match_quantity + 75_84_match_quantity + 50_74_match_quantity
      return { ...language, fuzzy };
    });
  }

  if (chartType === TmLeverageChartNames.perMonthPercentage && tmLeverages.length > 0) {
    return tmLeverages.map((language) => {
      const fuzzy = language.m95_99 + language.m85_94 + language.m75_84; // SUM=95_99_match_quantity + 85_94_match_quantity + 75_84_match_quantity
      const noMatch = language.m50_74 + (language.noMatch ?? 0); // SUM=50_74_match_quantity + no_match_quantity
      return { ...language, fuzzy, noMatch };
    });
  }

  return tmLeverages;
}

export function useTmLeverageFilter(tmLeverages: TmLeveragePerLanguagesType[], hasSearchParams: boolean) {
  const user = useAuthUserStore((store) => store.user);
  const filters = useTmLeverageStoredFilters();
  const { updateFilters } = useTmLeverageActions();
  const showClient = useMemo(() => user?.showClient ?? false, [user?.showClient]);

  useOnMount(() => {
    if (hasSearchParams) {
      onReset();
    }
  });

  const options = useMemo(() => {
    const deliveryDates = tmLeverages
      .filter((item) => filterItemForOptions(filters, item, showClient, "deliveryDate"))
      .filter((item) => !!item.deliveryDate)
      .map(({ deliveryDate }) => new Date(deliveryDate).getTime());
    const deliveryDateMinMax = {
      from: new Date(Math.min(...deliveryDates)),
      to: new Date(Math.max(...deliveryDates)),
    };

    return {
      deliveryDateMinMax,
      clientNames: chain(tmLeverages)
        .filter((item) => filterItemForOptions(filters, item, showClient, "clientNameIds"))
        .groupBy((item) => item.client.id)
        .map((items) => ({
          id: items[0].client.id.toString(),
          name: items[0].client.name,
          count: items.length,
        }))
        .value(),
      owners: chain(tmLeverages)
        .filter((item) => !!item.owner && filterItemForOptions(filters, item, showClient, "ownersIds"))
        .groupBy((item) => item.owner?.id)
        .map((items) => ({
          id: items[0].owner?.id,
          name: items[0].owner?.name,
          count: items.length,
        }))
        .orderBy(["count", "name"], ["desc", "asc"])
        .value(),
      sourceLanguages: chain(tmLeverages)
        .filter((item) => filterItemForOptions(filters, item, showClient, "sourceLanguageIds"))
        .groupBy("sourceCode")
        .map((items) => ({
          id: items[0].sourceCode,
          name: items[0].sourceName,
          count: items.length,
        }))
        .orderBy("count", "desc")
        .value(),
      targetLanguages: chain(tmLeverages)
        .filter((item) => filterItemForOptions(filters, item, showClient, "targetLanguageIds"))
        .groupBy("targetCode")
        .map((items) => ({
          id: items[0].targetCode,
          name: items[0].targetName,
          count: items.length,
        }))
        .orderBy("count", "desc")
        .value(),
      divisions: chain(tmLeverages)
        .filter((item) => filterItemForOptions(filters, item, showClient, "divisionNames"))
        .groupBy("ecosystemName")
        .map((items) => ({
          id: items[0].ecosystemName,
          name: items[0].ecosystemName,
          count: items.length,
        }))
        .orderBy("count", "desc")
        .value(),
    };
  }, [tmLeverages, filters, showClient]);

  const hasActiveFilters = useMemo(() => {
    return (
      !!filters.deliveryDate?.from ||
      !!filters.deliveryDate?.to ||
      Object.values(filters).filter(isArrayHasValue).length > 0 ||
      filters.searchText?.length > 0
    );
  }, [filters]);

  const onReset = () => {
    updateFilters({
      clientNameIds: [],
      ownersIds: [],
      targetLanguageIds: [],
      sourceLanguageIds: [],
      deliveryDate: undefined,
      divisionNames: [],
      searchText: "",
    });
  };

  return { filters, hasActiveFilters, options, onReset, updateFilters };
}

function filterItemForOptions(
  filters: TmLeverageFiltersType,
  item: TmLeveragePerLanguagesType,
  showClient: boolean,
  filterToExclude: keyof TmLeverageFiltersType
) {
  const isExcluded = (filter?: keyof TmLeverageFiltersType) => filter === filterToExclude;

  if (
    showClient &&
    !isExcluded("clientNameIds") &&
    isArrayHasValue(filters.clientNameIds) &&
    !filters.clientNameIds.includes(item.client.id.toString())
  ) {
    return false;
  }
  if (
    !isExcluded("ownersIds") &&
    isArrayHasValue(filters.ownersIds) &&
    !!item.owner &&
    !filters.ownersIds.includes(item.owner.id.toString())
  ) {
    return false;
  }
  if (
    !isExcluded("sourceLanguageIds") &&
    isArrayHasValue(filters.sourceLanguageIds) &&
    !filters.sourceLanguageIds.includes(item.sourceCode)
  ) {
    return false;
  }
  if (
    !isExcluded("targetLanguageIds") &&
    isArrayHasValue(filters.targetLanguageIds) &&
    !filters.targetLanguageIds.includes(item.targetCode)
  ) {
    return false;
  }
  if (!isExcluded("deliveryDate") && !isDateInRange(new Date(item.deliveryDate), filters.deliveryDate)) {
    return false;
  }
  if (
    !isExcluded("divisionNames") &&
    isArrayHasValue(filters.divisionNames) &&
    !filters.divisionNames.includes(item.ecosystemName)
  ) {
    return false;
  }
  return true;
}

function isArrayHasValue<T>(value: T | T[]): value is T[] {
  return Array.isArray(value) && value.length > 0;
}
