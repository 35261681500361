import { DatePicker } from "@/components/DatePicker";
import { PageShell } from "@/components/shell/PageShell";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Form, FormField, FormItem } from "@/components/ui/form";
import { Stepper } from "@/components/ui/stepper";
import { useDateOperations } from "@/hooks/useDate";
import { CustomField, DivisionType } from "@/model/request.typing";

import {
  divisionQuery,
  requestIdentifierQuery,
  useCustomFieldsQuery,
  useNewRequestMutation,
} from "@/query/request.query";
import { cn } from "@/utils/ui";
import type { QueryClient } from "@tanstack/react-query";
import { ArrowRightIcon, Loader2Icon } from "lucide-react";
import { useEffect, useState } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { unstable_usePrompt, useLoaderData, useNavigate } from "react-router";
import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { NewRequestButton } from "../common/NewRequestButton";
import ConfirmSubmitDialog, { ConfirmSubmitDialogProps } from "./common/ConfirmSubmitDialog";
import { DetailsWizardStep } from "./DetailsWizardStep";
import { ServicesWizardStep } from "./ServicesWizardStep";
import { SummaryWizardStep } from "./SummaryWizardStep";
import { UploadFilesStep } from "./UploadFilesStep";
import { type NewRequestFormType, useNewRequestForm } from "./useNewRequestForm";
import { useStepperState } from "./useStepperState";

export const tabNames = ["services", "upload", "details", "summary"];

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) => {
    const divisionId = params.divisionId;
    if (!divisionId) {
      throw redirect("/client/requests");
    }

    const divisions = await queryClient.ensureQueryData(divisionQuery);

    const division = divisions.find((division) => division.id === divisionId) ?? null;

    if (!division) {
      throw redirect("/client/requests");
    }

    const requestIdentifier = await queryClient.fetchQuery(requestIdentifierQuery(divisionId));

    if (!requestIdentifier) {
      throw redirect("/client/requests");
    }

    return { division, requestIdentifier };
  };
}

export function NewRequestPage() {
  const { division, requestIdentifier } = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>;

  return (
    <PageShell>
      <CreateWizard key={requestIdentifier} division={division} requestIdentifier={requestIdentifier} />
    </PageShell>
  );
}

function CreateWizard({ division, requestIdentifier }: { division: DivisionType; requestIdentifier: string }) {
  const { t } = useTranslation("translation");
  const [confirmDialogState, setConfirmDialogState] = useState<"confirm" | "quote" | null>(null);
  const { timezone } = useDateOperations();
  const { data: customFields = [] } = useCustomFieldsQuery(division.id);
  const form = useNewRequestForm(division, requestIdentifier, timezone, customFields);
  const { steps, canSwitchToStep } = useStepperState(form);

  const isFormDirty = form.formState.isDirty && !form.formState.isSubmitSuccessful;

  unstable_usePrompt({
    when: ({ currentLocation, nextLocation }) => {
      if (nextLocation?.state?.skipBlock) {
        return false;
      }
      return isFormDirty && currentLocation.pathname !== nextLocation.pathname;
    },
    message: t("requests.create.warningDialog.message"),
  });

  // Cleanup effect when component unmounts
  useEffect(() => {
    return () => {
      setConfirmDialogState(null);
      form.reset();
    };
  }, [form]);

  const onSubmit = () => {
    if (!form.formState.isValid) {
      return;
    }
    setConfirmDialogState("confirm");
  };

  return (
    <div className="size-full">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="size-full">
          <Stepper
            steps={steps}
            canSwitchToStep={canSwitchToStep}
            onSwitchStep={(currentStep) => {
              if (currentStep.stepCode === "upload") {
                form.trigger("sourceFiles");
              }
              if (currentStep.stepCode === "services") {
                form.trigger("service");
              }
              if (currentStep.stepCode === "details") {
                form.trigger("customFields");
              }
            }}
            customNextStep={({ currentStep }) => {
              const isFinalStep = currentStep.stepCode === "summary";
              return isFinalStep ? (
                <div className="inline-flex flex-wrap gap-2">
                  <Button
                    type="button"
                    data-test="button-submit-quote"
                    variant="outlinePrimary"
                    disabled={!form.formState.isValid}
                    onClick={() => setConfirmDialogState("quote")}
                  >
                    {t("requests.create.wizard.submitQuote")}
                  </Button>
                  <Button
                    type="submit"
                    variant="default"
                    disabled={!form.formState.isValid}
                    data-test="button-submit-request"
                  >
                    {t("requests.create.wizard.submit")}
                    <ArrowRightIcon className="ml-2 size-4" />
                  </Button>
                </div>
              ) : null;
            }}
          >
            <div>
              <FormField
                control={form.control}
                name="service"
                render={({ field }) => (
                  <ServicesWizardStep
                    form={form}
                    divisionId={division.id}
                    selectedId={field.value?.id ?? null}
                    onSelect={(value) => field.onChange(value)}
                  />
                )}
              />
            </div>
            <div>
              <UploadFilesStep form={form} division={division} requestIdentifier={requestIdentifier} />
            </div>
            <div>
              <DetailsWizardStep form={form} division={division} customFields={customFields} />
            </div>
            <div>
              <SummaryWizardStep form={form} division={division} customFields={customFields} />
            </div>
          </Stepper>
          {confirmDialogState && (
            <SubmitDialogLayout
              form={form}
              open={confirmDialogState}
              customFields={customFields}
              onClose={() => setConfirmDialogState(null)}
            />
          )}
        </form>
      </Form>
    </div>
  );
}

function SubmitDialogLayout({
  open,
  form,
  customFields,
  onClose,
}: {
  open: "confirm" | "quote" | null;
  form: UseFormReturn<NewRequestFormType>;
  customFields: CustomField[];
  onClose: () => void;
}) {
  const { t } = useTranslation("translation", { keyPrefix: "requests.create" });
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { isPending, mutate, serverErrors, createdRequestId, reset } = useNewRequestMutation();
  const { now } = useDateOperations();
  const navigate = useNavigate();

  const onSubmit = () => {
    if (!form.formState.isValid) {
      return;
    }
    const data = form.getValues();
    mutate({ form: data, customFields });
  };

  // set the success state when the request is created
  useEffect(() => {
    if (createdRequestId) {
      setIsSuccess(true);
    }
  }, [createdRequestId]);

  // reset the success state when the dialog is closed
  useEffect(() => {
    return () => {
      setIsSuccess(false);
      reset();
    };
  }, [reset]);

  if (open === null) {
    return null;
  }

  if (isSuccess && serverErrors === null) {
    return (
      <ConfirmSubmitDialog
        defaultOpen={true}
        illustrationName="newRequestSuccess"
        title={t("successDialog.title")}
        description={t("successDialog.message")}
        renderActions={() => (
          <div className="flex flex-col gap-2 sm:w-full sm:flex-row" data-test="dialog-success">
            <Button
              variant="outline"
              className="w-full"
              onClick={() => {
                navigate(`/client/requests/new/details/request/${createdRequestId}`, {
                  replace: true,
                  state: { skipBlock: true },
                });
              }}
              data-test="button-go-to-requests"
            >
              {t("successDialog.goToRequests")}
            </Button>
            <NewRequestButton buttonType="hero" />
          </div>
        )}
      />
    );
  }

  // quote confirm dialog and normal confirm dialog are the same, so we use the same component
  const isQuoteConfirmDialog = open === "quote";
  const props: ConfirmSubmitDialogProps = {
    defaultOpen: true,
    illustrationName: isQuoteConfirmDialog ? "newRequestQuote" : "newRequest",
    title: isQuoteConfirmDialog ? t("confirmQuoteDialog.title") : t("confirmDialog.title"),
    description: isQuoteConfirmDialog ? t("confirmQuoteDialog.message") : t("confirmDialog.message"),
    renderActions: () => (
      <div className="flex w-full flex-wrap gap-2 px-6 sm:flex-nowrap" data-test="dialog-confirm-submit">
        <Button
          type="button"
          variant="outline"
          className="w-full"
          disabled={isPending}
          data-test="button-cancel-request"
          onClick={() => {
            if (isQuoteConfirmDialog) {
              form.setValue("requestQuote", false);
              form.setValue("quoteDueDate", undefined, { shouldValidate: true });
            }
            onClose();
          }}
        >
          {isQuoteConfirmDialog ? t("confirmQuoteDialog.no") : t("confirmDialog.no")}
        </Button>
        <Button
          type="button"
          className="w-full"
          disabled={isPending || !form.formState.isValid}
          data-test="button-confirm"
          onClick={() => {
            if (isQuoteConfirmDialog) {
              form.setValue("requestQuote", true);
              if (form.formState.isValid) {
                onSubmit();
              }
            } else {
              onSubmit();
            }
          }}
        >
          {isPending ? <Loader2Icon className="mr-2 size-5 animate-spin" /> : null}
          {isQuoteConfirmDialog ? t("confirmQuoteDialog.yes") : t("confirmDialog.yes")}
        </Button>
      </div>
    ),
    renderContent: () =>
      isQuoteConfirmDialog ? (
        <div className="flex justify-center" data-test="dialog-confirm-quote">
          <FormField
            control={form.control}
            name="quoteDueDate"
            render={({ field: { onChange }, fieldState: { error } }) => (
              <div className={cn("flex flex-nowrap gap-2", error?.message ? "items-baseline" : "items-center")}>
                <div className="flex flex-col justify-center gap-1">
                  <FormItem>
                    <DatePicker
                      label={t("wizard.quoteDueDate")}
                      fromDate={now()}
                      toDate={form.getValues("deadline")}
                      value={form.getValues("quoteDueDate")}
                      timePicker={{ enable: true, defaultTime: { hour: 17 } }}
                      hasError={!!error?.message}
                      onChange={(date) => {
                        onChange(date);
                        form.trigger("quoteDueDate");
                      }}
                    />
                  </FormItem>
                  {error?.message ? (
                    <span className="text-xs text-destructive">{t(`errors.${error?.message}`)}</span>
                  ) : null}
                </div>
              </div>
            )}
          />
        </div>
      ) : null,
    renderErrors: () =>
      serverErrors && serverErrors.length > 0 ? (
        <Alert variant="destructive" className="mb-4">
          <AlertDescription className="flex flex-col items-start text-sm font-medium">
            {serverErrors.map((error, index) => (
              <div key={index} className="text-destructive">
                {error}
              </div>
            ))}
          </AlertDescription>
        </Alert>
      ) : null,
  };
  return <ConfirmSubmitDialog {...props} />;
}
