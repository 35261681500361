import { useDateOperations } from "@/hooks/useDate";
import { isRangeInside } from "@/utils/dates";
import { endOfDay, startOfDay } from "date-fns";
import { useCallback, useMemo } from "react";
import { type DateRange, TZDate } from "react-day-picker";

interface DateRangePreset {
  name: string;
  disabled: boolean;
  range: DateRange;
  presetType: "future" | "past";
}

const DEFAULT_PRESETS: Omit<DateRangePreset, "range">[] = [
  { name: "today", presetType: "past", disabled: false },
  { name: "last3", presetType: "past", disabled: false },
  { name: "lastWeek", presetType: "past", disabled: false },
  { name: "lastMonth", presetType: "past", disabled: false },
  { name: "lastQuarter", presetType: "past", disabled: false },
  { name: "lastYear", presetType: "past", disabled: false },
  // future
  { name: "pastDue", presetType: "future", disabled: false },
  { name: "today", presetType: "future", disabled: false },
  { name: "next3", presetType: "future", disabled: false },
  { name: "nextWeek", presetType: "future", disabled: false },
  { name: "nextMonth", presetType: "future", disabled: false },
  { name: "nextThreeMonths", presetType: "future", disabled: false },
  { name: "nextSixMonths", presetType: "future", disabled: false },
];

const assignRange = (preset: Omit<DateRangePreset, "range">, range: DateRange, minMaxDates: DateRange | undefined) => {
  // If no min/max dates, enable all presets
  if (!minMaxDates?.from && !minMaxDates?.to) {
    return { ...preset, range, disabled: false };
  }

  // Future presets are always enabled
  if (!minMaxDates?.to && preset.presetType === "future") {
    return { ...preset, range, disabled: false };
  }

  // For past presets, check if the range is completely within minMaxDates bounds
  if (!range.from || !range.to) {
    return { ...preset, range, disabled: true };
  }

  const isWithinBounds = isRangeInside(
    { from: range.from, to: range.to },
    { from: minMaxDates.from, to: minMaxDates.to }
  );

  return { ...preset, range, disabled: !isWithinBounds };
};

export const useDateRangePreset = (minMaxDates: DateRange | undefined, presetsType: "future" | "past" | "all") => {
  const { timezone } = useDateOperations();
  const presets = useMemo(() => {
    return DEFAULT_PRESETS.filter((preset) => {
      if (presetsType === "all") {
        return true;
      }
      return preset.presetType === presetsType;
    }).map((preset) => {
      const range = getPresetRange(preset.name, timezone, minMaxDates);
      return assignRange(preset, range, minMaxDates);
    });
  }, [minMaxDates, presetsType, timezone]);

  const getPresetName = useCallback(
    (range: DateRange): string | undefined => {
      if (!range.from || !range.to) return undefined;

      const availablePresets = presets.filter((preset) => !preset.disabled);
      const normalizedRangeFrom = startOfDay(range.from);
      const normalizedRangeTo = endOfDay(range.to);
      const rangeFromTime = normalizedRangeFrom.getTime();
      const rangeToTime = normalizedRangeTo.getTime();

      for (const preset of availablePresets) {
        const presetRange = getPresetRange(preset.name, timezone, minMaxDates);
        if (!presetRange.from || !presetRange.to) continue;

        const presetFromTime = startOfDay(presetRange.from).getTime();
        const presetToTime = endOfDay(presetRange.to).getTime();

        if (rangeFromTime === presetFromTime && rangeToTime === presetToTime) {
          return preset.name;
        }
      }
      return undefined;
    },
    [minMaxDates, presets, timezone]
  );

  const getPreset = useCallback(
    (presetName: string): DateRange => {
      const preset = presets.find((preset) => preset.name === presetName && !preset.disabled);
      return preset?.range ?? { from: undefined, to: undefined };
    },
    [presets]
  );

  return { presets, getPreset, getPresetName };
};

const getPresetRange = (presetName: string, timezone: string, minMaxDates?: DateRange): DateRange => {
  const today = new TZDate(new Date(), timezone);
  let startDate = new TZDate(today, timezone);
  let endDate = new TZDate(today, timezone);

  switch (presetName) {
    case "last3":
      startDate.setDate(today.getDate() - 3);
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);
      break;

    case "lastWeek":
      startDate.setDate(today.getDate() - 7 - today.getDay());
      endDate.setDate(today.getDate() - today.getDay() - 1);
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);
      break;

    case "thisMonth":
      startDate.setDate(1);
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);
      break;

    case "lastMonth":
      startDate.setMonth(today.getMonth() - 1);
      startDate.setDate(1);
      endDate.setDate(0);
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);
      break;

    case "thisQuarter":
      startDate.setMonth(Math.floor(today.getMonth() / 3) * 3);
      startDate.setDate(1);
      endDate.setMonth(startDate.getMonth() + 3);
      endDate.setDate(0);
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);
      break;

    case "lastQuarter":
      startDate.setMonth(Math.floor(today.getMonth() / 3) * 3 - 3);
      startDate.setDate(1);
      endDate.setMonth(startDate.getMonth() + 3);
      endDate.setDate(0);
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);
      break;

    case "lastYear":
      startDate.setFullYear(today.getFullYear() - 1);
      startDate.setMonth(0);
      startDate.setDate(1);
      endDate.setFullYear(today.getFullYear() - 1);
      endDate.setMonth(11);
      endDate.setDate(31);
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);
      break;

    case "pastDue":
      // use mindate if available, otherwise 1 year ago
      if (minMaxDates?.from) {
        startDate.setTime(minMaxDates.from.getTime());
      } else {
        startDate.setFullYear(today.getFullYear() - 1);
      }
      startDate = startOfDay(startDate);
      endDate = today;
      break;

    case "today":
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);
      break;

    case "next3":
      endDate.setDate(today.getDate() + 3);
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);
      break;

    case "nextWeek":
      startDate.setDate(today.getDate() + (7 - today.getDay()));
      endDate = new TZDate(startDate, timezone);
      endDate.setDate(startDate.getDate() + 6);
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);
      break;

    case "nextMonth":
      startDate.setMonth(today.getMonth() + 1);
      startDate.setDate(1);
      endDate = new TZDate(startDate, timezone);
      endDate.setMonth(startDate.getMonth() + 1);
      endDate.setDate(0);
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);
      break;

    case "nextThreeMonths":
      startDate.setMonth(today.getMonth() + 1);
      startDate.setDate(1);
      endDate = new TZDate(startDate, timezone);
      endDate.setMonth(startDate.getMonth() + 3);
      endDate.setDate(0);
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);
      break;

    case "nextSixMonths":
      startDate.setMonth(today.getMonth() + 1);
      startDate.setDate(1);
      endDate = new TZDate(startDate, timezone);
      endDate.setMonth(startDate.getMonth() + 6);
      endDate.setDate(0);
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);
      break;
  }

  return { from: startDate, to: endDate };
};
