import { ComboboxSearch } from "@/components/combobox/ComboboxSearch";
import { IdName } from "@/model/common";
import { useSearchContactsQuery } from "@/query/request.query";
import { Share2Icon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ContactSelectorProps {
  divisionId: string;
  selectedContacts: IdName[];
  filterContactsIds?: string[];
  onChange: (contacts: IdName[]) => void;
  inError?: boolean;
}

export const ContactSelector = ({
  divisionId,
  selectedContacts,
  onChange,
  inError,
  filterContactsIds = [],
}: ContactSelectorProps) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const { data: contacts = [], isLoading } = useSearchContactsQuery(divisionId, searchTerm);

  const filteredContacts = contacts.filter((contact) => !filterContactsIds.includes(contact.id));

  return (
    <ComboboxSearch<IdName>
      testId="contact-selector"
      tooltip={t("requests.details.accessors.add")}
      selected={selectedContacts}
      options={filteredContacts}
      onChange={onChange}
      searchTerm={searchTerm}
      onSearch={setSearchTerm}
      searchResults={filteredContacts}
      isLoading={isLoading}
      closeOnSelect={filteredContacts.length === 1}
      inError={inError}
      renderOption={(contact: IdName) => (
        <div className="flex flex-col gap-0.5">
          <div className="font-medium">{contact.name}</div>
          <div className="flex items-center gap-2 text-sm text-muted-foreground">{contact.description}</div>
        </div>
      )}
      minSearchLength={2}
      icon={Share2Icon}
    />
  );
};
