import { isStringEmptyOrNull } from "@/utils/text";
import { useMemo } from "react";
import { useSearchParams } from "react-router";

export const useSearchParamsString = (): string | null => {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    const stringValue = searchParams.toString();
    return isStringEmptyOrNull(stringValue) ? null : stringValue;
  }, [searchParams]);
};
