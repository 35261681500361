import { cn } from "@/utils/ui";

type Props = React.HTMLAttributes<HTMLDivElement>;
export function PageShell({ children, className, ...props }: Props) {
  return (
    <div
      data-test="page-container"
      className={cn("h-full overflow-auto rounded-2xl bg-background p-6", className)}
      {...props}
    >
      {children}
    </div>
  );
}
