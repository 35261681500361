import { confirmDisclaimer } from "@/api/machinetranslation.api";
import { Alert } from "@/components/ui/alert";
import { useAuthenticatedUser } from "@/query/user.query";
import { redirectToPortal } from "@/utils/url";
import { Button } from "components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "components/ui/dialog";
import { addMonths } from "date-fns";
import { t } from "i18next";
import { useState } from "react";

const MTConfirmationAlert = () => {
  const { user } = useAuthenticatedUser();
  const [open, setOpen] = useState<boolean>(true);

  const handleConfirm = async (value: boolean) => {
    const result = await confirmDisclaimer(value);
    if (!result) {
      redirectToPortal();
    } else {
      setOpen(false);
    }
  };

  if (user?.mtConfirmationDate && addMonths(new Date(), -6) < new Date(user.mtConfirmationDate)) return <></>;

  return (
    <Dialog open={open}>
      <DialogContent showClose={false}>
        <DialogHeader>
          <DialogTitle>{t("mt.disclaimer.title")}</DialogTitle>
        </DialogHeader>
        <div className="space-y-2 text-sm">
          <Alert variant={"info"}>{t("mt.disclaimer.paragraphOne")}</Alert>
          <p>{t("mt.disclaimer.paragraphTwo")}</p>
          <p>{t("mt.disclaimer.paragraphThree")}</p>
        </div>
        <DialogFooter>
          <Button className="w-full" variant={"destructive"} onClick={() => handleConfirm(false)}>
            {t("mt.disclaimer.doNotAgree")}
          </Button>
          <Button autoFocus className="w-full" variant={"accept"} onClick={() => handleConfirm(true)}>
            {t("mt.disclaimer.agree")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default MTConfirmationAlert;
