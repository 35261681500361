import { Illustration500, Illustrations404 } from "components/svgs/Illustrations";
import { Button } from "components/ui/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

interface Props {
  title: string;
  description: string;
  errorCode: 401 | 404 | 500 | "generic";
  options?: { hideFooter?: boolean; hideGoBackAction?: boolean };
}

const ErrorLayoutPage = ({ title, errorCode, description, options }: Props) => {
  const { t } = useTranslation("error");
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate("/", { replace: true });
  };
  return (
    <div data-test={`error-page-${errorCode}`} className="flex min-h-full flex-col pt-16">
      <main className="mx-auto flex w-full grow flex-col px-4 sm:justify-center sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center space-x-4 py-16 lg:flex-row">
          <div className="text-center">
            {errorCode !== "generic" && (
              <p className="text-base font-semibold uppercase tracking-wide text-primary">
                {t("page.error-code", { errorCode })}
              </p>
            )}
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl">{title}</h1>
            <p className="mt-2 text-base text-muted-foreground">{description}</p>
            {options?.hideGoBackAction ? null : (
              <div className="mt-6">
                <Button onClick={handleOnClick} className="inline-flex items-center">
                  {t("page.goBackHome")}
                </Button>
              </div>
            )}
          </div>
          {errorCode === 404 && (
            <div className="order-first mb-16 hidden w-80 sm:block lg:order-last lg:w-full lg:max-w-md">
              <Illustrations404 />
            </div>
          )}

          {(errorCode === 500 || errorCode === "generic") && (
            <div className="order-first mb-16 hidden w-96 sm:block lg:order-last lg:w-full lg:max-w-xl">
              <Illustration500 />
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default ErrorLayoutPage;
