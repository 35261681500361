import { format } from "date-fns";
import { User } from "model/user.typing";

interface Identity {
  cultureCode: string;
  origin: string;
  clients?: string;
  createdOn: string;
  [key: string]: string | boolean | undefined;
}

declare global {
  interface Window {
    Appcues: {
      identify: (
        id: string,
        values: {
          cultureCode: string;
          origin: string;
          clients?: string;
          createdOn: string;
          [key: string]: string | boolean | undefined;
        }
      ) => void;
    };
    AppcuesSettings: { enableURLDetection: boolean };
  }
}

export const loadAppcuesScripts = () => {
  if (!import.meta.env.AP_APPCUES_ENABLED) {
    return;
  }

  const script = document.createElement("script");
  script.src = "https://fast.appcues.com/113582.js";
  document.body.appendChild(script);

  window.AppcuesSettings = {
    enableURLDetection: true,
  };
};

export const initAppcue = (user: User) => {
  if (!import.meta.env.AP_APPCUES_ENABLED) {
    return;
  }

  // Ensure this user has clients w/ AppCues enabled
  if (window.Appcues && user.appCuesClients && user.appCuesClients.length > 0) {
    // Acolad Portal identification
    const identity: Identity = {
      cultureCode: user.cultureCode,
      origin: "portal",
      clients: user.appCuesClients?.map((client) => client.clientKey).join(", "),
      mtConfigs: user.appCuesClients
        ?.map((client) => `${client.clientKey}_${client.mtFilesEnabled}_${client.mtTextEnabled}_${client.mtProvider}_${client.terminologyEnabled}`)
        .join(", "),
      createdOn: user.createdOnDate ? `${format(user.createdOnDate, "yyyy-MM-dd'T'HH:mm:ss")}` : "",
    };
    user.appCuesModules?.forEach((module) => (identity[module.name] = module.hasAccess));
    window.Appcues.identify(user.id, identity);
  } else if (window.Appcues && user.isVNext) {
    // vNext Identification
    const vIdentity: Identity = {
      cultureCode: user.cultureCode,
      origin: "vNext",
      createdOn: user.createdOnDate ? `${format(user.createdOnDate, "yyyy-MM-dd'T'HH:mm:ss")}` : "",
    };
    window.Appcues.identify(user.id, vIdentity);
  }
};
