import { cn } from "@/utils/ui";
import { VariantProps, cva } from "class-variance-authority";
import { Rectangle, RectangleProps } from "recharts";

const tooltipCursorVariant = cva("", {
  variants: {
    variant: {
      indigo: "fill-indigo-50 stroke-indigo-200 dark:fill-indigo-400/50 dark:stroke-indigo-400",
      blue: "fill-blue-50 stroke-blue-200 dark:fill-blue-400/50 dark:stroke-blue-400",
      gray: "fill-gray-100 stroke-gray-200 opacity-50 dark:fill-gray-400/50 dark:stroke-gray-400",
      cyan: "fill-cyan-50 stroke-cyan-200 opacity-50 dark:fill-cyan-400/50 dark:stroke-cyan-400",
    },
  },
});

type Props = VariantProps<typeof tooltipCursorVariant> & RectangleProps;

export function ChartTooltipCursor({ className, variant, x, y, width, height }: Props) {
  return (
    <Rectangle className={cn(tooltipCursorVariant({ variant, className }))} x={x} y={y} width={width} height={height} />
  );
}
