import { DEFAULT_SORT_FIELD_CONFIG, SortControl } from "@/components/sorting/SortControl";
import { useSorting } from "@/components/sorting/useSorting";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandList } from "@/components/ui/command";
import { Tooltip, TooltipArrow, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import type { IdName } from "@/model/common";
import type { ServiceSortByType, ServiceType } from "@/model/request.typing";
import { useServiceBundleQuery } from "@/query/request.query";
import { sanitizeString } from "@/utils/text";
import { clsx } from "clsx";
import { Command as CommandPrimitive } from "cmdk";
import { ArrowDownIcon, ArrowUpIcon, InfoIcon } from "lucide-react";
import { useMemo, useState } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ServiceIcon } from "./ServiceIcon";
import type { NewRequestFormType } from "./useNewRequestForm";

interface Props {
  divisionId: string;
  selectedId: string | null;
  onSelect: (selected: IdName | null) => void;
  form: UseFormReturn<NewRequestFormType>;
}

export function ServicesWizardStep({ divisionId, selectedId, onSelect, form }: Props) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const errors = form.formState.errors;

  const { selected, unselected, sortState, updateSort } = useServiceBundleItems(divisionId, selectedId);

  const handleOnSelect = (service: ServiceType | null) => {
    if (service === null) {
      onSelect(null);
    } else {
      setSearchTerm("");
      onSelect(service);
    }
  };

  return (
    <div className="flex h-full flex-col gap-2">
      <div className="flex flex-col items-center p-6">
        <h2 className="text-2xl font-semibold leading-7">{t("requests.create.services.title")}</h2>
        <p className="leading-tight">{t("requests.create.services.description")}</p>
      </div>

      <div className="mx-auto w-full max-w-7xl">
        {errors.service?.message ? (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription className="text-sm font-medium">
              {t(`requests.create.errors.${errors.service?.message}`)}
            </AlertDescription>
          </Alert>
        ) : null}

        <Command>
          <div className="flex w-full flex-row flex-nowrap items-center gap-2">
            <CommandInput
              value={searchTerm}
              data-test="service-search"
              onValueChange={(term) => {
                setSearchTerm(term);
                if (term.trim().length > 0) setIsExpanded(false);
              }}
              className="h-10 flex-1 rounded-md border"
              placeholder={t("common.search.placeholder")}
            />
            {sortState ? (
              <SortControl<ServiceSortByType>
                testId="service"
                mode="button"
                currentSort={sortState}
                fields={[DEFAULT_SORT_FIELD_CONFIG.frequency, DEFAULT_SORT_FIELD_CONFIG.name]}
                onSortChange={(sortBy) => updateSort(sortBy)}
              />
            ) : null}
          </div>
          <TooltipProvider>
            <CommandList className="max-h-full">
              <CommandEmpty>{t("translation:facets.not-found")}</CommandEmpty>
              <CommandGroup>
                <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                  {selected ? (
                    <ServiceItem isSelected={true} service={selected} onSelect={() => handleOnSelect(null)} />
                  ) : null}
                  {(searchTerm.length === 0 && unselected.length > 6
                    ? unselected.slice(0, selected ? 5 : 6)
                    : unselected
                  ).map((service) => (
                    <ServiceItem
                      key={service.id}
                      isSelected={false}
                      service={service}
                      onSelect={() => handleOnSelect(service)}
                    />
                  ))}
                </div>
              </CommandGroup>
              {searchTerm.length === 0 && unselected.length > 6 ? (
                <>
                  {!isExpanded ? (
                    <div className="my-4 flex justify-center" data-test="show-more">
                      <Button
                        type="button"
                        variant={"secondary"}
                        className="select-none"
                        onClick={() => setIsExpanded(true)}
                      >
                        {t("requests.create.show-more")} <ArrowDownIcon className="ml-2 size-5" />
                      </Button>
                    </div>
                  ) : null}

                  <div
                    data-state={isExpanded ? "open" : "closed"}
                    className={clsx(
                      "mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3",
                      "data-[state=closed]:hidden data-[state=closed]:max-h-0", //hide the element when closed
                      "transition-all data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95"
                    )}
                  >
                    {unselected.slice(6, unselected.length).map((service) => (
                      <ServiceItem
                        key={service.id}
                        isSelected={false}
                        service={service}
                        onSelect={() => handleOnSelect(service)}
                      />
                    ))}
                  </div>
                </>
              ) : null}
            </CommandList>
          </TooltipProvider>
          {isExpanded ? (
            <div className="my-4 flex justify-center">
              <Button
                type="button"
                variant={"secondary"}
                className="select-none"
                onClick={() => {
                  setIsExpanded(false);
                  window.scrollTo({ top: 0, behavior: "smooth" }); // scroll to top
                }}
              >
                {t("requests.create.show-less")} <ArrowUpIcon className="ml-2 size-5" />
              </Button>
            </div>
          ) : null}
        </Command>
      </div>
    </div>
  );
}

function ServiceItem({
  service,
  isSelected,
  onSelect,
}: {
  service: ServiceType;
  isSelected: boolean;
  onSelect: (service: ServiceType) => void;
}) {
  const { t } = useTranslation();
  return (
    <CommandPrimitive.Item
      key={service.id}
      className={clsx(
        "group cursor-pointer rounded-2xl border py-3 shadow-sm",
        "hover:border-primary dark:hover:border-primary",
        isSelected
          ? "border-blue-600 bg-blue-50 dark:bg-blue-950"
          : "border-neutral-150 bg-neutral-50 dark:border-border dark:bg-background"
      )}
      asChild
      onSelect={() => onSelect(service)}
    >
      <div className="inline-flex flex-col items-start justify-center gap-2">
        {/* checkbox */}
        <div className="flex flex-row-reverse justify-between gap-2 self-stretch px-4">
          <div
            className={clsx(
              "flex size-5 items-center justify-center rounded-full border border-neutral-300",
              "group-hover:border-primary",
              isSelected ? "border-primary bg-blue-800" : "bg-background dark:border-border"
            )}
            data-test={`checkbox-${sanitizeString(service.name, true)}`}
          >
            {isSelected && <div className="relative size-2 rounded-full bg-white" />}
          </div>

          {service.highlighted && <Badge variant="blue">{t("common.new")}</Badge>}
        </div>

        {/* title */}
        <div className="flex flex-1 flex-row items-center gap-3 px-6 py-3">
          <ServiceIcon
            iconName={service.iconName}
            className="size-6 shrink-0 text-neutral-400 dark:text-muted-foreground"
          />
          <span className="font-semibold leading-6">{service.name}</span>
        </div>

        {/* footer */}
        <div className="flex w-full flex-row-reverse px-4">
          <Tooltip delayDuration={0}>
            <TooltipTrigger type="button" className="" onClick={(event) => event.stopPropagation()}>
              <InfoIcon className="size-5 text-neutral-400 dark:text-muted-foreground" />
            </TooltipTrigger>
            <TooltipContent side="top" className="max-w-sm">
              {service.description}
              <TooltipArrow />
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
    </CommandPrimitive.Item>
  );
}

const useServiceBundleItems = (divisionId: string, selectedId: string | null) => {
  const services = useServiceBundleQuery(divisionId);

  const { selected, unselected } = useMemo(() => {
    if (!selectedId) return { selected: null, unselected: services };
    const unselected = services.filter((service) => service.id !== selectedId);
    const selected = services.find((service) => service.id === selectedId);
    return { selected, unselected };
  }, [selectedId, services]);

  const { sortItems, sortState, updateSort } = useSorting<ServiceType, ServiceSortByType>({
    id: "service",
    defaultSort: { field: "frequency", direction: "desc" },
    priorityField: "highlighted",
  });

  return { selected, unselected: sortItems(unselected), sortState, updateSort };
};
