import { ColumnDef, Row } from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { Download, Loader2Icon } from "lucide-react";
import { useTranslation } from "react-i18next";

interface DatatableDownloadActionProps<TData> {
  downloadAction: {
    onDownload: (rows: Record<string, unknown>[]) => void;
    isDownloading: boolean;
    processRecord?: (
      record: Record<string, unknown>,
      row: TData,
      columnDef: ColumnDef<TData, unknown>
    ) => Record<string, unknown>;
  };
  rows: Row<TData>[];
}

export function DatatableDownloadAction<TData>({ downloadAction, rows }: DatatableDownloadActionProps<TData>) {
  const { t } = useTranslation("translation");

  function getDownloadData() {
    return rows.map((row) =>
      row.getVisibleCells().reduce(
        (acc, cell) => {
          acc[typeof cell.column.columnDef.header === "string" ? cell.column.columnDef.header : cell.column.id] = cell
            .column.columnDef.meta?.renderForExport
            ? cell.column.columnDef.meta.renderForExport(cell.getContext())
            : cell.getValue();

          if (downloadAction.processRecord)
            acc = downloadAction.processRecord(acc, row.original, cell.column.columnDef);

          return acc;
        },
        {} as Record<string, unknown>
      )
    );
  }

  return (
    <Button
      disabled={downloadAction.isDownloading}
      variant="outline"
      onClick={() => downloadAction.onDownload(getDownloadData())}
      className="max-sm:w-full"
    >
      {downloadAction.isDownloading ? (
        <Loader2Icon className="mr-2 size-4 animate-spin" />
      ) : (
        <Download className="mr-2 size-4" />
      )}
      {t("data-table.download")}
    </Button>
  );
}
