import { GlossaryTopBar } from "./components/GlossaryTopBar";
import useGlossary from "@/pages/machinetranslation/components/text/glossary/useGlossary";
import { ChevronLeft, Upload } from "lucide-react";
import { Button } from "@/components/ui/button";
import { TermList } from "./components/TermList";
import { t } from "i18next";
import { useDeleteTerminologyMutation } from "@/query/mt/machinetranslation.query";
import Loader from "@/components/Loader";
import { TooltipProvider } from "@/components/ui/tooltip";
import { ReactNode, useState } from "react";
import PortalTooltip from "@/components/PortalTooltip";
import GlossaryUploadDialog from "./components/GlossaryUploadDialog";
import { GlossaryResults } from "./components/GlossaryResults";
import { TerminologyUploadResult } from "@/model/mt.typing";

export const GlossaryEdit = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [glossaryResult, setGlossaryResult] = useState<TerminologyUploadResult | null>(null);
  const { setView, currentTerminology } = useGlossary();

  const { isFetchingDelete } = useDeleteTerminologyMutation();

  if (isFetchingDelete) return <Loader isShown={true} displayText={false} variant="md" className="m-0" />;

  if (!currentTerminology) return null;

  const actionButtons: ReactNode = (
    <TooltipProvider delayDuration={0}>
      <div className="flex gap-1">
        <PortalTooltip text={t("mt.glossary.importTermsFromFile")}>
          <Button
            type="button"
            variant="outline"
            className="h-8 align-middle text-xs"
            data-test={`mt-glossary-upload-button`}
            size="sm"
            onClick={() => setIsDialogOpen(true)}
          >
            <Upload className="size-4" />
          </Button>
        </PortalTooltip>
        <PortalTooltip text={t("common.back")}>
          <Button
            type="button"
            variant="outline"
            data-test={`mt-glossary-back-button`}
            className="h-8 align-middle text-xs"
            size="sm"
            onClick={() => setView("list")}
          >
            <ChevronLeft className="size-4" />
          </Button>
        </PortalTooltip>
      </div>
    </TooltipProvider>
  );

  return (
    <>
      <GlossaryTopBar
        title={`${currentTerminology.name}${currentTerminology.terminologyItems.length > 0 ? " " + t("mt.glossary.entries", { count: currentTerminology.terminologyItems.length }) : ""}`}
        actionButtons={actionButtons}
      />
      <TermList items={currentTerminology.terminologyItems} />
      <GlossaryUploadDialog
        isVisible={isDialogOpen}
        setIsVisible={setIsDialogOpen}
        setGlossaryResult={setGlossaryResult}
      />
      <GlossaryResults glossaryResult={glossaryResult} setGlossaryResult={setGlossaryResult} />
    </>
  );
};
