import type { ProjectCostType, TmLeverageType } from "model/report.typing";
import api, { requestDownloadFile } from "./api";

export async function fetchProjectCost(searchParam: string | null, signal: AbortSignal, projectId?: string) {
  const url = projectId
    ? `/api/client/reports/cost/${projectId}`
    : searchParam && searchParam.length > 0
      ? `/api/client/reports/cost?${searchParam}`
      : "/api/client/reports/cost";

  const response = await api.get<ProjectCostType[]>(url, { signal });
  return response.data;
}

export async function fetchTMLeverage(searchParam: string | null, signal: AbortSignal, projectId?: string) {
  const url = projectId
    ? `/api/client/reports/tmLeverage/${projectId}`
    : searchParam && searchParam.length > 0
      ? `/api/client/reports/tmLeverage?${searchParam}`
      : "/api/client/reports/tmLeverage";

  const response = await api.get<TmLeverageType[]>(url, { signal });
  return response.data;
}

export const requestProjectCostsExcelDownload = async (projectIds: string[]) => {
  await requestDownloadFile("/api/client/reports/downloadCost", projectIds);
};

export const requestTmLeverageExcelDownload = async (projectIds: string[]) => {
  await requestDownloadFile("/api/client/reports/downloadTmLeverage", projectIds);
};
