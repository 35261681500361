import { EmptyDataPlaceholder } from "@/components/EmptyPlaceholder";
import { MENU_ICONS } from "@/components/Icons";
import { Combobox } from "@/components/combobox/Combobox";
import { ComboboxSingleSelection } from "@/components/combobox/ComboboxSingleSelection";
import { DataTable } from "@/components/datatable/DataTable";
import { DateRangePicker } from "@/components/datePicker/DateRangePicker";
import { MultipleLanguageFilter } from "@/components/filters/LanguageFilters";
import { FloatingLabelInput } from "@/components/input/FloatingInput";
import { Card, CardContent } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useDateFormatter } from "@/hooks/useDate";
import { useSearchParamsString } from "@/hooks/useSearchParamsString";
import { CostReportChartNames, type ProjectCostType } from "@/model/report.typing";
import { useProjectCosts, useProjectCostsExcelDownload } from "@/query/report.query";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import type { ColumnDef } from "@tanstack/react-table";
import { BuildingIcon, DollarSignIcon, LandmarkIcon, UserIcon } from "lucide-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { DownloadExcelReportButton } from "../common/DownloadExcelReportButton";
import { ReportLayout } from "../common/ReportLayout";
import { useReportPathName } from "../common/useReportPathName";
import { useCostReportActions, useCostReportSortBy, useCostReportTabView } from "../reportStore";
import { useProjectCostsFilter, useProjectCostsFilteredData } from "./useProjectCostFilter";

const REPORT_PATHS = [
  CostReportChartNames.perLanguage,
  CostReportChartNames.perMonth,
  CostReportChartNames.perQuarter,
  CostReportChartNames.perRequestor,
];

export function CostReportLayout() {
  const searchParams = useSearchParamsString();
  const { projectCosts, isLoading } = useProjectCosts(searchParams);
  const { showClient, filteredProjectCosts, filteredProjectCostsPerLanguage } =
    useProjectCostsFilteredData(projectCosts);
  const { isDashboard, currentReportPathName } = useReportPathName("cost");
  const tab = useCostReportTabView();
  const { updateTabView } = useCostReportActions();
  const { isDownloading, downloadExcelFile } = useProjectCostsExcelDownload();

  return (
    <ReportLayout
      icon={MENU_ICONS.cost}
      i18nKeyPrefix={"cost"}
      pathNames={{
        isDashboard,
        currentReportPathName,
        reportsPathName: REPORT_PATHS,
      }}
      renderActions={() => (
        <DownloadExcelReportButton
          isLoading={isDownloading}
          disabled={isDownloading || filteredProjectCosts.length === 0}
          onDownload={() => downloadExcelFile(filteredProjectCosts)}
        />
      )}
    >
      <ReportLayout.Filter isLoading={isLoading}>
        <ProjectCostFilter showClient={showClient} projectCosts={projectCosts} hasSearchParams={!!searchParams} />
      </ReportLayout.Filter>
      <ReportLayout.ViewSwitcher
        tab={tab}
        isLoading={isLoading}
        onTabChange={(tab) => updateTabView(tab)}
        renderTable={() => (
          <ProjectCostTable isLoading={isLoading} showClient={showClient} projectCosts={filteredProjectCosts} />
        )}
        rendreCharts={() => (
          <Outlet
            context={{
              isLoading,
              pricePerPairLanguages: filteredProjectCostsPerLanguage,
              projectCosts,
            }}
          />
        )}
      />
    </ReportLayout>
  );
}

function ProjectCostFilter({
  showClient,
  projectCosts,
  hasSearchParams,
}: {
  showClient: boolean;
  projectCosts: ProjectCostType[];
  hasSearchParams: boolean;
}) {
  const { t } = useTranslation("translation", { keyPrefix: "reports.filters" });
  const { updateFilters } = useCostReportActions();
  const { options, hasActiveFilters, filters, onReset } = useProjectCostsFilter(projectCosts, hasSearchParams);

  if (!hasActiveFilters && projectCosts.length === 0) return null;

  return (
    <>
      <div className="flex flex-wrap items-center gap-2">
        <FloatingLabelInput
          className="max-w-72"
          data-test="costSearchTerm"
          type="search"
          label={t("common.search.label", { keyPrefix: "" })}
          value={filters.searchText}
          onChange={(e) => {
            updateFilters({ searchText: e.target.value });
          }}
        />
        <ComboboxSingleSelection
          testId="currency"
          icon={DollarSignIcon}
          title={t("currency")}
          options={options.currencies}
          selectedId={filters.currencyId}
          onChange={(value) => {
            if (value?.id) {
              updateFilters({ currencyId: value.id.toString() });
            }
          }}
          hideCount={true}
        />
        {showClient ? (
          <Combobox
            testId="client"
            icon={BuildingIcon}
            title={t("clients")}
            options={options.clientNames}
            selectedIds={filters.clientNameIds ?? []}
            onChange={(values) =>
              updateFilters({
                clientNameIds: values.map((v) => v.id.toString()),
              })
            }
            hideCount={true}
          />
        ) : null}
        <Combobox
          testId="owner"
          icon={UserIcon}
          title={t("owners")}
          options={options.owners}
          onChange={(values) => updateFilters({ ownersIds: values.map((v) => v.id.toString()) })}
          selectedIds={filters.ownersIds ?? []}
          hideCount={true}
        />
        <Combobox
          testId="division"
          icon={LandmarkIcon}
          title={t("divisions")}
          options={options.divisions}
          onChange={(values) =>
            updateFilters({
              divisionNames: values.map((v) => v.name.toString()),
            })
          }
          selectedIds={filters.divisionNames ?? []}
          hideCount={true}
        />
        <DateRangePicker
          align="start"
          testId="start-date"
          title={t("startDate")}
          range={filters.startDate}
          minMaxDate={options.startDateMinMax}
          onUpdate={(dateRange) => updateFilters({ startDate: dateRange })}
        />
        <DateRangePicker
          testId="delivery-date"
          title={t("deliveryDate")}
          range={filters.deliveryDate}
          minMaxDate={options.deliveryDateMinMax}
          onUpdate={(dateRange) => updateFilters({ deliveryDate: dateRange })}
        />
        {!filters.deliveryDate?.from ? (
          <Switch
            data-test="include-open-projects"
            checked={filters.includeOpenProjects}
            onCheckedChange={(value) => updateFilters({ includeOpenProjects: value })}
          >
            <Label
              data-test="include-open-projects-label"
              className="ml-1 cursor-pointer"
              onClick={() =>
                updateFilters({
                  includeOpenProjects: !filters.includeOpenProjects,
                })
              }
            >
              {t("includeOpenProjects")}
            </Label>
          </Switch>
        ) : null}
      </div>
      <div className="flex flex-wrap items-center gap-2">
        <MultipleLanguageFilter
          testId="source-language"
          title={t("sourceLanguage")}
          options={options.sourceLanguages}
          selectedLanguageCode={filters.sourceLanguageIds}
          onChange={(values) => updateFilters({ sourceLanguageIds: values })}
          hideCount={true}
          enableSorting={true}
        />
        <MultipleLanguageFilter
          testId="target-language"
          title={t("targetLanguage")}
          options={options.targetLanguages}
          selectedLanguageCode={filters.targetLanguageIds}
          onChange={(values) => updateFilters({ targetLanguageIds: values })}
          hideCount={true}
          enableSorting={true}
        />
        <ReportLayout.FilterReset isShown={hasActiveFilters} onReset={onReset} />
      </div>
    </>
  );
}

function ProjectCostTable({
  isLoading,
  showClient,
  projectCosts,
}: {
  isLoading: boolean;
  showClient: boolean;
  projectCosts: ProjectCostType[];
}) {
  const { formatDate } = useDateFormatter();
  const formatNumber = useAuthUserStore((store) => store.formatNumber);
  const { t } = useTranslation("translation", {
    keyPrefix: "reports.cost.table",
  });
  const sortBy = useCostReportSortBy();
  const { updateSortBy } = useCostReportActions();

  const columns = useMemo<ColumnDef<ProjectCostType>[]>(() => {
    return [
      {
        id: "projectCode",
        header: t("projectCode"),
        enableSorting: true,
        enableHiding: true,
        accessorFn: (projectCost) => projectCost.projectId,
        cell({ row }) {
          const projectCode = row.original.projectId;
          if (!projectCode) return <EmptyDataPlaceholder />;
          return <div className="whitespace-nowrap">{projectCode}</div>;
        },
      },
      {
        id: "projectId",
        header: t("projectId"),
        enableSorting: true,
        enableHiding: true,
        accessorFn: (projectCost) => projectCost.portalProjectId,
        cell({ row }) {
          const projectId = row.original.portalProjectId;
          if (!projectId) return <EmptyDataPlaceholder />;
          return <div>{projectId}</div>;
        },
      },
      {
        id: "projectName",
        header: t("projectName"),
        enableSorting: true,
        enableHiding: true,
        accessorFn: (projectCost) => projectCost.projectName,
        cell(props) {
          if (!props.row.original.projectName) return <EmptyDataPlaceholder />;
          return (
            <div className="max-w-xs" title={props.row.original.projectName}>
              <p className="truncate">{props.row.original.projectName}</p>
            </div>
          );
        },
      },
      ...(showClient
        ? [
            {
              id: "clientName",
              header: t("client"),
              enableSorting: true,
              enableHiding: true,
              enableGlobalFilter: true,
              enableColumnFilter: false,
              accessorFn: (projectCost: ProjectCostType) => projectCost.clientName,
            },
          ]
        : []),
      {
        id: "division",
        header: t("division"),
        enableSorting: true,
        enableHiding: true,
        enableGlobalFilter: false,
        enableColumnFilter: false,
        accessorFn: (projectCost: ProjectCostType) => projectCost.ecosystemName,
      },
      {
        id: "owner",
        header: t("owner"),
        enableSorting: true,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        accessorFn: (projectCost) => `${projectCost.ownerFirstName ?? ""} ${projectCost.ownerLastName ?? ""}`,
        cell(props) {
          if (!props.row.original.ownerFirstName && !props.row.original.ownerLastName) return <EmptyDataPlaceholder />;
          return (
            <div className="flex flex-col gap-1">
              {props.row.original.ownerFirstName} {props.row.original.ownerLastName}
            </div>
          );
        },
      },
      {
        id: "startDate",
        header: t("startDate"),
        enableSorting: true,
        enableGlobalFilter: false,
        enableColumnFilter: false,
        accessorFn: (projectCost) => projectCost.startDate,
        cell(props) {
          const date = props.row.original.startDate;
          if (!date) return <EmptyDataPlaceholder />;
          return formatDate(date);
        },
      },
      {
        id: "deliveryDate",
        header: t("deliveryDate"),
        enableSorting: true,
        enableGlobalFilter: false,
        enableColumnFilter: false,
        accessorFn: (projectCost) => projectCost.deliveryDate,
        cell(props) {
          const date = props.row.original.deliveryDate;
          if (!date) return <EmptyDataPlaceholder />;
          return formatDate(date);
        },
      },
      {
        id: "price",
        header: t("totalPrice"),
        meta: { position: "right" },
        enableSorting: true,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        accessorFn: (projectCost) => projectCost.price,
        cell(props) {
          return <span className="float-right">{formatNumber(props.row.original.price)}</span>;
        },
      },
      {
        id: "currency",
        header: t("currency"),
        enableSorting: true,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        accessorFn: (projectCost) => projectCost.currencyName,
      },
    ];
  }, [showClient, t, formatDate, formatNumber]);

  return (
    <Card>
      <CardContent className="mt-4">
        <DataTable
          data-test="cost-report-table"
          uniqueName="ap-cost-reports"
          isLoading={isLoading}
          showViews={true}
          showSearch={false}
          defaultPageSize={10}
          columns={columns}
          data={projectCosts}
          sortBy={sortBy}
          onSortBy={(sortBy) => updateSortBy(sortBy)}
        />
      </CardContent>
    </Card>
  );
}
