import { EmptyDataPlaceholder } from "@/components/EmptyPlaceholder";
import { InlineEditableCustomField } from "@/components/customFields/InlineEditableCustomField";
import { InlineEditablePoField } from "@/components/customFields/InlineEditablePoField";
import { Separator } from "@/components/ui/separator";
import { useDateFormatter } from "@/hooks/useDate";
import type { RequestDetailType } from "@/model/request.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { sanitizeHtml } from "@/utils/text";
import { cn } from "@/utils/ui";
import { type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { AccessorsSection } from "./components/AccessorsSection";
import { DownloadLink } from "./components/DownloadLink";
import { LanguagePairs } from "./components/LanguagePairs";
import { ServiceDescriptor } from "./components/ServiceDescriptor";

export function BasicDetailsContent({ data }: { data: RequestDetailType }) {
  const { t } = useTranslation();
  const { formatDateTime } = useDateFormatter();
  const formatNumber = useAuthUserStore((state) => state.formatNumber);
  return (
    <div className="mx-10">
      <div className="my-4 grid grid-cols-[25%_75%] items-start gap-3">
        <DetailsRow label={t("requests.table.projectCode")}>
          <span data-test="project-code">{data.projectCode}</span>
        </DetailsRow>
        <DetailsRow label={t("requests.table.services")}>
          <ServiceDescriptor service={data.service} className="text-secondary-foreground" />
        </DetailsRow>
        <DetailsRow label={t("requests.table.dueDate")}>
          <span data-test="due-date">
            {data.deadline ? formatDateTime(data.deadline) : <EmptyDataPlaceholder iconSize={20} />}
          </span>
        </DetailsRow>
        <DetailsRow label={t("requests.details.fields.total")}>
          <span data-test="total-cost">
            {!data.total ? <EmptyDataPlaceholder iconSize={20} /> : formatNumber(data.total, data.currency)}
          </span>
        </DetailsRow>
      </div>
    </div>
  );
}

export function DetailsTabContent({ data }: { data: RequestDetailType }) {
  const { t } = useTranslation();
  const { formatDateTime } = useDateFormatter();
  const customFields = data.customFields ?? [];

  return (
    <div className="p-6 pt-4">
      <div className="rounded-3xl bg-card p-6 pt-4" data-test="request-details">
        <div className="my-4 grid grid-cols-[25%_75%] items-start gap-3">
          <DetailsRow label={t("requests.table.customerName")}>
            <span data-test="customer-name">{data.customerName}</span>
          </DetailsRow>
          <DetailsRow label={t("requests.table.creationDate")}>
            <span data-test="request-date">{formatDateTime(data.requestDate)}</span>
          </DetailsRow>
          <DetailsRow label={t("requests.table.quoteDeadline")}>
            <span data-test="quote-deadline">
              {data.quoteSubmissionDueDate ? (
                formatDateTime(data.quoteSubmissionDueDate)
              ) : (
                <EmptyDataPlaceholder iconSize={20} />
              )}
            </span>
          </DetailsRow>
          <DetailsRow label={t("requests.table.invoicingReference")}>
            <span data-test="invoicing-reference">
              <InlineEditablePoField
                invoicingReference={data.invoicingReference}
                id={data.id}
                type={data.type}
                label={t("requests.table.invoicingReference")}
              />
            </span>
          </DetailsRow>

          <DetailsRow label={t("requests.details.fields.languagePairs")}>
            <span data-test="language-pairs">
              {!data.languages || data.languages.length === 0 ? (
                <EmptyDataPlaceholder />
              ) : (
                <LanguagePairs languages={data.languages} />
              )}
            </span>
          </DetailsRow>
          <Separator className="col-span-2 my-4" />
          <DetailsRow label={t("requests.table.requester")}>
            <span data-test="contact-name">{data.customerContactName ?? <EmptyDataPlaceholder />}</span>
          </DetailsRow>
          <DetailsRow label={t("requests.table.projectManager")}>
            <span data-test="project-manager">{data.projectManager ?? <EmptyDataPlaceholder />}</span>
          </DetailsRow>

          <Separator className="col-span-2 my-4" />
          <DetailsRow label={t("requests.details.fields.accessors")}>
            <AccessorsSection
              requestId={data.id.toString()}
              entityType={data.type}
              divisionId={data.divisionId}
              accessors={data.accessors}
            />
          </DetailsRow>
          <Separator className="col-span-2 my-4" />
          <DetailsRow label={t("requests.details.fields.referenceMaterials")}>
            <span data-test="reference-materials">
              {!data.referenceMaterials || data.referenceMaterials.length === 0 ? (
                <EmptyDataPlaceholder />
              ) : (
                data.referenceMaterials.map((material) => (
                  <div key={material.id} className="flex flex-col items-start gap-1">
                    <DownloadLink fileName={material.name} id={data.id.toString()} entityType={data.type} />
                  </div>
                ))
              )}
            </span>
          </DetailsRow>
          <Separator className="col-span-2 my-4" />
          <DetailsRow label={t("requests.details.fields.specialInstructions")}>
            <span data-test="special-instructions">
              {!data.comments || data.comments.length === 0 ? (
                <EmptyDataPlaceholder />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.comments) }} />
              )}
            </span>
          </DetailsRow>

          {/* Custom Fields */}
          {customFields.length > 0 ? (
            <div className="col-span-2">
              <Separator className="my-4" />
              <div className="grid grid-cols-[25%_75%] items-center gap-3">
                {customFields.map((field) => (
                  <DetailsRow key={field.customFieldId} label={`${field.label} ${field.isMandatory ? "*" : ""}`}>
                    <InlineEditableCustomField field={field} requestId={data.id.toString()} entityType={data.type} />
                  </DetailsRow>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function DetailsRow({ label, children, className }: { label: string; children: ReactNode; className?: string }) {
  return (
    <>
      <div className="flex h-full flex-col justify-center">
        <span className="text-sm font-light">{label}</span>
      </div>
      <div className="grid gap-x-4">
        <span className={cn("text-sm text-secondary-foreground", className)}>{children}</span>
      </div>
    </>
  );
}
