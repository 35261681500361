import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { CostReportChartNames, ProjectCostPerLanguagesType, PerLanguageBarItemType } from "@/model/report.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { chain, sumBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Bar, BarChart, Brush, CartesianGrid, Legend, Rectangle, Tooltip, XAxis, YAxis } from "recharts";
import { ChartCard } from "../common/ChartCard";
import { ChartEmptyPlaceholder } from "../common/ChartEmptyPlaceholder";
import { ChartCardSkeleton } from "../common/ChartSkeleton";
import { ChartTooltipCursor } from "../common/ChartTooltipCursor";
import { useCostReportActions, useCostReportChartSettings, useCostReportFilters } from "../reportStore";
import { useChartDescription } from "./useChartDescription";
import { useChartBrushIndexes } from "../common/useChartBrushIndexes";

const MAX_OWNER_FULL_NAME_CHARS = 18; // maximum fullname characters before truncating it
const MAX_DISPLAYED_REQUESTORS = 10; // maximum nbre of displayed requestors

export function SpendPerRequestorPage() {
  const { pricePerPairLanguages, isLoading } =
    useOutletContext<{ isLoading: boolean; pricePerPairLanguages: ProjectCostPerLanguagesType[] }>() ?? {};

  if (isLoading) return <ChartCardSkeleton />;
  if (pricePerPairLanguages === undefined) return null;

  return <SpendPerRequestorChart pricePerPairLanguages={pricePerPairLanguages} isFocusView={true} />;
}

export function SpendPerRequestorChart({
  pricePerPairLanguages,
  height = 600,
  isFocusView = false,
}: {
  pricePerPairLanguages: ProjectCostPerLanguagesType[];
  height?: number;
  isFocusView?: boolean;
}) {
  const { t } = useTranslation();
  const formatNumber = useAuthUserStore((store) => store.formatNumber);
  const { includeOpenProjects } = useCostReportFilters();
  const { updateFilters } = useCostReportActions();
  const { title, description } = useChartDescription(CostReportChartNames.perRequestor, true, pricePerPairLanguages);

  const { saveChartSettings } = useCostReportActions();
  const chartSettings = useCostReportChartSettings(CostReportChartNames.perRequestor);

  const pricePerOwner = useMemo(
    () =>
      chain(pricePerPairLanguages)
        .uniqBy("projectId")
        .filter((value) => !!value.ownerFirstName && !!value.ownerLastName)
        .map((value) => {
          return {
            ...value,
            owner: `${value.ownerFirstName} ${value.ownerLastName}`,
          };
        })
        .groupBy("owner")
        .map((value, key) => {
          return {
            owner: key,
            price: sumBy(
              value.filter((v) => v.deliveryDate),
              "price"
            ),
            priceOpenProjects: sumBy(
              value.filter((v) => !v.deliveryDate),
              "price"
            ),
            currencyCode: value[0].currencyCode,
            associatedData: value,
          };
        })
        .orderBy("price", "desc")
        .map((value, index) => ({ ...value, brushTickName: index + 1 }))
        .value(),
    [pricePerPairLanguages]
  );

  const hasAtLeastOneOpenProject = pricePerOwner.some((value) => value.priceOpenProjects > 0);

  const handleOnOwnerClick = (key: string) => {
    if (!key) return;
    updateFilters({ ownersIds: [key] });
  };

  const endIndex =
    pricePerOwner.length > MAX_DISPLAYED_REQUESTORS ? MAX_DISPLAYED_REQUESTORS : pricePerOwner.length - 1;

  const [brushStartEndIndex, setBrushStartEndIndex] = useChartBrushIndexes({ endIndex });

  if (pricePerPairLanguages.length === 0) {
    return <ChartEmptyPlaceholder />;
  }

  return (
    <ChartCard
      height={height}
      title={title}
      description={description}
      link={CostReportChartNames.perRequestor}
      isFocusView={isFocusView}
      chartSettings={chartSettings}
      saveChartSettings={saveChartSettings}
    >
      {({ displayGrid, displayBrush }) => (
        <BarChart data={pricePerOwner} margin={{ top: 20, left: 25, right: 30 }} layout="vertical">
          {displayGrid ? <CartesianGrid strokeDasharray="3 3" /> : null}
          {displayBrush || pricePerOwner.length > MAX_DISPLAYED_REQUESTORS ? (
            <Brush
              height={20}
              travellerWidth={5}
              onChange={setBrushStartEndIndex}
              startIndex={brushStartEndIndex.startIndex}
              endIndex={brushStartEndIndex.endIndex}
              className="text-sm"
              fill="#cbd5e1"
              stroke="#374151"
              dataKey="brushTickName"
            />
          ) : null}
          <XAxis type="number" tickFormatter={(value) => formatNumber(value)} />
          <YAxis
            dataKey="owner"
            type="category"
            width={120}
            minTickGap={0}
            tick={<RequestorChartYAxisTick />}
            className="text-xs font-medium text-secondary-foreground"
          />
          <Tooltip
            cursor={<ChartTooltipCursor variant={"cyan"} />}
            content={({ payload }) => {
              if (!payload?.[0]?.payload) return null;
              const data = payload[0].payload;
              return (
                <Card>
                  <CardHeader className="px-2 py-1 text-base">
                    <CardTitle className="flex flex-col gap-1 text-base">
                      <span>{data.owner}</span>
                      <div className="grid grid-cols-1 gap-1 text-base font-medium">
                        <span className="grid grid-cols-2 gap-2">
                          <span className="text-muted-foreground">{t("reports.charts.projects")}</span>
                          <span>{t("reports.charts.perOwnerProjectCount", { count: data.associatedData.length })}</span>
                          <span className="text-muted-foreground">{t("reports.charts.total")}</span>
                          <span>{formatNumber(data.price + data.priceOpenProjects, data.currencyCode)}</span>
                          {includeOpenProjects ? (
                            <>
                              <span className="text-muted-foreground">{t("reports.charts.completedProjects")}</span>
                              <span>{formatNumber(data.price, data.currencyCode)}</span>
                              <span className="text-muted-foreground">{t("reports.charts.openProjects")}</span>
                              <span>{formatNumber(data.priceOpenProjects, data.currencyCode)}</span>
                            </>
                          ) : null}
                        </span>
                      </div>
                    </CardTitle>
                  </CardHeader>
                </Card>
              );
            }}
          />
          {includeOpenProjects && hasAtLeastOneOpenProject ? (
            <Legend
              verticalAlign="bottom"
              formatter={(value) => {
                return value === "price"
                  ? t("reports.charts.legend.completedProjects")
                  : t("reports.charts.legend.openProjects");
              }}
            />
          ) : null}
          <Bar
            stackId={includeOpenProjects ? "a" : undefined}
            dataKey="price"
            fill="#06b6d4"
            className="cursor-pointer fill-cyan-400 stroke-cyan-500 dark:fill-cyan-700 dark:stroke-cyan-800"
            activeBar={<Rectangle className="fill-cyan-600 stroke-cyan-700" />}
            onClick={(data: PerLanguageBarItemType) => handleOnOwnerClick(data.owner)}
          />
          {includeOpenProjects ? (
            <Bar
              stackId={"a"}
              dataKey="priceOpenProjects"
              fill="#84cc16"
              className="cursor-pointer fill-lime-400 stroke-lime-500 dark:fill-lime-700 dark:stroke-lime-800"
              activeBar={<Rectangle className="fill-lime-600 stroke-lime-700" />}
              onClick={(data: PerLanguageBarItemType) => handleOnOwnerClick(data.owner)}
            />
          ) : null}
        </BarChart>
      )}
    </ChartCard>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function RequestorChartYAxisTick(props: any) {
  const { x, y, stroke, payload } = props;

  const owner =
    payload.value.length > MAX_OWNER_FULL_NAME_CHARS
      ? `${payload.value.substring(0, MAX_OWNER_FULL_NAME_CHARS)}...`
      : payload.value;
  return (
    <g>
      <line x1={x} y1={y} x2={x} y2={y + 6} stroke={stroke} />
      <text x={x} y={y} dy={3} fill="#666" textAnchor="end" className="text-xs">
        {owner}
      </text>
      <title>{payload.value}</title>
    </g>
  );
}
