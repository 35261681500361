import { ButtonIcon } from "@/components/button/ButtonIcon";
import PortalTooltip from "@/components/PortalTooltip";
import { TooltipProvider } from "components/ui/tooltip";
import { ArrowRightIcon, ClipboardCheck as ClipboardCheckIcon, Trash2 as Trash2Icon } from "lucide-react";
import { MachineTranslationResult } from "model/mt.typing";
import { useTranslation } from "react-i18next";

interface SavedTranslationProps {
  translation: MachineTranslationResult;
  deleteTranslation: (id: string) => void;
  copyToClipboard: (id: string) => void;
}

export const SavedTranslation = (props: SavedTranslationProps) => {
  const { t } = useTranslation();
  const { translation, deleteTranslation, copyToClipboard } = props;

  return (
    <div className="mt-2 rounded-md border p-4 sm:items-center">
      <div className="flex items-center justify-between">
        <div className="inline-flex items-center gap-1 text-xs">
          <span>{translation.sourceLanguage?.label}</span>
          <ArrowRightIcon className="size-4" />
          <span>{translation.targetLanguage?.label}</span>
        </div>
        <TooltipProvider delayDuration={0}>
          <div className="inline-flex items-center gap-2">
            <PortalTooltip text={t("mt.copyToClipboard")}>
              <ButtonIcon icon={ClipboardCheckIcon} onClick={() => copyToClipboard(translation.id)} />
            </PortalTooltip>
            <PortalTooltip text={t("mt.remove")}>
              <ButtonIcon icon={Trash2Icon} onClick={() => deleteTranslation(translation.id)} />
            </PortalTooltip>
          </div>
        </TooltipProvider>
      </div>
      <div className="my-1 text-sm font-medium">
        <p>{translation.sourceText}</p>
        <p>{translation.targetText}</p>
      </div>
    </div>
  );
};
