import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useDateFormatter, useDateOperations } from "@/hooks/useDate";
import { areRangesEqual, isRangeInside } from "@/utils/dates";
import { cn } from "@/utils/ui";
import { addMonths, endOfDay, isAfter, isBefore } from "date-fns";
import { CalendarIcon, CheckIcon } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { type DateRange } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { useIsSmallScreen } from "../../hooks/useIsSmallScreen";
import { Separator } from "../ui/separator";
import { useDateRangePreset } from "./useDateRangePreset";

export interface DateRangePickerProps {
  onUpdate?: (values: DateRange) => void;
  range?: DateRange;
  minMaxDate?: DateRange;
  align?: "start" | "center" | "end";
  testId?: string;
  title: string;
  presetsType?: "future" | "past" | "all";
  showSingleDate?: boolean;
}

export function DateRangePicker({
  minMaxDate,
  title,
  range,
  onUpdate,
  testId = "",
  align = "end",
  presetsType = "past", // Past by default
  showSingleDate = false,
}: DateRangePickerProps) {
  const { t } = useTranslation();
  const { dateLocale } = useDateFormatter();
  const { timezone, now } = useDateOperations();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState<DateRange>(range ?? { from: undefined, to: undefined });
  const [currentMonth, setCurrentMonth] = useState<Date>(range?.from ?? now());
  const [selectedPreset, setSelectedPreset] = useState<string | undefined>(undefined);
  const { presets, getPresetName, getPreset } = useDateRangePreset(minMaxDate, presetsType);
  const openedRangeRef = useRef<DateRange | undefined>();
  const isSmallScreen = useIsSmallScreen();

  const hidden = [{ before: minMaxDate?.from }, { after: minMaxDate?.to }].filter(
    (matcher): matcher is { before: Date } | { after: Date } => {
      if (!matcher.before && !matcher.after) return false;
      return true;
    }
  );

  const handleDateSelect = useCallback((selected: DateRange | undefined) => {
    if (!selected?.from && !selected?.to) {
      setSelectedRange({ from: undefined, to: undefined });
    } else {
      setSelectedRange({ from: selected.from, to: selected.to ? endOfDay(selected.to) : undefined });
    }
  }, []);

  // save on close
  const onClose = () => {
    if (!areRangesEqual(selectedRange, openedRangeRef.current) && onUpdate) {
      onUpdate(selectedRange);
    }
    setIsOpen(false);
  };

  const handleOnSelectPreset = (presetName: string) => {
    const range = getPreset(presetName);
    setSelectedRange(range);
    if (range?.from) setCurrentMonth(range?.from);
    else if (range?.to) setCurrentMonth(range?.to);
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    // how it works: We can have a range of dates, and we want to set the current month to the month of the range.
    // if the range is in the past, we want to set the current month to the month of the range.
    // if the range is in the future, we want to set the current month to the month of the range.
    const minDate = range?.from ?? minMaxDate?.from;
    const maxDate = range?.to ?? minMaxDate?.to;
    const nowMinusOneMonth = addMonths(now(), -1);
    if (maxDate && isBefore(maxDate, nowMinusOneMonth)) {
      setCurrentMonth(addMonths(maxDate, -1));
    } else if (minDate && isAfter(minDate, nowMinusOneMonth)) {
      setCurrentMonth(addMonths(minDate, -1));
    } else {
      setCurrentMonth(nowMinusOneMonth);
    }

    if (!range) {
      handleDateSelect({ from: undefined, to: undefined });
    } else if (minMaxDate) {
      const isInRange = isRangeInside(range, minMaxDate);
      if (isInRange) {
        handleDateSelect(range);
      }
    }
  }, [handleDateSelect, isOpen, minMaxDate, range, now]);

  useEffect(() => {
    const presetName = getPresetName(selectedRange);
    setSelectedPreset(presetName);
  }, [getPresetName, selectedRange]);

  useEffect(() => {
    if (isOpen) {
      openedRangeRef.current = range;
    }
  }, [isOpen, range]);

  return (
    <Popover
      modal={true}
      open={isOpen}
      onOpenChange={(open: boolean) => {
        if (!open) {
          onClose();
        }
        setIsOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className={"h-9 border font-normal"}>
          <CalendarIcon data-test={`calendar-${testId}-trigger-icon`} className="mr-2 size-4" />
          <span data-test={`calendar-${testId}-trigger-title`}>{title}</span>
          {isOpen ? (
            <DisplayCurrentValue testId={testId} range={selectedRange} showSingleDate={showSingleDate} />
          ) : (
            <DisplayCurrentValue testId={testId} range={range} showSingleDate={showSingleDate} />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent align={align} className="w-auto">
        <div className="flex">
          <div className="flex">
            <div className="flex flex-col">
              {isSmallScreen && (
                <Select defaultValue={selectedPreset} onValueChange={(value) => handleOnSelectPreset(value)}>
                  <SelectTrigger className="mb-2" data-test={`calendar-${testId}-preset-select`}>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {presets.map((preset) => (
                      <SelectItem key={preset.name} value={preset.name} disabled={preset.disabled}>
                        {t(`common.calendar.presets.${preset.name}`)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
              <div>
                <Calendar
                  mode="range"
                  data-test={`calendar-${testId}-picker`}
                  onSelect={(value) => {
                    if (value?.from === value?.to) {
                      handleDateSelect({ from: value?.from, to: undefined });
                    } else {
                      handleDateSelect(value);
                    }
                  }}
                  locale={dateLocale}
                  selected={selectedRange}
                  numberOfMonths={isSmallScreen ? 1 : 2}
                  month={currentMonth}
                  onMonthChange={setCurrentMonth}
                  startMonth={minMaxDate?.from}
                  endMonth={minMaxDate?.to}
                  captionLayout="dropdown"
                  autoFocus={true}
                  disabled={hidden}
                  timeZone={timezone}
                />
              </div>
            </div>
          </div>
          {!isSmallScreen && (
            <div className="flex flex-col items-start gap-1 pl-2">
              <div className="mb-6 flex w-full flex-col items-end gap-1">
                {presets.map((preset) => (
                  <PresetButton
                    key={preset.name}
                    preset={preset.name}
                    disabled={preset.disabled}
                    label={t(`common.calendar.presets.${preset.name}`)}
                    isSelected={selectedPreset === preset.name}
                    onPresetChange={(value) => handleOnSelectPreset(value)}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end gap-2 pb-2">
          <Button
            variant="secondary"
            disabled={!selectedRange.from && !selectedRange.to}
            onClick={() => {
              setSelectedRange({ from: undefined, to: undefined });
              setCurrentMonth(now());
            }}
          >
            {t("common.calendar.reset")}
          </Button>
          <Button onClick={() => onClose()}> {t("common.calendar.close")}</Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}

const DisplayCurrentValue = ({
  testId,
  range,
  showSingleDate,
}: {
  testId: string;
  range?: DateRange;
  showSingleDate: boolean;
}) => {
  const { formatDate } = useDateFormatter();

  return range?.from ? (
    <>
      <Separator orientation="vertical" className="mx-2 h-4" />
      <div data-test={`calendar-${testId}-value`} className="flex space-x-1 font-medium">
        {range.to && range.from === range.to && showSingleDate
          ? formatDate(range.from)
          : range.to
            ? `${formatDate(range.from)} - ${formatDate(range.to)}`
            : formatDate(range.from)}
      </div>
    </>
  ) : null;
};

const PresetButton = ({
  preset,
  label,
  disabled,
  isSelected,
  onPresetChange,
}: {
  preset: string;
  label: string;
  isSelected: boolean;
  disabled: boolean;
  onPresetChange: (preset: string) => void;
}) => (
  <Button
    disabled={disabled}
    className={cn(isSelected && "pointer-events-none")}
    variant="ghost"
    onClick={() => {
      if (!disabled) onPresetChange(preset);
    }}
  >
    <>
      <span className={cn("pr-2 opacity-0", !disabled && isSelected && "opacity-70")}>
        <CheckIcon width={18} height={18} />
      </span>
      {label}
    </>
  </Button>
);

DateRangePicker.displayName = "DateRangePicker";
