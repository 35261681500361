import { useAuthUserStore } from "@/store/useAuthUserStore";
import { createDateInTimeZone, createNowInTimeZone, is24HourFormat } from "@/utils/dates";
import { TZDate } from "@date-fns/tz";
import { useMemo } from "react";

type AnyDate = Date | TZDate | Date | string;

const useUserTimezone = () => {
  return useAuthUserStore((store) => store.userCulture.timeZoneInfo.shortName);
};

export const useTimeFormat = () => {
  const timeFormat = useAuthUserStore((store) => store.userCulture.timeFormat);
  return {
    timeFormat,
    is24Hour: is24HourFormat(timeFormat),
  };
};

export const useDateOperations = () => {
  const timezone = useUserTimezone();
  const isSameTimeZone = useMemo(
    () => new Date().getTimezoneOffset() === new TZDate(new Date(), timezone).getTimezoneOffset(),
    [timezone]
  );
  return useMemo(
    () => ({
      timezone,
      isSameTimeZone,
      now: () => createNowInTimeZone(timezone),
      convertDate: (date: Date) => {
        return createDateInTimeZone(date, timezone);
      },
    }),
    [timezone, isSameTimeZone]
  );
};

export const useDateFormatter = () => {
  const formatDate = useAuthUserStore((store) => store.formatDate);
  const { dateLocale } = useAuthUserStore((store) => store.userCulture);
  return useMemo(
    () => ({
      dateLocale,
      formatDate: (date: AnyDate | null | undefined) => formatDate(date, { timeFormat: { includeTime: false } }),
      formatDateTime: (date: AnyDate | null | undefined, includeTimeZone: "show" | "hide" | "auto" = "auto") =>
        formatDate(date, { timeFormat: { includeTime: true, includeTimeZone } }),
      formatDateCustom: (date: AnyDate | null | undefined, dateFormat: string, timeFormat?: string) =>
        formatDate(date, { dateFormat, timeFormat }),
    }),
    [formatDate, dateLocale]
  );
};

type DateFormatter = ReturnType<typeof useDateFormatter>;
export type FormatDateFn = DateFormatter["formatDate"];
export type FormatDateTimeFn = DateFormatter["formatDateTime"];
