import { CardTitle } from "@/components/ui/card";
import { CircleHelpIcon } from "lucide-react";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

const ExpandableHelpSection = ({ title, helpText }: { title: string; helpText: string }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleHelp = () => setIsExpanded((prev) => !prev);
  return (
    <CardTitle>
      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold">{title}</h2>
        <CircleHelpIcon
          className="ml-2 size-6 cursor-pointer text-secondary-foreground opacity-25"
          onMouseEnter={toggleHelp}
          onMouseLeave={toggleHelp}
        />
      </div>
      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="mt-2 text-sm font-normal lg:text-base">{helpText}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </CardTitle>
  );
};

export default ExpandableHelpSection;
