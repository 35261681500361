import { EmptyPlaceholder } from "@/components/EmptyPlaceholder";
import { MENU_ICONS } from "@/components/Icons";
import { useTranslation } from "react-i18next";

export function ChartEmptyPlaceholder() {
  const { t } = useTranslation();
  return (
    <EmptyPlaceholder>
      <EmptyPlaceholder.Icon icon={MENU_ICONS.cost} />
      <EmptyPlaceholder.Title>{t("reports.empty.title")}</EmptyPlaceholder.Title>
      <EmptyPlaceholder.Description>{t("reports.empty.description")}</EmptyPlaceholder.Description>
    </EmptyPlaceholder>
  );
}
