import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Loader2Icon, Trash2Icon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

interface FileActionsDeleteProps {
  onDelete: () => Promise<boolean>;
  onSuccess: () => void;
}

export function FileActionsDelete({ onDelete, onSuccess }: FileActionsDeleteProps) {
  const { t } = useTranslation();
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleDeleteFile = () => {
    setIsDeleting(true);
    onDelete().then((success) => {
      if (success) {
        onSuccess();
        toast.success(t("mt.file.deleteSuccess"));
        setShowDeleteAlert(false);
        setIsDeleting(false);
      } else {
        toast.error(t("mt.file.deleteFailed"));
      }
    });
  };

  return (
    <>
      <Button variant="outline" size={"icon"} onClick={() => setShowDeleteAlert(true)}>
        <Trash2Icon className="size-5" />
      </Button>
      <AlertDialog open={showDeleteAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t("mt.areYouSure")}</AlertDialogTitle>
            <AlertDialogDescription>{t("mt.file.deleteAlert")}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel disabled={isDeleting} onClick={() => setShowDeleteAlert(false)}>
              {t("common.cancel")}
            </AlertDialogCancel>
            <AlertDialogAction
              disabled={isDeleting}
              variant="destructive"
              onClick={() => handleDeleteFile()}
              className="inline-flex flex-nowrap items-center gap-2"
            >
              {isDeleting && <Loader2Icon className="animate-spin" />}
              {t("mt.file.deleteFiles")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
