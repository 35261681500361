import { IllustrationAnalysis } from "@/components/svgs/Illustrations";
import { Skeleton } from "@/components/ui/skeleton";
import { AnalysisType, AnalysisTypeAPI, RequestDetailType } from "@/model/request.typing";
import { useFiltredPerLanguageProjectCost } from "@/pages/reports/cost/useProjectCostFilter";
import { useProjectCosts, useTmLeverageData } from "@/query/report.query";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import useRequestAnalysis from "../useRequestAnalysis";
import { AnalysisEmptyPlaceholder } from "./components/analysis/AnalysisChartCard";
import { AnalysisCostPerLanguageChart } from "./components/analysis/AnalysisCostPerLanguage";
import { AnalysisFilter } from "./components/analysis/AnalysisFilter";
import { AnalysisMetricCards } from "./components/analysis/AnalysisMetricCards";
import {
  AnalysisLanguageOverviews,
  AnalysisOverview,
  AnalysisResultsHeader,
} from "./components/analysis/AnalysisOverview";
import { AnalysisTmPerLanguageChart } from "./components/analysis/AnalysisTmPerLanguage";

interface AnalysisTabContentProps {
  project: RequestDetailType;
  isLoading: boolean;
  entityType: string;
}

export const AnalysisTabContent = ({ entityType, project, isLoading }: AnalysisTabContentProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.analysis" });
  const { search } = useLocation();
  const debug = new URLSearchParams(search).get("debug");
  const { tmLeverageData } = useTmLeverageData(
    project ? (debug ?? project.projectCode?.toString() ?? null) : null,
    true
  );
  const { projectCosts } = useProjectCosts(project ? (debug ?? project.projectCode?.toString() ?? null) : null, true);
  const {
    analysis,
    projectTmData,
    projectCostData,
    resetFilters,
    sourceLanguages,
    sourceLanguageCode,
    targetLanguages,
    targetLanguageCodes,
    setTargets,
    updateLanguageCodes,
  } = useRequestAnalysis(project, tmLeverageData || [], useFiltredPerLanguageProjectCost(projectCosts, false) || []);
  const showAnalysisContent = analysis?.apeAnalysis && project?.apeOperationsStatus === "Finished";
  const showQualityMetrics = entityType === "project" && project?.explicitAI;

  if (isLoading) {
    return (
      <div className="px-6 pt-4">
        <div className="rounded-3xl bg-card p-6">
          <div className="grid grid-cols-3 items-start justify-between gap-4 p-2">
            <Skeleton className="h-12 w-full" />
            <Skeleton className="h-12 w-full" />
            <Skeleton className="h-12 w-full" />
          </div>
          <div className="grid grid-cols-3 items-start justify-between gap-4 p-2">
            <Skeleton className="h-32 w-full" />
            <Skeleton className="h-32 w-full" />
            <Skeleton className="h-32 w-full" />
          </div>
        </div>
        <div className="mt-6 rounded-3xl bg-card p-6">
          <div className="grid grid-cols-1 items-start justify-between gap-4 p-2">
            <Skeleton className="h-12 w-full" />
          </div>
          <div className="grid grid-cols-1 items-start justify-between gap-4 p-2">
            <Skeleton className="h-32 w-full" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-6 pb-6 pt-0">
      <AnalysisFilter
        {...{
          analysis,
          resetFilters,
          sourceLanguages,
          sourceLanguageCode,
          targetLanguages,
          targetLanguageCodes,
          setTargets,
          updateLanguageCodes,
        }}
      />
      {showQualityMetrics &&
        (showAnalysisContent ? (
          <AnalysisContent analysis={analysis as AnalysisTypeAPI} project={project} />
        ) : (
          <EmptyPlaceholderContent t={t} />
        ))}
      <div className={showQualityMetrics ? "my-6" : "mb-6 mt-0"}>
        <AnalysisCostPerLanguageChart
          data={projectCostData ?? []}
          height={400}
          className={showQualityMetrics ? "" : "mt-0"}
        />
        <AnalysisTmPerLanguageChart data={projectTmData ?? []} height={400} />
      </div>
    </div>
  );
};

const AnalysisContent = ({ analysis, project }: { analysis: AnalysisType; project: RequestDetailType }) => (
  <>
    <AnalysisMetricCards analysis={analysis} />
    <AnalysisResultsHeader analysis={analysis} />
    <AnalysisOverview analysis={analysis} project={project} />
    <AnalysisLanguageOverviews analysis={analysis} project={project} />
  </>
);

const EmptyPlaceholderContent = ({ t }: { t: TFunction }) => (
  <AnalysisEmptyPlaceholder
    id="quality-analysis"
    title={t("qualityAnalysis")}
    description={t("qualityAnalysisMsg")}
    height={400}
    message={t("stillProcessingAI")}
    illustration={<IllustrationAnalysis />}
    className="mt-0"
  />
);
