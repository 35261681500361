import { ThemeBranding } from "@/components/ThemeBranding";
import { SidebarProvider } from "@/components/ui/sidebar";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { Toaster } from "components/ui/toaster";
import useTheme from "hooks/useTheme";
import { useEffect } from "react";
import { Outlet } from "react-router";
import { initAppcue } from "utils/appcues";
import { AppHeader } from "./AppHeader";
import { AppSidebar } from "./AppSidebar";

export function MainAppLayout() {
  const { theme } = useTheme();
  const user = useAuthUserStore((store) => store.user);

  useEffect(() => {
    if (user) {
      initAppcue(user);
    }
  }, [user]);

  return (
    <ThemeBranding>
      <SidebarProvider>
        <AppSidebar />
        <div className="min-w-0 flex-1">
          <AppHeader />
          <div className="mobile:pr-0 h-[calc(100vh_-_64px)] bg-sidebar pb-4 sm:pr-4">
            <Outlet />
          </div>
        </div>
      </SidebarProvider>
      <Toaster theme={theme} expand={false} duration={5000} />
    </ThemeBranding>
  );
}
