import { Button } from "@/components/ui/button";
import { t } from "i18next";
import { Minimize2, PlusCircleIcon } from "lucide-react";
import useGlossary from "./useGlossary";
import { GlossaryListItem } from "./components/GlossaryListItem";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Terminology } from "@/model/mt.typing";
import {
  useDeleteTerminologyMutation,
  useGetTerminologyListQuery,
  useGetTerminologyMutation,
} from "@/query/mt/machinetranslation.query";
import Loader from "@/components/Loader";
import { TooltipProvider } from "@/components/ui/tooltip";

interface GlossaryListProps {
  setActive: (value: string) => void;
}

export const GlossaryList = (props: GlossaryListProps) => {
  const { setActive } = props;

  const { terminologies, setView, terminologyConfiguration } = useGlossary();
  const { isFetchingDelete } = useDeleteTerminologyMutation();
  const { isFetchingList } = useGetTerminologyListQuery(terminologyConfiguration.customerId);
  const { isFetching } = useGetTerminologyMutation();

  const renderTerminologyItems = () => {
    if (isFetching || isFetchingDelete || isFetchingList)
      return <Loader isShown={true} variant="md" className="my-2" />;

    return terminologies.length === 0 ? (
      <Alert className="my-2" variant="warning">
        <AlertDescription>{t("mt.noMatches")}</AlertDescription>
      </Alert>
    ) : (
      terminologies.map((item: Terminology) => (
        <div key={item.id}>
          <GlossaryListItem item={item} />
        </div>
      ))
    );
  };

  return (
    <TooltipProvider delayDuration={0}>
      <div data-test="mt-glossary-list-div">
        <div className="mb-2 flex items-center justify-between gap-2">
          <h2 className="text-xl font-medium">{t("mt.glossary.title")}</h2>
          <div className="flex items-center gap-1">
            <Button
              type="button"
              variant="outline"
              className="h-8 align-middle text-xs"
              size="sm"
              onClick={() => setActive("")}
            >
              <Minimize2 className="size-4" />
            </Button>
          </div>
        </div>

        {terminologies.length < 5 && (
          <Button
            className="mt-4 w-full"
            variant="outline"
            onClick={() => setView("add")}
            data-test="mt-add-glossary-button"
          >
            <PlusCircleIcon className="mr-2 opacity-50" />
            {t("mt.glossary.addGlossary")}
          </Button>
        )}

        {terminologies.length > 0 && renderTerminologyItems()}
      </div>
    </TooltipProvider>
  );
};
