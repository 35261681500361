import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

export const FileMachineTranslationFormSchema = z.object({
  sourceLanguage: z.object({
    label: z.string(),
    code: z.string(),
    mappedCode: z.string(),
    supportsTerminology: z.boolean(),
  }),
  targetLanguage: z.object({
    label: z.string(),
    code: z.string(),
    mappedCode: z.string(),
    supportsTerminology: z.boolean(),
  }),
});

export type FileMachineTranslationFormType = z.infer<typeof FileMachineTranslationFormSchema>;

export const useFileMachineTranslationForm = () => {
  return useForm<FileMachineTranslationFormType>({
    resolver: zodResolver(FileMachineTranslationFormSchema),
  });
};
