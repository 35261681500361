import { UploadIcon } from "lucide-react";
import { MachineTranslationConfig } from "model/mt.typing";
import { useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Button } from "./ui/button";

interface FileDropzoneProps {
  handleFilesSelected: (files: FileWithPath[]) => void;
  machineTranslationConfig?: MachineTranslationConfig;
}

export function FileDropzone({ handleFilesSelected, machineTranslationConfig }: FileDropzoneProps) {
  const { t } = useTranslation();
  const [showFormats, setShowFormats] = useState<boolean>(false);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "application/octet-stream": machineTranslationConfig ? machineTranslationConfig?.extensionsUI : [],
    },
    onDrop: (acceptedFiles: FileWithPath[]) => {
      handleFilesSelected(acceptedFiles);
    },
  });

  const handleShowFormats = () => {
    setShowFormats(!showFormats);
  };

  return (
    <div className="w-full">
      <div
        {...getRootProps()}
        className="flex cursor-pointer flex-col items-center gap-2 rounded-md border-2 border-dashed py-4 outline-none transition duration-150 ease-in-out hover:bg-accent"
        data-test="file-upload-dropzone"
      >
        <input {...getInputProps()} />
        <div className="text-center text-muted-foreground">
          <UploadIcon className="size-12 shrink-0" />
        </div>
        <div className="text-center text-lg">{t("mt.fileSelectionControl")}</div>
      </div>
      <div>
        <div>
          <div className="mt-2 flex pl-2 text-xs md:justify-between">
            <div>{t("mt.file.maxFileSize")}</div>
            <div>{t("mt.file.retentionInfo")}</div>
          </div>
          <Button variant="secondary" className="mt-2" size="sm" onClick={handleShowFormats}>
            {t("mt.supportedFormats")}
          </Button>
          {showFormats && machineTranslationConfig && (
            <div className="mt-2 pl-2 text-sm">{machineTranslationConfig.extensionsUI.join(", ")}</div>
          )}
        </div>
      </div>
    </div>
  );
}
