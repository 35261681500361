import { useEffect, useRef } from "react";

/**
 *  useOnDidMount hook
 *
 *  Fires callback on component mount (only once)
 *
 * @param {Function} callback The callback to be called on update
 */
function useOnMount(callback: () => void): void {
  const hasMountedRef = useRef<boolean>(true);

  useEffect(() => {
    if (hasMountedRef.current) {
      hasMountedRef.current = false;
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export { useOnMount };
