import { SortableItem, SortingProps } from "@/components/sorting/sort.typings";
import { useSorting } from "@/components/sorting/useSorting";
import { CommandList } from "components/ui/command";
import { ElementRef, useCallback, useEffect, useRef, useState } from "react";

interface UseComboboxProps<SortField extends string> {
  testId: string;
  sorting?: SortingProps<SortField>;
}

interface UseComboboxReturn<TItem extends SortableItem> {
  searchValue: string;
  setSearchValue: (value: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  listRef: React.RefObject<ElementRef<typeof CommandList>>;
  sortItems: (items: TItem[]) => TItem[];
  renderSortControl: (() => React.ReactNode) | undefined;
  isSortingEnabled: boolean;
}

export function useCombobox<TItem extends SortableItem, SortField extends string>({
  testId,
  sorting,
}: UseComboboxProps<SortField>): UseComboboxReturn<TItem> {
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const listRef = useRef<ElementRef<typeof CommandList>>(null);

  const {
    sortState,
    updateSort,
    sortItems,
    isEnabled: isSortingEnabled,
  } = useSorting<TItem, SortField>({
    id: testId,
    defaultSort: sorting?.defaultSort,
  });

  // Create memoized render function for sort control
  const renderSortControl = useCallback(() => {
    if (!isSortingEnabled || !sortState || !sorting?.renderSortControl) {
      return undefined;
    }
    return sorting.renderSortControl(sortState, updateSort);
  }, [isSortingEnabled, sortState, sorting, updateSort]);

  const scrollToTop = useCallback(() => {
    if (listRef.current) {
      listRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      scrollToTop();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [searchValue, scrollToTop]);

  return {
    searchValue,
    setSearchValue,
    open,
    setOpen,
    listRef,
    sortItems,
    renderSortControl,
    isSortingEnabled,
  };
}
