import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { Tooltip, TooltipArrow, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Terminology } from "@/model/mt.typing";
import { useGetTerminologyListQuery } from "@/query/mt/machinetranslation.query";
import { t } from "i18next";
import { BookOpenIcon, ChevronsUpDown, CogIcon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useGlossary from "./useGlossary";

interface GlossaryButtonProps {
  activeComponent: string;
  setActive: (value: string) => void;
}

export const GlossaryButton = (props: GlossaryButtonProps) => {
  const { activeComponent, setActive } = props;
  const { terminologyConfiguration, selectedTerminologyName } = useGlossary();
  const { fetchedTerminologies } = useGetTerminologyListQuery(terminologyConfiguration.customerId);

  if (!terminologyConfiguration.terminologyAvailable) return null;

  return (
    <TooltipProvider delayDuration={0}>
      <div className="flex items-end">
        <div className="grid">
          {getDropdown(fetchedTerminologies, selectedTerminologyName)}
        </div>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              data-test="mt-glossary-cog-button"
              className="mt-2 rounded-l-none border-l-0 px-2"
              onClick={() => setActive(activeComponent !== "glossary" ? "glossary" : "")}
            >
              <CogIcon className="size-5 opacity-50" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>{getButton(fetchedTerminologies?.length)}</TooltipContent>
        </Tooltip>
      </div>
    </TooltipProvider>
  );
};

function TerminologyDropdown({ terminologies }: { terminologies: Terminology[] | undefined }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { selectedTerminologyName, terminologyStoredSettings, updateTerminologyStoredSettings } = useGlossary();

  const handleSetTerminology = (terminology: Terminology | null) => {
    updateTerminologyStoredSettings({
      ...terminologyStoredSettings,
      selectedTerminologyId: terminology !== null ? terminology.id : null,
      isTerminologyEnabled: terminology !== null,
      // set changed flag if we are changing active terminology - to have refresh translation button shown
      terminologyChanged: terminology !== null && terminologyStoredSettings.selectedTerminologyId !== terminology.id,
    });
    setIsOpen(false);
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          role="combobox"
          disabled={terminologies?.length === 0}
          className="w-full min-w-48 justify-between rounded-r-none"
          data-test="mt-glossary-selection-dropdown"
        >
          <div className="inline-flex">
            <BookOpenIcon className="mr-3 size-5 opacity-50" />
            <p className="max-w-24 truncate">
              {terminologyStoredSettings.selectedTerminologyId && selectedTerminologyName
                ? selectedTerminologyName
                : t("mt.glossary.noGlossary")}
            </p>
          </div>
          <ChevronsUpDown className="size-4 shrink-0 text-muted-foreground" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="w-[var(--radix-popover-trigger-width)] p-0"
        data-test="glossary-button-list"
      >
        <Command>
          <CommandList>
            <CommandEmpty>{t(`mt.glossary.noTerminologyFound`)}</CommandEmpty>
            <CommandGroup>
              {terminologies
                ?.filter((x) => x.id !== terminologyStoredSettings.selectedTerminologyId)
                ?.map((terminology) => (
                  <CommandItem
                    value={terminology.name}
                    key={terminology.name}
                    onSelect={() => handleSetTerminology(terminology)}
                    data-test={`mt-glossary-selection-${terminology.id}`}
                  >
                    <p className="max-w-32 truncate">{terminology.name}</p>
                  </CommandItem>
                ))}
            </CommandGroup>
            <CommandGroup>
              <Separator />
              <CommandItem
                className="mt-2 justify-center font-semibold"
                onSelect={() => handleSetTerminology(null)}
                data-test={`mt-glossary-selection-clear-button`}
              >
                {t("mt.clear")}
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function getDropdown(terminologies: Terminology[] | undefined, selectedTerminologyName: string | null | undefined) {
  let tooltip: string = "";
  if (terminologies && terminologies.length === 0) tooltip = "mt.glossary.noGlossaryTip";
  if (terminologies && terminologies.length >= 1 && selectedTerminologyName === null)
    tooltip = "mt.glossary.chooseGlossary";
  if (terminologies && terminologies.length >= 1 && selectedTerminologyName && selectedTerminologyName.length > 10)
    tooltip = selectedTerminologyName;

  if (tooltip === "") return <TerminologyDropdown terminologies={terminologies} />;

  return (
    <Tooltip>
      <TooltipTrigger>
        <TerminologyDropdown terminologies={terminologies} />
      </TooltipTrigger>
      <TooltipContent>{t(tooltip)}</TooltipContent>
    </Tooltip>
  );
}

function getButton(terminologiesCount: number | undefined) {
  let tooltip: string = "";
  if (!terminologiesCount || terminologiesCount === 0) tooltip = "mt.glossary.buttonNoGlossaryTip";
  if (terminologiesCount && terminologiesCount >= 1 && terminologiesCount < 5)
    tooltip = "mt.glossary.createAndManageGlossaries";
  if (terminologiesCount && terminologiesCount === 5) tooltip = "mt.glossary.manageGlossaries";

  return (
    <>
      {t(tooltip)}
      <TooltipArrow />
    </>
  );
}
