import { MENU_ICONS } from "@/components/Icons";
import { PageHeaderShell } from "@/components/shell/PageHeaderShell";
import { PageShell } from "@/components/shell/PageShell";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  useAllRequestsQuery,
  useDeliveredRequestsQuery,
  useInProgressRequestsQuery,
  useNewRequestsQuery,
} from "@/query/request.query";
import { Loader2Icon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import {
  AllRequestsContent,
  DeliveredRequestsContent,
  InProgressRequestsContent,
  NewRequestsContent,
} from "./RequestListContent";

export function RequestsPage() {
  const { t } = useTranslation();
  const { type } = useParams();
  const navigate = useNavigate();
  const { data: newRequests, isLoading: isLoadingNewRequests } = useNewRequestsQuery();
  const { data: inProgressRequests, isLoading: isLoadingInProgressRequests } = useInProgressRequestsQuery();
  const { data: deliveredRequests, isLoading: isLoadingDeliveredRequests } = useDeliveredRequestsQuery();
  const { data: allRequests, isLoading: isLoadingAllRequests } = useAllRequestsQuery();

  return (
    <PageShell data-test="requests-page">
      <PageHeaderShell
        data-test="requests-page-header"
        icon={MENU_ICONS.requests}
        heading={t("requests.home.title")}
        description={t("requests.home.description")}
      />
      <Tabs defaultValue={type} onValueChange={(value) => navigate(`../${value}`)}>
        <TabsList
          className="flex h-auto max-w-fit flex-wrap items-center justify-start print:hidden"
          data-test="tabs-requests-list"
        >
          <TabsTrigger value="new">
            <TabHeader
              title={t("requests.type.new")}
              isLoading={isLoadingNewRequests}
              count={newRequests.length ?? 0}
              isActive={type === "new"}
            />
          </TabsTrigger>
          <TabsTrigger value="in-progress">
            <TabHeader
              title={t("requests.type.in-progress")}
              isLoading={isLoadingInProgressRequests}
              count={inProgressRequests?.length ?? 0}
              isActive={type === "in-progress"}
            />
          </TabsTrigger>
          <TabsTrigger value="delivered">
            <TabHeader
              title={t("requests.type.delivered")}
              isLoading={isLoadingDeliveredRequests}
              count={deliveredRequests?.length ?? 0}
              isActive={type === "delivered"}
            />
          </TabsTrigger>
          <TabsTrigger value="all">
            <TabHeader
              title={t("requests.type.all")}
              isLoading={isLoadingAllRequests}
              count={allRequests?.length ?? 0}
              isActive={type === "all"}
            />
          </TabsTrigger>
        </TabsList>
        <TabsContent data-test="new" value="new" className="mt-4">
          <NewRequestsContent />
        </TabsContent>
        <TabsContent data-test="in-progress" value="in-progress" className="mt-4">
          <InProgressRequestsContent />
        </TabsContent>
        <TabsContent data-test="delivered" value="delivered" className="mt-4">
          <DeliveredRequestsContent />
        </TabsContent>
        <TabsContent data-test="all" value="all" className="mt-4">
          <AllRequestsContent />
        </TabsContent>
      </Tabs>
    </PageShell>
  );
}

function TabHeader({
  title,
  isLoading,
  count,
  isActive,
}: {
  title: string;
  isLoading: boolean;
  count: number;
  isActive: boolean;
}) {
  if (isLoading) {
    return (
      <div className="inline-flex flex-nowrap items-center gap-2">
        <span>{title}</span>
        <Loader2Icon className="size-4 shrink-0 animate-spin" />
      </div>
    );
  }

  return (
    <div className="inline-flex flex-nowrap items-center gap-2">
      <span>{title}</span>
      {count > 0 ? (
        <Badge variant={isActive ? "default" : "muted"} className="px-1.5 py-px">
          {count}
        </Badge>
      ) : null}
    </div>
  );
}
