import { Loader2Icon, TrashIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../ui/alert-dialog";
import { useState } from "react";
import { Button } from "../../ui/button";
import { cn } from "@/utils/ui";

interface DatatableDeleteActionProps {
  selectedRowCount: number;
  onConfirmDeletion: () => Promise<boolean> | undefined;
  className?: string;
}

export function DatatableDeleteAction({ selectedRowCount, onConfirmDeletion, className }: DatatableDeleteActionProps) {
  const { t } = useTranslation("translation");
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  return (
    <>
      <Button variant="outline" onClick={() => setIsDeleteConfirmOpen(true)} className={cn("max-sm:w-full", className)}>
        {t("data-table.delete-selected")}
        <TrashIcon className="ml-2 size-4" />
      </Button>

      <DatatableDeleteConfirmDialog
        selectedRowCount={selectedRowCount}
        isOpen={isDeleteConfirmOpen}
        setIsOpen={setIsDeleteConfirmOpen}
        onConfirm={onConfirmDeletion}
      />
    </>
  );
}

interface DatatableDeleteConfirmDialogProps {
  selectedRowCount: number;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onConfirm: () => Promise<boolean> | undefined;
}

export function DatatableDeleteConfirmDialog({
  selectedRowCount,
  isOpen,
  setIsOpen,
  onConfirm,
}: DatatableDeleteConfirmDialogProps) {
  const { t } = useTranslation("translation");
  const [isDeleting, setIsDeleting] = useState(false);

  function handleDelete() {
    setIsDeleting(true);
    onConfirm()?.then(() => {
      setIsOpen(false);
      setIsDeleting(false);
    });
  }

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("mt.areYouSure")}</AlertDialogTitle>
          <AlertDialogDescription>{t("mt.file.deleteManyAlert", { count: selectedRowCount })}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isDeleting} onClick={() => setIsOpen(false)}>
            {t("common.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={isDeleting}
            onClick={() => handleDelete()}
            variant="destructive"
            className="inline-flex flex-nowrap items-center gap-2"
          >
            {isDeleting && <Loader2Icon className="animate-spin" />}
            {t("mt.file.deleteFiles")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
