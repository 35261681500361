import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

export const TextMachineTranslationFormSchema = z.object({
  sourceLanguage: z
    .object({
      label: z.string(),
      code: z.string(),
      mappedCode: z.string(),
      supportsTerminology: z.boolean(),
    })
    .optional(),
  targetLanguage: z
    .object({
      label: z.string(),
      code: z.string(),
      mappedCode: z.string(),
      supportsTerminology: z.boolean(),
    })
    .optional(),
  sourceText: z.string(),
  targetText: z.string(),
});

export type TextMachineTranslationFormType = z.infer<typeof TextMachineTranslationFormSchema>;

export const useTextMachineTranslationForm = (defaultValues?: TextMachineTranslationFormType) => {
  return useForm<TextMachineTranslationFormType>({
    resolver: zodResolver(TextMachineTranslationFormSchema),
    defaultValues,
  });
};
