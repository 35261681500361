import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from "./ui/tooltip";

interface Props {
  text: string;
  side?: "left" | "top" | "right" | "bottom";
  children: string | React.JSX.Element;
}

const PortalTooltip = ({ text, side, children }: Props) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent side={side ?? "top"}>
        {text}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};

export default PortalTooltip;
