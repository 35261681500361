import { ButtonIcon } from "@/components/button/ButtonIcon";
import Loader from "components/Loader";
import { Tooltip, TooltipArrow, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip";
import { ClipboardCheck, PlusCircle, RefreshCwIcon } from "lucide-react";
import { MachineTranslationResult } from "model/mt.typing";
import useWorklist from "pages/machinetranslation/useWorklist";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import useGlossary from "./glossary/useGlossary";

interface TargetTextToolsProps {
  isTranslating: boolean;
  targetCount: number;
  targetText: string | undefined;
  translationResults: MachineTranslationResult | undefined;
  processTranslation: () => void;
}

interface SourceTextToolsProps {
  isDetecting: boolean;
  charCount: number | "" | undefined;
}

export const TargetTextTools = (props: TargetTextToolsProps) => {
  const { t } = useTranslation();
  const { isTranslating, targetCount, targetText, translationResults, processTranslation } = props;

  const { terminologyConfiguration, terminologyStoredSettings, updateTerminologyStoredSettings } = useGlossary();

  const copyToClipboard = () => {
    if (targetText) {
      navigator.clipboard.writeText(targetText).then();
      toast.success(t("mt.copiedToClipboard"));
    }
  };

  const { addItem } = useWorklist();

  const handleRefresh = () => {
    updateTerminologyStoredSettings({ ...terminologyStoredSettings, terminologyChanged: false });
    processTranslation();
  };

  return (
    <div className="mt-1 flex min-h-5 w-full items-center justify-between gap-2 px-1 text-muted-foreground">
      {!isTranslating && targetCount > 0 ? (
        <TooltipProvider delayDuration={0}>
          <div className="inline-flex items-center gap-1">
            <Tooltip>
              <TooltipTrigger asChild>
                <ButtonIcon icon={ClipboardCheck} onClick={() => copyToClipboard()} />
              </TooltipTrigger>
              <TooltipContent>
                {t("mt.copyToClipboard")}
                <TooltipArrow />
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <ButtonIcon icon={PlusCircle} onClick={() => translationResults && addItem(translationResults)} />
              </TooltipTrigger>
              <TooltipContent>
                {t("mt.addToWorklist")}
                <TooltipArrow />
              </TooltipContent>
            </Tooltip>
            {terminologyConfiguration.terminologyAvailable && terminologyStoredSettings.terminologyChanged && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <ButtonIcon icon={RefreshCwIcon} onClick={() => handleRefresh()} />
                </TooltipTrigger>
                <TooltipContent>
                  {t("mt.refreshTranslation")}
                  <TooltipArrow />
                </TooltipContent>
              </Tooltip>
            )}
          </div>
        </TooltipProvider>
      ) : null}

      {/* Removing target word count for now
        !isTranslating && targetCount > 0 ? <div className="text-nowrap text-sm">{targetCount} words</div> : null
      */}
    </div>
  );
};

export const SourceTextTools = (props: SourceTextToolsProps) => {
  const { t } = useTranslation();
  const { isDetecting, charCount } = props;

  return (
    <div className="mt-1 flex min-h-5 w-full items-center justify-between gap-2 px-1 text-sm text-muted-foreground">
      <div>
        {isDetecting && (
          <div className="inline-flex flex-row flex-nowrap items-center gap-1">
            <Loader isShown={true} className="p-0" variant="md" />
            {t("mt.autoDetectingSourceLanguage")}
          </div>
        )}
      </div>
      <div>
        {typeof charCount === "number" && charCount > 0
          ? `${charCount} / ${t("mt.maxCharacters")}`
          : t("mt.maxCharacters")}
      </div>
    </div>
  );
};
