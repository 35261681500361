import { useFileUploadStore } from "../../useFileUploads";
import { t } from "i18next";
import Loader from "@/components/Loader";
import { Alert } from "@/components/ui/alert";

export const FileUploadStatus = () => {
  const { files } = useFileUploadStore();

  if (files.length === 0) return null;

  const buildUploadMessage = (fileCount: number) => {
    return fileCount == 1 ? t("mt.file.uploadingFiles_one") : t("mt.file.uploadingFiles_other", { count: fileCount });
  };

  return (
    <Alert className="mb-4 flex w-full p-2 opacity-75" variant={"info"}>
      <Loader className="flex-1" isShown={true} variant="sm" />
      <p className="ml-3 flex-1">{buildUploadMessage(files.length)}</p>
    </Alert>
  );
};
