import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { clsx } from "clsx";
import { MinimizeIcon } from "lucide-react";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResponsiveContainer } from "recharts";

export interface Props {
  id: string;
  title: string;
  description?: string;
  height: number;
  children: ReactElement;
  className?: string;
}

export function AnalysisChartCard({ id, title, description, children, height, className }: Props) {
  const { t } = useTranslation();
  const [fullScreen, setFullScreen] = useState(false);
  return (
    <Card
      data-test={`chart-${id}-container`}
      className={clsx({ "fixed inset-x-0 top-0 z-40 min-h-full px-6": fullScreen }, "rounded-3xl p-6", className)}
    >
      <CardHeader>
        <CardTitle className="flex items-center justify-between gap-2">
          <div data-test={`chart-${id}-title`} className="flex-1">
            {title}
          </div>
          <div data-test={`chart-${id}-actions`} className="hidden items-center gap-2">
            {fullScreen ? (
              <Button
                data-test={`chart-${id}-minimize-action`}
                size="sm"
                variant="secondary"
                className="flex cursor-pointer items-center gap-2 print:hidden"
                onClick={() => setFullScreen(!fullScreen)}
              >
                <MinimizeIcon className="size-4" />
                <span>{t("reports.charts.options.exitFullScreen")}</span>
              </Button>
            ) : null}
          </div>
        </CardTitle>
        {description ? <CardDescription>{description}</CardDescription> : null}
      </CardHeader>
      <CardContent data-test={`chart-${id}-content`} style={{ height: fullScreen ? "calc(100vh - 200px)" : height }}>
        <div className="flex size-full flex-col">
          <ResponsiveContainer width="100%" height="100%">
            {children}
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
}

export function AnalysisEmptyPlaceholder({
  id,
  height,
  title,
  description,
  illustration,
  message,
  className = "mt-6",
}: {
  id: string;
  height?: number;
  title: string;
  description?: string;
  message?: string;
  illustration?: JSX.Element;
  className?: string;
}) {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.analysis" });
  return (
    <Card data-test={`chart-${id}-container`} className={clsx("rounded-3xl p-6", className)}>
      <CardHeader>
        <CardTitle className="flex items-center justify-between gap-2">
          <div className="flex-1">{title}</div>
        </CardTitle>
        {description ? <CardDescription>{description}</CardDescription> : null}
      </CardHeader>
      <CardContent
        data-test={`empty-chart-${id}-content`}
        style={{ height: height ?? 400, display: "flex", flexDirection: "column" }}
      >
        <div className="flex grow flex-col items-center justify-center rounded-2xl">
          {illustration ?? null}
          <div className="mt-4 text-center">
            <p className="text-lg font-medium text-gray-500 sm:text-xl">{t("dataNotReady")}</p>
            {message && <p className="text-sm text-gray-500 sm:text-base">{message}</p>}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
