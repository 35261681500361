import { IllustrationTmChart } from "@/components/svgs/Illustrations";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { TmLeverageChartNames, TmLeveragePerLanguagesType } from "@/model/report.typing";
import { ChartCardSkeleton } from "@/pages/reports/common/ChartSkeleton";
import { ChartTooltipCursor } from "@/pages/reports/common/ChartTooltipCursor";
import { useTmLeverageChartViewFilter } from "@/pages/reports/reportStore";
import { ChartCategoryInfo } from "@/pages/reports/tmLeverage/common/ChartComponents";
import { applyFuzzyNoMatchCalculation } from "@/pages/reports/tmLeverage/useTmLeverageFilter";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { chain, sumBy } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Bar, BarChart, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { AnalysisChartCard, AnalysisEmptyPlaceholder } from "./AnalysisChartCard";

export function AnalysisTmPerLanguage() {
  const { filteredData, isLoading } =
    useOutletContext<{ isLoading: boolean; filteredData: TmLeveragePerLanguagesType[] }>() ?? {};
  if (isLoading) return <ChartCardSkeleton />;
  if (filteredData === undefined) return null;
  return <AnalysisTmPerLanguageChart data={filteredData} height={600} />;
}

export function AnalysisTmPerLanguageChart({
  data,
  height = 600,
}: {
  height?: number;
  data: TmLeveragePerLanguagesType[];
}) {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.analysis" });
  const formatNumber = useAuthUserStore((state) => state.formatNumber);
  const chartViewStates = useTmLeverageChartViewFilter();
  const chartViewState = chartViewStates[TmLeverageChartNames.perLanguage];
  const perLanguageData = useMemo(
    () =>
      chain(applyFuzzyNoMatchCalculation(TmLeverageChartNames.perLanguage, data))
        .groupBy((value) => `${value.sourceCode}${value.targetCode}`)
        .map((items) => {
          const xTranslated = sumBy(items, "xTranslated");
          const repsAnd100 = sumBy(items, "repsAnd100");
          const fuzzy = sumBy(items, "fuzzy");
          const noMatch = sumBy(items, "noMatch");
          return {
            targetName: items[0].targetName,
            targetCode: items[0].targetCode,
            xTranslated,
            repsAnd100,
            fuzzy,
            noMatch,
            words: sumBy(items, "words"),
          };
        })
        .orderBy("targetCode")
        .value(),
    [data]
  );

  const renderTooltipContent = useCallback(
    ({ active, payload }: TooltipProps<ValueType, NameType>) => {
      if (!active || !payload?.[0]?.payload) return null;
      const data = payload[0].payload;
      return <ChartToolTip data={data} formatNumber={formatNumber} />;
    },
    [formatNumber]
  );

  if (perLanguageData.length === 0)
    return (
      <AnalysisEmptyPlaceholder
        id="tm-leverage"
        height={height}
        title={t("tmMemoryLeverage")}
        illustration={<IllustrationTmChart />}
        message={t("processingTmData")}
        className="mt-6"
      />
    );

  return (
    <AnalysisChartCard height={height} title={t("tmMemoryLeverage")} id="tm-leverage" className="mt-6">
      <BarChart
        data={perLanguageData}
        margin={{
          top: 20,
          left: 40,
          right: 25,
          bottom: 10,
        }}
      >
        <XAxis
          dataKey={"targetCode"}
          className="text-xs font-medium text-secondary-foreground"
          tickFormatter={(value) => value}
          angle={310}
          minTickGap={15}
          height={45}
          dy={12}
          dx={-18}
        />
        <YAxis tickFormatter={(value) => formatNumber(value)} />
        <Tooltip cursor={<ChartTooltipCursor variant={"blue"} />} content={renderTooltipContent} />
        {chartViewState.xTranslated && <Bar stackId="a" barSize={32} dataKey="xTranslated" fill="#61A3FF" />}
        {chartViewState.repsAnd100 && <Bar barSize={32} dataKey="repsAnd100" stackId={"a"} fill="#67E3F9" />}
        {chartViewState.fuzzy && <Bar barSize={32} dataKey="fuzzy" stackId={"a"} fill="#FDE272" />}
        {chartViewState.noMatch && <Bar barSize={32} dataKey="noMatch" stackId={"a"} fill="#C3B5FD" />}
      </BarChart>
    </AnalysisChartCard>
  );
}

const ChartToolTip = ({
  data,
  formatNumber,
}: {
  data: TmLeveragePerLanguagesType;
  formatNumber: (value: number) => string;
}) => (
  <Card>
    <CardHeader className="px-2 py-1 text-base">
      <CardTitle className="flex flex-col gap-1 text-base">
        <span>
          {data.targetName} {data.targetCode}
        </span>
        <div className="grid grid-cols-[min-content_auto] gap-2">
          <ChartCategoryInfo prefix={"noMatch"} />
          <span className="text-right">{formatNumber(data.noMatch)}</span>
          <ChartCategoryInfo prefix={"fuzzy"} />
          <span className="text-right">{formatNumber(data.fuzzy)}</span>
          <ChartCategoryInfo prefix={"repsAnd100"} />
          <span className="text-right">{formatNumber(data.repsAnd100)}</span>
          <ChartCategoryInfo prefix={"xTranslated"} />
          <span className="text-right">{formatNumber(data.xTranslated)}</span>
          <Separator className="col-span-2" />
          <ChartCategoryInfo prefix={"words"} />
          <span className="text-right">{formatNumber(data.words)}</span>
        </div>
      </CardTitle>
    </CardHeader>
  </Card>
);
