import * as i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { languages } from "./languages";

void i18next.use(initReactI18next).init({
  defaultNS: "translation",
  ns: ["translation", "error"],
  fallbackNS: "translation",
  fallbackLng: "en-US",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: (value, format, lng) => {
      if (value === null || value === undefined) {
        return "-";
      }
      if (format === "number" && typeof value === "number") {
        return value.toLocaleString(lng);
      }
      return value as string;
    },
  },
  resources: languages,
});
