import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { TmLeverageChartNames, TmLeveragePerLanguagesType } from "@/model/report.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { chain, sumBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Bar, BarChart, Brush, CartesianGrid, Rectangle, Tooltip, XAxis, YAxis } from "recharts";
import { ChartCard } from "../common/ChartCard";
import { ChartEmptyPlaceholder } from "../common/ChartEmptyPlaceholder";
import { ChartCardSkeleton } from "../common/ChartSkeleton";
import { ChartTooltipCursor } from "../common/ChartTooltipCursor";
import { useChartBrushIndexes } from "../common/useChartBrushIndexes";
import { useTmLeverageActions, useTmLeverageChartSettings, useTmLeverageChartViewFilter } from "../reportStore";
import { ChartCategoryInfo, ChartFilter } from "./common/ChartComponents";
import { applyFuzzyNoMatchCalculation } from "./useTmLeverageFilter";

export function TmLeveragePerMonthPercentagePage() {
  const { filteredData, isLoading } =
    useOutletContext<{ isLoading: boolean; filteredData: TmLeveragePerLanguagesType[] }>() ?? {};

  if (isLoading) return <ChartCardSkeleton />;
  if (filteredData === undefined) return null;

  return <TmLeveragePerMonthPercentageChart data={filteredData} isFocusView={true} height={600} />;
}

export function TmLeveragePerMonthPercentageChart({
  data,
  height = 600,
  isFocusView,
}: {
  height?: number;
  data: TmLeveragePerLanguagesType[];
  isFocusView: boolean;
}) {
  const { t } = useTranslation();
  const formatNumber = useAuthUserStore((state) => state.formatNumber);
  const formatDate = useAuthUserStore((state) => state.formatDate);
  const chartViewSates = useTmLeverageChartViewFilter();
  const chartViewSate = chartViewSates[TmLeverageChartNames.perMonthPercentage];
  const { saveChartSettings } = useTmLeverageActions();
  const chartSettings = useTmLeverageChartSettings(TmLeverageChartNames.perMonthPercentage);

  const [brushStartEndIndex, setBrushStartEndIndex] = useChartBrushIndexes();

  const perMonthData = useMemo(
    () =>
      chain(applyFuzzyNoMatchCalculation(TmLeverageChartNames.perMonthPercentage, data))
        .groupBy((data) => {
          const date = new Date(data.deliveryDate);
          return `${date.getMonth() + 1}/${date.getFullYear()}`;
        })
        .map((items) => {
          const total = sumBy(items, "words");

          const calculatePercentage = (value: number) => {
            const result = total > 0 ? Math.floor((value / total) * 100) : 0;
            return result;
          };

          const xTranslated = calculatePercentage(sumBy(items, "xTranslated"));
          const repsAnd100 = calculatePercentage(sumBy(items, "repsAnd100"));
          const fuzzy = calculatePercentage(sumBy(items, "fuzzy"));

          return {
            monthYear: items[0].deliveryDate,
            xTranslated,
            repsAnd100,
            fuzzy,
            noMatch: 100 - (xTranslated + repsAnd100 + fuzzy),
            words: total,
          };
        })
        .orderBy("monthYear")
        .map((value) => ({ ...value, brushTickName: formatDate(value.monthYear, { dateFormat: "MMM yy" }) }))
        .value(),
    [data, formatDate]
  );

  if (perMonthData.length === 0) {
    return <ChartEmptyPlaceholder />;
  }

  return (
    <div>
      <ChartCard
        height={height}
        title={t("reports.tmleverage.charts.per-month-percentage")}
        link={TmLeverageChartNames.perMonthPercentage}
        isFocusView={isFocusView}
        chartSettings={chartSettings}
        saveChartSettings={saveChartSettings}
        renderBeforeChart={() => <ChartFilter chartType={TmLeverageChartNames.perMonthPercentage} />}
      >
        {({ displayGrid, displayBrush }) => {
          return (
            <BarChart
              data={perMonthData}
              margin={{
                top: 20,
                left: 25,
                right: 25,
                bottom: 10,
              }}
            >
              {displayGrid ? <CartesianGrid strokeDasharray="3 3" /> : null}
              {displayBrush ? (
                <Brush
                  height={20}
                  travellerWidth={5}
                  onChange={setBrushStartEndIndex}
                  startIndex={brushStartEndIndex.startIndex}
                  endIndex={brushStartEndIndex.endIndex}
                  className="text-sm"
                  fill="#cbd5e1"
                  stroke="#374151"
                  dataKey="brushTickName"
                />
              ) : null}
              <XAxis
                dataKey={"monthYear"}
                className="text-xs font-medium text-secondary-foreground"
                tickFormatter={(value) => {
                  return formatDate(value, { dateFormat: "MMM yy" });
                }}
                angle={320}
                minTickGap={15}
                height={50}
                dy={18}
                dx={-18}
              />
              <YAxis tickFormatter={(value) => formatNumber(value)} />
              <Tooltip
                cursor={<ChartTooltipCursor variant={"blue"} />}
                content={({ payload }) => {
                  if (!payload?.[0]?.payload) return null;
                  const data = payload[0].payload;
                  return (
                    <ChartToolTip
                      data={data}
                      formatNumber={formatNumber}
                      formatDate={(date) => formatDate(date, { dateFormat: "MMMM yyyy" })}
                    />
                  );
                }}
              />
              {chartViewSate.xTranslated ? (
                <Bar
                  stackId="a"
                  dataKey="xTranslated"
                  className="fill-blue-400 stroke-blue-500 dark:fill-blue-700 dark:stroke-blue-800"
                  activeBar={<Rectangle className="fill-blue-600 stroke-blue-700" />}
                />
              ) : null}
              {chartViewSate.repsAnd100 ? (
                <Bar
                  dataKey="repsAnd100"
                  stackId={"a"}
                  className="fill-cyan-400 stroke-cyan-500 dark:fill-cyan-700 dark:stroke-cyan-800"
                  activeBar={<Rectangle className="fill-cyan-600 stroke-cyan-700" />}
                />
              ) : null}
              {chartViewSate.fuzzy ? (
                <Bar
                  dataKey="fuzzy"
                  stackId={"a"}
                  className="fill-amber-400 stroke-amber-500 dark:fill-amber-700 dark:stroke-amber-800"
                  activeBar={<Rectangle className="fill-amber-600 stroke-amber-700" />}
                />
              ) : null}
              {chartViewSate.noMatch ? (
                <Bar
                  dataKey="noMatch"
                  stackId={"a"}
                  className="fill-violet-400 stroke-violet-500 dark:fill-violet-700 dark:stroke-violet-800"
                  activeBar={<Rectangle className="fill-violet-600 stroke-violet-700" />}
                />
              ) : null}
            </BarChart>
          );
        }}
      </ChartCard>
    </div>
  );
}

function ChartToolTip({
  data,
  formatDate,
  formatNumber,
}: {
  data: TmLeveragePerLanguagesType & { monthYear: Date };
  formatDate: (date: Date) => string;
  formatNumber: (value: number) => string;
}) {
  return (
    <Card>
      <CardHeader className="px-2 py-1 text-base">
        <CardTitle className="flex flex-col gap-1 text-base">
          <span>{formatDate(data.monthYear)}</span>

          <div className="grid grid-cols-[min-content_auto] gap-2">
            <ChartCategoryInfo prefix={"noMatch"}></ChartCategoryInfo>
            <span className="text-right">{`${data.noMatch}%`}</span>
            <ChartCategoryInfo prefix={"fuzzy"}></ChartCategoryInfo>
            <span className="text-right">{`${data.fuzzy}%`}</span>
            <ChartCategoryInfo prefix={"repsAnd100"}></ChartCategoryInfo>
            <span className="text-right">{`${data.repsAnd100}%`}</span>
            <ChartCategoryInfo prefix={"xTranslated"}></ChartCategoryInfo>
            <span className="text-right">{`${data.xTranslated}%`}</span>
            <div className="col-span-2">
              <Separator />
            </div>
            <ChartCategoryInfo prefix={"words"}></ChartCategoryInfo>
            <span className="text-right">{formatNumber(data.words)}</span>
          </div>
        </CardTitle>
      </CardHeader>
    </Card>
  );
}
