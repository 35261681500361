import { useCallback } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { NewRequestFormType } from "./useNewRequestForm";

const stepCodes = ["services", "upload", "details", "summary"];

export const fieldsToStep: Record<string, string> = {
  service: "services",
  sourceFiles: "upload",
  referenceFiles: "details",
  invoicingReference: "details",
  name: "details",
  deadline: "details",
  customFields: "details",
  requestQuote: "summary",
  quoteDueDate: "summary",
};

export const useStepperState = (form: UseFormReturn<NewRequestFormType>) => {
  const { t } = useTranslation("translation", { keyPrefix: "requests.create.wizard" });

  const stepsOnError = Array.from(
    new Set(Object.keys(form.formState.errors).map((fieldName) => fieldsToStep[fieldName]))
  );

  const stepsCompleted = Array.from(
    new Set(
      Object.entries(form.formState.dirtyFields)
        .filter(([fieldName, value]) => {
          return (
            (value === true || (Array.isArray(value) && value.length > 0)) &&
            !stepsOnError.includes(fieldsToStep[fieldName])
          );
        })
        .map(([fieldName]) => fieldsToStep[fieldName])
    )
  );

  const steps = stepCodes.map((stepCode) => ({
    stepCode,
    stepName: t(`${stepCode}`),
    isCompleted: stepsCompleted.includes(stepCode),
    isInvalid: stepsOnError.includes(stepCode),
  }));

  const canSwitchToStep = useCallback(
    (currentStep: string): boolean => {
      const { sourceFiles, referenceFiles } = form.getValues();
      const filesToCheck = currentStep === "upload" ? sourceFiles : currentStep === "details" ? referenceFiles : [];

      const isFileUploading = filesToCheck.some((file) => file.status === "uploading");

      if (isFileUploading) {
        toast.message(t("waitUntilUpload"), { id: "waitUntilUpload" });
      }

      return !isFileUploading;
    },
    [form, t]
  );

  return { steps, canSwitchToStep };
};
