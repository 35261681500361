import type { RequestFinancialType, RequestListType } from "@/model/request.typing";

/**
 * Determines if a quote can be accepted or rejected based on the quote's properties and request state
 * @param item - The financial quote item to check
 * @param requestType - The type of request (new, in-progress, delivered, all)
 * @param requestStatus - The current status of the request
 * @returns boolean - True if the quote can be accepted/rejected, false otherwise
 */
export function canAcceptOrRejectQuote(
  item: RequestFinancialType,
  requestType: RequestListType | undefined,
  requestStatus: string | null
): boolean {
  // First check if the quote itself allows acceptance/rejection
  if (!item.canBeAcceptedOrRejected) {
    return false;
  }

  // Validate required parameters
  if (!requestType || !requestStatus) {
    return false;
  }

  // Check if the request type and status combination prevents acceptance/rejection
  if (
    ["in-progress", "delivered", "all"].includes(requestType) &&
    ["in-progress", "files-archived", "invoiced", "ready-for-invoice", "delivery-complete"].includes(requestStatus)
  ) {
    return false;
  }

  return true;
}