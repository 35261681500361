import {
  NewRequestIllustration,
  NewRequestQuoteIllustration,
  NewRequestSuccessIllustration,
} from "@/components/svgs/Illustrations";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
} from "@/components/ui/alert-dialog";
import { AlertDialogTitle } from "@radix-ui/react-alert-dialog";
import { ReactNode } from "react";

export interface ConfirmSubmitDialogProps {
  defaultOpen: boolean;
  illustrationName?: "newRequest" | "newRequestSuccess" | "newRequestQuote";
  title: string;
  description: string;
  renderContent?: () => ReactNode;
  renderActions: () => ReactNode;
  renderErrors?: () => ReactNode;
}

export default function ConfirmSubmitDialog({
  defaultOpen,
  illustrationName,
  title,
  description,
  renderContent,
  renderActions,
  renderErrors,
}: ConfirmSubmitDialogProps) {
  const illustration = getIllustration(illustrationName);

  return (
    <AlertDialog defaultOpen={defaultOpen}>
      <AlertDialogContent className="gap-6 p-6">
        {illustration ? (
          <div className="-mx-6 -mt-6 hidden flex-row justify-center rounded bg-blue-75 py-3 sm:flex">
            {illustration}
          </div>
        ) : null}

        <AlertDialogHeader className="pt-3">
          <AlertDialogTitle className="text-center text-2xl font-semibold sm:text-3xl">{title}</AlertDialogTitle>
          <AlertDialogDescription className="px-6 text-center text-foreground">{description}</AlertDialogDescription>
        </AlertDialogHeader>
        {renderContent?.()}
        {renderErrors?.()}
        <AlertDialogFooter className="sm:justify-center">{renderActions()}</AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function getIllustration(illustrationName?: string) {
  if (!illustrationName) return null;
  switch (illustrationName) {
    case "newRequest":
      return <NewRequestIllustration />;
    case "newRequestSuccess":
      return <NewRequestSuccessIllustration />;
    case "newRequestQuote":
      return <NewRequestQuoteIllustration />;
    default:
      return null;
  }
}
