import { PageHeaderShell } from "@/components/shell/PageHeaderShell";
import { PageShell } from "@/components/shell/PageShell";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { BarChartBigIcon, LucideIcon, TableIcon } from "lucide-react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

interface Props {
  icon: LucideIcon;
  i18nKeyPrefix: string; // use it also as testId
  pathNames: {
    isDashboard: boolean;
    currentReportPathName: string;
    reportsPathName: string[];
  };
  renderActions?: () => JSX.Element;
}

export function ReportLayout({ children, icon, pathNames, i18nKeyPrefix, renderActions }: PropsWithChildren<Props>) {
  return (
    <PageShell data-test={`${i18nKeyPrefix}-page`}>
      <PageHeaderShell
        data-test={`${i18nKeyPrefix}-header`}
        icon={icon}
        heading={
          <SelectReport
            i18nKeyPrefix={i18nKeyPrefix}
            isDashboard={pathNames.isDashboard}
            currentReportPathName={pathNames.currentReportPathName}
            reportsPathName={pathNames.reportsPathName}
          />
        }
      >
        <div className="flex items-center gap-2">
          <BackToDashboardButton i18nKeyPrefix={i18nKeyPrefix} isDashboard={pathNames.isDashboard} />
          {renderActions?.()}
        </div>
      </PageHeaderShell>
      <div className="flex flex-col gap-4 px-2">{children}</div>
    </PageShell>
  );
}

interface ReportViewSwitcherProps {
  tab: "charts" | "table";
  isLoading: boolean;
  onTabChange: (tab: "charts" | "table") => void;
  renderTable: () => JSX.Element;
  rendreCharts: () => JSX.Element;
}

ReportLayout.ViewSwitcher = function ReportViewSwitcher({
  tab,
  isLoading,
  onTabChange,
  renderTable,
  rendreCharts,
}: ReportViewSwitcherProps) {
  const { t } = useTranslation();

  return (
    <Tabs value={tab} onValueChange={(value) => onTabChange(value as "charts" | "table")}>
      <TabsList className="grid w-full max-w-[400px] grid-cols-2 print:hidden">
        <TabsTrigger value="charts" className="flex flex-nowrap items-center gap-1">
          {isLoading ? (
            <Skeleton className="h-5 w-28" />
          ) : (
            <>
              <BarChartBigIcon className="size-5" />
              {t("reports.toggle.chart")}
            </>
          )}
        </TabsTrigger>
        <TabsTrigger value="table" className="flex flex-nowrap items-center gap-1">
          {isLoading ? (
            <Skeleton className="h-5 w-28" />
          ) : (
            <>
              <TableIcon className="size-5" />
              {t("reports.toggle.table")}
            </>
          )}
        </TabsTrigger>
      </TabsList>
      <TabsContent data-test="cost-report-charts" value="charts" className="mt-4">
        {rendreCharts()}
      </TabsContent>
      <TabsContent data-test="cost-report-table" value="table" className="mt-4">
        {renderTable()}
      </TabsContent>
    </Tabs>
  );
};

ReportLayout.Filter = function ReportFilter({ children, isLoading }: PropsWithChildren<{ isLoading: boolean }>) {
  const { t } = useTranslation("translation", { keyPrefix: "reports.filters" });
  if (isLoading)
    return (
      <div data-test="cost-report-filter-loading" className="grid gap-4">
        <h3 className="mx-2 text-xl font-medium leading-3 text-muted-foreground">{t("title")}</h3>
        <div className="flex flex-wrap items-center gap-2">
          <Skeleton className="h-5 w-full" />
        </div>
        <div className="flex flex-wrap items-center gap-2">
          <Skeleton className="h-5 w-full" />
        </div>
      </div>
    );

  return (
    <div data-test="cost-report-filter" className="grid gap-4 print:hidden">
      <h3 data-test="cost-report-title" className="mx-2 text-xl font-medium leading-3 text-muted-foreground">
        {t("title")}
      </h3>
      {children}
    </div>
  );
};

ReportLayout.FilterReset = function ReportFilterReset({ isShown, onReset }: { isShown: boolean; onReset: () => void }) {
  const { t } = useTranslation("translation", { keyPrefix: "reports.filters" });
  if (!isShown) return null;
  return (
    <Button
      data-test="cost-report-filter-reset"
      variant="secondary"
      size="sm"
      className="print:hidden"
      onClick={onReset}
    >
      {t("reset")}
    </Button>
  );
};

function BackToDashboardButton({ isDashboard, i18nKeyPrefix }: { isDashboard: boolean; i18nKeyPrefix: string }) {
  const { t } = useTranslation();
  const { search } = useLocation();

  return !isDashboard ? (
    <Button data-test={`${i18nKeyPrefix}-back-action`} variant="secondary" className="print:hidden" size="lg" asChild>
      <Link to={`.${search}`}>{t("reports.backToDashboard")}</Link>
    </Button>
  ) : null;
}

function SelectReport({
  isDashboard,
  currentReportPathName,
  reportsPathName,
  i18nKeyPrefix,
}: {
  isDashboard: boolean;
  currentReportPathName: string;
  reportsPathName: string[];
  i18nKeyPrefix: string;
}) {
  const { t } = useTranslation("translation", { keyPrefix: `reports.${i18nKeyPrefix}` });
  const { search } = useLocation();

  const navigate = useNavigate();

  if (isDashboard) {
    return <span data-test={`${i18nKeyPrefix}-title-dashboard`}>{t("dashboard")}</span>;
  }

  return (
    <div className="inline-flex flex-wrap items-center gap-2">
      <span data-test={`${i18nKeyPrefix}-title-part`}>{t("page-title")} -</span>
      <Select
        data-test={`${i18nKeyPrefix}-title-select`}
        key={currentReportPathName}
        value={currentReportPathName}
        onValueChange={(value) => navigate(value + search)}
      >
        <SelectTrigger className="h-12 w-fit gap-4 text-3xl focus:ring-0 focus:ring-offset-0 md:h-14 md:text-4xl">
          <SelectValue data-test={`${i18nKeyPrefix}-title-select-value`} placeholder="light" />
        </SelectTrigger>
        <SelectContent>
          {reportsPathName.map((path) => (
            <SelectItem
              data-test={`${i18nKeyPrefix}-title-select-item`}
              data-test-value={path}
              key={path}
              value={path}
              className="capitalize md:text-base"
            >
              {t(`reports.${path}`)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
