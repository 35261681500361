import { cn } from "@/utils/ui";
import { Button } from "components/ui/button";
import type { LucideIcon } from "lucide-react";
import { type ButtonHTMLAttributes, forwardRef } from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: LucideIcon;
}

const ButtonIcon = forwardRef<HTMLButtonElement, Props>(({ icon: Icon, className, ...props }, ref) => {
  return (
    <Button type="button" ref={ref} variant="link" size="icon" className={cn("group size-5", className)} {...props}>
      <Icon className="size-full shrink-0 text-muted-foreground group-hover:text-foreground" />
    </Button>
  );
});
ButtonIcon.displayName = "ButtonIcon";

export { ButtonIcon };
