import ErrorLayoutPage from "routes/layout/ErrorLayoutPage";
import { useTranslation } from "react-i18next";

function NotFoundPage() {
  const { t } = useTranslation("error");
  return (
    <ErrorLayoutPage errorCode={404} title={t("page.notfound.title")} description={t("page.notfound.description")} />
  );
}

export default NotFoundPage;
