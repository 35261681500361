import { IconName, library } from "@fortawesome/fontawesome-svg-core";
import {
  faBookOpen,
  faChartBar,
  faChartLine,
  faCog,
  faCommentDollar,
  faDatabase,
  faFileAlt,
  faFileArchive,
  faFolderOpen,
  faFolderTree,
  faLanguage,
  faMoneyBill,
  faPercent,
  faQuestionCircle,
  faStar,
  faUniversity,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import {
  BarChart3Icon,
  BarChartBigIcon,
  CirclePercentIcon,
  FileArchiveIcon,
  FileBarChart2Icon,
  HomeIcon,
  LanguagesIcon,
  PlusIcon,
  UserCog,
} from "lucide-react";
import { useMemo } from "react";

library.add(
  faFileAlt,
  faFileArchive,
  faFolderOpen,
  faDatabase,
  faChartLine,
  faFolderTree,
  faLanguage,
  faBookOpen,
  faChartBar,
  faQuestionCircle,
  faCog,
  faStar,
  faMoneyBill,
  faUsers,
  faUniversity,
  faCommentDollar,
  faPercent
);

type Props = Omit<FontAwesomeIconProps, "icon"> & { icon: string };
export function FaIcon({ icon, ...props }: Props) {
  const iconName = useMemo(() => {
    if (!icon) return null;
    const [, iconNameValue] = icon.split(" ");
    if (!iconNameValue) return null;
    return iconNameValue.replace(/^fa-/, "") as IconName;
  }, [icon]);

  if (!iconName) return null;

  return <FontAwesomeIcon {...props} icon={iconName} />;
}

// names should be the same as the path in the AppRoutes
export const MENU_ICONS = {
  home: HomeIcon,
  machinetranslation: LanguagesIcon,
  cost: BarChartBigIcon,
  tmleverage: CirclePercentIcon,
  downloadReports: FileBarChart2Icon,
  reports: BarChart3Icon,
  requests: FileArchiveIcon,
  profile: UserCog,
  createRequest: PlusIcon,
};
