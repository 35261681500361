import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ErrorLayoutPage from "routes/layout/ErrorLayoutPage";

function ServerErrorPage() {
  const { t } = useTranslation("error");
  const { state } = useLocation();

  return (
    <ErrorLayoutPage
      errorCode={500}
      title={t("page.server.title")}
      description={state && state.message ? t(state.message) : t("page.server.description")}
    />
  );
}

export default ServerErrorPage;
