import { forwardRef } from "react";
import TextareaAutosize from "react-textarea-autosize";

type Props = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "style">;

const TextareaAutoSize = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  return (
    <TextareaAutosize
      ref={ref}
      {...props}
      className="scrollbar-hide flex min-h-80 w-full rounded-md border border-input bg-transparent p-2 pr-8 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:min-w-80"
      dir="auto"
    />
  );
});

export { TextareaAutoSize };
