import { FormField, FormItem, FormMessage } from "components/ui/form";
import { LanguageOption } from "model/mt.typing";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../LanguageSelector";
import { FileMachineTranslationFormType } from "../../useFileMachineTranslationForm";

interface FileTargetControlsProps {
  targetLanguages: LanguageOption[] | null;
  form: UseFormReturn<FileMachineTranslationFormType>;
}

export const FileTargetControls = ({ form, targetLanguages }: FileTargetControlsProps) => {
  const { t } = useTranslation();
  return (
    <FormField
      control={form.control}
      data-test="mt-file-target-languages"
      name="targetLanguage"
      render={({ field }) => (
        <FormItem className="flex w-full flex-col">
          <LanguageSelector
            languages={targetLanguages}
            placeholder={t("mt.selectLanguage")}
            field={field}
            onSelect={(language: LanguageOption) => {
              if (language?.code != form.getValues("targetLanguage")?.code) {
                form.setValue("targetLanguage", language, { shouldValidate: true });
                localStorage.setItem("mtLastTarget", JSON.stringify(language));
              } else {
                // Clear the form element and reset the targets
                form.resetField("targetLanguage");
              }
            }}
          />
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
