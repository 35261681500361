import useGlossary from "@/pages/machinetranslation/components/text/glossary/useGlossary";
import { OcrMachineTranslationFormType } from "@/pages/machinetranslation/useOcrMachineTranslationForm";
import { TextMachineTranslationFormType } from "@/pages/machinetranslation/useTextMachineTranslationForm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addTerm,
  addTerminology,
  autoDetect,
  deleteTerm,
  deleteTerminology,
  getConfigs,
  getOcrParagraphs,
  getOcrResults,
  getTerminology,
  getTerminologyList,
  getTranslation,
  getTranslations,
  processOcr,
  translate,
  updateTerm,
  updateTerminology,
  upload,
  uploadGlossary,
  uploadOcr,
} from "api/machinetranslation.api";
import { AxiosError } from "axios";
import {
  AutoDetectResults,
  FileToTranslate,
  LanguageOption,
  MachineFileTranslationResult,
  MachineOcrTranslationResult,
  MachineTranslationConfig,
  MachineTranslationResult,
  OcrFileResult,
  OcrParagraphResult,
  OcrParagraphsResult,
  TermPayload,
  Terminology,
  TerminologyPayload,
  TerminologyUploadResult,
} from "model/mt.typing";
import { useEffect, useState } from "react";
import { FileWithPath } from "react-dropzone";
import { UseFormReturn } from "react-hook-form";
import { toast } from "sonner";
import { getWordCount, trimAutodetect } from "utils/text";

// Fetches

export const useMachineTranslationConfig = () => {
  const [sourceLanguages, setSourceLanguages] = useState<LanguageOption[]>([]);
  const [fileSourceLanguages, setFileSourceLanguages] = useState<LanguageOption[]>([]);
  const [sourceLanguagesOpen, setSourceLanguagesOpen] = useState(false);
  const [targetLanguagesOpen, setTargetLanguagesOpen] = useState(false);
  const [isTextEnabled, setIsTextEnabled] = useState<boolean>(true);
  const [isFilesEnabled, setIsFilesEnabled] = useState<boolean>(true);
  const [collectionId, setCollectionId] = useState<string>("");
  const [mtProviderType, setMtProviderType] = useState<number>(0);

  const {
    data: configuration,
    isLoading,
    error,
    status,
  } = useQuery<MachineTranslationConfig[], AxiosError>({
    queryKey: ["mtConfig"],
    queryFn: getConfigs,
  });

  function setSources(data: MachineTranslationConfig[] | undefined) {
    const sources: LanguageOption[] = [];
    const fileSources: LanguageOption[] = [];

    if (data) {
      const { languages, bannedLanguages } = data[0];

      for (const language of languages) {
        const { sourceLanguage } = language;
        const sourceDetails = {
          label: sourceLanguage.languageName,
          code: sourceLanguage.languageCode,
          isDefaultDialect: sourceLanguage.isDefaultDialect,
          mappedCode: sourceLanguage.mappedLanguageCode,
          supportsTerminology: sourceLanguage.supportsTerminology,
        };

        if (bannedLanguages && bannedLanguages.length > 0) {
          const match = bannedLanguages.find((x) => x.sourceLanguage.languageName === sourceLanguage.languageName);
          if (match && language.targetLanguages.length === match.targetLanguages.length) {
            sources.push(sourceDetails);
            continue;
          }
        }

        fileSources.push(sourceDetails);
        sources.push(sourceDetails);
      }
    }

    setFileSourceLanguages(fileSources);
    setSourceLanguages(sources);
  }

  function setEnablements(data: MachineTranslationConfig[] | undefined) {
    if (data) {
      setIsFilesEnabled(data[0].isFilesEnabled);
      setIsTextEnabled(data[0].isTextEnabled);
    }
  }

  useEffect(() => {
    if (status === "success") {
      setEnablements(configuration);
      setSources(configuration);
    }
  }, [configuration, status]);

  return {
    isLoading,
    error,
    sourceLanguages,
    fileSourceLanguages,
    configuration,
    collectionId,
    setCollectionId,
    mtProviderType,
    setMtProviderType,
    sourceLanguagesOpen,
    setSourceLanguagesOpen,
    targetLanguagesOpen,
    setTargetLanguagesOpen,
    isFilesEnabled,
    isTextEnabled,
  };
};

export const useTranslationFiles = () => {
  const {
    data: translations,
    isFetching: isFetchingFiles,
    error,
    status,
    refetch: getFileTranslations,
  } = useQuery<MachineFileTranslationResult[], AxiosError>({
    queryKey: ["translationFiles"],
    queryFn: () => getTranslations(),
  });

  return { translations, isFetchingFiles, error, status, getFileTranslations };
};

export const useTranslationFile = (fileKey: string) => {
  const {
    data: translation,
    isFetching,
    error,
    status,
    refetch: getFileTranslation,
  } = useQuery<MachineFileTranslationResult, AxiosError>({
    queryKey: ["translationFile", fileKey],
    queryFn: () => getTranslation(fileKey),
    enabled: false,
  });

  return { translation, isFetching, error, status, getFileTranslation };
};

export const useOcrParagraphs = (ocrId: string) => {
  const [sourceParagraphs, setSourceParagraphs] = useState<OcrParagraphResult[] | null>(null);

  // Declare error variable with type annotation
  let error: boolean = false;

  const {
    data: paragraphResults,
    isFetching,
    status,
  } = useQuery<OcrParagraphsResult, AxiosError>({
    queryKey: ["ocrParagraphResults", ocrId],
    queryFn: () => getOcrParagraphs(ocrId),
    refetchInterval: (data) => ((data && data.state.data?.status === "done") || error ? undefined : 5000),
  });

  error = status === "error";

  function setParagraphs(paragraphs: OcrParagraphResult[] | undefined) {
    const ps: OcrParagraphResult[] = [];
    if (paragraphs) {
      paragraphs.forEach((paragraph) => ps.push(paragraph));
    }
    setSourceParagraphs(ps);
  }

  useEffect(() => {
    if (paragraphResults?.status === "done") setParagraphs(paragraphResults.paragraphs);
  }, [paragraphResults, status]);

  return { sourceParagraphs, paragraphResults, isFetching, status };
};

export const useGetOcrResults = (ocrId: string) => {
  const [lastOcrResult, setLastOcrResult] = useState<MachineOcrTranslationResult | null>(null);

  const {
    data: ocrResults,
    status,
    mutate: fetchLastOcrResult,
  } = useMutation<MachineOcrTranslationResult, AxiosError>({
    mutationKey: ["getOcrResults", ocrId],
    mutationFn: () => getOcrResults(ocrId),
  });

  useEffect(() => {
    if (ocrResults?.status === "finished") setLastOcrResult(ocrResults);
  }, [ocrResults, status]);

  return { lastOcrResult, ocrResults, status, fetchLastOcrResult };
};

export const useGetTerminologyListQuery = (customerId: string) => {
  const {
    data: fetchedTerminologies,
    status,
    refetch,
    isFetching: isFetchingList,
  } = useQuery<Terminology[], AxiosError>({
    enabled: false,
    queryKey: ["getTerminologyList", customerId],
    queryFn: () => getTerminologyList(customerId),
  });

  return { fetchedTerminologies, status, refetch, isFetchingList };
};

// Mutations

export const useAutoDetectMutation = (text: string | undefined) => {
  const {
    data: autoDetectResults,
    isPending: isDetecting,
    error,
    status,
    mutate: attemptAutoDetect,
  } = useMutation<AutoDetectResults, AxiosError, string | null>({
    mutationKey: ["autoDetect"],
    mutationFn: () => autoDetect(trimAutodetect(text)),
  });

  return { autoDetectResults, isDetecting, error, status, attemptAutoDetect };
};

export const useProcessTranslationMutation = (
  collectionId: string,
  mtProviderType: number,
  form: UseFormReturn<TextMachineTranslationFormType>
) => {
  const [targetCount, setTargetCount] = useState(0);

  const { terminologyStoredSettings } = useGlossary();

  const {
    data: translationResults,
    isPending: isTranslating,
    status,
    mutate: processTranslation,
  } = useMutation<MachineTranslationResult, AxiosError>({
    mutationKey: ["processTranslation"],
    mutationFn: () =>
      translate(
        collectionId,
        mtProviderType,
        form.getValues("sourceLanguage"),
        form.getValues("targetLanguage"),
        form.getValues("sourceText"),
        terminologyStoredSettings.isTerminologyEnabled,
        terminologyStoredSettings.selectedTerminologyId
      ),
  });

  useEffect(() => {
    if (status === "success") {
      if (translationResults.targetText && translationResults.wordCount) {
        form.setValue("targetText", translationResults.targetText);
        setTargetCount(translationResults.wordCount);
      } else {
        toast.warning(translationResults.errorMessage);
      }
    }
  }, [status, translationResults, form]);

  const setTargetTextExternally = (text: string) => {
    form.setValue("targetText", text);
    setTargetCount(getWordCount(text));
  };

  return {
    targetCount,
    translationResults,
    setTargetTextExternally,
    isTranslating,
    status,
    processTranslation,
  };
};

export const useUploadOcrFileMutation = (clientKey: string, file: FileToTranslate | null) => {
  const [ocrFileResult, setOcrFileResult] = useState<OcrFileResult | undefined>(undefined);
  const {
    data,
    isPending: isUploadingOcr,
    error,
    status,
    mutate: uploadOcrTranslation,
  } = useMutation<OcrFileResult, AxiosError>({
    mutationKey: ["uploadOcrFile"],
    mutationFn: () => uploadOcr(clientKey, file),
  });

  useEffect(() => {
    setOcrFileResult(data);
  }, [data]);

  const resetOcrFileResult = () => {
    setOcrFileResult(undefined);
  };

  return { ocrFileResult, resetOcrFileResult, isUploadingOcr, error, status, uploadOcrTranslation };
};

type UploadTranslationFileType = {
  currentFile: FileToTranslate;
  clientKey: string;
  mtProviderType: number;
  sourceLanguage?: LanguageOption;
  targetLanguage?: LanguageOption;
};

export const useUploadTranslationFileMutation = (
  onSettled?: (file: FileToTranslate, isSuccessful: boolean, errorMessage?: string) => void
) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation<MachineOcrTranslationResult, AxiosError, UploadTranslationFileType>({
    mutationFn: ({ currentFile, clientKey, mtProviderType, sourceLanguage, targetLanguage }) =>
      upload(clientKey, mtProviderType, sourceLanguage, targetLanguage, currentFile),
    onSettled: (_, error, { currentFile }) => {
      const isSuccessful = error === null;
      let errorMessage;
      if (error?.response?.data) {
        errorMessage = error.response.data as string;
      }
      if (isSuccessful) {
        queryClient.invalidateQueries({ queryKey: ["translationFiles"] }); // trigger refetch
      }
      onSettled?.(currentFile, isSuccessful, errorMessage);
    },
  });

  return { mutate };
};

export const useProcessOcrMutation = (
  collectionId: string,
  mtProviderType: number,
  file: OcrFileResult,
  form: OcrMachineTranslationFormType
) => {
  const { terminologyStoredSettings } = useGlossary();

  const {
    data: ocrResults,
    isPending: isProcessingOcr,
    error,
    status,
    mutate: ocr,
  } = useMutation<MachineOcrTranslationResult, AxiosError>({
    mutationKey: ["processOcr"],
    mutationFn: () =>
      processOcr(
        collectionId,
        mtProviderType,
        file.fileId,
        form.sourceLanguage,
        form.targetLanguage,
        form.paragraphs,
        terminologyStoredSettings.isTerminologyEnabled,
        terminologyStoredSettings.selectedTerminologyId
      ),
  });

  return {
    ocrResults,
    isProcessingOcr,
    error,
    status,
    ocr,
  };
};

export const useGetTerminologyMutation = () => {
  const {
    data: terminology,
    isPending: isFetching,
    status,
    mutateAsync: fetchTerminology,
  } = useMutation<Terminology, AxiosError, { customerId: string; terminologyId: string }>({
    mutationKey: ["getTerminology"],
    mutationFn: async (params: { customerId: string; terminologyId: string }) =>
      await getTerminology(params.customerId, params.terminologyId),
  });

  return { terminology, status, isFetching, fetchTerminology };
};

export const useAddTerminologyMutation = () => {
  const {
    data: terminology,
    isPending: isFetchingAdd,
    status,
    mutateAsync: addNewTerminology,
  } = useMutation<Terminology, AxiosError, { customerId: string; payload: TerminologyPayload }>({
    mutationKey: ["addTerminology"],
    mutationFn: async (params: { customerId: string; payload: TerminologyPayload }) =>
      await addTerminology(params.customerId, params.payload),
  });

  return { terminology, status, isFetchingAdd, addNewTerminology };
};

export const useEditTerminologyMutation = () => {
  const {
    data: terminology,
    isPending: isFetchingEdit,
    status,
    mutateAsync: editTerminology,
  } = useMutation<Terminology, AxiosError, { customerId: string; terminologyId: string; payload: TerminologyPayload }>({
    mutationKey: ["editTerminology"],
    mutationFn: async (params: { customerId: string; terminologyId: string; payload: TerminologyPayload }) =>
      await updateTerminology(params.customerId, params.terminologyId, params.payload),
  });

  return { terminology, status, isFetchingEdit, editTerminology };
};

export const useDeleteTerminologyMutation = () => {
  const {
    data: success,
    isPending: isFetchingDelete,
    status,
    mutateAsync: removeTerminology,
  } = useMutation<boolean, AxiosError, { customerId: string; terminologyId: string }>({
    mutationKey: ["deleteTerminology"],
    mutationFn: async (params: { customerId: string; terminologyId: string }) =>
      await deleteTerminology(params.customerId, params.terminologyId),
  });

  return { success, status, isFetchingDelete, removeTerminology };
};

export const useAddTerminologyItemMutation = () => {
  const {
    data: terminology,
    isPending: isFetchingAddItem,
    status,
    mutateAsync: addNewTerminologyItem,
  } = useMutation<
    Terminology,
    AxiosError,
    { customerId: string; instanceId: string; terminologyId: string; payload: TermPayload }
  >({
    mutationKey: ["addTerminologyItem"],
    mutationFn: async (params: {
      customerId: string;
      instanceId: string;
      terminologyId: string;
      payload: TermPayload;
    }) => await addTerm(params.customerId, params.instanceId, params.terminologyId, params.payload),
  });

  return { terminology, status, isFetchingAddItem, addNewTerminologyItem };
};

export const useEditTerminologyItemMutation = () => {
  const {
    data: terminology,
    isPending: isFetchingEditItem,
    status,
    mutateAsync: editTerminologyItem,
  } = useMutation<
    Terminology,
    AxiosError,
    { customerId: string; instanceId: string; terminologyId: string; termId: string; payload: TermPayload }
  >({
    mutationKey: ["editTerminologyItem"],
    mutationFn: async (params: {
      customerId: string;
      instanceId: string;
      terminologyId: string;
      termId: string;
      payload: TermPayload;
    }) => await updateTerm(params.customerId, params.instanceId, params.terminologyId, params.termId, params.payload),
  });

  return { terminology, status, isFetchingEditItem, editTerminologyItem };
};

export const useDeleteTerminologyItemMutation = () => {
  const {
    data: terminology,
    isPending: isFetchingDeleteItem,
    status,
    mutateAsync: deleteTerminologyItem,
  } = useMutation<
    { success: boolean },
    AxiosError,
    { customerId: string; instanceId: string; terminologyId: string; termId: string }
  >({
    mutationKey: ["deleteTerminologyItem"],
    mutationFn: async (params: { customerId: string; instanceId: string; terminologyId: string; termId: string }) =>
      await deleteTerm(params.customerId, params.instanceId, params.terminologyId, params.termId),
  });

  return { terminology, status, isFetchingDeleteItem, deleteTerminologyItem };
};

export const useUploadGlossaryFileMutation = () => {
  const { mutateAsync } = useMutation<
    TerminologyUploadResult,
    AxiosError,
    {
      customerId: string;
      instanceId: string;
      terminologyId: string;
      sourceLanguageCode: string;
      targetLanguageCode: string;
      file: FileWithPath;
    }
  >({
    mutationKey: ["uploadGlossaryFile"],
    mutationFn: async ({ customerId, instanceId, terminologyId, sourceLanguageCode, targetLanguageCode, file }) =>
      await uploadGlossary(customerId, instanceId, terminologyId, sourceLanguageCode, targetLanguageCode, file),
  });

  return { mutateAsync };
};
