import { DismissibleBadge } from "@/components/badge/DismissibleBadge";
import { ContactSelector } from "@/components/contacts/ContactSelector";
import { CustomFieldDisplayValue } from "@/components/customFields/common/CustomFieldDisplayValue";
import { EmptyDataPlaceholder } from "@/components/EmptyPlaceholder";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { FormControl, FormField, FormItem } from "@/components/ui/form";
import { useStepper } from "@/components/ui/stepper";
import { useDateFormatter } from "@/hooks/useDate";
import { CustomField, CustomFieldValue, DivisionType } from "@/model/request.typing";
import { sanitizeHtml } from "@/utils/text";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronRightIcon, ChevronUpIcon } from "lucide-react";
import { Fragment, type HTMLAttributes, useEffect, useState } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NewFilesContent } from "./NewFilesListContent";
import { useGenerateRequestName } from "./useGenerateRequestName";
import type { NewRequestFormType } from "./useNewRequestForm";
import { fieldsToStep } from "./useStepperState";

interface Props {
  division: DivisionType;
  form: UseFormReturn<NewRequestFormType>;
  customFields: CustomField[];
}

function mapToCustomFieldFromFromValues(
  customFields: CustomField[],
  formValues: Record<string, CustomFieldValue> = {}
): CustomField[] {
  return Object.entries(formValues)
    .map(([key, value]) => {
      const field = customFields.find((field) => field.customFieldId.toString() === key);
      if (!field || value === undefined || value === null) {
        return null;
      }
      return {
        ...field,
        currentValue: value,
      };
    })
    .filter((field) => field !== null);
}

export function SummaryWizardStep({ form, division, customFields }: Props) {
  const { t } = useTranslation();
  const { goToStep } = useStepper();
  const [isShowingAllDetails, setIsShowingAllDetails] = useState<boolean>(false);
  const { formatDateTime } = useDateFormatter();
  const values = form.getValues();
  const { setGeneratedName } = useGenerateRequestName({ form });
  const name = form.watch("name");
  const contacts = form.watch("contacts");
  const errors = Object.entries(form.formState.errors).map(([key, value]) => {
    if (key === "sourceFiles" && Array.isArray(value) && value.length > 0) {
      const values = value.filter(Boolean);

      const message =
        values?.[0]?.sourceLanguageCode?.message ?? values?.[0]?.targetLanguageCodes?.message ?? "sourceFiles";
      return { key, value: message };
    }
    const message = value.message ?? key;
    return { key, value: message };
  });

  useEffect(() => {
    setGeneratedName();
  }, [setGeneratedName]); // Dependency array to control when the effect runs

  const mappedCustomFields = mapToCustomFieldFromFromValues(customFields, values.customFields);

  return (
    <div className="flex h-full flex-col gap-2 px-6">
      <div className="flex flex-col items-center p-6">
        <h2 className="text-2xl font-semibold leading-7">{t("requests.create.summary.title")}</h2>
      </div>

      <div className="mx-auto w-full max-w-7xl">
        {errors.length > 0 ? (
          <Alert variant="destructive" className="mb-4 py-2">
            <AlertDescription className="flex flex-col items-start text-sm font-medium">
              {errors.map((errors) => (
                <Button
                  type="button"
                  variant="link"
                  key={errors.key}
                  className="m-0 h-5 p-0 text-destructive"
                  onClick={() => goToStep(fieldsToStep[errors.key])}
                >
                  {t(`requests.create.errors.${errors.value}`)}
                </Button>
              ))}
            </AlertDescription>
          </Alert>
        ) : null}
        <div className="flex w-full flex-col gap-4 pr-4" data-test="project-details-summary">
          <div>
            <div className="mb-2 flex items-center justify-between">
              <h3 className="font-semibold">{t("requests.create.summary.projectDetails")}</h3>
              <FormField
                control={form.control}
                name="contacts"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <ContactSelector
                        divisionId={division.id}
                        selectedContacts={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-col gap-y-2 rounded-xl border border-neutral-200 p-6 dark:border-border">
              <div className="grid grid-cols-[auto_1fr] gap-x-8 gap-y-2">
                <SummaryLabel label={t("requests.table.name")} />
                <SummaryStringValue className="truncate" title={name} value={name} data-test="label-request-name" />

                <SummaryLabel label={t("requests.table.service")} />
                <SummaryStringValue value={values.service?.name} data-test="label-service-name" />

                <SummaryLabel label={t("requests.table.customerName")} />
                <SummaryStringValue value={values.division?.name} data-test="label-division-name" />

                <SummaryLabel label={t("requests.create.wizard.dueDate")} />
                <SummaryStringValue data-test="label-duedate" value={formatDateTime(values.deadline)} />

                <SummaryLabel label={t("requests.create.wizard.contacts")} />
                {contacts && contacts.length > 0 ? (
                  <div className="flex flex-wrap gap-2" data-test="selected-contacts">
                    {contacts.map((contact) => (
                      <DismissibleBadge
                        key={contact.id}
                        testId={`contact-${contact.id}-badge`}
                        onDismiss={() => {
                          const currentContacts = form.getValues("contacts");
                          form.setValue(
                            "contacts",
                            currentContacts.filter((c) => c.id !== contact.id),
                            { shouldValidate: true }
                          );
                        }}
                      >
                        {contact.name}
                      </DismissibleBadge>
                    ))}
                  </div>
                ) : (
                  <EmptyDataPlaceholder />
                )}

                <button
                  type="button"
                  onClick={() => setIsShowingAllDetails(!isShowingAllDetails)}
                  className="col-span-2 inline-flex items-center justify-start gap-2 py-2"
                  data-test="button-show-details"
                >
                  {isShowingAllDetails ? (
                    <ChevronUpIcon className="size-4 text-ribbon" />
                  ) : (
                    <ChevronRightIcon className="size-4 text-ribbon" />
                  )}
                  <div className="text-sm font-semibold leading-tight text-ribbon">
                    {isShowingAllDetails
                      ? t("requests.create.summary.hideDetails")
                      : t("requests.create.summary.showDetails")}
                  </div>
                </button>
              </div>

              <AnimatePresence initial={false}>
                {isShowingAllDetails && (
                  <motion.div
                    className="grid grid-cols-[auto_1fr] gap-x-8 gap-y-2"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                  >
                    <SummaryLabel label={t("requests.create.wizard.invoicingReference")} />
                    <SummaryStringValue
                      className="truncate"
                      data-test="label-invoicing-reference"
                      title={values.invoicingReference}
                      value={values.invoicingReference}
                    />
                    <SummaryLabel label={t("requests.create.wizard.specialInstructions")} />
                    {values.instructions ? (
                      <div
                        data-test="label-instructions"
                        className="restore-list"
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(values.instructions) ?? "",
                        }}
                      />
                    ) : (
                      <EmptyDataPlaceholder />
                    )}
                    <SummaryLabel label={t("requests.create.wizard.referenceMaterials")} />
                    <div data-test="label-reference-files">
                      {values.referenceFiles.length > 0 ? (
                        values.referenceFiles.map((file) => (
                          <div className="text-base" key={file.id}>
                            <div title={file.file.name} className="truncate">
                              {file.file.name}
                            </div>
                          </div>
                        ))
                      ) : (
                        <EmptyDataPlaceholder />
                      )}
                    </div>
                    {mappedCustomFields.map((field) => (
                      <Fragment key={field.customFieldId}>
                        <SummaryLabel label={t(field.label)} />
                        <CustomFieldDisplayValue field={field} />
                      </Fragment>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          {values.sourceFiles.length > 0 && (
            <div>
              <h3 className="-mb-2 font-semibold">{t("requests.create.summary.languages")}</h3>
              <div data-test="source-files">
                <NewFilesContent data={values.sourceFiles} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function SummaryLabel({ label }: { label: string }) {
  return <div className="text-sm font-medium text-neutral-500 dark:text-muted-foreground">{label}</div>;
}

function SummaryStringValue({
  value,
  ...others
}: { value: string | null | undefined } & HTMLAttributes<HTMLDivElement>) {
  const hasValue = value !== null && value !== undefined && value !== "";
  if (hasValue) {
    return <div {...others}>{value}</div>;
  }
  return <EmptyDataPlaceholder />;
}
