import { ButtonIcon } from "@/components/button/ButtonIcon";
import { validateFilename } from "@/utils/file";
import { FileDropzone } from "components/FileDropzone";
import { t } from "i18next";
import { Trash2 } from "lucide-react";
import { FileToTranslate, MachineTranslationConfig } from "model/mt.typing";
import { FileWithPath } from "react-dropzone";
import { toast } from "sonner";
import { mapFileToTranslate } from "utils/mapping";
import { LanguageLoader } from "../LanguageLoader";

interface FileUploadsProps {
  config: MachineTranslationConfig | undefined;
  isLoading: boolean;
  selectedFile: FileToTranslate | null;
  setSelectedFile: (file: FileToTranslate | null) => void;
  handleOcrUpload: (file: FileToTranslate | null) => void;
}

const ONE_GIGABYTE = 1073741824;

export const FileUploads = (props: FileUploadsProps) => {
  const { config, isLoading, selectedFile, setSelectedFile, handleOcrUpload } = props;

  const handleFilesSelected = (incomingFiles: FileWithPath[]) => {
    if (incomingFiles.length > 0) {
      const fileToAdd: FileToTranslate = mapFileToTranslate(incomingFiles[0]);

      if (!config?.extensions.includes(`.${fileToAdd.name.toLowerCase().split(".").pop()}`)) {
        toast.error(t("mt.file.extensionNotSupported", { filename: fileToAdd.name }));
        return;
      }

      if (!validateFilename(fileToAdd.name)) {
        toast.error(t("mt.file.filenameNotValid"));
        return;
      }

      if (fileToAdd.size === 0) {
        toast.error(t("mt.file.fileIsNullOrEmpty"));
        return;
      }

      if (fileToAdd.size > ONE_GIGABYTE) {
        toast.error(t("mt.file.fileTooLarge"));
        return;
      }

      // Determine file processing path
      if (
        config &&
        (config.ocrProvider == 2 || config.ocrProvider == 3 || config.ocrProvider == 4) &&
        config.extensionsOcr.some((x: string) => x == `.${fileToAdd.file.name.toLowerCase().split(".").pop()}`)
      ) {
        handleOcrUpload(fileToAdd);
      } else setSelectedFile(fileToAdd);
    }
  };

  if (isLoading) return <LanguageLoader />;

  return (
    <>
      <FileDropzone
        handleFilesSelected={handleFilesSelected}
        machineTranslationConfig={config}
        data-test="mt-file-uploads"
      />
      {selectedFile ? (
        <div
          data-test="mt-selected-file"
          className="mt-2 flex w-full items-center justify-between gap-2 border px-4 py-2"
        >
          <span>{selectedFile.name}</span>
          <ButtonIcon icon={Trash2} onClick={() => setSelectedFile(null)} />
        </div>
      ) : null}
    </>
  );
};
