import { Badge } from "@/components/ui/badge";
import { cn } from "@/utils/ui";

export function LanguageInfoDisplay({ code, name, className }: { code: string; name: string; className?: string }) {
  return (
    <div className={cn("mr-6 inline-flex max-w-fit items-center gap-2", className)}>
      <span className="truncate">{name}</span>
      <Badge variant="muted" className="text-nowrap rounded-sm px-1 text-xs font-normal">
        {code}
      </Badge>
    </div>
  );
}
