import type { UserModule } from "@/model/user.typing";

export const VNEXT_BASE_PATH = "/app";

export function getBaseUrl() {
  const hostname = window.location.hostname;
  const isDevelopment = hostname === "localhost" || hostname === "127.0.0.1";
  return isDevelopment ? `https://${hostname}:7151` : `https://${hostname}`;
}

export function redirectToLogin(returnUrl?: string) {
  const portalServerUrl = getBaseUrl();
  const searchParams = returnUrl ? `?returnUrl=${returnUrl}` : "";
  window.location.assign(`${portalServerUrl}/account/login${searchParams}`);
}

export function redirectToGoodbye({ isTimeout = false, returnUrl }: { isTimeout?: boolean; returnUrl?: string }) {
  const portalServerUrl = getBaseUrl();
  const searchParams = new URLSearchParams();
  if (isTimeout) searchParams.append("s", "t");
  if (returnUrl) searchParams.append("returnUrl", returnUrl);
  window.location.assign(`${portalServerUrl}/account/goodbye?${searchParams.toString()}`);
}

export function redirectToUserProfile() {
  const portalServerUrl = getBaseUrl();
  window.location.assign(`${portalServerUrl}/account/profile`);
}

export function redirectToPortal() {
  const portalServerUrl = getBaseUrl();
  window.location.assign(portalServerUrl);
}

/**
 * Returns the classic URL by combining the portal server URL and the path.
 * Trailing slashes are removed from both the URL and the path.
 *
 * @param {Object} options - The options object.
 * @param {string} options.portalServerUrl - The portal server URL.
 * @param {string} options.path - The path.
 * @returns {string} The classic URL.
 */
export function getClassicPath({ portalServerUrl, path }: { portalServerUrl: string; path: string }): string {
  const normalizedUrl = typeof portalServerUrl === "string" ? portalServerUrl.replace(/\/+$/, "") : ""; // Remove trailing slashes
  const normalizedPath = typeof path === "string" ? path.replace(/^\/+/, "") : "";
  const result = `${normalizedUrl}/${normalizedPath}`;
  if (result === "/") return result;
  return result.replace(/\/+$/, ""); // Remove trailing slashes
}

/**
 * Sanitizes a path by removing trailing slashes, leading spaces, and replacing multiple consecutive slashes with a single slash.
 * @param path - The path to sanitize.
 * @returns The sanitized path.
 */
export function sanitizePath(path: string): string {
  const result = path.trim().toLowerCase().replace(/\/+/g, "/"); // Replace multiple consecutive slashes with a single slash
  return result === "/"
    ? result
    : result
      .replace(/\/+$/, "") // Remove trailing slashes
      .replace(/^\s+/, ""); // Remove leading spaces
}

/**
 * Checks if the given path is a Vnext path.
 * @param path - The path to check.
 * @returns A boolean indicating whether the path is a Vnext path.
 */
export function isVnextPath(path: string | null | undefined) {
  if (typeof path !== "string") return false;
  return sanitizePath(path).startsWith(VNEXT_BASE_PATH);
}

export function extractAuthorizedPaths(...moduleLists: (Pick<UserModule, "link">[] | null | undefined)[]): string[] {
  const result = moduleLists
    .flat()
    .map((module: Pick<UserModule, "link"> | undefined | null) => {
      if (typeof module?.link !== "string" || module.link.trim().length === 0) {
        return null;
      }
      const link = sanitizePath(module.link);
      return isVnextPath(link) ? link.replace(VNEXT_BASE_PATH, "") : null;
    })
    .filter((link): link is string => link !== null);

  return result;
}

export const getProxiedUrl = (fullUrl: string): string => {
  const isProd = import.meta.env.PROD
  if (isProd) {
    return fullUrl
  }

  // If it's not a full URL (no protocol), return as is
  if (!fullUrl.startsWith("http")) {
    return fullUrl;
  }

  let isLocal = true; // assume it's local by default
  try {
    const hostname = new URL(fullUrl).hostname.toLowerCase();
    isLocal = hostname === "localhost" || hostname === "127.0.0.1";
  } catch (e) {
    console.error(e);
  }

  if (isLocal) {
    return fullUrl;
  }

  return `/external?url=${encodeURIComponent(fullUrl)}`
}