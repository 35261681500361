import { useDateFormatter, useDateOperations } from "@/hooks/useDate";
import type { User } from "@/model/user.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import type { UseFormReturn } from "react-hook-form";
import type { NewRequestFormType } from "./useNewRequestForm";

interface GenerateRequestNameProps {
  form: UseFormReturn<NewRequestFormType>;
}

export function useGenerateRequestName({ form }: GenerateRequestNameProps) {
  const { firstName, lastName } = useAuthUserStore((state) => state.user as User);
  const { now } = useDateOperations();
  const { formatDateTime } = useDateFormatter();

  const generateName = (): string => {
    const {
      division: { name: divisionName },
    } = form.getValues();

    const nowDate = now();
    const dateTime = formatDateTime(nowDate);
    const maxLength = 100;
    let baseName = ` ${firstName} ${lastName} ${dateTime}`;
    let generatedName = `${divisionName}${baseName}`;

    if (generatedName.length > maxLength) {
      baseName = `_${firstName}${lastName}_${dateTime}`.replace(/\s/g, "");
      const division = divisionName.replace(/\s/g, "").substring(0, maxLength - baseName.length);
      generatedName = (division + baseName).substring(0, maxLength);
    }

    return generatedName;
  };

  const setGeneratedName = () => {
    const { name } = form.getValues();
    if (!name) {
      const generatedName = generateName();
      form.setValue("name", generatedName, { shouldDirty: true });
    }
  };

  return { generateName, setGeneratedName };
}

export default useGenerateRequestName;
