import { SortableItem, SortDirection, SortState, UseSortingOptions } from "@/components/sorting/sort.typings";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { useLocalStorage } from "@uidotdev/usehooks";
import { orderBy } from "lodash";
import { useCallback, useMemo } from "react";

export function useSorting<TItem extends SortableItem, Field extends string>({
  id,
  defaultSort,
  priorityField,
}: UseSortingOptions<Field>) {
  const { user } = useAuthUserStore();
  const storageKey = `${user?.id}-sorting-${id}`;
  const [currentSort, setCurrentSort] = useLocalStorage<SortState<Field> | undefined>(storageKey);

  const sortState = useMemo(() => (defaultSort ? (currentSort ?? defaultSort) : undefined), [currentSort, defaultSort]);

  const sortItems = useCallback(
    (items: TItem[]) => {
      if (!sortState) {
        return items;
      }

      const sortFields: Array<keyof TItem> = [];
      const sortDirections: SortDirection[] = [];

      if (priorityField) {
        sortFields.push(priorityField);
        sortDirections.push("desc");
      }

      sortFields.push(sortState.field as keyof TItem);
      sortDirections.push(sortState.direction);

      return orderBy(items, sortFields, sortDirections);
    },
    [sortState, priorityField]
  );

  const updateSort = useCallback(
    (newSort: SortState<Field>) => {
      if (!defaultSort) return;

      if (sortState?.field === newSort.field) {
        setCurrentSort({
          field: newSort.field,
          direction: sortState.direction === "asc" ? "desc" : "asc",
        });
      } else {
        setCurrentSort(newSort);
      }
    },
    [defaultSort, sortState, setCurrentSort]
  );

  return {
    sortState,
    updateSort,
    sortItems,
    isEnabled: defaultSort !== undefined,
  } as const;
}
