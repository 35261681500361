import { Button } from "@/components/ui/button";
import { ChartGroupToggleState, TmLeverageChartNames, TmLeverageChartNamesType } from "@/model/report.typing";
import { clsx } from "clsx";
import { RectangleHorizontalIcon } from "lucide-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTmLeverageActions, useTmLeverageChartViewFilter } from "../../reportStore";

const CHART_CATEGORIES: Record<keyof ChartGroupToggleState, { color: string; chartTypes: TmLeverageChartNamesType[] }> =
  {
    xTranslated: {
      color: "fill-blue-400",
      chartTypes: [
        TmLeverageChartNames.perLanguage,
        TmLeverageChartNames.perMonthPercentage,
        TmLeverageChartNames.perMonthWords,
      ],
    },
    repetitions: {
      color: "fill-cyan-400",
      chartTypes: [TmLeverageChartNames.perMonthWords],
    },
    repsAnd100: {
      color: "fill-cyan-300",
      chartTypes: [TmLeverageChartNames.perLanguage, TmLeverageChartNames.perMonthPercentage],
    },
    m50_74: {
      color: "fill-amber-400",
      chartTypes: [TmLeverageChartNames.perMonthWords],
    },
    m75_84: {
      color: "fill-blue-400",
      chartTypes: [TmLeverageChartNames.perMonthWords],
    },
    m85_94: {
      color: "fill-rose-400",
      chartTypes: [TmLeverageChartNames.perMonthWords],
    },
    m95_99: {
      color: "fill-lime-400",
      chartTypes: [TmLeverageChartNames.perMonthWords],
    },
    m100: {
      color: "fill-stone-400",
      chartTypes: [TmLeverageChartNames.perMonthWords],
    },
    fuzzy: {
      color: "fill-amber-300",
      chartTypes: [TmLeverageChartNames.perLanguage, TmLeverageChartNames.perMonthPercentage],
    },
    noMatch: {
      color: "fill-violet-300",
      chartTypes: [
        TmLeverageChartNames.perLanguage,
        TmLeverageChartNames.perMonthPercentage,
        TmLeverageChartNames.perMonthWords,
      ],
    },
    words: { color: "", chartTypes: [] },
  };

interface Props {
  chartType: TmLeverageChartNamesType;
}

export function ChartFilter({ chartType }: Props) {
  const chartViewSates = useTmLeverageChartViewFilter();
  const chartViewSate = chartViewSates[chartType];
  const { updateChartViewFilter } = useTmLeverageActions();

  const toggleList = useMemo(() => {
    return Object.entries(CHART_CATEGORIES)
      .filter(([, value]) => value.chartTypes.includes(chartType))
      .map(([key]) => key as keyof typeof CHART_CATEGORIES);
  }, [chartType]);

  return (
    <div className="mb-4 flex flex-wrap items-center gap-2">
      {toggleList.map((prefix) => {
        const isToggled = chartViewSate[prefix];
        return (
          <Button
            key={prefix}
            size={"sm"}
            className="h-8 px-1.5 text-sm"
            variant={isToggled ? "secondary" : "ghost"}
            onClick={() => updateChartViewFilter(chartType, { [prefix]: !isToggled })}
          >
            <ChartCategoryInfo prefix={prefix} />
          </Button>
        );
      })}
    </div>
  );
}

export const ChartCategoryInfo = ({ prefix }: { prefix: keyof typeof CHART_CATEGORIES }) => {
  const { t } = useTranslation("translation", { keyPrefix: "reports.tmleverage.table" });
  return (
    <div className="inline-flex flex-nowrap items-center gap-2">
      <RectangleHorizontalIcon
        className={clsx("w-5 stroke-none", CHART_CATEGORIES[prefix].color, { "opacity-0": prefix === "words" })}
      />
      <span className="whitespace-nowrap font-semibold">{t(prefix)}</span>
    </div>
  );
};
