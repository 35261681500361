import { Button } from "@/components/ui/button";
import { User, UserPermissionsType } from "@/model/user.typing";
import { t } from "i18next";
import { ChartColumnIcon, LanguagesIcon } from "lucide-react";
import { ReactNode } from "react";
import { useNavigate } from "react-router";
import { NewRequestButton } from "./requests/common/NewRequestButton";

export interface HeroButtonType {
  id: string;
  label: ReactNode;
  variant:
    | "default"
    | "outline"
    | "secondary"
    | "link"
    | "accept"
    | "primary"
    | "destructive"
    | "outlinePrimary"
    | "ghost"
    | "none"
    | null
    | undefined;
  className?: string;
  onClick?: () => void;
}

export interface HomeHeroSlideType {
  id: string;
  message: string;
  actionButton: ReactNode;
}

const useHomePage = (user: User | null) => {
  const navigate = useNavigate();

  const getUserPermissions = (user: User | null): UserPermissionsType => {
    if (!user) return { hasRequests: false, hasMachineTranslations: false, hasReports: false };
    return {
      hasRequests: user.modules.some((module) => module.link.includes("/app/client/requests")),
      hasMachineTranslations: user.modules.some((module) => module.link.includes("/app/client/machinetranslation")),
      hasReports: user.modules.some((module) => module.link.includes("/app/client/reports")),
    };
  };

  const getUserGreeting = (): string | null => {
    if (!user) return null;
    const localHour = new Date().getHours();
    let greeting;
    if (localHour < 4) {
      // 10pm - 4am
      greeting = t("home.hero.goodEvening");
    } else if (localHour < 12) {
      // 4am - 12pm
      greeting = t("home.hero.goodMorning");
    } else if (localHour < 18) {
      // 12pm - 6pm
      greeting = t("home.hero.goodAfternoon");
    } else {
      // 6pm - 10pm
      greeting = t("home.hero.goodEvening");
    }
    return `${greeting}, ${user.firstName} ${user.lastName}!`;
  };

  const getUserHeroSlides = (): HomeHeroSlideType[] => {
    if (!user) return [];

    const permissions = getUserPermissions(user);
    const permissionSlides: { [key: string]: HomeHeroSlideType } = {
      hasRequests: {
        id: "requests",
        message: t("home.hero.requestsMessage"),
        actionButton: <NewRequestButton buttonType="hero" />,
      },
      hasMachineTranslations: {
        id: "machineTranslations",
        message: t("home.hero.machineTranslationsMessage"),
        actionButton: (
          <Button
            variant="default"
            data-test="hero-button-machinetranslations"
            onClick={() => {
              navigate("/client/machinetranslation");
            }}
          >
            <LanguagesIcon className="mr-2 size-4" />
            {t("home.hero.machineTranslationsButton")}
          </Button>
        ),
      },
      hasReports: {
        id: "reports",
        message: t("home.hero.reportsMessage"),
        actionButton: (
          <Button
            variant="default"
            data-test="hero-button-reports"
            onClick={() => {
              navigate("/client/reports");
            }}
          >
            <ChartColumnIcon className="mr-2 size-4" />
            {t("home.hero.reportsButton")}
          </Button>
        ),
      },
    };

    const permissionOrder = ["hasRequests", "hasMachineTranslations", "hasReports"];

    // Create an array of HomeHeroSlideType for matching permissions
    return permissionOrder
      .filter((key) => permissions[key as keyof typeof permissions])
      .map((key) => ({
        id: key,
        message: permissionSlides[key].message,
        actionButton: permissionSlides[key].actionButton,
      }));
  };

  return {
    getUserGreeting,
    getUserHeroSlides,
  };
};

export default useHomePage;
