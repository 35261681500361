import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import type { CustomField, CustomFieldOption, CustomFieldValue, CustomFieldValueType } from "@/model/request.typing";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2Icon, PencilIcon } from "lucide-react";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { CustomFieldDisplayValue } from "./CustomFieldDisplayValue";
import { CustomFieldFormField } from "./CustomFieldFormField";
import { createCustomFieldsRecordSchema } from "./customFieldFormSchema";

interface Props {
  field: CustomField;
  isPending: boolean;
  onSave: (data: { customFieldId: number; fieldType: CustomFieldValueType; value: CustomFieldValue }) => Promise<void>;
}

export function InlineEditableField({ field, isPending, onSave }: Props) {
  const [isEditing, setIsEditing] = useState(false);
  if (!isEditing) {
    return (
      <div className="group flex items-center gap-2">
        <CustomFieldDisplayValue field={field} />
        <Button
          variant="icon"
          size="xs"
          className="invisible group-hover:visible"
          onClick={() => setIsEditing(true)}
          type="button"
        >
          <PencilIcon className="size-4" />
        </Button>
      </div>
    );
  }

  return <InlineFrom field={field} onDoneEditing={() => setIsEditing(false)} onSave={onSave} isPending={isPending} />;
}

function InlineFrom({
  field,
  onDoneEditing,
  onSave,
  isPending,
}: Props & {
  onDoneEditing: () => void;
}) {
  const { t } = useTranslation();
  const { form, cancelEditing } = useCustomFieldForm(field, onDoneEditing);

  //  calculate if the current value is invalid
  const invalidOptions = useCustomFieldValidation(field);

  const handleOnSave = form.handleSubmit(async (data) => {
    const value = data.customFields[field.customFieldId.toString()];
    await onSave({ customFieldId: field.customFieldId, fieldType: field.fieldType, value });
    cancelEditing();
  });

  return (
    <Form {...form}>
      <form onSubmit={handleOnSave} className="mr-6 flex flex-row items-start gap-2" noValidate={true}>
        <div className="flex-1">
          <CustomFieldFormField
            isCreate={false}
            fieldConfig={field}
            control={form.control}
            invalidOptions={invalidOptions}
          />
        </div>
        <div className="mt-1 flex flex-row-reverse gap-2">
          <Button type="submit" size="sm" disabled={isPending || !form.formState.isDirty}>
            {isPending ? <Loader2Icon className="mr-2 size-4 animate-spin" /> : null}
            {t("common.modal.save")}
          </Button>
          <Button type="button" size="sm" variant="secondary" onClick={cancelEditing} disabled={isPending}>
            {t("common.modal.cancel")}
          </Button>
        </div>
      </form>
    </Form>
  );
}

function useCustomFieldForm(field: CustomField, onDoneEditing: () => void) {
  const zodSchema = z.object({
    customFields: createCustomFieldsRecordSchema([field]),
  });

  const form = useForm<z.infer<typeof zodSchema>>({
    resolver: zodResolver(zodSchema),
    mode: "onBlur",
    defaultValues: {
      customFields: {
        [field.customFieldId.toString()]: field.currentValue ?? null,
      },
    },
  });

  const cancelEditing = () => {
    form.reset();
    onDoneEditing();
  };

  return {
    form,
    cancelEditing,
  };
}

export function useCustomFieldValidation(field: CustomField): CustomFieldOption[] {
  return useMemo(() => {
    const currentValue = field.currentValue;

    // Early return if no options or no value
    if (field.options.length === 0 || currentValue == null || currentValue === undefined) {
      return [];
    }

    // Convert current value to array of strings for consistent comparison
    const currentValues = Array.isArray(currentValue) ? currentValue.map(String) : [String(currentValue)];

    // Return options that are both selected and invalid
    return field.options.filter((option) => currentValues.includes(String(option.id)) && !option.isValid);
  }, [field.options, field.currentValue]);
}
