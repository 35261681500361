import { TmLeveragePerLanguagesType } from "@/model/report.typing";
import { useOutletContext } from "react-router-dom";
import { ChartCardSkeleton } from "../common/ChartSkeleton";
import { TmLeveragePerLanguageChart } from "./TmLeveragePerLanguagePage";
import { TmLeveragePerMonthPercentageChart } from "./TmLeveragePerMonthPercentagePage";
import { TmLeveragePerMonthWordsChart } from "./TmLeveragePerMonthWordsPage";

export function TmLeverageDashboard() {
  const { filteredData, isLoading } =
    useOutletContext<{ isLoading: boolean; filteredData: TmLeveragePerLanguagesType[] }>() ?? {};

  if (isLoading) {
    return (
      <div data-test="dashboard-loader" className="grid grid-cols-1 gap-4 xl:grid-cols-2">
        <ChartCardSkeleton />
      </div>
    );
  }
  if (filteredData === undefined) return null;

  return (
    <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
      <TmLeveragePerLanguageChart data={filteredData} height={600} isFocusView={false} />
      <TmLeveragePerMonthPercentageChart data={filteredData} height={600} isFocusView={false} />
      <div className="col-span-1 xl:col-span-2">
        <TmLeveragePerMonthWordsChart data={filteredData} height={600} isFocusView={false} />
      </div>
    </div>
  );
}
