import type { ProjectCostType, TmLeveragePerLanguagesType, TmLeverageType } from "@/model/report.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchProjectCost,
  fetchTMLeverage,
  requestProjectCostsExcelDownload,
  requestTmLeverageExcelDownload,
} from "api/report.api";
import type { AxiosError } from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

const commonReportQueryOptions = {
  staleTime: 1000 * 60 * 30, // 30 minutes
};
export const useProjectCosts = (searchParam: string | null, forSingle?: boolean) => {
  const userId = useAuthUserStore((state) => state.user?.id) as string;
  const { data: projectCosts = [], isLoading } = useQuery({
    ...commonReportQueryOptions,
    meta: {
      persist: !searchParam,
    },
    queryKey: [userId, "reports", "cost-reports", searchParam, forSingle],
    queryFn: async ({ signal }) => {
      // If for single, pass the parameter as the projectId - not the query string
      const projects = !forSingle
        ? await fetchProjectCost(searchParam, signal)
        : await fetchProjectCost(null, signal, searchParam ?? "");
      return setDeliveryDate(projects);
    },
  });

  return { projectCosts, isLoading };
};

export function useProjectCostsExcelDownload() {
  const { t } = useTranslation();
  const { error, isPending, mutate } = useMutation<void, AxiosError, ProjectCostType[]>({
    mutationFn: async (projectCosts: ProjectCostType[]) => {
      const ids = projectCosts.map((project) => project.projectId);
      return requestProjectCostsExcelDownload(ids);
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }
    toast.error(t("common.errors.failed-download"), {
      id: "download-cost-report-error",
    });
  }, [error, t]);

  return { error, isDownloading: isPending, downloadExcelFile: mutate };
}

export const useTmLeverageData = (searchParam: string | null, forSingle?: boolean) => {
  const { data: tmLeverageData = [], isLoading } = useQuery<TmLeveragePerLanguagesType[]>({
    ...commonReportQueryOptions,
    meta: {
      persist: !searchParam,
    },
    queryKey: ["reports", "tm-leverage", searchParam, forSingle],
    queryFn: async ({ signal }) => {
      // If for single, pass the parameter as the projectId - not the query string
      const projects = !forSingle
        ? await fetchTMLeverage(searchParam, signal)
        : await fetchTMLeverage(null, signal, searchParam ?? "");
      return mapToPerLanguagePairTmLeverage(projects);
    },
  });

  return { tmLeverageData, isLoading };
};

export function useTmLeverageExcelDownload() {
  const { t } = useTranslation();
  const { error, isPending, mutate } = useMutation<void, AxiosError, TmLeveragePerLanguagesType[]>({
    mutationFn: async (tmLEverageData) => {
      // get unique project ids
      const ids = Array.from(new Set(tmLEverageData.map((language) => language.id)));
      return requestTmLeverageExcelDownload(ids);
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }
    toast.error(t("common.errors.failed-download"), {
      id: "download-tm-leverage-report-error",
    });
  }, [error, t]);

  return { error, isDownloading: isPending, downloadExcelFile: mutate };
}

function setDeliveryDate<T extends ProjectCostType | TmLeverageType>(projects: T[]): T[] {
  return projects.map((project) => {
    let allDelivered = true;
    let maxDate = Number.NEGATIVE_INFINITY;

    for (const language of project.languages) {
      const date = language.deliveryDate ? new Date(language.deliveryDate).getTime() : undefined;
      if (date === undefined) {
        allDelivered = false;
        break;
      }
      if (date > maxDate) {
        maxDate = date;
      }
    }

    const deliveryDate = allDelivered ? new Date(maxDate) : null;

    return { ...project, deliveryDate };
  });
}

function mapToPerLanguagePairTmLeverage(tmLeverages: TmLeverageType[]): TmLeveragePerLanguagesType[] {
  return tmLeverages.flatMap((project) => {
    return project.languages
      .filter((language) => !!language.deliveryDate)
      .map((language) => {
        const isOwnerDefined = project.firstName && project.lastName;
        const m100 = language.m100 ?? 0;
        const m95_99 = language.m95_99 ?? 0;
        const m85_94 = language.m85_94 ?? 0;
        const m75_84 = language.m75_84 ?? 0;
        const m50_74 = language.m50_74 ?? 0;
        const xTranslated = language.xTranslated ?? 0;
        const repetitions = language.repetitions ?? 0;
        const words = language.words ?? 0;
        const noMatch = language.noMatch ?? 0;
        const fuzzy = m95_99 + m85_94 + m75_84 + m50_74;
        const projectLanguage: TmLeveragePerLanguagesType = {
          ...language,
          m100,
          m95_99,
          m85_94,
          m75_84,
          m50_74,
          xTranslated, // => exact
          noMatch, // => new
          words, // =>  total
          fuzzy,
          repetitions,
          repsAnd100: repetitions + m100, // => REPS & 100%
          portalProjectId: project.portalProjectId,
          projectName: project.projectName,
          startDate: project.startDate,
          client: {
            id: project.clientId,
            name: project.clientName,
          },
          owner: isOwnerDefined
            ? {
                id: `${project.firstName} ${project.lastName}`,
                name: `${project.firstName} ${project.lastName}`,
              }
            : null,
          ecosystemName: project.ecosystemName,
        };
        return projectLanguage;
      })
      .filter(({ owner }) => owner !== null);
  });
}
