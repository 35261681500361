import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { CostReportChartNames, ProjectCostPerLanguagesType, ProjectCostType } from "@/model/report.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { clsx } from "clsx";
import { chain, sumBy } from "lodash";
import { MoveDownRightIcon, MoveUpRightIcon } from "lucide-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Bar, BarChart, Brush, CartesianGrid, Legend, Rectangle, Tooltip, XAxis, YAxis } from "recharts";
import { ChartCard } from "../common/ChartCard";
import { ChartEmptyPlaceholder } from "../common/ChartEmptyPlaceholder";
import { ChartCardSkeleton } from "../common/ChartSkeleton";
import { ChartTooltipCursor } from "../common/ChartTooltipCursor";
import { useCostReportActions, useCostReportChartSettings, useCostReportChartView } from "../reportStore";
import { useChartDescription } from "./useChartDescription";
import { useFiltredPerLanguageProjectCost } from "./useProjectCostFilter";
import { useChartBrushIndexes } from "../common/useChartBrushIndexes";

export function SpendPerQuarterPage() {
  const { pricePerPairLanguages, projectCosts, isLoading } =
    useOutletContext<{
      isLoading: boolean;
      pricePerPairLanguages: ProjectCostPerLanguagesType[];
      projectCosts: ProjectCostType[];
    }>() ?? {};

  if (isLoading) return <ChartCardSkeleton />;
  if (pricePerPairLanguages === undefined) return null;

  return (
    <SpendPerQuarterChart
      projectCosts={projectCosts}
      pricePerPairLanguages={pricePerPairLanguages}
      isFocusView={true}
    />
  );
}

export function SpendPerQuarterChart({
  pricePerPairLanguages,
  projectCosts,
  height = 600,
  isFocusView = false,
}: {
  pricePerPairLanguages: ProjectCostPerLanguagesType[];
  projectCosts: ProjectCostType[];
  height?: number;
  isFocusView?: boolean;
}) {
  const { t } = useTranslation();
  const formatNumber = useAuthUserStore((store) => store.formatNumber);

  const perQuarterChartView = useCostReportChartView();
  const { updatePerQuarterChartView } = useCostReportActions();

  const historicalData = useFiltredPerLanguageProjectCost(projectCosts, true);

  const isYoyView = perQuarterChartView === "perQyarterYoy";

  const { title } = useChartDescription(
    isYoyView ? "per-quarter-yoy" : CostReportChartNames.perQuarter,
    false,
    pricePerPairLanguages
  );

  const { saveChartSettings } = useCostReportActions();
  const chartSettings = useCostReportChartSettings(CostReportChartNames.perQuarter);

  const [brushStartEndIndex, setBrushStartEndIndex] = useChartBrushIndexes();

  // price per quarter
  const pricePerQuarter = useMemo(() => {
    const aggregatedHistoricalData = mapToQuarterData(historicalData);
    const aggregatedData = mapToQuarterData(pricePerPairLanguages);

    // Enrich the data with previousYearPrice and YoY change.
    const aggregatedWithYoyData = aggregatedData.map((data) => {
      const previousYear = parseInt(data.year) - 1;
      const previousYearData = aggregatedHistoricalData.find((d) => {
        return d.year === previousYear.toString() && d.quarter === data.quarter;
      });
      const previousYearPrice = previousYearData ? previousYearData.price : 0;

      let yoYChange = null;
      if (previousYearPrice > 0) {
        yoYChange = ((data.price - previousYearPrice) / previousYearPrice) * 100;
      }

      return {
        ...data,
        previousYearPrice: previousYearPrice ?? "0",
        yoYChange: yoYChange !== null ? parseFloat(yoYChange.toFixed(2)) : 0,
      };
    });

    return aggregatedWithYoyData;
  }, [historicalData, pricePerPairLanguages]);

  if (pricePerPairLanguages.length === 0) {
    return <ChartEmptyPlaceholder />;
  }

  return (
    <ChartCard
      height={height}
      title={title}
      link={CostReportChartNames.perQuarter}
      isFocusView={isFocusView}
      chartSettings={chartSettings}
      saveChartSettings={saveChartSettings}
      renderAction={() => (
        <ToggleGroup
          type="single"
          className="print:hidden"
          value={perQuarterChartView}
          onValueChange={(value) => updatePerQuarterChartView(value as "perQyarterYoy" | "perQyarterDefault")}
        >
          <ToggleGroupItem size="sm" value="perQyarterYoy">
            {t("reports.charts.perQyarterYoy")}
          </ToggleGroupItem>
        </ToggleGroup>
      )}
    >
      {({ displayGrid, displayBrush }) => (
        <BarChart data={pricePerQuarter} margin={{ top: 20, left: 40, right: 25, bottom: 0 }}>
          {displayGrid ? <CartesianGrid strokeDasharray="3 3" /> : null}
          {displayBrush ? (
            <Brush
              height={20}
              travellerWidth={5}
              onChange={setBrushStartEndIndex}
              startIndex={brushStartEndIndex.startIndex}
              endIndex={brushStartEndIndex.endIndex}
              className="text-sm"
              fill="#cbd5e1"
              stroke="#374151"
              dataKey="quarterYear"
            />
          ) : null}

          <XAxis
            dataKey={"quarterYear"}
            className="text-sm font-medium text-secondary-foreground"
            angle={320}
            minTickGap={15}
            height={55}
            dy={18}
            dx={-18}
          />
          <YAxis tickFormatter={(value) => formatNumber(value)} />
          <Tooltip
            cursor={<ChartTooltipCursor variant={"gray"} />}
            content={({ payload }) => {
              if (!payload?.[0]?.payload) return null;
              const data = payload[0].payload;
              return (
                <Card>
                  <CardHeader className="px-2 py-1">
                    <CardTitle className="text-base">
                      {data.quarter} {data.year}
                    </CardTitle>
                    <CardDescription className="text-base font-medium text-foreground">
                      {isYoyView ? (
                        <span className="grid grid-cols-2 gap-2">
                          <span className="text-muted-foreground">{t("reports.charts.currentYear")}</span>
                          <span>{formatNumber(data.price, data.currencyCode)}</span>
                          <span className="text-muted-foreground">{t("reports.charts.previousYear")}</span>
                          <span>{formatNumber(data.previousYearPrice, data.currencyCode)}</span>

                          {data.yoYChange === 0 ? null : (
                            <>
                              <span className="text-muted-foreground">{t("reports.charts.yoYChange")}</span>
                              <span
                                className={clsx(
                                  "inline-flex flex-nowrap items-center gap-1",
                                  data.yoYChange > 0 ? "text-green-500" : "text-red-500"
                                )}
                              >
                                {parseFloat(data.yoYChange)}%
                                {data.yoYChange > 0 ? (
                                  <MoveUpRightIcon className="inline-block size-4" />
                                ) : (
                                  <MoveDownRightIcon className="inline-block size-4" />
                                )}
                              </span>
                            </>
                          )}
                        </span>
                      ) : (
                        formatNumber(data.price, data.currencyCode)
                      )}
                    </CardDescription>
                  </CardHeader>
                </Card>
              );
            }}
          />
          {isYoyView ? (
            <>
              <Legend
                verticalAlign="top"
                height={40}
                formatter={(value) => {
                  return value === "price" ? t("reports.charts.currentYear") : t("reports.charts.previousYear");
                }}
              />
              (
              <Bar
                fill="#14b8a6"
                dataKey="previousYearPrice"
                className="cursor-pointer fill-teal-400 stroke-teal-500 dark:fill-teal-700 dark:stroke-teal-800"
                activeBar={<Rectangle className="fill-teal-600 stroke-teal-700" />}
              />
            </>
          ) : null}
          <Bar
            dataKey="price"
            fill="#8b5cf6"
            className="cursor-pointer fill-violet-400 stroke-violet-500 dark:fill-violet-700 dark:stroke-violet-800"
            activeBar={<Rectangle className="fill-violet-600 stroke-violet-700" />}
          />
        </BarChart>
      )}
    </ChartCard>
  );
}

function mapToQuarterData(pricePerPairLanguages: ProjectCostPerLanguagesType[]) {
  return chain(pricePerPairLanguages)
    .unionBy("projectId")
    .filter((data) => data.deliveryDate !== null)
    .groupBy((data) => {
      const date = new Date(data.deliveryDate as Date);
      const quarter = Math.floor(date.getMonth() / 3) + 1;
      return `Q${quarter} ${date.getFullYear()}`;
    })
    .map((value, key) => {
      const currencyCode = value[0].currencyCode;
      const price = sumBy(value, "price");
      const [quarter, year] = key.split(" ");
      return { quarterYear: key, quarter, year, currencyCode, price };
    })
    .orderBy(["year", "quarter"])
    .value();
}
