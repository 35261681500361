import { CustomField } from "@/model/request.typing";

import { useUpdatePONumberMutation } from "@/query/request.query";
import { useMemo } from "react";
import { InlineEditableField } from "./common/InlineEditableField";

interface Props {
  invoicingReference: string | null;
  id: number;
  type: string;
  label: string;
}

export const InlineEditablePoField = ({ invoicingReference, id, type, label }: Props) => {
  const field = useMemo<CustomField>(() => {
    return {
      customFieldId: 1,
      id: 1,
      label,
      isMandatory: true,
      maxValue: 256,
      fieldType: "String",
      options: [],
      currentValue: invoicingReference,
      displayOrder: 1,
      isValid: true,
    } satisfies CustomField;
  }, [invoicingReference, label]);
  const { mutateAsync, isPending } = useUpdatePONumberMutation(id.toString(), type);
  return (
    <InlineEditableField
      field={field}
      isPending={isPending}
      onSave={({ value }) => mutateAsync({ value: value as string })}
    />
  );
};
