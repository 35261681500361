import { cn } from "@/utils/ui";
import { LanguagesIcon, LucideProps } from "lucide-react";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import { Suspense, lazy } from "react";

type LucideIconType = keyof typeof dynamicIconImports;

interface ServiceIconProps extends Omit<LucideProps, "ref"> {
  name: LucideIconType;
}

function isLucideIconType(name: string): name is LucideIconType {
  return name in dynamicIconImports;
}

// Cache for lazy-loaded icons
const iconCache = new Map<LucideIconType, React.LazyExoticComponent<React.ComponentType<LucideProps>>>();

function getLazyIcon(name: LucideIconType) {
  if (!iconCache.has(name)) {
    iconCache.set(name, lazy(dynamicIconImports[name]));
  }
  return iconCache.get(name)!;
}

export const ServiceIcon = ({
  iconName,
  className,
  ...props
}: Omit<ServiceIconProps, "name"> & { iconName: string | null }) => {
  // check iconName is in dynamicIconImports
  if (!iconName || !isLucideIconType(iconName)) {
    return <LanguagesIcon className={cn("size-8 text-muted-foreground", className)} />;
  }

  return <LucidDynamicIcon name={iconName} className={className} {...props} />;
};

const LucidDynamicIcon = ({ name, className, ...props }: ServiceIconProps) => {
  const LucideIcon = getLazyIcon(name);
  return (
    <Suspense fallback={<LanguagesIcon className={cn("size-8 text-muted-foreground", className)} />}>
      <LucideIcon {...props} className={cn("size-8 text-muted-foreground", className)} />
    </Suspense>
  );
};
