import { useMatches } from "react-router";

export const useMatchedParam = (paramName: string) => {
  const matches = useMatches();

  if (matches.length === 0) {
    return null;
  }

  const params = matches.flatMap((m) => (Array.isArray(m.params) ? m.params : [m.params]));
  const match = params.find((params) => {
    return typeof params === "object" && paramName in params;
  });

  return match ? match[paramName] : null;
};
