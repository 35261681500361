import { type CarouselApi } from "@/components/ui/carousel";
import { User } from "@/model/user.typing";
import useHomePage from "@/pages/useHomePage";
import { CircleIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { CardContent } from "./ui/card";
import { Carousel, CarouselContent, CarouselItem } from "./ui/carousel";

export function HomePageHero({ user }: { user: User | null }) {
  const { getUserGreeting, getUserHeroSlides } = useHomePage(user);
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);
  const heroSlides = getUserHeroSlides();

  useEffect(() => {
    if (!api) return;
    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);
    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <div
      className="relative flex min-h-[400px] items-center rounded-2xl bg-cover bg-center text-white"
      style={{ backgroundImage: "url('/app/images/bg-hero.jpg')" }}
      data-test="home-hero-banner"
    >
      <div className="absolute inset-0 rounded-2xl bg-black opacity-25"></div>
      <div className="relative z-10 max-w-4xl px-4 sm:px-6 lg:px-8">
        <CardContent></CardContent>
        <CardContent>
          <h1 className="text-4xl">{getUserGreeting()}</h1>
          <Carousel setApi={setApi}>
            <CarouselContent>
              {heroSlides.map((slide) => (
                <CarouselItem key={slide.id}>
                  <p className="my-4">{slide.message}</p>
                  <div className="space-x-2">{slide.actionButton}</div>
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
          <div className="flex justify-center">
            {count > 1 &&
              Array.from(Array(count).keys()).map((i) => (
                <div className="mr-2 mt-6 inline-block cursor-pointer" key={i} onClick={() => api?.scrollTo(i)}>
                  <CircleIcon className={`size-3 w-6 text-white ${current - 1 === i && "fill-current"}`} />
                </div>
              ))}
          </div>
        </CardContent>
      </div>
    </div>
  );
}
