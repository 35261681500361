import { ComboboxOption } from "@/components/combobox/common/ComboboxOption";
import { BaseComboboxProps } from "@/components/combobox/common/combobox.typings";
import { IdName } from "@/model/common";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandList } from "components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Loader2 } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";
import { Button } from "../ui/button";
import { Tooltip, TooltipArrow, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";

interface ComboboxSearchProps<T extends IdName>
  extends Omit<BaseComboboxProps<T>, "placeholder" | "customValueLabel" | "renderSelected" | "title"> {
  searchTerm: string;
  tooltip?: string;
  onSearch: (searchTerm: string) => void;
  searchResults: T[];
  selected: T[];
  isLoading?: boolean;
  minSearchLength?: number;
  closeOnSelect?: boolean;
  onChange: (options: T[]) => void;
}

export function ComboboxSearch<T extends IdName>({
  searchTerm,
  selected = [],
  icon: Icon,
  className,
  onChange,
  testId = "",
  tooltip,
  hideCount = false,
  inError = false,
  closeOnSelect = false,
  formatCount,
  renderOption,
  triggerSize,
  onSearch,
  searchResults,
  isLoading = false,
  minSearchLength = 2,
}: ComboboxSearchProps<T>) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const hasNoResults = !isLoading && searchResults.length === 0 && searchTerm.length >= minSearchLength;
  const displayMinLength = !isLoading && searchTerm.length < minSearchLength;

  const onSelect = (option: T) => {
    onChange([...selected, option]);
  };
  const onDeselect = (option: T) => {
    onChange(selected.filter((v) => v.id !== option.id));
  };

  useEffect(() => {
    if (!open) {
      onSearch("");
    }
    return () => {
      onSearch("");
    };
  }, [open, onSearch]);

  const trigger = useMemo(() => {
    const buttonTrigger = (
      <Button
        data-test={`combobox-${testId}-trigger`}
        variant="outline"
        size={triggerSize}
        role="combobox"
        className={cn("group pr-2", { "border-spacing-6 border-red-400": inError })}
      >
        {Icon && <Icon data-test={`combobox-${testId}-trigger-icon`} className="mr-2 size-4 shrink-0" />}
      </Button>
    );

    const popoverTrigger = <PopoverTrigger asChild>{buttonTrigger}</PopoverTrigger>;

    if (tooltip) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>{popoverTrigger}</TooltipTrigger>
            <TooltipContent>
              {tooltip}
              <TooltipArrow />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    return popoverTrigger;
  }, [Icon, testId, triggerSize, inError, tooltip]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {trigger}
      <PopoverContent
        data-test={`combobox-${testId}-content`}
        className={cn("w-[var(--radix-popover-trigger-width)] min-w-[400px] p-0", className)}
        align="end"
      >
        <Command shouldFilter={false}>
          <CommandInput
            placeholder={t("requests.create.wizard.searchContacts")}
            data-test={`combobox-${testId}-input`}
            onValueChange={onSearch}
            value={searchTerm}
          >
            {isLoading ? (
              <div className="relative left-1 top-0">
                <Loader2 className="size-4 animate-spin" />
              </div>
            ) : null}
          </CommandInput>
          {hasNoResults && (
            <CommandEmpty data-test={`combobox-${testId}-empty`}>{t("translation:facets.not-found")}</CommandEmpty>
          )}
          {displayMinLength && (
            <CommandEmpty data-test={`combobox-${testId}-min-length`}>
              {t("common.search.minLength", { count: minSearchLength })}
            </CommandEmpty>
          )}
          <CommandList>
            {searchResults.length > 0 ? (
              <CommandGroup data-test={`combobox-${testId}-search-results`}>
                {searchResults.map((option) => {
                  const isSelected = selected.some((v) => v.id === option.id);
                  return (
                    <ComboboxOption
                      key={option.id}
                      option={option}
                      hideCount={hideCount}
                      isSelected={isSelected}
                      testId={testId}
                      onSelect={() => {
                        if (closeOnSelect) {
                          setOpen(false);
                        }
                        if (isSelected) {
                          onDeselect(option);
                        } else {
                          onSelect(option);
                        }
                      }}
                      renderOption={renderOption}
                      formatCount={formatCount}
                      useValueAsKey={true}
                    />
                  );
                })}
              </CommandGroup>
            ) : null}

            {searchTerm.length === 0 && selected.length > 0 ? (
              <CommandGroup data-test={`combobox-${testId}-selected-options`}>
                {selected.map((option) => (
                  <ComboboxOption
                    key={option.id}
                    option={option}
                    isSelected={true}
                    hideCount={hideCount}
                    testId={testId}
                    onSelect={() => {
                      if (closeOnSelect) {
                        setOpen(false);
                      }
                      onDeselect(option);
                    }}
                    renderOption={renderOption}
                    formatCount={formatCount}
                    useValueAsKey={true}
                  />
                ))}
              </CommandGroup>
            ) : null}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
