import type { DateRange } from "react-day-picker";
import type { BarRectangleItem } from "recharts/types/cartesian/Bar";
import type { IdName } from "./common";

export interface ProjectCostType {
  projectId: string;
  projectName: string;
  portalProjectId: string;
  clientId: number;
  clientName: string;
  currencyCode: string;
  currencyName: string;
  ownerFirstName: string | undefined | null;
  ownerLastName: string | undefined | null;
  startDate: Date;
  deliveryDate: Date | null; // this date is calculated from the languages delivery date
  price: number;
  priceString: string;
  languages: ProjectCostLanguageType[];
  ecosystemName: string;
}

export interface ProjectCostLanguageType {
  sourceLanguageName: string;
  sourceLanguageCode: string;
  targetLanguageName: string;
  targetLanguageCode: string;
  deliveryDate: Date | null;
  price: number;
}
export interface ProjectCostPerLanguagesType extends Omit<ProjectCostType, "languages"> {
  languagePairCode: string;
  languagePairName: string;
  sourceLanguageCode: string;
  sourceLanguageName: string;
  targetLanguageCode: string;
  targetLanguageName: string;
  perLanguagePrice: number;
}

export interface ProjectCostFilterValuesType {
  clientNames: IdName[];
  owners: IdName[];
  currencies: IdName[];
  sourceLanguages: IdName[];
  targetLanguages: IdName[];
  startDateMinMax: DateRange;
  deliveryDateMinMax: DateRange;
}
export type ChartSettingsType = {
  displayBrush: boolean;
  displayGrid: boolean;
};

export type ChartGroupToggleState = {
  xTranslated: boolean;
  repetitions: boolean;
  repsAnd100: boolean;
  m100: boolean;
  m95_99: boolean;
  m85_94: boolean;
  m75_84: boolean;
  m50_74: boolean;
  fuzzy: boolean;
  noMatch: boolean;
  words: boolean;
};

export interface TmLeverageType {
  projectId: string;
  portalProjectId: string;
  projectName: string;
  clientId: number;
  clientName: string;
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  startDate: Date;
  languages: TmLeverageLanguageType[];
  ecosystemName: string;
}

export interface TmLeverageLanguageType {
  id: string;
  projectId: string;
  sourceName: string;
  sourceCode: string;
  targetName: string;
  targetCode: string;
  deliveryDate: Date;
  words: number;
  noMatch: number;
  xTranslated: number;
  repetitions: number;
  m100: number;
  m95_99: number;
  m85_94: number;
  m75_84: number;
  m50_74: number;
}

export interface TmLeveragePerLanguagesType extends TmLeverageLanguageType {
  portalProjectId: string;
  projectName: string;
  client: IdName<number>;
  owner: IdName | null;
  startDate: Date;
  repsAnd100: number;
  fuzzy: number;
  ecosystemName: string;
}

export interface PerLanguageBarItemType extends BarRectangleItem {
  languageCode: string;
  owner: string;
  monthYear: Date;
}

/// Filter types

interface CommonFiltersType {
  searchText: string;
  clientNameIds: string[];
  ownersIds: string[];
  targetLanguageIds: string[];
  sourceLanguageIds: string[];
  divisionNames: string[];
  deliveryDate?: DateRange;
}

export interface ProjectCostFiltersType extends CommonFiltersType {
  currencyId: string | null;
  startDate?: DateRange;
  deliveryDate?: DateRange;
  includeOpenProjects: boolean;
}

export type TmLeverageFiltersType = CommonFiltersType;

export const CostReportChartNames = {
  perLanguage: "per-target-language" as const,
  perMonth: "per-month" as const,
  perQuarter: "per-quarter" as const,
  perRequestor: "per-requestor" as const,
};

export type CostReportChartNamesType = (typeof CostReportChartNames)[keyof typeof CostReportChartNames];

export const TmLeverageChartNames = {
  perLanguage: "per-target-language" as const,
  perMonthPercentage: "per-month-percentage" as const,
  perMonthWords: "per-month-words" as const,
};

export type TmLeverageChartNamesType = (typeof TmLeverageChartNames)[keyof typeof TmLeverageChartNames];
