import { IdName } from "@/model/common";
import {
  closestCorners,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { arrayMove } from "@dnd-kit/sortable";
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  functionalUpdate,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  Table as TanstackTable,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import { Checkbox } from "components/ui/checkbox";
import { Table } from "components/ui/table";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "../ui/skeleton";
import { DatatableBody } from "./components/DatatableBody";
import { DatatableColumnVisibilityDropdown } from "./components/DatatableColumnVisibilityDropdown";
import { DatatableDeleteAction } from "./components/DatatableDeleteAction";
import { DatatableDownloadAction } from "./components/DatatableDownloadAction";
import { DatatableFiltersListDropdown, DatatableFiltersSection } from "./components/DataTableFacetedFilter";
import { DatatableGlobalFilter } from "./components/DatatableGlobalFilter";
import { DatatableHeader } from "./components/DatatableHeader";
import { DatatablePagination } from "./components/DatatablePagination";
import { globalFilterWithHighlighting, GlobalFilterWithHighlightingConfig } from "./filtersWithHighlighting";
import { useDataTableColumnSettings } from "./hooks/useDatatableColumnSettings";
import { useDatatableFilters } from "./hooks/useDatatableFilters";

interface DatatableProps<TData> {
  uniqueName: string;
  activeRowId?: string;
  data: TData[];
  isWithSelectColumn?: boolean;
  searchTerm?: string | null;
  onSearch?: (term: string) => void;
  sortBy: SortingState;
  onSortBy: (sort: SortingState) => void;
  columns: Array<ColumnDef<TData>>;
  onRowClick?: (row: TData) => void;
  hasDetailsDrawer?: boolean;
  defaultPageSize?: number;
  facetedFilters?: (table: TanstackTable<TData>, displayedFilters: IdName<string>[]) => ReactElement;
  showViews: boolean;
  showResetView?: boolean;
  onDeleteSelected?: (rows: TData[]) => Promise<boolean>;
  showSearch?: boolean;
  defaultColumnVisibility?: VisibilityState;
  defaultColumnOrder?: ColumnOrderState;
  isLoading?: boolean;
  downloadAction?: {
    onDownload: (rows: Record<string, unknown>[]) => void;
    isDownloading: boolean;
    processRecord?: (
      record: Record<string, unknown>,
      row: TData,
      columnDef: ColumnDef<TData, unknown>
    ) => Record<string, unknown>;
  };
}

export function DataTable<TData>({
  uniqueName,
  data,
  columns,
  searchTerm = null,
  sortBy = [],
  onSortBy,
  onSearch,
  isWithSelectColumn = false,
  defaultPageSize = 10,
  onRowClick,
  hasDetailsDrawer = false,
  activeRowId,
  facetedFilters,
  showViews,
  showResetView = true,
  onDeleteSelected,
  showSearch = true,
  defaultColumnVisibility,
  defaultColumnOrder,
  isLoading = false,
  downloadAction,
}: DatatableProps<TData>) {
  const { t } = useTranslation("translation");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const { columnVisibility, columnOrder, isDefaultSettings, setColumnVisibility, setColumnOrder, resetColumnSettings } =
    useDataTableColumnSettings(uniqueName, columns, { order: defaultColumnOrder, visibility: defaultColumnVisibility });
  const [rowSelection, setRowSelection] = useState({});
  const [isDetailDrawerOpen, setIsDetailDrawerOpen] = useState(false);

  useEffect(() => {
    setIsDetailDrawerOpen(hasDetailsDrawer && !!activeRowId);
  }, [hasDetailsDrawer, activeRowId]);

  const [globalFilter, setGlobalFilter] = useState<GlobalFilterWithHighlightingConfig | undefined>(
    searchTerm && searchTerm.length > 0 ? { term: searchTerm } : undefined
  );

  const allColumn = useMemo(() => {
    if (isWithSelectColumn) {
      const selectColumn: ColumnDef<TData> = {
        id: "select",
        enableGlobalFilter: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableHiding: false,
        header: ({ table }) => (
          <Checkbox
            className="align-middle"
            checked={table.getIsAllPageRowsSelected()}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label={t("data-table.selection.selectAll")}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            className="align-middle"
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label={t("data-table.selection.selectRow")}
          />
        ),
      };
      return [selectColumn, ...columns];
    }
    return columns;
  }, [columns, isWithSelectColumn, t]);

  const table = useReactTable<TData>({
    data,
    columns: allColumn,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: (onSortingChange) => {
      const newValue = functionalUpdate(onSortingChange, sortBy);
      onSortBy(newValue);
    },
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: (globalFilter) => {
      setGlobalFilter(globalFilter);
      onSearch?.(globalFilter?.term ?? "");
    },
    globalFilterFn: globalFilterWithHighlighting,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    initialState: {
      pagination: { pageSize: defaultPageSize },
    },
    state: {
      sorting: sortBy,
      columnFilters,
      columnVisibility: columnVisibility,
      rowSelection,
      globalFilter: globalFilter,
      columnOrder: isWithSelectColumn ? ["select", ...columnOrder] : columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
  });

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = columnOrder.indexOf(over.id as string);
        return arrayMove(columnOrder, oldIndex, newIndex);
      });
    }
  }

  function resetDefaultView() {
    resetColumnSettings();
  }

  if (isLoading) {
    return (
      <div className="mt-5">
        <Skeleton className="h-72 w-full" />
      </div>
    );
  }

  return (
    <div className="w-full">
      <DatatableFiltersAndActions
        table={table}
        uniqueName={uniqueName}
        showSearch={showSearch}
        showViews={showViews}
        showResetView={showResetView && !isDefaultSettings}
        rowSelection={rowSelection}
        downloadAction={downloadAction}
        facetedFilters={facetedFilters}
        onDeleteSelected={onDeleteSelected}
        onResetDefaultView={resetDefaultView}
      />
      <div className="mt-4 rounded-lg border dark:border-border">
        <DndContext
          collisionDetection={closestCorners}
          modifiers={[restrictToHorizontalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <Table className="overflow-x-auto" data-test="data-table">
            <DatatableHeader headerGroups={table.getHeaderGroups()} columnOrder={columnOrder} />
            <DatatableBody
              rows={table.getRowModel().rows}
              activeRowId={activeRowId}
              columnOrder={columnOrder}
              onRowClick={onRowClick}
            />
          </Table>
        </DndContext>
        <DatatablePagination
          totalCount={table.getFilteredRowModel().rows.length}
          pageSize={table.getState().pagination.pageSize}
          currentPage={table.getState().pagination.pageIndex + 1}
          onPaginationChange={(pagination) => {
            table.setPageSize(pagination.pageSize);
            table.setPageIndex(pagination.pageIndex - 1);
          }}
          isShrinkedLeft={isDetailDrawerOpen}
        />
      </div>
    </div>
  );
}

interface DatatableFiltersAndActionsProps<TData> {
  table: TanstackTable<TData>;
  uniqueName: string;
  showSearch?: boolean;
  showViews: boolean;
  showResetView: boolean;
  rowSelection: RowSelectionState;
  downloadAction?: {
    onDownload: (rows: Record<string, unknown>[]) => void;
    isDownloading: boolean;
    processRecord?: (
      record: Record<string, unknown>,
      row: TData,
      columnDef: ColumnDef<TData, unknown>
    ) => Record<string, unknown>;
  };
  facetedFilters?: (table: TanstackTable<TData>, displayedFilters: IdName<string>[]) => ReactElement;
  onDeleteSelected?: (rows: TData[]) => Promise<boolean>;
  onResetDefaultView?: () => void;
}

function DatatableFiltersAndActions<TData>({
  table,
  uniqueName,
  showSearch = true,
  showViews,
  showResetView,
  rowSelection,
  downloadAction,
  facetedFilters,
  onDeleteSelected,
  onResetDefaultView,
}: DatatableFiltersAndActionsProps<TData>) {
  const { displayedFilters, setDisplayedFilters, isFiltered } = useDatatableFilters(table, uniqueName);

  return (
    <>
      <div className="flex flex-wrap gap-2 max-xl:flex-col">
        <div className="flex flex-1 flex-wrap justify-between gap-2 max-lg:w-full">
          {showSearch && <DatatableGlobalFilter table={table} />}
          {facetedFilters && (
            <DatatableFiltersListDropdown
              table={table}
              id={`${uniqueName}-filter-list`}
              selectedIds={displayedFilters.map((x) => x.id)}
              onFiltersListChange={setDisplayedFilters}
            />
          )}
        </div>
        <div className="flex flex-1 flex-wrap justify-end gap-2 max-lg:w-full">
          {Object.keys(rowSelection).length > 0 && (
            <DatatableDeleteAction
              selectedRowCount={table.getSelectedRowModel().rows.length}
              onConfirmDeletion={() => {
                return onDeleteSelected?.(table.getSelectedRowModel().rows.map((x) => x.original)).then(() => {
                  table.resetRowSelection();
                  return true;
                });
              }}
            />
          )}
          {downloadAction && (
            <DatatableDownloadAction downloadAction={downloadAction} rows={table.getSortedRowModel().rows} />
          )}
          {showViews && (
            <DatatableColumnVisibilityDropdown
              columns={table.getAllColumns()}
              onResetDefaultView={showResetView ? onResetDefaultView : undefined}
            />
          )}
        </div>
      </div>
      <div>
        {facetedFilters && displayedFilters.length > 0 && (
          <DatatableFiltersSection
            filters={facetedFilters(table, displayedFilters)}
            isFiltered={isFiltered}
            onResetClick={() => {
              table.resetColumnFilters();
              table.resetGlobalFilter();
            }}
          />
        )}
      </div>
    </>
  );
}
