import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChartSettingsType, CostReportChartNamesType, TmLeverageChartNamesType } from "@/model/report.typing";
import { clsx } from "clsx";
import { CheckIcon, ExpandIcon, MinimizeIcon, PrinterIcon, ScanEyeIcon, SettingsIcon } from "lucide-react";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { ResponsiveContainer } from "recharts";

type ChartType = CostReportChartNamesType | TmLeverageChartNamesType;

export interface Props<T extends ChartType> {
  link: T;
  title: string;
  description?: string;
  isFocusView: boolean;
  height: number;
  chartSettings: ChartSettingsType;
  saveChartSettings: (chartType: T, value: Partial<ChartSettingsType>) => void;
  renderAction?: () => ReactElement;
  renderBeforeChart?: () => ReactElement;
  children: (options: ChartSettingsType) => ReactElement;
}

export function ChartCard<T extends ChartType>({
  link, // is also used as testId and as chartType
  title,
  description,
  isFocusView,
  chartSettings,
  saveChartSettings,
  renderBeforeChart,
  renderAction,
  children,
  height,
}: Props<T>) {
  const { t } = useTranslation();
  const [fullScreen, setFullScreen] = useState(false);
  const { displayBrush, displayGrid } = chartSettings ?? {};
  const { search } = useLocation();

  return (
    <Card
      data-test={`chart-${link}-container`}
      className={clsx({ "fixed inset-x-0 top-0 z-40 min-h-full px-6": fullScreen })}
    >
      <CardHeader>
        <CardTitle className="flex items-center justify-between gap-2">
          <div data-test={`chart-${link}-title`} className={clsx("flex-1", { "hover:underline": !isFocusView })}>
            {isFocusView ? title : <Link to={link + search}>{title}</Link>}
          </div>
          <div data-test={`chart-${link}-actions`} className="flex items-center gap-2">
            {renderAction ? renderAction() : null}
            {fullScreen ? (
              <Button
                data-test={`chart-${link}-minimize-action`}
                size="sm"
                variant="secondary"
                className="flex cursor-pointer items-center gap-2 print:hidden"
                onClick={() => setFullScreen(!fullScreen)}
              >
                <MinimizeIcon className="size-4" />
                <span>{t("reports.charts.options.exitFullScreen")}</span>
              </Button>
            ) : null}
            <DropdownMenu data-test={`chart-${link}-settings`}>
              <DropdownMenuTrigger data-test={`chart-${link}-settings-trigger`} className="print:hidden" asChild>
                <Button size="icon" variant="secondary">
                  <SettingsIcon className="size-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent data-test={`chart-${link}-settings-content`} align="end">
                {!isFocusView ? (
                  <DropdownMenuItem data-test={`chart-${link}-settings-focus-view`} asChild>
                    <Link
                      data-test={`chart-${link}-settings-focus-action`}
                      to={link + search}
                      className="flex cursor-pointer items-center gap-2"
                    >
                      <ScanEyeIcon className="size-4" />
                      {t("reports.charts.options.focusView")}
                    </Link>
                  </DropdownMenuItem>
                ) : null}
                {!fullScreen ? (
                  <DropdownMenuItem
                    data-test={`chart-${link}-settings-full-screen-action`}
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => setFullScreen(!fullScreen)}
                  >
                    <ExpandIcon className="size-4" />
                    <span>{t("reports.charts.options.fullScreen")}</span>
                  </DropdownMenuItem>
                ) : null}

                <DropdownMenuSub data-test={`chart-${link}-subsetting`}>
                  <DropdownMenuSubTrigger
                    data-test={`chart-${link}-subsetting-trigger`}
                    direction="left"
                    className="flex items-center gap-2"
                  >
                    {t("reports.charts.options.chartSettings")}
                  </DropdownMenuSubTrigger>
                  <DropdownMenuSubContent>
                    <DropdownMenuItem
                      data-test={`chart-${link}-subsetting-display-grid-action`}
                      className="flex cursor-pointer items-center gap-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        saveChartSettings(link, { displayGrid: !displayGrid });
                      }}
                    >
                      {displayGrid ? <CheckIcon className="size-4" /> : <span className="size-4" />}
                      <span>{t("reports.charts.options.grid")}</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      data-test={`chart-${link}-subsetting-display-brush-action`}
                      className="flex cursor-pointer items-center gap-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        saveChartSettings(link, { displayBrush: !displayBrush });
                      }}
                    >
                      {displayBrush ? <CheckIcon className="size-4" /> : <span className="size-4" />}
                      <span>{t("reports.charts.options.brush")}</span>
                    </DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuSub>
                {fullScreen ? (
                  <>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      data-test={`chart-${link}-settings-print-action`}
                      className="flex cursor-pointer items-center gap-2"
                      onClick={() => window.print()}
                    >
                      <PrinterIcon className="size-4" />
                      <span>{t("reports.charts.options.print")}</span>
                    </DropdownMenuItem>
                  </>
                ) : null}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </CardTitle>
        {description ? <CardDescription>{description}</CardDescription> : null}
      </CardHeader>
      <CardContent data-test={`chart-${link}-content`} style={{ height: fullScreen ? "calc(100vh - 200px)" : height }}>
        <div className="flex size-full flex-col">
          {renderBeforeChart ? renderBeforeChart() : null}
          <ResponsiveContainer width="100%" height="100%">
            {children({ displayBrush, displayGrid })}
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
}
