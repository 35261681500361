import { useTranslation } from "react-i18next";
import ErrorLayoutPage from "routes/layout/ErrorLayoutPage";

function GenericErrorPage() {
  const { t } = useTranslation("error");

  return (
    <ErrorLayoutPage
      errorCode={"generic"}
      title={t("page.generic.title")}
      description={t("page.generic.description")}
    />
  );
}

export default GenericErrorPage;
