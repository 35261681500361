import { ContentCard } from "@/components/ContentCard";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { SettingsItem, UserPreferences, UserProfileFormModel } from "@/model/user.typing";
import { useUpdateUserPreferencesMutation } from "@/query/user.query";
import { mapCurrencySymbols } from "@/utils/mapping";
import { Loader2Icon } from "lucide-react";
import { FormEvent, useEffect, useMemo } from "react";
import { Control, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

const getProfileSettingsByCulture = (userPreferences: UserPreferences, cultureCode: string) => {
  if (!userPreferences?.profileSettings) return null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const profileSettings = JSON.parse(userPreferences.profileSettings) as Record<string, any>;
  return profileSettings[cultureCode];
};

const useTimeFormats = (userPreferences: UserPreferences, cultureCode: string) => {
  return useMemo<SettingsItem[]>(() => {
    const neededProfile = getProfileSettingsByCulture(userPreferences, cultureCode);
    if (!neededProfile) {
      return [];
    }
    return neededProfile.TimeFormats.map((item: { Name: string; Value: string }) => ({
      name: item.Name,
      value: item.Value,
    }));
  }, [userPreferences, cultureCode]);
};

const useCurrencies = (userPreferences: UserPreferences, cultureCode: string, currencySymbolPosition: string) => {
  const numberFormats = useMemo(() => {
    if (!userPreferences?.numberFormats) return [];
    return userPreferences.numberFormats;
  }, [userPreferences]);

  const currencySymbols = useMemo<SettingsItem[]>(() => {
    const neededProfile = getProfileSettingsByCulture(userPreferences, cultureCode);
    if (!neededProfile) {
      return [];
    }
    const currentNumberFormat = numberFormats.find(({ value }) => value === currencySymbolPosition);
    return mapCurrencySymbols(currentNumberFormat?.name, neededProfile.CurrencySymbol);
  }, [userPreferences, cultureCode, numberFormats, currencySymbolPosition]);

  return currencySymbols;
};

export function UserProfileForm({ userPreferences }: { userPreferences: UserPreferences }) {
  const { t } = useTranslation();
  const { mutate, isPending } = useUpdateUserPreferencesMutation();

  const { setValue, ...form } = useForm<UserProfileFormModel>({
    values: {
      language: userPreferences.cultureCode,
      timezone: userPreferences.timezoneId,
      dateFormat: userPreferences.cultureDate,
      timeFormat: userPreferences.cultureTime,
      decimalFormat: userPreferences.cultureNumber,
      currencySymbol: userPreferences.currencyPositivePattern?.toString() ?? "0",
    },
  });

  const language = form.watch("language");
  const decimalFormat = form.watch("decimalFormat");

  const timeFormats = useTimeFormats(userPreferences, language);
  const currencySymbols = useCurrencies(userPreferences, language, decimalFormat);

  const lists = useMemo(() => {
    const { languages = [], timezones = [], dateFormats = [], numberFormats = [], timeFormats } = userPreferences ?? {};
    return { languages, timezones, dateFormats, numberFormats, timeFormats };
  }, [userPreferences]);

  useEffect(() => {
    const neededProfile = getProfileSettingsByCulture(userPreferences, language);
    if (!neededProfile) return;
    // set the default values of the selected language for the date, time, and decimal format
    setValue("dateFormat", neededProfile.CultureDate, { shouldDirty: true });
    setValue("timeFormat", neededProfile.CultureTime, { shouldDirty: true });
    // check if the number format is available in the list
    const hasNumberFormat = lists.numberFormats.findIndex(({ value }) => value === neededProfile.CultureNumber) !== -1;
    if (hasNumberFormat) {
      setValue("decimalFormat", neededProfile.CultureNumber, { shouldDirty: true });
    }
  }, [userPreferences, language, setValue, lists.numberFormats]);

  const handleUpdate = (e: FormEvent<HTMLFormElement>) => {
    if (!form.formState.isDirty) {
      e.preventDefault();
      e.stopPropagation();
      toast.success(t("profile.preferencesUpdated"), { id: "profile-preferences-updated" });
      return;
    }
    return form.handleSubmit((data) => mutate(data))(e);
  };

  return (
    <ContentCard title={t("profile.preferences")} description={t("profile.description")}>
      <Form setValue={setValue} {...form}>
        <form className="max-w-4xl" onSubmit={(e) => handleUpdate(e)} onReset={() => form.reset()}>
          <div className="mb-6 space-y-6 md:space-y-4">
            <UserProfileField
              control={form.control}
              name="language"
              label={t("profile.language")}
              items={lists.languages}
            />
            <UserProfileField
              control={form.control}
              name="timezone"
              label={t("profile.timezone")}
              items={lists.timezones}
            />
            <UserProfileField
              control={form.control}
              name="dateFormat"
              label={t("profile.dateFormat")}
              items={lists.dateFormats}
            />
            <UserProfileField
              control={form.control}
              name="timeFormat"
              label={t("profile.timeFormat")}
              items={timeFormats}
            />
            <UserProfileField
              control={form.control}
              name="decimalFormat"
              label={t("profile.decimalFormat")}
              items={lists.numberFormats}
            />
            <UserProfileField
              control={form.control}
              name="currencySymbol"
              label={t("profile.currencySymbol")}
              items={currencySymbols}
            />
          </div>
          <div className="grid items-center gap-2 py-2 md:grid-cols-4">
            <div className="flex items-center gap-2 md:col-start-2">
              <Button disabled={isPending} type="submit">
                {isPending ? <Loader2Icon className="mr-2 animate-spin" /> : null}
                {t("profile.save")}
              </Button>
              <Button disabled={isPending || !form.formState.isDirty} type="reset" variant={"secondary"}>
                {t("profile.reset")}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </ContentCard>
  );
}

interface UserProfileFieldProps {
  control: Control<UserProfileFormModel>;
  name: keyof UserProfileFormModel;
  label: string;
  items: SettingsItem[];
}

function UserProfileField({ control, name, label, items }: UserProfileFieldProps) {
  const { t } = useTranslation();
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, formState, fieldState }) => (
        <FormItem className="grid items-center md:grid-cols-4 md:gap-x-4 md:rounded md:px-2 md:hover:bg-muted/40">
          <FormLabel className="md:text-right">{label}</FormLabel>
          <div className="col-span-3 h-12 self-center">
            <Select disabled={formState.isSubmitting} onValueChange={field.onChange} value={field.value}>
              <FormControl>
                <SelectTrigger className={fieldState.isDirty ? "font-medium text-ribbon" : ""}>
                  <SelectValue />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {items.map((item) => (
                  <SelectItem key={item.value} value={item.value}>
                    {item.name}
                  </SelectItem>
                ))}
              </SelectContent>
              {name === "language" ? (
                <FormDescription
                  title={t("profile.languageHint")}
                  className="mt-1 hidden truncate px-2 text-xs md:block"
                >
                  {t("profile.languageHint")}
                </FormDescription>
              ) : null}
            </Select>
          </div>
        </FormItem>
      )}
    />
  );
}
