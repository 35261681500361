import Loader from "@/components/Loader";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { TerminologyItem } from "@/model/mt.typing";
import { useDeleteTerminologyItemMutation, useGetTerminologyMutation } from "@/query/mt/machinetranslation.query";
import { mapTerminologyItemLanguages } from "@/utils/mapping";
import { t } from "i18next";
import { toast } from "sonner";
import useGlossary from "../useGlossary";
import { TermDetails } from "./TermDetails";
import { TermListItem } from "./TermListItem";
import { FloatingLabelInput } from "@/components/input/FloatingInput";

interface TermListProps {
  items: TerminologyItem[];
}

export const TermList = (props: TermListProps) => {
  const { items } = props;

  const {
    terminologyConfiguration,
    currentTerminology,
    setCurrentTerminology,
    filter,
    filteredTermItems,
    changeFilter,
  } = useGlossary();

  const { isFetching, fetchTerminology } = useGetTerminologyMutation();
  const { isFetchingDeleteItem, deleteTerminologyItem } = useDeleteTerminologyItemMutation();

  if (isFetching || isFetchingDeleteItem) return <Loader isShown={true} variant="md" className="my-2" />;

  const terms = filter != "" ? filteredTermItems : items;

  const handleDelete = async (id: string) => {
    if (currentTerminology) {
      const result = await deleteTerminologyItem({
        customerId: terminologyConfiguration.customerId,
        instanceId: terminologyConfiguration.instanceId,
        terminologyId: currentTerminology.id,
        termId: id,
      });

      if (result.success) {
        const updatedTerminology = await fetchTerminology({
          customerId: terminologyConfiguration.customerId,
          terminologyId: currentTerminology.id,
        });
        setCurrentTerminology(
          mapTerminologyItemLanguages(terminologyConfiguration.mappedLanguages, updatedTerminology)
        );
      } else {
        toast.warning(t("mt.glossary.deleteTerminologyItemError"));
      }
    }
  };

  const renderTermItems = () => {
    // sort by language then by source text
    terms.sort(
      (a, b) =>
        (a.sourceLanguageName && b.sourceLanguageName && a.sourceLanguageName.localeCompare(b.sourceLanguageName)) ||
        a.source.localeCompare(b.source)
    );

    return terms.length === 0 ? (
      <Alert className="my-2" variant="warning">
        <AlertDescription>{t("mt.noMatches")}</AlertDescription>
      </Alert>
    ) : (
      terms.map((item: TerminologyItem) => (
        <div key={item.id} data-test={`mt-glossary-term-list-${item.id}`}>
          <TermListItem item={item} handleDelete={handleDelete} />
        </div>
      ))
    );
  };

  return (
    <div className="mt-2" data-test="glossary-term-list">
      <hr className="mb-3 mt-1 h-px border-0 bg-gray-200 dark:bg-gray-700"></hr>
      {(terms === null || (terms && terms.length == 0)) && (
        <Alert variant="info" className="mb-2">
          <AlertDescription>{t("mt.glossary.termMessage")}</AlertDescription>
        </Alert>
      )}

      {terms && terms.length < 500 && <TermDetails />}

      {items.length > 0 && (
        <>
          <FloatingLabelInput
            type="search"
            label={t("common.search.label")}
            data-test="mt-glossary-term-search"
            className="my-2 h-8"
            value={filter}
            onChange={(e) => {
              changeFilter(e.target.value);
            }}
          />
          <div data-test="glossary-term-list-items">{renderTermItems()}</div>
        </>
      )}
    </div>
  );
};
