import { Button } from "@/components/ui/button";
import { ArrowRightIcon, Ban, Loader2Icon, Pencil, Save, Trash } from "lucide-react";
import { Terminology, TerminologyItem, TermPayload } from "@/model/mt.typing";
import { TooltipProvider } from "@/components/ui/tooltip";
import { t } from "i18next";
import PortalTooltip from "@/components/PortalTooltip";
import { useState } from "react";
import { useEditTerminologyItemMutation } from "@/query/mt/machinetranslation.query";
import useGlossary from "../useGlossary";
import { mapTerminologyItemLanguages } from "@/utils/mapping";
import { FloatingLabelInput } from "@/components/input/FloatingInput";

interface TermListItemProps {
  item: TerminologyItem;
  handleDelete: (id: string) => Promise<void>;
}

export const TermListItem = (props: TermListItemProps) => {
  const { item, handleDelete } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [source, setSource] = useState<string>("");
  const [target, setTarget] = useState<string>("");

  const {
    currentTerminology,
    terminologyConfiguration,
    setCurrentTerminology,
    updateTerminologyStoredSettings,
    terminologyStoredSettings,
  } = useGlossary();

  const { editTerminologyItem, isFetchingEditItem } = useEditTerminologyItemMutation();

  const handleSave = async () => {
    const payload: TermPayload = {
      source,
      target,
      sourceLanguageCode: item.sourceLanguageCode,
      targetLanguageCode: item.targetLanguageCode,
    };

    if (currentTerminology && checkForDuplicates(currentTerminology, payload, item)) {
      let result: Terminology;

      result = await editTerminologyItem({
        customerId: terminologyConfiguration.customerId,
        instanceId: terminologyConfiguration.instanceId,
        terminologyId: currentTerminology.id,
        termId: item.id,
        payload,
      });

      result = mapTerminologyItemLanguages(terminologyConfiguration.mappedLanguages, result);
      setCurrentTerminology(result);
      updateTerminologyStoredSettings({ ...terminologyStoredSettings, terminologyChanged: true });
    }
  };

  const renderViewActionButtons = () => {
    return (
      <div className="flex items-center gap-1">
        <PortalTooltip text={t("common.edit")}>
          <Button
            type="button"
            variant="outline"
            className="h-8 align-middle text-xs"
            size="sm"
            onClick={() => {
              setIsEdit(true);
              setSource(item.source);
              setTarget(item.target);
            }}
          >
            <Pencil className="size-4" />
          </Button>
        </PortalTooltip>
        <PortalTooltip text={t("common.delete")}>
          <Button
            type="button"
            variant="outline"
            className="h-8 align-middle text-xs"
            size="sm"
            onClick={async () => await handleDelete(item.id)}
          >
            <Trash className="size-4" />
          </Button>
        </PortalTooltip>
      </div>
    );
  };

  const renderEditActionButtons = () => {
    return (
      <div className="flex items-center gap-1">
        <PortalTooltip text={t("common.cancel")}>
          <Button
            disabled={isFetchingEditItem}
            type="button"
            variant="outline"
            className="h-8 align-middle text-xs"
            size="sm"
            onClick={() => setIsEdit(false)}
          >
            <Ban className="size-4" />
          </Button>
        </PortalTooltip>
        <PortalTooltip text={t("common.save")}>
          <Button
            disabled={isFetchingEditItem || source.length === 0 || target.length === 0}
            type="button"
            variant="outline"
            className="h-8 align-middle text-xs"
            size="sm"
            onClick={async () => await handleSave()}
          >
            {isFetchingEditItem ? (
              <Loader2Icon className="size-4 shrink-0 animate-spin" />
            ) : (
              <Save className="size-4" />
            )}
          </Button>
        </PortalTooltip>
      </div>
    );
  };

  return (
    <div className="my-2 rounded-sm border px-4 py-2">
      <div className="flex items-center justify-between gap-2">
        <div className="inline-flex opacity-50">
          <span>{item.sourceLanguageName ?? item.sourceLanguageCode}</span>
          <ArrowRightIcon className="mx-1 mt-1 size-4" />
          <span>{item.targetLanguageName ?? item.sourceLanguageCode}</span>
        </div>
        <TooltipProvider delayDuration={0}>
          {isEdit ? renderEditActionButtons() : renderViewActionButtons()}
        </TooltipProvider>
      </div>

      {isEdit ? (
        <div className="my-2 inline-flex items-center">
          <FloatingLabelInput
            disabled={isFetchingEditItem}
            label={t("mt.glossary.enterTerm")}
            data-test="mt-terminology-item-source-text"
            maxLength={256}
            value={source}
            onChange={(e) => {
              setSource(e.target.value);
            }}
          />
          <ArrowRightIcon className="mx-1 size-8" />
          <FloatingLabelInput
            disabled={isFetchingEditItem}
            label={t("mt.glossary.enterTranslation")}
            data-test="mt-terminology-item-target-text"
            maxLength={256}
            value={target}
            onChange={(e) => {
              setTarget(e.target.value);
            }}
          />
        </div>
      ) : (
        <div>
          {item.source}
          <br />
          <p className="italic">{item.target}</p>
        </div>
      )}
    </div>
  );
};

function checkForDuplicates(terminology: Terminology, newItem: TermPayload, currentItem: TerminologyItem | null) {
  const duplicates = terminology.terminologyItems.filter(
    (x) =>
      x.source === newItem.source &&
      x.sourceLanguageCode === newItem.sourceLanguageCode &&
      x.targetLanguageCode === newItem.targetLanguageCode
  );

  if (currentItem !== null && duplicates.find((x) => x.id === currentItem.id) !== undefined) return true;

  return duplicates.length <= 0;
}
