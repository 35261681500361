import { CellContext } from "@tanstack/react-table";
import { getHighlightRanges } from "../filtersWithHighlighting";

export function DatatableCellWithHighlighting<T>({ value, cell }: { value: string; cell: CellContext<T, string> }) {
  const ranges = getHighlightRanges(cell);

  const elements: JSX.Element[] = [];
  let lastEndIndex = 0;

  ranges.forEach(([start, end]) => {
    // Add unhighlighted text before the current range
    if (start > lastEndIndex) {
      elements.push(<span key={`span-${lastEndIndex}-${start}`}>{value.slice(lastEndIndex, start)}</span>);
    }

    // Add highlighted text
    elements.push(<mark key={`mark-${start}-${end}`}>{value.slice(start, end)}</mark>);

    // Update the last processed index
    lastEndIndex = end;
  });

  // Add remaining unhighlighted text after the last range
  if (lastEndIndex < value.length) {
    elements.push(<span key={`span-last-${lastEndIndex}`}>{value.slice(lastEndIndex)}</span>);
  }

  return <>{elements}</>;
}
