import { cn } from "@/utils/ui";
import { cva, type VariantProps } from "class-variance-authority";
import { XIcon } from "lucide-react";
import { forwardRef, HTMLAttributes } from "react";

const alertVariants = cva(
  "relative w-full rounded-lg border-0 border-l-4 p-3 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground [&>svg~*]:pl-7",
  {
    variants: {
      variant: {
        default: "bg-background text-foreground",
        destructive:
          "border-destructive/50 bg-red-50 text-destructive dark:border-destructive dark:bg-transparent [&>svg]:text-destructive",
        info: "border-blue-500/50 bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-200 [&>svg]:text-blue-500 dark:[&>svg]:text-blue-200",
        warning:
          "border-yellow-500/50 bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-200 [&>svg]:text-yellow-500 dark:[&>svg]:text-yellow-200",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

const Alert = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants> & { onClose?: () => void }
>(({ className, variant, children, onClose, ...props }, ref) => (
  <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props}>
    {children}
    {onClose ? (
      <button type="button" onClick={onClose} className="absolute right-2 top-2 cursor-pointer hover:opacity-60">
        <XIcon className="size-4 shrink-0" />
      </button>
    ) : null}
  </div>
));
Alert.displayName = "Alert";

const AlertTitle = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h5 ref={ref} className={cn("mb-1 font-medium leading-none tracking-tight", className)} {...props} />
  )
);
AlertTitle.displayName = "AlertTitle";

const AlertDescription = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn("text-sm [&_p]:leading-relaxed", className)} {...props} />
  )
);
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertDescription, AlertTitle };
