import { clsx } from "clsx";
import { Button } from "components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Check, ChevronsUpDown } from "lucide-react";
import { LanguageOption, TextMachineTranslationFormModel } from "model/mt.typing";
import { useState } from "react";
import { ControllerRenderProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

interface LanguageSelectorProps {
  placeholder: string;
  languages: LanguageOption[] | null;
  field:
    | ControllerRenderProps<TextMachineTranslationFormModel, "targetLanguage">
    | ControllerRenderProps<TextMachineTranslationFormModel, "sourceLanguage">;

  onSelect: (value: LanguageOption) => void;
  closeOnSelect?: boolean;
}

// todo use ComboBox component
export const LanguageSelector = ({
  field,
  languages = [],
  onSelect,
  placeholder,
  closeOnSelect = true,
}: LanguageSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          data-test={`mt-language-selection-btn-${field.name}`}
          className="justify-between dark:bg-transparent"
          disabled={languages?.length === 0}
        >
          {field.value ? languages?.find((language) => language.code === field.value?.code)?.label : placeholder}
          <ChevronsUpDown className="size-4 shrink-0 text-muted-foreground" />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="w-[var(--radix-popover-trigger-width)] p-0">
        <Command>
          <CommandInput
            data-test={`mt-language-selection-${field.name}-search`}
            placeholder={t("common.search.placeholder")}
          />
          <CommandList>
            <CommandEmpty>{t(`mt.noLanguageFound`)}</CommandEmpty>
            <CommandGroup>
              {languages?.map((language) => (
                <CommandItem
                  value={language.label}
                  key={language.code}
                  onSelect={() => {
                    onSelect(language);
                    if (closeOnSelect) {
                      setIsOpen(false);
                    }
                  }}
                  className={clsx({ "[&_svg]:invisible": language?.code !== field.value?.code })}
                >
                  <Check className={cn("mr-2 h-4 w-4")} />
                  {language.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
