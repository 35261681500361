import { SearchInput } from "@/components/SearchInput";
import { Table } from "@tanstack/react-table";

export function DatatableGlobalFilter<T>({ table }: { table: Table<T> }) {
  return (
    <SearchInput
      label="data-table.global-search"
      term={table.getState().globalFilter?.term ?? ""}
      onSearch={(value) => {
        const valueStr = value ? String(value) : null;
        const good = !!valueStr && !/^\s+$/.test(valueStr);
        table.setGlobalFilter(
          good
            ? {
                term: valueStr,
              }
            : undefined
        );
      }}
    />
  );
}
