import { useMediaQuery } from "@uidotdev/usehooks";
import { ButtonIcon } from "components/button/ButtonIcon";
import { Form } from "components/ui/form";
import { ArrowLeftRight } from "lucide-react";
import { LanguageOption } from "model/mt.typing";
import {
  useAutoDetectMutation,
  useMachineTranslationConfig,
  useProcessTranslationMutation,
} from "query/mt/machinetranslation.query";
import { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { LanguageLoader } from "../components/LanguageLoader";
import { Glossary } from "../components/text/glossary/Glossary";
import { GlossaryButton } from "../components/text/glossary/GlossaryButton";
import { SavedTextTranslations } from "../components/text/SavedTextTranslations";
import { TextSourceControls } from "../components/text/TextSourceControls";
import { TextTargetControls } from "../components/text/TextTargetControls";
import { SourceTextTools } from "../components/text/TextToolbars";
import { WorklistButton } from "../components/text/WorklistButton";
import useTextMachineTranslation from "../useTextMachineTranslation";
import { useTextMachineTranslationForm } from "../useTextMachineTranslationForm";

function TextMachineTranslation() {
  const form = useTextMachineTranslationForm();
  const sourceText = form.watch("sourceText");
  const targetLanguage = form.watch("targetLanguage");

  // Local state
  const [textChanged, setTextChanged] = useState<boolean>(false);
  const [targetLanguages, setTargetLanguages] = useState<LanguageOption[]>([]);
  const [activeComponent, setActiveComponent] = useState("");
  const scrollRef = useRef<HTMLDivElement | null>(null);

  // Queries
  const {
    error,
    isLoading,
    sourceLanguages,
    configuration,
    collectionId,
    mtProviderType,
    setCollectionId,
    setMtProviderType,
  } = useMachineTranslationConfig();
  const { attemptAutoDetect, autoDetectResults, isDetecting } = useAutoDetectMutation(sourceText);
  const { processTranslation, translationResults, setTargetTextExternally, isTranslating } =
    useProcessTranslationMutation(collectionId, mtProviderType, form);

  // Custom text translation hook
  const { setTargets, clearText, swapLanguages } = useTextMachineTranslation(
    sourceLanguages,
    autoDetectResults,
    form,
    configuration,
    setCollectionId,
    setMtProviderType,
    setTargetLanguages,
    setTargetTextExternally,
    processTranslation
  );

  // Attempt to auto-detect
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (sourceText && textChanged) {
        attemptAutoDetect(sourceText);
        setTextChanged(false);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [sourceText, textChanged, attemptAutoDetect]);

  // Process translation
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (
        !isDetecting &&
        form.getValues("sourceLanguage") &&
        targetLanguage &&
        form.getValues("sourceText") &&
        collectionId
      ) {
        processTranslation();
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDetecting, targetLanguage, processTranslation, collectionId]);

  // scroll into active component when opening it on small resolutions (worklist/glossary)
  const isSmall = useMediaQuery("only screen and (max-width : 768px)");
  const isMedium = useMediaQuery("only screen and (min-width : 769px) and (max-width : 1565px)");
  useEffect(() => {
    if ((isSmall || isMedium) && scrollRef && (activeComponent === "worklist" || activeComponent === "glossary")) {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeComponent]);

  // Redirect to error page if GET MT config failed
  if (error != null) {
    return <Navigate to={"/error/500"} state={{ message: `mt.file.${error.response?.data}` }} replace={false} />;
  }

  return (
    <div>
      <div className="mr-2 flex items-end justify-end gap-2">
        <WorklistButton activeComponent={activeComponent} setActive={setActiveComponent} />
        <GlossaryButton activeComponent={activeComponent} setActive={setActiveComponent} />
      </div>
      <Form {...form}>
        <div className="md:flex-wrap lg:flex">
          <div className="flex-1">
            <div className="m-2">
              <div className="flex flex-col items-start rounded-md border p-4 sm:items-center">
                {isLoading ? (
                  <LanguageLoader />
                ) : (
                  <>
                    <TextSourceControls
                      sourceLanguages={sourceLanguages}
                      form={form}
                      setTargets={setTargets}
                      clearText={clearText}
                      setTextChanged={setTextChanged}
                    />
                    <SourceTextTools isDetecting={isDetecting} charCount={sourceText?.length} />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="text-center md:my-2 lg:my-6">
            <ButtonIcon
              icon={ArrowLeftRight}
              data-test="mt-swap-languages"
              className="size-10"
              disabled={!form.getValues("sourceLanguage") || !form.getValues("targetLanguage")}
              onClick={() => swapLanguages()}
            />
          </div>
          <div className="flex-1">
            <div className="m-2">
              <div className="flex flex-col items-start rounded-md border p-4 sm:items-center">
                {isLoading ? (
                  <LanguageLoader />
                ) : (
                  <TextTargetControls
                    clearText={clearText}
                    isTranslating={isTranslating}
                    form={form}
                    targetLanguages={targetLanguages}
                    translationResults={translationResults}
                    processTranslation={processTranslation}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div ref={scrollRef} />
            <Glossary active={activeComponent === "glossary"} setActive={setActiveComponent} />
            <SavedTextTranslations active={activeComponent === "worklist"} setActive={setActiveComponent} />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default TextMachineTranslation;
