import { AnimatePresence, motion } from "framer-motion";
import { GlossaryList } from "./GlossaryList";
import useGlossary from "./useGlossary";
import { GlossaryEdit } from "./GlossaryEdit";
import { GlossaryInfo } from "./GlossaryInfo";
import { useEffect } from "react";
import { useGetTerminologyListQuery } from "@/query/mt/machinetranslation.query";

interface GlossaryProps {
  active: boolean;
  setActive: (value: string) => void;
}

export const Glossary = (props: GlossaryProps) => {
  const { active, setActive } = props;
  const { view, terminologyConfiguration, setTerminologies, currentTerminology } = useGlossary();
  const { fetchedTerminologies, refetch } = useGetTerminologyListQuery(terminologyConfiguration.customerId);

  // this triggers a terminologies fetch. query has 'enabled' = false. we trigger it when config loading is finished
  useEffect(() => {
    if (terminologyConfiguration.customerId && !fetchedTerminologies) {
      refetch();
    }
    if (fetchedTerminologies)
      // sorting alphabetically
      setTerminologies(fetchedTerminologies.sort((a, b) => a.name.localeCompare(b.name)));
  }, [terminologyConfiguration.customerId, fetchedTerminologies, refetch, setTerminologies]);

  if (!active) {
    return null;
  }

  // render a component based on 'view' variable
  const renderComponent = () => {
    if (view === "add" || (fetchedTerminologies?.length === 0 && currentTerminology === null))
      return <GlossaryInfo showBack={!(fetchedTerminologies?.length === 0 && currentTerminology === null)} />;

    if (view === "editTerms") return <GlossaryEdit />;

    return <GlossaryList setActive={setActive} />;
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: 0, opacity: 0 }}
        animate={{ x: -8, opacity: 1 }}
        exit={{ x: 0, opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
      >
        <div className="my-2 ml-4 min-w-[480px] rounded-md border p-4 sm:items-center lg:max-w-96">
          {renderComponent()}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
