import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "./ui/button";

interface Props {
  text: string;
  maxChars?: number;
  className?: string;
  hideExpand?: boolean;
}

export function TruncatedText({ text, maxChars = 100, className = "", hideExpand }: Props) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <div className={className} data-test="truncated-text">
      {text.length > maxChars ? (
        <div>
          <AnimatePresence initial={false}>
            <motion.div
              key={isExpanded ? "expanded" : "collapsed"}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              {isExpanded ? text : `${text.slice(0, maxChars)}...`}
            </motion.div>
          </AnimatePresence>
          {!hideExpand && (
            <Button
              data-test="truncated-text-toggle-action"
              variant="link"
              className="h-0 px-0 text-xs font-medium"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? t("requests.create.show-less") : t("requests.create.show-more")}
            </Button>
          )}
        </div>
      ) : (
        text
      )}
    </div>
  );
}
