import ExpandableHelpSection from "@/components/ExpandableHelpSection";
import { TruncatedText } from "@/components/TruncatedText";
import { Card, CardContent } from "@/components/ui/card";
import {
  AnalysisType,
  LanguageType,
  RequestDetailType,
  StackedBarType,
  TaskAnalysisType,
} from "@/model/request.typing";
import { useLanguagesQuery } from "@/query/request.query";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { ChevronRightIcon, SparklesIcon } from "lucide-react";
import { useMemo } from "react";
import HSBar from "react-horizontal-stacked-bar-chart";
import { useTranslation } from "react-i18next";
import useRequestAnalysis from "../../../useRequestAnalysis";
import { AnalysisUnsupported } from "./AnalysisUnsupported";

const AnalysisOverview = ({ analysis, project }: { analysis: AnalysisType; project: RequestDetailType }) => {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.analysis" });
  return (
    <div className="mt-2 flex justify-between gap-4">
      <Card className="h-full flex-1 rounded-3xl border-0 p-10">
        <ExpandableHelpSection title={t("wordBreakdown")} helpText={t("wordBreakdownHelp")} />
        <CardContent className="mt-6 p-0">
          <AnalysisOverviewChart analysis={analysis} project={project} />
          <AnalysisUnsupported project={project} />
        </CardContent>
      </Card>
    </div>
  );
};

const AnalysisLanguageOverviews = ({ analysis, project }: { analysis: AnalysisType; project: RequestDetailType }) => {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.analysis" });
  const { languages } = useLanguagesQuery();

  const tasks = useMemo(() => {
    const operation = analysis.analysisResult === "increase" ? "ExecuteApe" : "ExecuteMtqe";
    return analysis.analysisTasks.filter((task) => task.operation === operation);
  }, [analysis]);

  const groupedSources = useMemo(() => {
    return tasks.reduce((acc: { [key: string]: TaskAnalysisType[] }, task) => {
      if (!acc[task.sourceLanguage]) acc[task.sourceLanguage] = [];
      acc[task.sourceLanguage].push(task);
      return acc;
    }, {});
  }, [tasks]);

  return (
    <div className="mt-6 flex justify-between gap-4">
      <Card className="h-full flex-1 rounded-3xl border-0 p-10">
        <ExpandableHelpSection title={t("languageBreakdowns")} helpText={t("languageBreakdownHelp")} />
        <CardContent className="mt-6 p-0">
          {Object.entries(groupedSources).map(([sourceLanguage, tasks]) => {
            const source = languages?.find((l) => l.id == sourceLanguage);
            if (!source) return null;
            return (
              <div key={sourceLanguage} className="mb-4">
                <h3 className="mb-4 flex items-center text-xl">
                  {source.name} <ChevronRightIcon className="ml-2 opacity-50" />
                </h3>
                <div className="mb-2 grid grid-cols-[160px,1fr,120px] gap-4">
                  <div className="flex items-center text-xs font-semibold sm:text-xs lg:text-sm">
                    {t("targetLanguages")}
                  </div>
                  <div />
                  <div className="flex items-center justify-center text-center text-sm font-semibold sm:text-xs lg:text-sm">
                    {t("quality")}
                    <br />
                    {t("estimation")}
                  </div>
                </div>
                {tasks.map((task, index) => (
                  <AnalysisTaskChart
                    key={task.tempoTaskId}
                    task={task}
                    languages={languages || null}
                    project={project}
                    hideLegend={index !== tasks.length - 1}
                  />
                ))}
              </div>
            );
          })}
        </CardContent>
      </Card>
    </div>
  );
};

function AnalysisOverviewLegend() {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.analysis" });
  return (
    <div className="mt-2 flex flex-wrap items-center gap-4 sm:gap-2">
      <LegendItem color="#61A3FF" label={t("translationMemory")} />
      <LegendItem color="#67E3F9" label={t("highQuality")} />
      <LegendItem color="#FDE272" label={t("humanReviewNeeded")} />
    </div>
  );
}

function LegendItem({ label, color }: { label: string; color: string }) {
  return (
    <div className="flex items-center">
      <div className="mr-2 size-2 rounded-full" style={{ backgroundColor: color }} />
      <span className="text-xs">{label}</span>
    </div>
  );
}

const AnalysisOverviewChart = ({ project, analysis }: { project: RequestDetailType; analysis: AnalysisType }) => {
  const { generateChartData } = useRequestAnalysis(project, null, null);
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.analysis" });
  const metrics =
    analysis.analysisResult === "increase"
      ? generateChartData(analysis.apeAnalysis, t)
      : generateChartData(analysis.mtAnalysis, t);

  return <ChartWithLegend data={metrics} />;
};

const AnalysisTaskChart = ({
  project,
  task,
  languages,
  hideLegend,
}: {
  project: RequestDetailType;
  task: TaskAnalysisType;
  languages: LanguageType[] | null;
  hideLegend?: boolean;
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.analysis" });
  const { generateTaskChartData } = useRequestAnalysis(project, null, null);
  const formatPercentage = useAuthUserStore((store) => store.formatPercentage);
  const source = languages?.find((l) => l.id == task.sourceLanguage);
  const target = languages?.find((l) => l.id == task.targetLanguage);
  if (!source || !target) return null;

  const languageData = generateTaskChartData(task, t);

  return (
    <div className="mb-2 grid grid-cols-[160px,1fr,120px] gap-4">
      <div className="mt-3 flex text-xs">
        <TruncatedText text={target.name} maxChars={20} hideExpand={true} />
      </div>
      <div>
        <ChartWithLegend data={languageData} hideLegend={hideLegend} />
      </div>
      <div className="mt-3 flex justify-center text-sm">{formatPercentage(task.qualityEstimateScore, false)}</div>
    </div>
  );
};

const ChartWithLegend = ({ data, hideLegend = false }: { data: StackedBarType[]; hideLegend?: boolean }) => (
  <div className="mt-1">
    <div className="w-full cursor-pointer overflow-hidden rounded-lg">
      <HSBar height={30} data={data} />
    </div>
    {!hideLegend && <AnalysisOverviewLegend />}
  </div>
);

const AnalysisResultsHeader = ({ analysis }: { analysis: AnalysisType | null }) => {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.analysis" });
  const hasIncreaseResult = analysis?.analysisResult === "increase";
  return (
    <div className={hasIncreaseResult ? "my-4" : "my-6"}>
      {hasIncreaseResult && (
        <h1 className="flex text-xl font-semibold">
          <SparklesIcon className="mr-2 text-primary" />
          {t("increaseHeader")}
        </h1>
      )}
    </div>
  );
};

export { AnalysisLanguageOverviews, AnalysisOverview, AnalysisResultsHeader };
