import { CommandItem } from "@/components/ui/command";
import { IdName } from "@/model/common";
import { cn } from "@/utils/ui";
import { CheckIcon } from "lucide-react";

interface ComboboxOptionProps<TData extends IdName> {
  option: TData;
  isSelected?: boolean;
  hideCount?: boolean;
  testId: string;
  onSelect: () => void;
  renderOption?: (option: TData) => React.ReactNode;
  formatCount?: (count: number) => string;
  useValueAsKey?: boolean;
}

export function ComboboxOption<TData extends IdName>({
  option,
  isSelected,
  hideCount,
  testId,
  useValueAsKey = false,
  onSelect,
  renderOption,
  formatCount,
}: ComboboxOptionProps<TData>) {
  return (
    <CommandItem
      data-test={`combobox-${testId}-${isSelected ? "selected-option" : "option"}`}
      key={option.id}
      value={useValueAsKey ? option.id : undefined}
      onSelect={onSelect}
      disabled={!isSelected && option.isDisabled}
      className={cn({ "opacity-50": option.isDisabled })}
    >
      <div
        className={cn(
          "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border",
          isSelected
            ? "border-primary bg-primary text-primary-foreground"
            : "border-neutral-400 dark:border-neutral-600 [&_svg]:invisible"
        )}
      >
        <CheckIcon
          data-test={`${isSelected ? "select" : "combobox"}-${testId}-${
            isSelected ? "selected-option" : "option"
          }-icon`}
          className={cn("h-4 w-4")}
        />
      </div>
      {renderOption ? renderOption(option) : <span>{option.name}</span>}
      {!hideCount && option.count != null ? (
        <span className="ml-auto flex size-4 items-center justify-end text-xs">
          {formatCount ? formatCount(option.count) : option.count}
        </span>
      ) : null}
    </CommandItem>
  );
}
