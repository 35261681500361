import { CellContext, Row } from "@tanstack/react-table";

export type HighlightRange = [number, number];
export const HIGHLIGHT_RANGE_START = 0;
export const HIGHLIGHT_RANGE_END = 1;

declare module "@tanstack/table-core" {
  interface FilterMeta {
    globalFilterRanges?: HighlightRange[];
  }
}

export interface GlobalFilterWithHighlightingConfig {
  /** Search term */
  term: string;
}

interface ResolvedGlobalFilterWithHighlightingConfig extends GlobalFilterWithHighlightingConfig {
  /** Resolved search terms */
  resolvedTerms: string[];
  /** Helper array that indicates if each term from resolvedTerms is found in row */
  termsFound: boolean[];
  /** Caches column list for better performance */
  columns?: string[];
}

function find(value: string, term: string): HighlightRange[] | undefined {
  const ranges: HighlightRange[] = [];
  let position = 0;

  while (position < value.length) {
    const startIndex = value.indexOf(term, position);
    if (startIndex < 0) break;

    const endIndex = startIndex + term.length;
    ranges.push([startIndex, endIndex]);

    position = endIndex; // Move position forward to avoid overlapping
  }

  return ranges.length > 0 ? ranges : undefined;
}

export function globalFilterWithHighlighting<T>(
  row: Row<T>,
  columnId: string,
  filterConfig: ResolvedGlobalFilterWithHighlightingConfig
) {
  if (!filterConfig.columns) {
    filterConfig.columns = row
      .getAllCells()
      .filter((cell) => cell.column.getCanGlobalFilter())
      .map((cell) => cell.column.id);
  }
  const allCols = filterConfig.columns;
  // Perform global filtering only when columnId=firstColumnId
  if (columnId !== allCols[0]) return false;
  const filterTerms = filterConfig.resolvedTerms;
  const filterTermsFound = filterConfig.termsFound.fill(false);
  for (const colId of allCols) {
    const value = row.getValue(colId);
    let valueStr: string;
    if (typeof value === "string") {
      valueStr = value.toLowerCase();
    } else {
      continue;
    }
    let globalFilterRanges: HighlightRange[] | undefined = undefined;
    for (let i = 0; i < filterTerms.length; ++i) {
      const ranges = find(valueStr, filterTerms[i]!);
      if (ranges) {
        filterTermsFound[i] = true;
        if (!globalFilterRanges) globalFilterRanges = [];
        globalFilterRanges.push(...ranges);
      }
    }
    // Hacky way to change filter meta for different columns
    row.columnFiltersMeta[colId] = {
      ...row.columnFiltersMeta[colId],
      globalFilterRanges,
    };
  }
  // Row is passing filter only when all filter terms found in this row
  return filterTermsFound.every((found) => found);
}

globalFilterWithHighlighting.resolveFilterValue = function (
  filterValue: GlobalFilterWithHighlightingConfig
): ResolvedGlobalFilterWithHighlightingConfig {
  const filter = filterValue.term.split(/\s+/).filter((term) => !!term);
  if (filter.length === 0) throw new Error("Filter cannot be empty");
  return {
    ...filterValue,
    resolvedTerms: filter.map((term) => term.toLowerCase()),
    termsFound: new Array(filter.length).fill(false),
  };
};

export function getHighlightRanges<T>(cellContext: CellContext<T, string>) {
  const globalFilter = cellContext.table.getState().globalFilter as GlobalFilterWithHighlightingConfig | undefined;
  const searchTerm = globalFilter?.term?.trim(); // The search term
  const content = cellContext.getValue(); // The text content to search within

  if (!searchTerm || !content) {
    return [];
  }

  // Detect matches for the search term allowing extra characters after it
  const ranges: HighlightRange[] = [];
  let match: RegExpExecArray | null;

  const regex = new RegExp(`${searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")}`, "gi"); // Escape special chars
  while ((match = regex.exec(content)) !== null) {
    const start = match.index;
    const end = start + match[0].length;
    ranges.push([start, end]);
  }

  // Return the ranges for matches
  return ranges;
}
