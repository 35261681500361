import { getTerminology } from "@/api/machinetranslation.api";
import {
  GlossaryView,
  LanguageMap,
  LanguageOption,
  Terminology,
  TerminologyConfiguration,
  TerminologyItem,
  TerminologyStoredSettings,
} from "@/model/mt.typing";
import { User } from "@/model/user.typing";
import { TextMachineTranslationFormType } from "@/pages/machinetranslation/useTextMachineTranslationForm";
import { useMachineTranslationConfig } from "@/query/mt/machinetranslation.query";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { downloadAsExcelReport } from "@/utils/excel";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { create } from "zustand";

export interface GlossaryStoreState {
  sourceLanguage: LanguageOption | undefined;
  setSourceLanguage: (value: LanguageOption | undefined) => void;
  targetLanguage: LanguageOption | undefined;
  setTargetLanguage: (value: LanguageOption | undefined) => void;
  currentTerminology: Terminology | null;
  setCurrentTerminology: (item: Terminology | null) => void;
  view: GlossaryView;
  setView: (value: GlossaryView) => void;
  navigateTo: (item: Terminology, view: GlossaryView) => void;
  terminologies: Terminology[];
  setTerminologies: (items: Terminology[]) => void;
  addTerminologyToCache: (item: Terminology) => void;
  terminologyConfiguration: TerminologyConfiguration;
  setTerminologyConfiguration: (value: TerminologyConfiguration) => void;
  terminologyStoredSettings: TerminologyStoredSettings;
  setTerminologyStoredSettings: (value: TerminologyStoredSettings) => void;
  currentTerm: TerminologyItem | null;
  setCurrentTerm: (value: TerminologyItem | null) => void;
}

const initialConfiguration: TerminologyConfiguration = {
  customerId: "",
  instanceId: "",
  terminologyLanguages: [],
  sourceLanguages: [],
  targetLanguages: [],
  mappedLanguages: [],
  terminologyAvailable: false,
};

const useStore = create<GlossaryStoreState>((set) => ({
  sourceLanguage: undefined,
  setSourceLanguage: (value: LanguageOption | undefined) => {
    set(() => ({ sourceLanguage: value }));
  },
  targetLanguage: undefined,
  setTargetLanguage: (value: LanguageOption | undefined) => {
    set(() => ({ targetLanguage: value }));
  },
  currentTerminology: null,
  setCurrentTerminology: (item: Terminology | null) => {
    set(() => ({ currentTerminology: item }));
  },
  view: "list",
  setView: (value: GlossaryView) => {
    set(() => ({ view: value }));
  },
  navigateTo: (item: Terminology | null, view: GlossaryView) => {
    set(() => ({ currentTerminology: item, view: view }));
  },
  terminologies: [],
  addTerminologyToCache: (item: Terminology) => {
    set((state) => ({ terminologies: [...state.terminologies, item] }));
  },
  setTerminologies: (items: Terminology[]) => {
    set(() => ({ terminologies: items }));
  },
  terminologyConfiguration: initialConfiguration,
  setTerminologyConfiguration: (value: TerminologyConfiguration) => {
    set(() => ({ terminologyConfiguration: value }));
  },
  terminologyStoredSettings: {
    sourceLanguage: undefined,
    targetLanguage: undefined,
    isTerminologyEnabled: false,
    selectedTerminologyId: null,
    terminologyChanged: false,
  },
  setTerminologyStoredSettings: (value: TerminologyStoredSettings) => {
    set(() => ({ terminologyStoredSettings: value }));
  },
  currentTerm: null,
  setCurrentTerm: (value: TerminologyItem | null) => {
    set(() => ({ currentTerm: value }));
  },
}));

function useGlossary() {
  const user = useAuthUserStore((state) => state.user as User);
  const [filter, setFilter] = useState("");
  const [filteredTermItems, setFilteredTermItems] = useState<TerminologyItem[]>([]);

  const {
    sourceLanguage,
    setSourceLanguage,
    targetLanguage,
    setTargetLanguage,
    currentTerminology,
    setCurrentTerminology,
    view,
    setView,
    navigateTo,
    terminologies,
    setTerminologies,
    addTerminologyToCache,
    terminologyConfiguration,
    setTerminologyConfiguration,
    terminologyStoredSettings,
    setTerminologyStoredSettings,
    currentTerm,
    setCurrentTerm,
  } = useStore();

  const { configuration } = useMachineTranslationConfig();

  useEffect(() => {
    // check if configuration is loaded and termConfig is not filled yet
    if (configuration?.[0] && terminologyConfiguration.customerId === "") {
      const sources: LanguageOption[] = [];
      const mappedLanguages: LanguageMap[] = [];
      const languagesWithTerminology = configuration[0].languages.filter((x) => x.sourceLanguage.supportsTerminology);
      if (languagesWithTerminology.length > 0)
        languagesWithTerminology.forEach((l) => {
          sources.push({
            label: l.sourceLanguage.languageName,
            code: l.sourceLanguage.languageCode,
            isDefaultDialect: l.sourceLanguage.isDefaultDialect,
            mappedCode: l.sourceLanguage.mappedLanguageCode,
            supportsTerminology: l.sourceLanguage.supportsTerminology,
          });

          const sourcePresentInMapping = mappedLanguages.find((x) => x.code === l.sourceLanguage.languageCode);
          if (!sourcePresentInMapping)
            mappedLanguages.push({ code: l.sourceLanguage.languageCode, name: l.sourceLanguage.languageName });
          l.targetLanguages.forEach((tl) => {
            const targetPresentInMapping = mappedLanguages.find((x) => x.code === tl.languageCode);
            if (!targetPresentInMapping) mappedLanguages.push({ code: tl.languageCode, name: tl.languageName });
          });
        });

      setTerminologyConfiguration({
        customerId: configuration[0].customerId,
        instanceId: configuration[0].instanceId,
        terminologyLanguages: languagesWithTerminology,
        sourceLanguages: sources,
        targetLanguages: [],
        mappedLanguages,
        // terminology available only for MT Hub - and if it's enabled for selected client
        terminologyAvailable: configuration[0].isTerminologyEnabled && configuration[0].mtProviderType === 2,
      });

      const savedSettings = localStorage.getItem(`mtGlossarySettings-${user.id}`);
      if (savedSettings) {
        const settings: TerminologyStoredSettings = JSON.parse(savedSettings);
        if (settings) {
          setTerminologyStoredSettings(settings);
        }
      }
    }
  }, [
    configuration,
    setTerminologyConfiguration,
    terminologyConfiguration.customerId,
    setTerminologyStoredSettings,
    user.id,
  ]);

  const changeFilter = (value: string) => {
    setFilter(value);
    if (value !== "" && currentTerminology) {
      const searchText = value.toLowerCase().trim();
      const filteredTerms = currentTerminology.terminologyItems.filter((item) => {
        const sourceText = item.source.toLowerCase();
        const targetText = item.target.toLowerCase();

        return sourceText.includes(searchText) || targetText.includes(searchText);
      });
      setFilteredTermItems(filteredTerms);
    }
  };

  const saveAsFile = async (item: Terminology, fetch: boolean) => {
    const mappedTerms: object[] = [];

    if (fetch) item = await getTerminology(terminologyConfiguration.customerId, item.id);

    item.terminologyItems.forEach((item) => {
      mappedTerms.push({
        "Source Text": item.source,
        "Target Text": item.target,
        "Source Language": item.sourceLanguageCode,
        "Target Language": item.targetLanguageCode,
      });
    });

    const columns = [
      { width: 50, header: "Source Text", key: "Source Text" },
      { width: 50, header: "Target Text", key: "Target Text" },
      { width: 20, header: "Source Language", key: "Source Language" },
      { width: 20, header: "Target Language", key: "Target Language" },
    ];

    downloadAsExcelReport(mappedTerms, columns, "Glossary");
  };

  const setTargets = (
    language: LanguageOption | undefined,
    targetLanguageCode: string | undefined,
    form: UseFormReturn<TextMachineTranslationFormType> | undefined
  ) => {
    const targets: LanguageOption[] = [];

    if (language) {
      const targetLanguages = terminologyConfiguration.terminologyLanguages.find(
        (x) => x.sourceLanguage.languageCode === language.code
      )?.targetLanguages;

      const targetLanguagesWithTerminology = targetLanguages?.filter((x) => x.supportsTerminology);
      if (targetLanguagesWithTerminology && targetLanguagesWithTerminology.length > 0) {
        targetLanguagesWithTerminology.forEach((l) =>
          targets.push({
            label: l.languageName,
            code: l.languageCode,
            isDefaultDialect: l.isDefaultDialect,
            mappedCode: l.mappedLanguageCode,
            supportsTerminology: l.supportsTerminology,
          })
        );

        if (targets?.length === 1) {
          setTargetLanguage(targets[0]);
          if (form) form.setValue("targetLanguage", targets[0]);
        } else {
          if (targetLanguage && targetLanguageCode === undefined) targetLanguageCode = targetLanguage.code;

          const targetMatch =
            targetLanguageCode !== undefined ? targets.find((x) => x.code === targetLanguageCode) : undefined;
          setTargetLanguage(targetMatch);
          if (form) form.setValue("targetLanguage", targetMatch);
        }
      }
    }

    setTerminologyConfiguration({ ...terminologyConfiguration, targetLanguages: targets });
  };

  const swapLanguages = (
    source: LanguageOption | undefined,
    target: LanguageOption | undefined,
    form: UseFormReturn<TextMachineTranslationFormType>
  ) => {
    if (source && target) {
      if (terminologyConfiguration.sourceLanguages.find((x) => x.code === target.code) !== undefined) {
        const targets: LanguageOption[] = [];
        const targetLanguages = terminologyConfiguration.terminologyLanguages.find(
          (x) => x.sourceLanguage.languageCode === target.code
        )?.targetLanguages;

        const targetLanguagesWithTerminology = targetLanguages?.filter((x) => x.supportsTerminology);
        if (targetLanguagesWithTerminology && targetLanguagesWithTerminology.length > 0) {
          targetLanguagesWithTerminology.forEach((l) =>
            targets.push({
              label: l.languageName,
              code: l.languageCode,
              isDefaultDialect: l.isDefaultDialect,
              mappedCode: l.mappedLanguageCode,
              supportsTerminology: l.supportsTerminology,
            })
          );
        }

        setTerminologyConfiguration({ ...terminologyConfiguration, targetLanguages: targets });

        if (targets.find((x) => x.code === source.code) !== undefined) form.setValue("targetLanguage", source);
        else form.resetField("targetLanguage", undefined);

        form.setValue("sourceLanguage", target);
      } else {
        form.resetField("sourceLanguage");
        form.resetField("targetLanguage");
      }
    }
  };

  const selectedTerminologyName =
    terminologyStoredSettings.selectedTerminologyId !== null
      ? terminologies.find((x) => x.id === terminologyStoredSettings.selectedTerminologyId)?.name
      : null;

  const updateSourceLanguage = (value: LanguageOption | undefined) => {
    setSourceLanguage(value);
    const newSettings: TerminologyStoredSettings = {
      ...terminologyStoredSettings,
      sourceLanguage: value,
    };
    updateTerminologyStoredSettings(newSettings);
  };

  const updateTargetLanguage = (value: LanguageOption | undefined) => {
    setTargetLanguage(value);
    const newSettings: TerminologyStoredSettings = {
      ...terminologyStoredSettings,
      targetLanguage: value,
    };
    updateTerminologyStoredSettings(newSettings);
  };

  const updateTerminologyStoredSettings = (value: TerminologyStoredSettings) => {
    setTerminologyStoredSettings(value);
    updateLocalStorage(value);
  };

  const updateLocalStorage = (data: TerminologyStoredSettings) => {
    localStorage.setItem(`mtGlossarySettings-${user.id}`, JSON.stringify(data));
  };

  const setTargetsForUpload = (
    sourceLanguageCode: string | null,
    targetLanguageCode: string | null,
    setTargetLanguageCode: (value: string | null) => void,
    setTargetLanguages: (value: LanguageOption[]) => void,
    terminologyConfiguration: TerminologyConfiguration
  ) => {
    const targets: LanguageOption[] = [];

    if (sourceLanguageCode !== null) {
      const targetLanguages = terminologyConfiguration.terminologyLanguages.find(
        (x) => x.sourceLanguage.languageCode === sourceLanguageCode
      )?.targetLanguages;

      const targetLanguagesWithTerminology = targetLanguages?.filter((x) => x.supportsTerminology);
      if (targetLanguagesWithTerminology && targetLanguagesWithTerminology.length > 0) {
        targetLanguagesWithTerminology.forEach((l) =>
          targets.push({
            label: l.languageName,
            code: l.languageCode,
            isDefaultDialect: l.isDefaultDialect,
            mappedCode: l.mappedLanguageCode,
            supportsTerminology: l.supportsTerminology,
          })
        );

        if (targets?.length === 1) {
          setTargetLanguageCode(targets[0].code);
        } else {
          const targetMatch = targets.find((x) => x.code === targetLanguageCode);
          if (targetMatch?.code) setTargetLanguageCode(targetMatch.code);
        }
      }
    }

    setTargetLanguages(targets);
  };

  return {
    view,
    setView,
    navigateTo,
    terminologyConfiguration,
    sourceLanguage,
    updateSourceLanguage,
    targetLanguage,
    updateTargetLanguage,
    changeFilter,
    filter,
    filteredTermItems,
    saveAsFile,
    setTargets,
    currentTerminology,
    setCurrentTerminology,
    terminologies,
    setTerminologies,
    addTerminologyToCache,
    swapLanguages,
    selectedTerminologyName,
    terminologyStoredSettings,
    updateTerminologyStoredSettings,
    currentTerm,
    setCurrentTerm,
    setTargetsForUpload,
  };
}

export default useGlossary;
