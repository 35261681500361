import { ProjectCostPerLanguagesType, ProjectCostType } from "@/model/report.typing";
import { useOutletContext } from "react-router-dom";
import { ChartCardSkeleton } from "../common/ChartSkeleton";
import { SpendPerMonthChart } from "./SpendPerMonthPage";
import { SpendPerQuarterChart } from "./SpendPerQuarterPage";
import { SpendPerRequestorChart } from "./SpendPerRequestorPage";
import { SpendPerTargetLanguageChart } from "./SpendPerTargetLanguagePage";

const cardHeight = 400;

export function CostReportDashboardPage() {
  const { pricePerPairLanguages, projectCosts, isLoading } =
    useOutletContext<{
      isLoading: boolean;
      pricePerPairLanguages: ProjectCostPerLanguagesType[];
      projectCosts: ProjectCostType[];
    }>() ?? {};

  if (isLoading) {
    return (
      <div data-test="dashboard-loader" className="grid grid-cols-1 gap-4 xl:grid-cols-2">
        <ChartCardSkeleton />
        <ChartCardSkeleton />
        <ChartCardSkeleton />
        <ChartCardSkeleton />
      </div>
    );
  }
  if (pricePerPairLanguages === undefined) return null;

  return (
    <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
      <SpendPerTargetLanguageChart
        pricePerPairLanguages={pricePerPairLanguages}
        height={cardHeight}
        isFocusView={false}
      />
      <SpendPerMonthChart pricePerPairLanguages={pricePerPairLanguages} height={cardHeight} isFocusView={false} />
      <SpendPerQuarterChart
        projectCosts={projectCosts}
        pricePerPairLanguages={pricePerPairLanguages}
        height={cardHeight}
        isFocusView={false}
      />
      <SpendPerRequestorChart pricePerPairLanguages={pricePerPairLanguages} height={cardHeight} isFocusView={false} />
    </div>
  );
}
