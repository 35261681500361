import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { PopoverTrigger } from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { IdName } from "@/model/common";
import { clsx } from "clsx";
import { ChevronDown, LucideIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

interface ComboboxTriggerProps<TData extends IdName> {
  testId: string;
  title: string;
  placeholder?: string;
  icon?: LucideIcon | null;
  inError?: boolean;
  customValueLabel?: string;
  selected?: TData[] | TData | null;
  renderSelected?: (option: TData) => React.ReactNode;
  isShouldDisplayPlaceholder: boolean;
  size?: "sm" | "default" | "none" | "lg" | "xs";
}

export function ComboboxTrigger<TData extends IdName>({
  testId,
  title,
  placeholder,
  icon: Icon,
  inError,
  customValueLabel,
  selected,
  renderSelected,
  isShouldDisplayPlaceholder,
  size = "sm",
}: ComboboxTriggerProps<TData>) {
  const { t } = useTranslation();
  const selectedArray = Array.isArray(selected) ? selected : selected ? [selected] : [];
  const hasSelected = selectedArray.length > 0;

  return (
    <PopoverTrigger asChild>
      <Button
        data-test={`combobox-${testId}-trigger`}
        variant="outline"
        size={size}
        role="combobox"
        className={clsx("group border", {
          "border-spacing-6 border-red-400": inError,
        })}
      >
        {Icon && <Icon data-test={`combobox-${testId}-trigger-icon`} className="mr-2 size-4 shrink-0" />}
        {isShouldDisplayPlaceholder ? (
          <span data-test={`combobox-${testId}-trigger-placeholder`} className="text-sm font-normal">
            {placeholder}
          </span>
        ) : (
          <span data-test={`combobox-${testId}-trigger-title`} className="text-sm font-normal">
            {title}
          </span>
        )}
        {!hasSelected && customValueLabel ? (
          <>
            <Separator orientation="vertical" className="mx-2 h-4" />
            <span data-test={`combobox-${testId}-badge-all`} className="px-1 font-normal">
              {customValueLabel}
            </span>
          </>
        ) : null}
        {hasSelected && (
          <>
            <Separator orientation="vertical" className="mx-2 h-4" />
            {Array.isArray(selected) ? (
              <>
                <Badge
                  data-test={`combobox-${testId}-badge-total`}
                  variant="muted"
                  className="rounded-sm px-1 font-normal lg:hidden"
                >
                  {selectedArray.length}
                </Badge>
                <div data-test={`combobox-${testId}-badges`} className="hidden space-x-1 lg:flex">
                  {selectedArray.length > 1 ? (
                    <Badge
                      data-test={`combobox-${testId}-badges`}
                      variant="muted"
                      className="rounded-sm px-1 font-normal"
                    >
                      {selectedArray.length} {t("translation:facets.selected")}
                    </Badge>
                  ) : (
                    selectedArray.map((option) => (
                      <div key={option.id} className="truncate" data-testid={`combobox-${testId}-trigger-content`}>
                        {renderSelected ? renderSelected(option) : option.name}
                      </div>
                    ))
                  )}
                </div>
              </>
            ) : (
              <div className="flex space-x-1">
                {selectedArray.map((option) => (
                  <div key={option.id} className="truncate">
                    {renderSelected ? renderSelected(option) : option.name}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        <ChevronDown className="ml-2 size-4 shrink-0 text-foreground" />
      </Button>
    </PopoverTrigger>
  );
}
