import { ComboboxOption } from "@/components/combobox/common/ComboboxOption";
import { ComboboxTrigger } from "@/components/combobox/common/ComboboxTrigger";
import { SingleSelectionProps } from "@/components/combobox/common/combobox.typings";
import { useCombobox } from "@/components/combobox/common/useCombobox";
import { clsx } from "clsx";
import { Command, CommandEmpty, CommandInput, CommandList, CommandSeparator } from "components/ui/command";
import { Popover, PopoverContent } from "components/ui/popover";
import { t } from "i18next";
import { IdName } from "model/common";
import { useCallback, useMemo, useState } from "react";

export function ComboboxSingleSelection<T extends IdName, SortByFieldType extends string>({
  inError = false,
  title,
  options,
  selectedId,
  customValueLabel,
  placeholder,
  icon,
  className,
  onChange,
  testId = "",
  hideCount = false,
  formatCount,
  renderOption,
  renderSelected,
  sortOptions: sorting,
}: SingleSelectionProps<T, SortByFieldType>) {
  const [open, setOpen] = useState(false);
  const { searchValue, setSearchValue, listRef, sortItems, isSortingEnabled, renderSortControl } = useCombobox<
    T,
    SortByFieldType
  >({
    testId,
    sorting,
  });

  const selected = useMemo(() => {
    if (options.length === 0) return null;
    return options.find((option) => option.id.toString() === selectedId?.toString());
  }, [options, selectedId]);

  const unselected = useMemo(() => sortItems(options), [sortItems, options]);
  const isShouldDisplayPlaceholder = !!placeholder && !selected && !customValueLabel;

  const handleOptionSelect = useCallback(
    (option: T) => {
      onChange(option.id.toString() === selected?.id.toString() ? null : option);
    },
    [onChange, selected]
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <ComboboxTrigger
        testId={testId}
        title={title}
        placeholder={placeholder}
        icon={icon}
        inError={inError}
        customValueLabel={customValueLabel}
        selected={selected}
        renderSelected={renderSelected}
        isShouldDisplayPlaceholder={isShouldDisplayPlaceholder}
      />
      <PopoverContent
        data-test={`combobox-${testId}-content`}
        className={clsx("w-[var(--radix-popover-trigger-width)] min-w-[400px] p-0", className)}
        align="start"
      >
        <Command>
          <CommandInput
            role="textbox"
            data-test={`combobox-${testId}-input`}
            placeholder={title}
            onValueChange={setSearchValue}
            value={searchValue}
          />
          <CommandList ref={listRef}>
            {isSortingEnabled && renderSortControl?.()}
            {isSortingEnabled && <CommandSeparator />}
            <CommandEmpty data-test={`combobox-${testId}-empty`}>{t("translation:facets.not-found")}</CommandEmpty>
            {unselected.map((option) => (
              <ComboboxOption
                key={option.id}
                option={option}
                isSelected={option.id === selected?.id}
                hideCount={hideCount}
                testId={testId}
                onSelect={() => {
                  setOpen(false);
                  setSearchValue("");
                  handleOptionSelect(option);
                }}
                renderOption={renderOption}
                formatCount={formatCount}
              />
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
