import { t } from "i18next";
import { AutoDetectResults, LanguageOption, MachineTranslationConfig } from "model/mt.typing";
import { useCallback, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { toast } from "sonner";
import { TextMachineTranslationFormType } from "./useTextMachineTranslationForm";

function useTextMachineTranslation(
  sourceLanguages: LanguageOption[],
  autoDetectResults: AutoDetectResults | undefined,
  form: UseFormReturn<TextMachineTranslationFormType>,
  configuration: MachineTranslationConfig[] | undefined,
  setCollectionId: (text: string) => void,
  setMtProviderType: (mtProviderType: number) => void,
  setTargetLanguages: (text: LanguageOption[]) => void,
  setTargetTextExternally: (text: string) => void,
  processTranslation: () => void
) {
  const setTargets = useCallback(
    (source: LanguageOption | null) => {
      const targets: LanguageOption[] = [];
      if (configuration && source) {
        setCollectionId(configuration[0].collectionId);
        setMtProviderType(configuration[0].mtProviderType);
        const targetLanguages = configuration[0].languages.find(
          (x) => x.sourceLanguage.languageCode === source.code
        )?.targetLanguages;
        targetLanguages?.map((l) =>
          targets.push({
            label: l.languageName,
            code: l.languageCode,
            isDefaultDialect: l.isDefaultDialect,
            mappedCode: l.mappedLanguageCode,
            supportsTerminology: l.supportsTerminology,
          })
        );
      }
      setTargetLanguages(targets);
      const lastItem = localStorage.getItem("mtLastTarget");
      if (targets?.length === 1) {
        form.setValue("targetLanguage", targets[0]);
      } else if (lastItem) {
        // Attempt to set to last selected target
        const targetOption: LanguageOption = JSON.parse(lastItem);
        if (
          targets.some(
            (t) => t.code == targetOption.code && targetOption.code != form.getValues("sourceLanguage")?.code
          )
        ) {
          form.setValue("targetLanguage", targetOption);
          if (targetOption.code != form.getValues("targetLanguage")?.code) toast.success(t("mt.targetLastSelection"));
        } else {
          form.setValue("targetLanguage", undefined);
        }
      } else {
        form.setValue("targetLanguage", undefined);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configuration, setCollectionId, setMtProviderType, setTargetLanguages]
  );

  const findAutoDetectMatch = useCallback(
    (detectedCode: string) => {
      const defaultDialectLanguages = sourceLanguages.filter((t) => t.isDefaultDialect);
      const defaultDialectMatch = defaultDialectLanguages.find(
        (t) => t.code === detectedCode || t.code.startsWith(detectedCode + "-")
      );
      if (defaultDialectMatch) return defaultDialectMatch;

      return (
        sourceLanguages.find(
          (t) =>
            (t.code === detectedCode || t.code.startsWith(detectedCode + "-")) &&
            sourceLanguages.some((s) => s.code === detectedCode || s.code.startsWith(detectedCode + "-"))
        ) ?? null
      );
    },
    [sourceLanguages]
  );

  useEffect(() => {
    if (autoDetectResults && sourceLanguages.length > 0) {
      const matchedTarget = findAutoDetectMatch(autoDetectResults.detections[0].language);
      if (matchedTarget && matchedTarget.code !== form.getValues("sourceLanguage")?.code) {
        toast.success(t("mt.autoDetectedSource", { value: matchedTarget.label }));
        form.setValue("sourceLanguage", matchedTarget);
        setTargets(matchedTarget);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoDetectResults, sourceLanguages, setTargets, findAutoDetectMatch]);

  const clearText = (areaType: string) => {
    if (areaType === "sourceText") {
      form.setValue("sourceText", "");
    } else setTargetTextExternally("");
  };

  const swapLanguages = useCallback(() => {
    const source = form.getValues("sourceLanguage");
    const target = form.getValues("targetLanguage");
    const targetText = form.getValues("targetText");

    if (configuration && source && target) {
      if (configuration[0].languages.find((x) => x.sourceLanguage.languageCode === target.code) !== undefined) {
        const targets: LanguageOption[] = [];
        const targetLanguages = configuration[0].languages.find(
          (x) => x.sourceLanguage.languageCode === target.code
        )?.targetLanguages;
        targetLanguages?.map((l) =>
          targets.push({
            label: l.languageName,
            code: l.languageCode,
            isDefaultDialect: l.isDefaultDialect,
            mappedCode: l.mappedLanguageCode,
            supportsTerminology: l.supportsTerminology,
          })
        );
        setTargetLanguages(targets);

        const isTargetPresent = targets.find((x) => x.code === source.code) !== undefined;
        if (isTargetPresent) {
          form.setValue("targetLanguage", source);
          localStorage.setItem("mtLastTarget", JSON.stringify(source));
        }
        else form.setValue("targetLanguage", undefined);

        form.setValue("sourceLanguage", target);

        if (targetText) {
          form.setValue("sourceText", targetText);
          form.setValue("targetText", "");

        }
      } else {
        form.setValue("sourceLanguage", undefined);
        form.setValue("targetLanguage", undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration, setTargetLanguages, processTranslation]);

  return { findAutoDetectMatch, setTargets, clearText, swapLanguages };
}

export default useTextMachineTranslation;
