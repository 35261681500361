import * as ExcelJS from "exceljs";

export function downloadAsExcelReport(items: object[], columns: object[], reportName: string) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(reportName);
  worksheet.columns = columns;

  worksheet.addRows(items);

  workbook.xlsx.writeBuffer().then((result) => {
    const blob = new Blob([result], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${reportName}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
}
