import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { useRejectQuoteMutation } from "@/query/request.query";
import { Loader2Icon } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function RejectDialog({
  projectId,
  open,
  setOpen,
}: {
  projectId: string;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}) {
  const { t } = useTranslation("translation");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [comments, setComments] = useState<string>("");
  const { mutate: rejectQuote, isPending, data: isSuccess = false } = useRejectQuoteMutation(projectId);

  useEffect(() => {
    if (isSuccess && isSubmitted) {
      setIsSubmitted(false);
      setOpen(false);
    }
  }, [isSuccess, isSubmitted, setOpen]);

  const handleReject = () => {
    rejectQuote({ comments });
    setIsSubmitted(true);
  };

  return (
    <AlertDialog
      data-test="quote-reject-dialog"
      open={open}
      onOpenChange={(open) => {
        if (!isPending) setOpen(open);
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle data-test="quote-reject-title">
            {t("requests.details.financials.rejectTitle")}
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription data-test="quote-reject-description">
          {t("requests.details.financials.rejectMessage")}
        </AlertDialogDescription>
        <div className="my-4 flex flex-col gap-2">
          <Label>{t("requests.details.financials.rejectComments")}</Label>
          <Textarea
            data-test="quote-reject-comment"
            disabled={isPending}
            onChange={(e) => setComments(e.target.value)}
          />
        </div>
        <AlertDialogFooter className="grid grid-cols-2">
          <AlertDialogCancel data-test="quote-reject-cancel" className="col-span-1">
            {t("common.cancel")}
          </AlertDialogCancel>
          <Button disabled={isPending} variant="default" onClick={handleReject} data-test="quote-reject-action">
            {isPending && <Loader2Icon className="mr-2 animate-spin" />}
            {t("requests.details.financials.reject")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
