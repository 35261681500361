import { Button } from "@/components/ui/button";
import { TooltipProvider } from "@/components/ui/tooltip";
import { t } from "i18next";
import { ListIcon } from "lucide-react";
import useWorklist from "../../useWorklist";
import PortalTooltip from "@/components/PortalTooltip";

interface WorklistButtonProps {
  activeComponent: string;
  setActive: (value: string) => void;
}

export const WorklistButton = (props: WorklistButtonProps) => {
  const { activeComponent, setActive } = props;

  const { worklistItems } = useWorklist();

  if (!worklistItems || worklistItems.length === 0) return null;

  return (
    <TooltipProvider delayDuration={0}>
      <PortalTooltip text={t("mt.manageWorklist")}>
        <Button
          variant="outline"
          size="default"
          className="mt-2"
          onClick={() => setActive(activeComponent !== "worklist" ? "worklist" : "")}
        >
          <ListIcon className="mr-2 size-4" />
          {t("mt.worklist")}
        </Button>
      </PortalTooltip>
    </TooltipProvider>
  );
};
