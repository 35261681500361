import { FormField, FormItem, FormMessage } from "components/ui/form";
import { t } from "i18next";
import { LanguageOption } from "model/mt.typing";
import { UseFormReturn } from "react-hook-form";
import { LanguageSelector } from "../LanguageSelector";
import { FileMachineTranslationFormType } from "../../useFileMachineTranslationForm";

interface FileSourceControlsProps {
  sourceLanguages: LanguageOption[] | null;
  form: UseFormReturn<FileMachineTranslationFormType>;
  setTargets: (source: LanguageOption | null) => void;
}

export const FileSourceControls = ({ form, sourceLanguages, setTargets }: FileSourceControlsProps) => {
  return (
    <FormField
      control={form.control}
      data-test="mt-file-source-languages"
      name="sourceLanguage"
      render={({ field }) => (
        <FormItem className="flex w-full flex-col">
          <LanguageSelector
            languages={sourceLanguages}
            placeholder={t("mt.selectLanguage")}
            field={field}
            onSelect={(language: LanguageOption) => {
              if (language?.code != form.getValues("sourceLanguage")?.code) {
                form.setValue("sourceLanguage", language, { shouldValidate: true });
                setTargets(language);
              } else {
                // Clear the form element and reset the targets
                form.resetField("sourceLanguage");
                setTargets(null);
              }
            }}
          />
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
