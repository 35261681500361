import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { CostReportChartNames, ProjectCostPerLanguagesType , PerLanguageBarItemType } from "@/model/report.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { t } from "i18next";
import { chain, sumBy, uniqBy } from "lodash";
import { useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { Bar, BarChart, Brush, CartesianGrid, Legend, Rectangle, Tooltip, XAxis, YAxis } from "recharts";
import { ChartCard } from "../common/ChartCard";
import { ChartEmptyPlaceholder } from "../common/ChartEmptyPlaceholder";
import { ChartCardSkeleton } from "../common/ChartSkeleton";
import { ChartTooltipCursor } from "../common/ChartTooltipCursor";
import { useCostReportActions, useCostReportChartSettings, useCostReportFilters } from "../reportStore";
import { useChartDescription } from "./useChartDescription";
import { useChartBrushIndexes } from "../common/useChartBrushIndexes";

export function SpendPerTargetLanguagePage() {
  const { pricePerPairLanguages, isLoading } =
    useOutletContext<{ isLoading: boolean; pricePerPairLanguages: ProjectCostPerLanguagesType[] }>() ?? {};

  if (isLoading) return <ChartCardSkeleton />;
  if (pricePerPairLanguages === undefined) return null;

  return <SpendPerTargetLanguageChart pricePerPairLanguages={pricePerPairLanguages} isFocusView={true} />;
}

export function SpendPerTargetLanguageChart({
  pricePerPairLanguages,
  height = 600,
  isFocusView = false,
}: {
  pricePerPairLanguages: ProjectCostPerLanguagesType[];
  height?: number;
  isFocusView?: boolean;
}) {
  const formatNumber = useAuthUserStore((store) => store.formatNumber);
  const { title, description } = useChartDescription(CostReportChartNames.perLanguage, true, pricePerPairLanguages);
  const { includeOpenProjects } = useCostReportFilters();
  const { updateFilters } = useCostReportActions();
  const { saveChartSettings } = useCostReportActions();
  const chartSettings = useCostReportChartSettings(CostReportChartNames.perLanguage);

  const [brushStartEndIndex, setBrushStartEndIndex] = useChartBrushIndexes();

  const pricePerTargetLanguages = useMemo(
    () =>
      chain(pricePerPairLanguages)
        .groupBy((value) => `${value.targetLanguageCode}`)
        .map((value) => {
          return {
            languageCode: value[0].targetLanguageCode,
            languageName: value[0].targetLanguageName,
            currencyCode: value[0].currencyCode,
            price: sumBy(
              value.filter((v) => v.deliveryDate),
              "perLanguagePrice"
            ),
            priceOpenProjects: sumBy(
              value.filter((v) => !v.deliveryDate),
              "perLanguagePrice"
            ),
            associatedData: uniqBy(value, "sourceLanguageCode"),
            value,
          };
        })
        .orderBy("price", "desc")
        .map((value, index) => ({ ...value, brushTickName: index + 1 }))
        .value(),
    [pricePerPairLanguages]
  );

  const hasAtLeastOneOpenProject = pricePerTargetLanguages.some((value) => value.priceOpenProjects > 0);

  const handleOnTargetLanguageClick = (targetLanguageCode: string) => {
    if (!targetLanguageCode) return;
    updateFilters({ targetLanguageIds: [targetLanguageCode] });
  };

  if (pricePerPairLanguages.length === 0) {
    return <ChartEmptyPlaceholder />;
  }

  return (
    <ChartCard
      height={height}
      title={title}
      description={description}
      link={CostReportChartNames.perLanguage}
      isFocusView={isFocusView}
      chartSettings={chartSettings}
      saveChartSettings={saveChartSettings}
    >
      {({ displayGrid, displayBrush }) => {
        return (
          <BarChart
            data={pricePerTargetLanguages}
            margin={{
              top: 20,
              left: 40,
              right: 25,
              bottom: includeOpenProjects ? 20 : 10,
            }}
          >
            {displayGrid ? <CartesianGrid strokeDasharray="3 3" /> : null}
            {displayBrush ? (
              <Brush
                height={20}
                travellerWidth={5}
                onChange={setBrushStartEndIndex}
                startIndex={brushStartEndIndex.startIndex}
                endIndex={brushStartEndIndex.endIndex}
                className="text-sm"
                fill="#cbd5e1"
                stroke="#374151"
                dataKey="brushTickName"
              />
            ) : null}
            <XAxis
              dataKey={"languageCode"}
              className="text-xs font-medium text-secondary-foreground"
              tickFormatter={(value) => value}
              angle={310}
              minTickGap={15}
              height={displayBrush ? 45 : 30}
              dy={12}
              dx={-18}
            />
            <YAxis tickFormatter={(value) => formatNumber(value)} />
            <Tooltip
              cursor={<ChartTooltipCursor variant={"blue"} />}
              content={({ payload }) => {
                if (!payload?.[0]?.payload) return null;
                const data = payload[0].payload;
                return (
                  <Card>
                    <CardHeader className="px-2 py-1 text-base">
                      <CardTitle className="flex flex-col gap-1 text-base">
                        <span>
                          {data.languageName} {data.languageCode}
                        </span>
                        <span className="grid grid-cols-2 gap-2">
                          {includeOpenProjects ? (
                            <span className="text-muted-foreground">{t("reports.charts.total")}</span>
                          ) : null}
                          <span>{formatNumber(data.price + data.priceOpenProjects, data.currencyCode)}</span>
                          {includeOpenProjects ? (
                            <>
                              <span className="text-muted-foreground">{t("reports.charts.completedProjects")}</span>
                              <span>{formatNumber(data.price, data.currencyCode)}</span>
                              <span className="text-muted-foreground">{t("reports.charts.openProjects")}</span>
                              <span>{formatNumber(data.priceOpenProjects, data.currencyCode)}</span>
                            </>
                          ) : null}
                        </span>
                      </CardTitle>
                    </CardHeader>
                  </Card>
                );
              }}
            />
            {includeOpenProjects && hasAtLeastOneOpenProject ? (
              <Legend
                verticalAlign="bottom"
                wrapperStyle={{
                  paddingTop: "10px",
                }}
                formatter={(value) => {
                  return value === "price"
                    ? t("reports.charts.legend.completedProjects")
                    : t("reports.charts.legend.openProjects");
                }}
              />
            ) : null}
            <Bar
              fill="#3b82f6"
              stackId={includeOpenProjects ? "a" : undefined}
              dataKey="price"
              className="cursor-pointer fill-blue-400 stroke-blue-500 dark:fill-blue-700 dark:stroke-blue-800"
              activeBar={<Rectangle className="fill-blue-600 stroke-blue-700" />}
              onClick={(data: PerLanguageBarItemType) => handleOnTargetLanguageClick(data.languageCode)}
            />
            {includeOpenProjects ? (
              <Bar
                dataKey="priceOpenProjects"
                stackId={"a"}
                fill="#84cc16"
                className="cursor-pointer fill-lime-400 stroke-lime-500 dark:fill-lime-700 dark:stroke-lime-800"
                activeBar={<Rectangle className="fill-lime-600 stroke-lime-700" />}
                onClick={(data: PerLanguageBarItemType) => handleOnTargetLanguageClick(data.languageCode)}
              />
            ) : null}
          </BarChart>
        );
      }}
    </ChartCard>
  );
}
