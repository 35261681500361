import DOMPurify from "dompurify";

export function trimAutodetect(text: string | undefined) {
  if (!text) return null;
  if (text.length < 100) return text;
  const textToDetect = text.substring(0, 100);
  const lastSpaceIndex = textToDetect.lastIndexOf(" ");
  if (lastSpaceIndex > 0) return textToDetect.substring(0, lastSpaceIndex);
  return textToDetect;
}

export function getWordCount(text: string): number {
  const matches = text.match(/[\u00ff-\uffff]|\S+/g);
  return matches ? matches.length : 0;
}

export function isStringEmptyOrNull(value: string | null | undefined) {
  return !value || value.trim().length === 0;
}

export function sanitizeString(s: string, toLower?: boolean) {
  if (!s) return "";
  const sanitized = s.trim().replace(/\s+/g, "-");
  return toLower ? sanitized.toLowerCase() : sanitized;
}

/**
 * Sort compare function to be use in a sort to order an array of string alphabetically
 * e.g: ['C', 'A', 'B'].sort(sortAlphabeticallyCompareFn) // ['A', 'B', 'C']
 */
export const sortAlphabeticallyCompareFn = (a: string, b: string) => (a > b ? 1 : -1);

/**
 * Sort compare function to be use in a sort to reverse order an array of string alphabetically
 * e.g: ['C', 'A', 'B'].sort(reverseSortAlphabeticallyCompareFn) // ['C', 'B', 'A']
 */
export const reverseSortAlphabeticallyCompareFn = (a: string, b: string) => (a > b ? -1 : 1);

export function sanitizeHtml(s: string) {
  return DOMPurify.sanitize(s);
}
