import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useFileDownloadMutation } from "@/query/request.query";
import { DownloadIcon, FileDownIcon, Loader2Icon } from "lucide-react";

interface Props {
  entityType: string;
  fileName: string;
  id: string;
}

export function DownloadLink({ entityType, fileName, id }: Props) {
  const { mutateAsync: downloadFile, isPending } = useFileDownloadMutation(id, entityType, fileName);
  return (
    <Button
      data-test="file-download-link-action"
      variant="link"
      className="m-0 h-fit p-0"
      onClick={() => downloadFile()}
    >
      <span data-test="file-download-name" title={fileName} className="max-w-72 select-text truncate text-sm">
        {fileName}
      </span>
      <FileDownIcon className="mx-2 size-4" />
      {isPending ? <Loader2Icon data-test="loader-icon" className="mr-2 size-4 shrink-0 animate-spin" /> : null}
    </Button>
  );
}

export function DownloadLinkIcon({ entityType, fileName, id }: { entityType: string; fileName: string; id: string }) {
  const { mutate: fileDownload, isPending } = useFileDownloadMutation(id, entityType, fileName);

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            data-test="file-download-link-action"
            variant="outline"
            size="icon"
            disabled={isPending}
            onClick={() => fileDownload()}
          >
            {!isPending ? (
              <DownloadIcon className="size-5" />
            ) : (
              <Loader2Icon data-test="loader-icon" className="size-5 shrink-0 animate-spin" />
            )}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span data-test="file-download-name" title={fileName} className="max-w-72 select-text truncate text-sm">
            {fileName}
          </span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
