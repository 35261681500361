import { EmptyPlaceholder } from "@/components/EmptyPlaceholder";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { Skeleton } from "@/components/ui/skeleton";
import { ProjectDocument } from "@/model/request.typing";
import { clsx } from "clsx";
import { ChevronDownIcon, ChevronUpIcon, FilesIcon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DownloadLink } from "./components/DownloadLink";

export function DocumentsTabContent({
  id,
  entityType,
  documentData,
  isLoading,
}: {
  id: string;
  entityType: string;
  documentData: ProjectDocument[];
  isLoading: boolean;
}) {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.documents" });
  if (!documentData || isLoading) {
    return (
      <div className="p-6 pt-4">
        <div className="rounded-3xl bg-card p-4">
          <Skeleton className="h-5 w-48" />
          <div className="mt-4 grid grid-cols-2 items-start justify-between border-y border-border p-2">
            <Skeleton className="h-10 w-52" />
            <Skeleton className="h-10 w-52" />
          </div>
        </div>
      </div>
    );
  }

  if (documentData.length === 0) {
    return (
      <div className="p-6 pt-4">
        <div className="rounded-3xl bg-card p-6">
          <EmptyPlaceholder className="mt-12 border-none p-4">
            <EmptyPlaceholder.Icon icon={FilesIcon} />
            <EmptyPlaceholder.Title>{t("empty.title")}</EmptyPlaceholder.Title>
            <EmptyPlaceholder.Description>{t("empty.description")}</EmptyPlaceholder.Description>
          </EmptyPlaceholder>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 pt-4">
      <div className="rounded-3xl bg-card p-6" data-test="source-documents">
        {documentData.map((document) => {
          return (
            <div
              key={document.sourceFile.id}
              className={"grid grid-cols-2 items-start justify-between border-b border-border p-2"}
            >
              <div className="flex flex-col gap-1 text-sm">
                <span className="text-muted-foreground">{t("sourceLanguage")}</span>
                <div>
                  <div className="gap-x-2 text-sm">
                    <span className="mr-2">{document.sourceFile.sourceLanguage.name}</span>
                    {document.sourceFile.sourceLanguage.id}
                  </div>
                  <div className="overflow-hidden" data-test="source-files">
                    {document.sourceFile?.fileName ? (
                      <DownloadLink fileName={document.sourceFile.fileName} id={id} entityType={entityType} />
                    ) : null}
                  </div>
                </div>
              </div>
              <TargetLanguages targetFiles={document.targetFiles} id={id} entityType={entityType} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

function TargetLanguages({
  targetFiles,

  id,
  entityType,
}: {
  targetFiles: ProjectDocument["targetFiles"];
  id: string;
  entityType: string;
}) {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.documents" });
  const [isOpen, setIsOpen] = useState(false);

  if (targetFiles.length === 1) {
    const targetFile = targetFiles[0];
    return (
      <div className="flex flex-col gap-1 text-sm">
        <span className="text-muted-foreground">{t("targetLanguage", { count: 1 })}</span>
        <div>
          <div className="flex items-center gap-2">
            <div className="gap-x-2 text-sm">
              <span className="mr-2">{targetFile.targetLanguage.name}</span>
              <span>{targetFile.targetLanguage.id}</span>
            </div>
          </div>
          {targetFile.targetFile?.fileName ? (
            <DownloadLink fileName={targetFile.targetFile.fileName} id={id} entityType={entityType} />
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-1 text-sm">
      <span className="text-muted-foreground">{t("targetLanguage", { count: targetFiles.length })}</span>
      <Collapsible open={isOpen} onOpenChange={setIsOpen} className="space-y-2 px-0">
        {isOpen ? null : (
          <CollapsibleTrigger asChild>
            <Button variant="link" size="sm" className="mx-0 h-fit gap-2 px-0 text-sm">
              <span>{t("language", { count: targetFiles.length })}</span>
              <ChevronDownIcon className="ml-2 size-4" />
            </Button>
          </CollapsibleTrigger>
        )}
        <CollapsibleContent>
          <div className="flex flex-col">
            {targetFiles.map(({ targetFile, targetLanguage }) => {
              return (
                <div
                  key={targetLanguage.id}
                  className={clsx("flex flex-col items-start", targetFile?.fileName ? "mb-3" : "")}
                >
                  <div className="text-sm">
                    {targetLanguage.name} {targetLanguage.id}
                  </div>

                  {targetFile?.fileName ? (
                    <div>
                      <DownloadLink fileName={targetFile.fileName} id={id} entityType={entityType} />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
          <CollapsibleTrigger asChild>
            <Button variant="link" size="sm" className="px-0">
              <ChevronUpIcon className="size-4" />
            </Button>
          </CollapsibleTrigger>
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
}
