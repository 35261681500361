import { MENU_ICONS } from "@/components/Icons";
import Loader from "@/components/Loader";
import { PageHeaderShell } from "@/components/shell/PageHeaderShell";
import { PageShell } from "@/components/shell/PageShell";
import { useUserPreferencesQuery } from "@/query/user.query";
import { useTranslation } from "react-i18next";
import { UserProfileForm } from "./UserProfileForm";

export function UserProfile() {
  const { t } = useTranslation();
  const { data, isLoading } = useUserPreferencesQuery();

  return (
    <PageShell>
      <PageHeaderShell heading={t("profile.title")} icon={MENU_ICONS.profile} />
      <Loader isShown={isLoading} variant="lg" />
      {data ? <UserProfileForm userPreferences={data} /> : null}
    </PageShell>
  );
}
