// prettier-ignore
// eslint-disable-next-line no-irregular-whitespace
const forbiddenCharacters: RegExp = /[ÇüéâäàåçêëèïîìÄÅÉæÆôöòûùÿÖÜø£Ø×ƒáíóúñÑªº¿®¬½¼¡«»░▒▓│┤ÁÂÀ©╣║╗╝¢¥┐└┴┬├─┼ãÃ╚╔╩╦╠═╬¤ðÐÊËÈıÍÎÏ┘┌█▄¦Ì▀ÓßÔÒõÕµþÞÚÛÙýÝ¯´≡±‗¾¶§÷¸°¨·¹³²■ ]/;
const FILE_SIZES = ["Bytes", "KB", "MB", "GB", "TB"];

export const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function getFilenameFromUrl(url: string) {
  return url.substring(url.indexOf("=") + 1, url.length);
}

export function validateFilename(filename: string) {
  return !forbiddenCharacters.test(filename);
}

export const bytesToSize = (bytes: number | null | undefined): string => {
  if (bytes === undefined || bytes === null || Number.isNaN(bytes) || bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const unit = FILE_SIZES[i];
  const value = Math.round(bytes / 1024 ** i);
  return `${value} ${unit}`;
};

export const excludedFileExtensions = [
  "0xe",
  "73k",
  "73p",
  "89k",
  "89z",
  "8ck",
  "a6p",
  "a7r",
  "ac",
  "acc",
  "acr",
  "actc",
  "action",
  "actm",
  "afmacro",
  "afmacros",
  "ahk",
  "air",
  "apk",
  "app",
  "appimage",
  "applescript",
  "arscript",
  "as",
  "asb",
  "atmx",
  "awk",
  "azw2",
  "ba_",
  "bat",
  "beam",
  "bin",
  "bms",
  "bns",
  "btm",
  "cab",
  "caction",
  "cel",
  "celx",
  "cfs",
  "cgi",
  "cheat",
  "cmd",
  "cof",
  "coffee",
  "com",
  "command",
  "cpl",
  "crt",
  "csh",
  "cyw",
  "dek",
  "dld",
  "dll",
  "dmc",
  "docm",
  "dotm",
  "ds",
  "dxl",
  "e_e",
  "ear",
  "ebacmd",
  "ebm",
  "ebs",
  "ebs2",
  "ecf",
  "eham",
  "elf",
  "epk",
  "es",
  "esh",
  "ex_",
  "ex4",
  "ex5",
  "exe",
  "exe1",
  "exopc",
  "exz",
  "ezs",
  "ezt",
  "fap",
  "fas",
  "fba",
  "fky",
  "fpi",
  "frs",
  "fxp",
  "gadget",
  "gm9",
  "gpe",
  "gpu",
  "gs",
  "ham",
  "hms",
  "hpf",
  "hta",
  "icd",
  "iim",
  "inf1",
  "ins",
  "inx",
  "ipa",
  "ipf",
  "ipk",
  "isp",
  "isu",
  "ita",
  "jar",
  "job",
  "js",
  "jse",
  "jsf",
  "jsx",
  "kix",
  "ksh",
  "kx",
  "lnk",
  "lo",
  "ls",
  "mac",
  "mam",
  "mamc",
  "mcr",
  "mel",
  "mem",
  "mhm",
  "mio",
  "mlappinstall",
  "mlx",
  "mm",
  "mpk",
  "mpx",
  "mrc",
  "mrp",
  "ms",
  "msc",
  "msi",
  "msl",
  "msp",
  "mst",
  "mxe",
  "n",
  "ncl",
  "nexe",
  "obs",
  "ore",
  "osx",
  "otm",
  "out",
  "p",
  "paf",
  "pafexe",
  "pex",
  "phar",
  "pif",
  "plsc",
  "plx",
  "potm",
  "ppam",
  "ppsm",
  "prc",
  "prg",
  "ps1",
  "pvd",
  "pwc",
  "pxo",
  "pyc",
  "pyo",
  "qit",
  "qpx",
  "rbf",
  "rbx",
  "reg",
  "rfs",
  "rfu",
  "rgs",
  "rox",
  "rpg",
  "rpj",
  "run",
  "rxe",
  "s2a",
  "sapk",
  "sbs",
  "sca",
  "scar",
  "scb",
  "scpt",
  "scptd",
  "scr",
  "script",
  "sct",
  "seed",
  "server",
  "sh",
  "shb",
  "shortcut",
  "shs",
  "sk",
  "smm",
  "snap",
  "spr",
  "srec",
  "sts",
  "tcp",
  "thm",
  "tiapp",
  "tipa",
  "tlb",
  "tms",
  "tpk",
  "u3p",
  "udf",
  "upx",
  "url",
  "uvm",
  "uw8",
  "vb",
  "vbe",
  "vbs",
  "vbscript",
  "vdo",
  "vexe",
  "vlx",
  "vpm",
  "vxp",
  "wcm",
  "widget",
  "wiz",
  "workflow",
  "wpk",
  "wpm",
  "ws",
  "wsf",
  "wsh",
  "x86",
  "x86_64",
  "xap",
  "xbap",
  "xbe",
  "xex",
  "xlsm",
  "xqt",
  "xys",
  "ygh",
  "zl9",
];

export const excludedFileTypes = ["application/octet-stream", "text/x-sh"];
export const excludedFileChars = ["*", '"', "/", "\\", ":", "|", "<", ">", "?"];
