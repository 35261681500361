import { useDateOperations } from "@/hooks/useDate";
import { differenceInHours } from "date-fns";

function useFileActions(code: number | null, uploadDate: Date) {
  const { convertDate, now } = useDateOperations();

  const checkForError = (status: string) => {
    const hours = differenceInHours(now(), convertDate(uploadDate));
    return (status !== "finished" && hours > 12) || code === 500;
  };

  const refreshNeeded = (currentStatus: string | undefined) => {
    return (currentStatus === "new" || currentStatus === "analyzed") && !checkForError(currentStatus);
  };

  return { checkForError, refreshNeeded };
}

export default useFileActions;
