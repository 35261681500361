import { IdName } from "@/model/common";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { getLocalStorageValue, setLocalStorageValue } from "@/utils/storage";
import { Table } from "@tanstack/react-table";
import { useState } from "react";

export function useDatatableFilters<TData>(table: Table<TData>, datatableName: string) {
  const { user } = useAuthUserStore();
  const localStorageId = `${user?.id}-${datatableName}-filters`;

  const [filters, setFilters] = useState<IdName<string>[]>(() => {
    const savedValue = getLocalStorageValue<IdName<string>[]>(localStorageId);
    return savedValue ?? [];
  });

  function setDisplayedFilters(updaterOrValue: IdName<string>[]) {
    setLocalStorageValue(localStorageId, updaterOrValue);
    setFilters(updaterOrValue);
  }

  function getIsFiltered() {
    return table.getState().columnFilters.length > 0 || !!table.getState().globalFilter;
  }

  return { isFiltered: getIsFiltered(), displayedFilters: filters, setDisplayedFilters };
}
