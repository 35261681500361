import { DownloadOptions, MachineOcrTranslationResult } from "model/mt.typing";
import { FormControl, FormField, FormItem, FormMessage } from "components/ui/form";
import { ControllerRenderProps, UseFormReturn } from "react-hook-form";
import { OcrParagraph } from "./OcrParagraph";
import { t } from "i18next";
import { OcrDownloadButton } from "./OcrDownloadButton";
import { LanguageLoader } from "../LanguageLoader";
import { OcrMachineTranslationFormType } from "../../useOcrMachineTranslationForm";

interface OcrParagraphsProps {
  form: UseFormReturn<OcrMachineTranslationFormType>;
  handleDownloadFiles: (downloadType: DownloadOptions) => Promise<boolean>;
  showOcrDownload: boolean;
  lastOcrResult: MachineOcrTranslationResult | null;
  isOcrPreprocessed: boolean;
}

export const OcrParagraphs = (props: OcrParagraphsProps) => {
  const { form, handleDownloadFiles, showOcrDownload, lastOcrResult, isOcrPreprocessed } = props;

  const isResultLoading = !lastOcrResult || lastOcrResult.status != "finished";

  const updateParagraph = (pageNumber: number, paragraphNumber: number, paragraph: string | null) => {
    const paragraphs = form.getValues("paragraphs");
    const p = paragraphs?.find((x) => x.pageNumber == pageNumber && x.paragraphNumber == paragraphNumber);
    if (p && paragraph) p.paragraph = paragraph;
    else if (p && paragraph === null) p.paragraph = "";
    form.setValue("paragraphs", paragraphs);
  };

  const renderParagraphs = (
    field: ControllerRenderProps<OcrMachineTranslationFormType, "paragraphs">,
    renderTarget: boolean
  ) => {
    // render loader instead of target text
    if (isResultLoading && renderTarget)
      return (
        <div className="grid w-full grid-cols-2 items-start gap-2">
          <div>
            {field.value?.map((paragraph) => {
              return (
                <OcrParagraph
                  data-test={`mt-ocr-paragraphs-source-${paragraph.pageNumber}_${paragraph.paragraphNumber}`}
                  key={`source_${paragraph.pageNumber}_${paragraph.paragraphNumber}`}
                  pageNumber={paragraph.pageNumber}
                  paragraphNumber={paragraph.paragraphNumber}
                  paragraph={paragraph.paragraph}
                  updateParagraph={updateParagraph}
                />
              );
            })}
          </div>
          <LanguageLoader />
        </div>
      );

    // render source & target paragraph on the same line
    return (
      <>
        {field.value?.map((paragraph) => {
          return (
            <div className="grid w-full grid-cols-2 gap-2" key={`${paragraph.pageNumber}_${paragraph.paragraphNumber}`}>
              <OcrParagraph
                data-test={`mt-ocr-paragraphs-source-${paragraph.pageNumber}_${paragraph.paragraphNumber}`}
                key={`source_${paragraph.pageNumber}_${paragraph.paragraphNumber}`}
                pageNumber={paragraph.pageNumber}
                paragraphNumber={paragraph.paragraphNumber}
                paragraph={paragraph.paragraph}
                updateParagraph={updateParagraph}
              />
              {renderTarget && (
                <OcrParagraph
                  data-test={`mt-ocr-paragraphs-target-${paragraph.pageNumber}_${paragraph.paragraphNumber}`}
                  key={`target_${paragraph.pageNumber}_${paragraph.paragraphNumber}`}
                  pageNumber={paragraph.pageNumber}
                  paragraphNumber={paragraph.paragraphNumber}
                  paragraph={
                    lastOcrResult?.paragraphs?.filter(
                      (x) => x.pageNumber === paragraph.pageNumber && x.paragraphNumber === paragraph.paragraphNumber
                    )[0].paragraph
                  }
                />
              )}
            </div>
          );
        })}
      </>
    );
  };

  // render only source text - the ocr process is not started yet
  const renderOnlySourceParagraphs = () => {
    return (
      <div className="flex-1">
        <div className="mb-5 flex w-full items-end justify-between border-b-2 pb-1 text-xl">
          <div>{t("mt.file.sourceText")}</div>
          <div className="h-9" />
        </div>
        <div>
          <FormField
            control={form.control}
            name="paragraphs"
            data-test={`mt-ocr-paragraphs`}
            render={({ field }) => (
              <FormItem className="flex w-full flex-col">
                <FormControl>
                  <div className="relative">{renderParagraphs(field, false)}</div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    );
  };

  if (!isOcrPreprocessed) return renderOnlySourceParagraphs();

  return (
    <div className="flex-1">
      <div className="mb-5 flex w-full justify-between text-xl">
        <div className="grid w-full grid-cols-2 gap-2">
          <div className="flex items-end justify-between border-b-2 pb-1">
            <div>{t("mt.file.sourceText")}</div>
            {showOcrDownload ? (
              <OcrDownloadButton type="sourceText" onDownload={() => handleDownloadFiles("sourceText")} />
            ) : (
              <div className="h-9" />
            )}
          </div>
          <div className="flex items-end justify-between border-b-2 pb-1">
            <div>{t("mt.file.targetText")}</div>
            {showOcrDownload ? (
              <OcrDownloadButton type="targetText" onDownload={() => handleDownloadFiles("targetText")} />
            ) : (
              <div className="h-9"></div>
            )}
          </div>
        </div>
      </div>
      <div className="flex grid-cols-2">
        <FormField
          control={form.control}
          name="paragraphs"
          data-test={`mt-ocr-paragraphs`}
          render={({ field }) => (
            <FormItem className="flex w-full flex-col">
              <FormControl>
                <div className="relative">{renderParagraphs(field, true)}</div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};
