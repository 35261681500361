import { EmptyPlaceholder } from "@/components/EmptyPlaceholder";
import { MENU_ICONS } from "@/components/Icons";
import { CardLink } from "@/components/Links";
import { User } from "@/model/user.typing";
import { redirectToPortal } from "@/utils/url";
import { t } from "i18next";
import { Button } from "./ui/button";

export function HomePageTiles({ user }: { user: User | null }) {
  const tiles = user?.modules ?? [];
  return (
    <div className="mt-4">
      {tiles && tiles.length > 0 ? (
        <div data-test="home-page-cards" className="grid grid-cols-1 gap-4 lg:grid-cols-2 2xl:grid-cols-3">
          {tiles.map((tile) => (
            <CardLink
              key={tile.name}
              link={tile.link}
              icon={tile.iconName}
              title={tile.name}
              description={tile.description}
            />
          ))}
        </div>
      ) : (
        <EmptyPlaceholder>
          <EmptyPlaceholder.Icon icon={MENU_ICONS.home} />
          <EmptyPlaceholder.Title>{t("home.empty.title")}</EmptyPlaceholder.Title>
          <EmptyPlaceholder.Description>{t("home.empty.description")}</EmptyPlaceholder.Description>
          <Button data-test="empty-placeholder-action" onClick={() => redirectToPortal()} className="hidden">
            {t("home.empty.link")}
          </Button>
        </EmptyPlaceholder>
      )}
    </div>
  );
}
