import { create } from "zustand";
import { persist } from "zustand/middleware";

interface RequestsSearchStoreState {
  searchText: string;
  actions: {
    updateSearchText: (newSearchText: string) => void;
    reset: () => void;
  };
}

const initialState = {
  searchText: "",
};

const requestsSearchStore = create(
  persist<RequestsSearchStoreState>(
    (set) => ({
      ...initialState,
      actions: {
        updateSearchText: (newSearchText: string) => {
          set({ searchText: newSearchText });
        },
        reset: () => {
          set(initialState);
        },
      },
    }),
    {
      name: "requests-search-storage",
      partialize: (state) => {
        // removes actions from the serialized state
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { actions, ...rest } = state;
        return { ...rest } as RequestsSearchStoreState;
      },
    }
  )
);

export const useRequestsSearchText = () => requestsSearchStore((state) => state.searchText);
export const useRequestsSearchTextActions = () => requestsSearchStore((state) => state.actions);
