import { IllustrationCostChart } from "@/components/svgs/Illustrations";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { ProjectCostPerLanguagesType } from "@/model/report.typing";
import { ChartCardSkeleton } from "@/pages/reports/common/ChartSkeleton";
import { ChartTooltipCursor } from "@/pages/reports/common/ChartTooltipCursor";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { chain, sumBy, uniqBy } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Bar, BarChart, Rectangle, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { AnalysisChartCard, AnalysisEmptyPlaceholder } from "./AnalysisChartCard";

export function AnalysisCostPerLanguage() {
  const { pricePerPairLanguages, isLoading } =
    useOutletContext<{ isLoading: boolean; pricePerPairLanguages: ProjectCostPerLanguagesType[] }>() ?? {};

  if (isLoading) return <ChartCardSkeleton />;
  if (!pricePerPairLanguages || pricePerPairLanguages.length === 0) return null;

  return <AnalysisCostPerLanguageChart data={pricePerPairLanguages} />;
}

export function AnalysisCostPerLanguageChart({
  data,
  height = 600,
  className,
}: {
  data: ProjectCostPerLanguagesType[];
  height?: number;
  className?: string;
}) {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.analysis" });
  const formatNumber = useAuthUserStore((store) => store.formatNumber);

  const pricePerTargetLanguages = useMemo(
    () =>
      chain(data)
        .groupBy("targetLanguageCode")
        .map((groupedData) => ({
          languageCode: groupedData[0].targetLanguageCode,
          languageName: groupedData[0].targetLanguageName,
          currencyCode: groupedData[0].currencyCode,
          price: sumBy(
            groupedData.filter((item) => item.deliveryDate),
            "perLanguagePrice"
          ),
          priceOpenProjects: sumBy(
            groupedData.filter((item) => !item.deliveryDate),
            "perLanguagePrice"
          ),
          associatedData: uniqBy(groupedData, "sourceLanguageCode"),
        }))
        .orderBy("targetLanguageCode")
        .value(),
    [data]
  );

  const renderTooltipContent = useCallback(
    ({ active, payload }: TooltipProps<ValueType, NameType>) => {
      if (!active || !payload?.[0]?.payload) return null;
      const data = payload[0].payload;
      return (
        <Card>
          <CardHeader className="px-2 py-1 text-base">
            <CardTitle className="flex flex-col gap-1 text-base">
              <span>
                {data.languageName} ({data.languageCode})
              </span>
              <span className="grid grid-cols-2 gap-2">
                <span>{formatNumber(data.price + data.priceOpenProjects, data.currencyCode)}</span>
              </span>
            </CardTitle>
          </CardHeader>
        </Card>
      );
    },
    [formatNumber]
  );

  if (pricePerTargetLanguages.length === 0) {
    return (
      <AnalysisEmptyPlaceholder
        id="project-cost"
        title={t("projectCostOverview")}
        height={height}
        message={t("processingCostData")}
        illustration={<IllustrationCostChart />}
        className={className}
      />
    );
  }

  return (
    <AnalysisChartCard height={height} title={t("projectCostOverview")} id="project-cost" className={className}>
      <BarChart data={pricePerTargetLanguages} margin={{ top: 20, left: 40, right: 25, bottom: 20 }}>
        <XAxis
          dataKey="languageCode"
          className="text-xs font-medium text-secondary-foreground"
          tickFormatter={(value) => value}
          angle={310}
          minTickGap={15}
          height={30}
          dy={12}
          dx={-18}
        />
        <YAxis tickFormatter={(value) => formatNumber(value)} />
        <Tooltip cursor={<ChartTooltipCursor variant="blue" />} content={renderTooltipContent} />
        <Bar
          fill="#61A3FF"
          dataKey="price"
          barSize={32}
          activeBar={<Rectangle className="fill-blue-600 stroke-blue-700" />}
        />
      </BarChart>
    </AnalysisChartCard>
  );
}
