import { ReactNode } from "react";

interface GlossaryTopBarProps {
  title: string;
  actionButtons: ReactNode;
}

export const GlossaryTopBar = (props: GlossaryTopBarProps) => {
  const { title, actionButtons } = props;
  return (
    <div className="mb-2 flex items-center justify-between gap-2" data-test={`mt-glossary-topbar-div`}>
      <h2 className="text-xl font-medium">{title}</h2>
      <div className="flex items-center gap-1">{actionButtons}</div>
    </div>
  );
};
