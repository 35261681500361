import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "components/ui/dialog";
import { t } from "i18next";
import { useState } from "react";
import { LanguageOption, Terminology, TerminologyUploadResult } from "@/model/mt.typing";
import useGlossary from "../useGlossary";
import { CircleX, Download, Info, Loader2Icon, Upload, X } from "lucide-react";
import { ButtonIcon } from "@/components/button/ButtonIcon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { FileWithPath, useDropzone } from "react-dropzone";
import { useUploadGlossaryFileMutation } from "@/query/mt/machinetranslation.query";
import { toast } from "sonner";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { mapTerminologyItemLanguages } from "@/utils/mapping";
import { SingleLanguageFilter } from "@/components/filters/LanguageFilters";

type UploadMessage = {
  success: boolean;
  message: string;
};

const GlossaryUploadDialog = ({
  isVisible,
  setIsVisible,
  setGlossaryResult,
}: {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  setGlossaryResult: (value: TerminologyUploadResult) => void;
}) => {
  const { currentTerminology, setCurrentTerminology, terminologyConfiguration, setTargetsForUpload } = useGlossary();
  const { mutateAsync: uploadGlossary } = useUploadGlossaryFileMutation();

  const [sourceLanguageCode, setSourceLanguageCode] = useState<string | null>(null);
  const [targetLanguageCode, setTargetLanguageCode] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<FileWithPath | null>(null);
  const [uploadMessage, setUploadMessage] = useState<UploadMessage | null>(null);
  const [targetLanguages, setTargetLanguages] = useState<LanguageOption[]>(terminologyConfiguration.targetLanguages);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "text/csv": [".csv"],
    },
    onDrop: (acceptedFiles: FileWithPath[]) => {
      if (acceptedFiles.length === 0) {
        toast.warning(t("mt.glossary.uploadDialog.extensionNotSupported"));
        return;
      }

      if (!sourceLanguageCode || !targetLanguageCode) {
        toast.warning(t("mt.glossary.uploadDialog.warningNoLanguages"));
        return;
      }

      setSelectedFile(acceptedFiles[0]);
    },
  });

  const handleGlossaryUpload = async () => {
    if (currentTerminology && selectedFile && sourceLanguageCode && targetLanguageCode) {
      setIsProcessing(true);
      const result = await uploadGlossary({
        customerId: terminologyConfiguration.customerId,
        instanceId: terminologyConfiguration.instanceId,
        terminologyId: currentTerminology.id,
        sourceLanguageCode: sourceLanguageCode,
        targetLanguageCode: targetLanguageCode,
        file: selectedFile,
      });

      if (result.success && result.terminology) {
        result.terminology = mapTerminologyItemLanguages(terminologyConfiguration.mappedLanguages, result.terminology);
        setCurrentTerminology(result.terminology);
      }
      setSelectedFile(null);
      setIsVisible(false);
      setGlossaryResult(getResult(result, currentTerminology));
      setIsProcessing(false);
    }
  };

  const handleOpenChange = (value: boolean) => {
    setIsVisible(value);
    setSelectedFile(null);
    setUploadMessage(null);
  };

  return (
    <Dialog open={isVisible} onOpenChange={(value) => handleOpenChange(value)}>
      <DialogContent
        data-test="glossary-upload-dialog"
        className="max-w-4xl"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <GlossaryDialogHeader isProcessing={isProcessing} />

        <span className="text-lg font-bold">{t("mt.glossary.uploadDialog.stepOne")}</span>
        <span className="text-sm">
          <Trans i18nKey="mt.glossary.uploadDialog.stepOneDescription" />
        </span>

        <DownloadTemplateButton isProcessing={isProcessing} />

        <span className="text-lg font-bold">{t("mt.glossary.uploadDialog.stepTwo")}</span>
        <span className="text-sm">{t("mt.glossary.uploadDialog.stepTwoDescription")}</span>

        {uploadMessage !== null ? (
          <Alert variant={uploadMessage.success ? "info" : "warning"} className="mb-2">
            <AlertDescription className="flex items-center gap-2">{uploadMessage.message}</AlertDescription>
          </Alert>
        ) : null}

        <SelectLanguagesForm
          sourceLanguageCode={sourceLanguageCode}
          setSourceLanguageCode={setSourceLanguageCode}
          targetLanguageCode={targetLanguageCode}
          setTargetLanguageCode={setTargetLanguageCode}
          sourceLanguages={terminologyConfiguration.sourceLanguages}
          targetLanguages={targetLanguages}
          setTargetsForUpload={(languageCode) =>
            setTargetsForUpload(
              languageCode,
              targetLanguageCode,
              setTargetLanguageCode,
              setTargetLanguages,
              terminologyConfiguration
            )
          }
        />

        {selectedFile === null ? (
          <div
            {...getRootProps()}
            className="flex cursor-pointer flex-col items-center gap-2 rounded-md border-2 border-dashed py-4 outline-none transition duration-150 ease-in-out hover:bg-accent"
            data-test="glossaryfile-upload-dropzone"
          >
            <input {...getInputProps()} />
            <Upload className="mr-2 size-4" />
            {t("mt.glossary.uploadDialog.chooseFile")}
            <span className="text-sm font-medium">{t("mt.glossary.uploadDialog.uploadRule5")}</span>
          </div>
        ) : (
          <div className="space-y-4">
            {!isProcessing ? (
              <div className="flex items-center">
                <span className="text-sm">
                  {t("mt.glossary.uploadDialog.selectedFile", { fileName: selectedFile.name })}
                </span>
                <ButtonIcon icon={CircleX} className="ml-2 size-4" onClick={() => setSelectedFile(null)} />
              </div>
            ) : null}
            <Button
              variant="default"
              size="lg"
              disabled={isProcessing}
              className="w-full justify-center text-center font-normal"
              onClick={async () => {
                await handleGlossaryUpload();
              }}
            >
              {isProcessing ? (
                <Loader2Icon className="mr-2 size-4 animate-spin opacity-50" />
              ) : (
                <Upload className="mr-2 size-4" />
              )}
              {t("mt.glossary.uploadDialog.uploadTemplate")}
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

function GlossaryDialogHeader({ isProcessing }: { isProcessing: boolean }) {
  return (
    <DialogHeader>
      <div className="mb-2 inline-flex items-center justify-between">
        <DialogTitle>{t("mt.glossary.uploadDialog.title")}</DialogTitle>
        {!isProcessing ? (
          <DialogClose asChild>
            <ButtonIcon icon={X} className="size-6" />
          </DialogClose>
        ) : null}
      </div>
      <DialogDescription>
        <Alert variant="info" className="mb-2">
          <AlertDescription className="flex items-center gap-2">
            {t("mt.glossary.uploadDialog.alert")}
            <Popover modal={true}>
              <PopoverTrigger asChild>
                <ButtonIcon icon={Info} className="size-5 opacity-50" />
              </PopoverTrigger>
              <PopoverContent align="center" className="min-w-96 p-3">
                <ul className="ml-4 list-disc space-y-1 text-sm">
                  <li>{t("mt.glossary.uploadDialog.uploadRule1")}</li>
                  <li>{t("mt.glossary.uploadDialog.uploadRule2")}</li>
                  <li>{t("mt.glossary.uploadDialog.uploadRule3")}</li>
                  <li>{t("mt.glossary.uploadDialog.uploadRule4")}</li>
                  <li>{t("mt.glossary.uploadDialog.uploadRule5")}</li>
                </ul>
              </PopoverContent>
            </Popover>
          </AlertDescription>
        </Alert>
      </DialogDescription>
    </DialogHeader>
  );
}

function DownloadTemplateButton({ isProcessing }: { isProcessing: boolean }) {
  return (
    <DropdownMenu modal={true}>
      <DropdownMenuTrigger asChild>
        <Button disabled={isProcessing} variant={"outline"} className="w-full justify-center text-center font-normal">
          <Download className="mr-2 size-4" />
          {t("mt.glossary.uploadDialog.downloadTemplate")}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="center">
        <DropdownMenuItem asChild>
          <Link className="cursor-pointer" to="/files/glossaryTemplate.xlsx" target="_blank" download>
            {t("mt.glossary.uploadDialog.downloadXLSXTemplate")}
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link className="cursor-pointer" to="/files/glossaryTemplate.csv" target="_blank" download>
            {t("mt.glossary.uploadDialog.downloadCSVTemplate")}
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function SelectLanguagesForm({
  sourceLanguageCode,
  setSourceLanguageCode,
  targetLanguageCode,
  setTargetLanguageCode,
  sourceLanguages,
  targetLanguages,
  setTargetsForUpload,
}: {
  sourceLanguageCode: string | null;
  setSourceLanguageCode: (value: string | null) => void;
  targetLanguageCode: string | null;
  setTargetLanguageCode: (value: string | null) => void;
  sourceLanguages: LanguageOption[];
  targetLanguages: LanguageOption[];
  setTargetsForUpload: (value: string | null) => void;
}) {
  const sourceOptions = sourceLanguages.map((language) => ({
    id: language.code,
    name: language.label,
  }));
  const targetOptions = targetLanguages.map((language) => ({
    id: language.code,
    name: language.label,
  }));

  return (
    <div className="flex gap-4">
      <div className="col-span-5 flex w-full flex-col">
        <SingleLanguageFilter
          selectedLanguageCode={sourceLanguageCode}
          onChange={(sourceLanguageCode) => {
            setSourceLanguageCode(sourceLanguageCode);
            setTargetsForUpload(sourceLanguageCode);
          }}
          isShowIcon={false}
          testId={"mt-terminology-upload-source-language"}
          title={t("mt.glossary.sourceLanguage")}
          options={sourceOptions}
        />
      </div>
      <div className="col-span-5 flex w-full flex-col">
        <SingleLanguageFilter
          selectedLanguageCode={targetLanguageCode}
          onChange={(targetLanguageCode) => {
            setTargetLanguageCode(targetLanguageCode);
          }}
          isShowIcon={false}
          testId={"mt-terminology-upload-target-language"}
          title={t("mt.glossary.targetLanguage")}
          options={targetOptions}
        />
      </div>
    </div>
  );
}

function getResult(result: TerminologyUploadResult, currentTerminology: Terminology) {
  if (result.success && result.terminology)
    result.resultData = {
      addedCount: result.terminology.terminologyItems.length - currentTerminology.terminologyItems.length,
    };

  return result;
}

export default GlossaryUploadDialog;
