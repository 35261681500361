import { IdName } from "@/model/common";
import { orderBy, uniqBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RequestTableDataType } from "../../RequestListContent";

export function useRequestListFiltersOptions<T extends RequestTableDataType>(data: T[]) {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      customerName: getCustomerNamesOptions(data),
      status: getStatusesOptions(data, t),
      services: getServicesOptions(data, t),
      customerContactName: getCustomerContactNamesOptions(data),
    }),
    [data, t]
  );
}

function getCustomerNamesOptions<T extends RequestTableDataType>(data: T[]) {
  return orderBy(
    uniqBy(
      (data ?? [])
        .filter((v) => !!v.customerName)
        .map((v) => ({
          id: v.customerName.trim().toString(),
          name: v.customerName,
        })),
      "id"
    ),
    "count"
  );
}

function getCustomerContactNamesOptions<T extends RequestTableDataType>(data: T[]) {
  return orderBy(
    uniqBy(
      (data ?? [])
        .filter((v) => !!v.customerContactName)
        .map((v) => ({
          id: v.customerContactName.trim().toString(),
          name: v.customerContactName,
        })),
      "id"
    ),
    "count"
  );
}

function getStatusesOptions<T extends RequestTableDataType>(data: T[], t: (key: string) => string) {
  return orderBy(
    uniqBy(
      (data ?? [])
        .filter((v) => !!v.status)
        .map((v) => ({
          id: v.status,
          name: t(`requests.statuses.${v.status}`),
        })),
      "id"
    ),
    "count"
  );
}

function getServicesOptions<T extends RequestTableDataType>(data: T[], t: (key: string) => string) {
  return orderBy(
    uniqBy(
      (data ?? [])
        .filter((v) => !!v.service)
        .map((v) => {
          const service = v.service as IdName<string>;
          return {
            id: service.name,
            name: !service.name || service.name.trim() === "" ? t("common.none") : service.name,
          };
        }),
      "id"
    ),
    "count"
  );
}
