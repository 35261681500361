import api from "@/api/api";
import { redirectToLogin } from "@/utils/url";
import { User, UserPreferences, UserProfileFormModel } from "model/user.typing";

export const requestXSRFToken = async (signal: AbortSignal) => await api.get("/api/xsrft", { signal });

export const requestUser = async (signal: AbortSignal): Promise<User> => {
  const response = await api.get<User>(`/api/account/getuser`, {
    signal,
    validateStatus: (status) => status === 200,
  });
  return response.data;
};

export const requestRefreshToken = async () => {
  try {
    // Fetch the refresh token
    await api.post(`/api/account/refreshtoken`, {}, { withCredentials: true });
  } catch {
    localStorage.removeItem("user");
    redirectToLogin(location.pathname);
  }
};

export const getUserPreferences = async () => {
  const response = await api.get<UserPreferences>("/api/account/GetProfileSettings");
  return response.data;
};

export const updateUserPreferences = async (values: UserProfileFormModel) => {
  const response = await api.post<void>("/api/account/UpdateProfileSettings", values, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};

export const requestLogout = (): Promise<void> => api.post("/api/account/logout");
