import { DatePicker } from "@/components/DatePicker";
import TipTapEditor from "@/components/TipTapEditor";
import { CustomFieldFormField } from "@/components/customFields/common/CustomFieldFormField";
import { FloatingLabelInput } from "@/components/input/FloatingInput";
import { FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Separator } from "@/components/ui/separator";
import { useDateOperations } from "@/hooks/useDate";
import type { CustomField as CustomFieldType } from "@/model/request.typing";
import { DivisionType } from "@/model/request.typing";
import { addYears } from "date-fns";
import { Controller, type UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UploadReferenceFiles } from "./UploadReferenceFiles";
import type { NewRequestFormType } from "./useNewRequestForm";

interface Props {
  division: DivisionType;
  form: UseFormReturn<NewRequestFormType>;
  customFields: CustomFieldType[];
}

export function DetailsWizardStep({ form, division, customFields }: Props) {
  const { now } = useDateOperations();
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col gap-2">
      <div className="flex flex-col items-center p-6">
        <h2 className="text-2xl font-semibold leading-7">{t("requests.create.details.title")}</h2>
        <p className="leading-tight">{t("requests.create.details.description")}</p>
      </div>

      <div className="mx-auto w-full max-w-7xl">
        <div className="flex flex-col gap-y-3 rounded-xl border border-neutral-200 p-6 dark:border-border">
          <FormField
            control={form.control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <FormItem>
                <FormControl>
                  <FloatingLabelInput
                    {...field}
                    label={t("requests.create.wizard.requestName")}
                    data-test="input-request-name"
                    value={field.value ?? ""}
                    hasError={!!error?.message}
                  />
                </FormControl>
                <FormMessage i18Prefix="requests.create.errors" />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="invoicingReference"
            render={({ field, fieldState: { error } }) => (
              <FormItem>
                <FormControl>
                  <FloatingLabelInput
                    {...field}
                    id="invoicingReference"
                    label={t("requests.create.wizard.invoicingReference")}
                    data-test="input-invoicing-reference"
                    value={field.value ?? ""}
                    hasError={!!error?.message}
                  />
                </FormControl>
                <FormMessage i18Prefix="requests.create.errors" />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="deadline"
            render={({ field: { onChange }, fieldState: { error } }) => (
              <FormItem>
                <FormControl>
                  <div className="w-fit">
                    <DatePicker
                      label={t("requests.create.wizard.dueDate")}
                      fromDate={now()}
                      toDate={addYears(now(), 1)}
                      value={form.getValues("deadline")}
                      timePicker={{ enable: true, defaultTime: { hour: 17 } }}
                      hasError={!!error?.message}
                      onChange={(value) => {
                        onChange(value);
                        form.trigger("deadline");
                        if (form.getValues("quoteDueDate")) {
                          form.trigger("quoteDueDate");
                        }
                      }}
                    />
                  </div>
                </FormControl>
                <FormMessage i18Prefix="requests.create.errors" />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="instructions"
            render={() => (
              <FormItem>
                <FormControl>
                  <Controller
                    control={form.control}
                    name="instructions"
                    render={({ field: { onChange } }) => (
                      <TipTapEditor
                        value={form.getValues("instructions")}
                        placeholder={t("requests.create.wizard.specialInstructions")}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormControl>
                <FormMessage i18Prefix="requests.create.errors" />
              </FormItem>
            )}
          />
          <UploadReferenceFiles
            form={form}
            divisionId={division.id}
            uploadToS3={division.useAmazonS3UploadWithAntivirus}
            requestIdentifier={form.getValues("requestIdentifier")}
          />
          {customFields.length > 0 && <Separator className="my-2" />}
          {customFields.map((field) => (
            <CustomFieldFormField
              key={field.customFieldId}
              isCreate={true}
              fieldConfig={field}
              control={form.control}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
