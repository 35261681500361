import {
  DatatableDateFilter,
  DatatableSelectFilter,
  DatatableTextFilter,
} from "@/components/datatable/components/DataTableFacetedFilter";
import { useDateOperations } from "@/hooks/useDate";
import { IdName } from "@/model/common";
import { InProgressRequestType } from "@/model/request.typing";
import { Table } from "@tanstack/react-table";
import { orderBy } from "lodash";
import { ReactElement, useMemo } from "react";
import { RequestTableDataType } from "../../RequestListContent";
import { useRequestListFiltersOptions } from "../hooks/useRequestListFiltersOptions";

interface RequestListFiltersProps<T> {
  table: Table<T>;
  data: T[];
  displayedFilters: IdName<string>[];
}

export function RequestListFilters<T extends RequestTableDataType>({
  table,
  data,
  displayedFilters,
}: RequestListFiltersProps<T>): ReactElement {
  const { convertDate } = useDateOperations();
  const filtersOptions = useRequestListFiltersOptions(data);
  const deadlineMinMax = useMemo(() => {
    const startDates = data
      .filter((row) => !!(row as InProgressRequestType).deadline)
      .map((row) => new Date((row as InProgressRequestType).deadline as Date).getTime());
    return {
      from: convertDate(new Date(Math.min(...startDates))),
      to: undefined /* in case we want to limit the filter to max date => new Date(Math.max(...startDates)) */,
    };
  }, [data, convertDate]);

  return (
    <>
      {orderBy(displayedFilters, ["name"], ["asc"]).map((displayedFilter) => {
        switch (displayedFilter.id) {
          case "customerName":
          case "status":
          case "services":
          case "customerContactName":
            return (
              filtersOptions[displayedFilter.id].length > 0 && (
                <DatatableSelectFilter
                  key={displayedFilter.id}
                  testId={`requests.filter.${displayedFilter.id}`}
                  column={table.getColumn(displayedFilter.id)}
                  title={displayedFilter.name}
                  options={filtersOptions[displayedFilter.id]}
                />
              )
            );
          case "createdOn":
          case "deliveredOn":
            return (
              <DatatableDateFilter
                key={displayedFilter.id}
                testId={`requests.filter.${displayedFilter.id}`}
                column={table.getColumn(displayedFilter.id)}
                title={displayedFilter.name}
              />
            );
          case "deadline":
            return (
              <DatatableDateFilter
                key={displayedFilter.id}
                testId={`requests.filter.${displayedFilter.id}`}
                column={table.getColumn(displayedFilter.id)}
                title={displayedFilter.name}
                minMaxDate={deadlineMinMax}
                presetsType={"future"}
              />
            );
          default:
            return (
              <DatatableTextFilter
                key={displayedFilter.id}
                title={displayedFilter.name}
                testId={`requests.filter.${displayedFilter.id}`}
                column={table.getColumn(displayedFilter.id)}
              />
            );
        }
      })}
    </>
  );
}
