import { FloatingLabelInput } from "@/components/input/FloatingInput";
import PortalTooltip from "@/components/PortalTooltip";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { TooltipProvider } from "@/components/ui/tooltip";
import { Terminology, TerminologyPayload } from "@/model/mt.typing";
import useGlossary from "@/pages/machinetranslation/components/text/glossary/useGlossary";
import {
  useDeleteTerminologyMutation,
  useEditTerminologyMutation,
  useGetTerminologyListQuery,
  useGetTerminologyMutation,
} from "@/query/mt/machinetranslation.query";
import { mapTerminologyItemLanguages } from "@/utils/mapping";
import { QueryObserverResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { t } from "i18next";
import { Ban, FileDown, List, Loader2Icon, Pencil, Save, Trash } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

interface GlossaryListItemProps {
  item: Terminology;
}

export const GlossaryListItem = (props: GlossaryListItemProps) => {
  const { item } = props;
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const { terminologyConfiguration } = useGlossary();
  const { refetch } = useGetTerminologyListQuery(terminologyConfiguration.customerId);

  return (
    <TooltipProvider delayDuration={0}>
      {showEdit ? (
        <EditGlossaryName
          item={item}
          setShowEdit={setShowEdit}
          customerId={terminologyConfiguration.customerId}
          refetch={refetch}
        />
      ) : (
        <GlossaryRow item={item} setShowEdit={setShowEdit} refetch={refetch} />
      )}
    </TooltipProvider>
  );
};

function GlossaryRow({
  setShowEdit,
  item,
  refetch,
}: {
  setShowEdit: (value: boolean) => void;
  item: Terminology;
  refetch: () => Promise<QueryObserverResult<Terminology[], AxiosError>>;
}) {
  const [showEditButton, setShowEditButton] = useState<boolean>(false);

  const {
    navigateTo,
    saveAsFile,
    terminologyConfiguration,
    setTerminologies,
    terminologyStoredSettings,
    updateTerminologyStoredSettings,
    setCurrentTerminology,
  } = useGlossary();

  const { isFetching, fetchTerminology } = useGetTerminologyMutation();
  const { removeTerminology } = useDeleteTerminologyMutation();

  const handleSuccess = async () => {
    // if deleted terminology was the one selected, clearing the selection
    if (item.id === terminologyStoredSettings.selectedTerminologyId)
      updateTerminologyStoredSettings({ ...terminologyStoredSettings, selectedTerminologyId: null });

    // refetching list after deletion
    const result = await refetch();
    if (result.data) {
      if (result.data.length === 0) setCurrentTerminology(null);
      setTerminologies(result.data);
    }
  };

  const handleEdit = async () => {
    if (terminologyConfiguration.customerId && item && !isFetching) {
      let terminology = await fetchTerminology({
        customerId: terminologyConfiguration.customerId,
        terminologyId: item.id,
      });
      terminology = mapTerminologyItemLanguages(terminologyConfiguration.mappedLanguages, terminology);
      if (terminology) navigateTo(terminology, "editTerms");
      else toast.warning("mt.glossary.fetchTerminologyError");
    }
  };

  const handleDelete = async () => {
    let success = false;

    if (terminologyConfiguration.customerId && item) {
      success = await removeTerminology({
        customerId: terminologyConfiguration.customerId,
        terminologyId: item.id,
      });
    }

    return success;
  };

  return (
    <div className="my-4 flex items-center justify-between rounded-md border p-3">
      <div
        className="flex w-full items-center justify-between gap-2 rounded-md"
        onMouseEnter={() => setShowEditButton(true)}
        onMouseLeave={() => setShowEditButton(false)}
      >
        <p className="max-w-48 truncate">{item.name}</p>
        {showEditButton ? (
          <PortalTooltip text={t("common.edit")}>
            <Button
              type="button"
              variant="outline"
              className="mr-2 h-8 align-middle text-xs"
              size="sm"
              onClick={() => setShowEdit(true)}
            >
              <Pencil className="size-4" />
            </Button>
          </PortalTooltip>
        ) : null}
      </div>
      <div className="flex gap-1" onClick={(e) => e.stopPropagation()}>
        <PortalTooltip text={t("mt.glossary.manageGlossary")}>
          <Button
            type="button"
            variant="outline"
            className="h-8 align-middle text-xs"
            size="sm"
            onClick={async () => await handleEdit()}
          >
            {isFetching ? <Loader2Icon className="size-4 animate-spin" /> : <List className="size-4" />}
          </Button>
        </PortalTooltip>
        <PortalTooltip text={t("common.delete")}>
          <div>
            <DeleteGlossary onDelete={async () => await handleDelete()} onSuccess={async () => await handleSuccess()} />
          </div>
        </PortalTooltip>
        <PortalTooltip text={t("common.download")}>
          <Button
            type="button"
            variant="outline"
            className="h-8 align-middle text-xs"
            size="sm"
            onClick={() => saveAsFile(item, true)}
          >
            <FileDown className="size-4" />
          </Button>
        </PortalTooltip>
      </div>
    </div>
  );
}

function DeleteGlossary({ onDelete, onSuccess }: { onSuccess: () => void; onDelete: () => Promise<boolean> }) {
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleDeleteGlossary = () => {
    setIsDeleting(true);
    onDelete().then((success) => {
      if (success) {
        onSuccess();
        toast.success(t("mt.glossary.deleteSuccess"));
        setShowDeleteAlert(false);
        setIsDeleting(false);
      } else {
        toast.error(t("mt.glossary.deleteGlossaryError"));
      }
    });
  };

  return (
    <>
      <Button className="h-8 align-middle text-xs" variant="outline" size="sm" onClick={() => setShowDeleteAlert(true)}>
        <Trash className="size-4" />
      </Button>
      <AlertDialog open={showDeleteAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t("mt.areYouSure")}</AlertDialogTitle>
            <AlertDialogDescription>{t("mt.glossary.deleteAlert")}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel disabled={isDeleting} onClick={() => setShowDeleteAlert(false)}>
              {t("common.cancel")}
            </AlertDialogCancel>
            <AlertDialogAction
              disabled={isDeleting}
              variant="destructive"
              onClick={() => handleDeleteGlossary()}
              className="inline-flex flex-nowrap items-center gap-2"
            >
              {isDeleting && <Loader2Icon className="animate-spin" />}
              {t("mt.glossary.deleteGlossary")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

function EditGlossaryName({
  item,
  setShowEdit,
  customerId,
  refetch,
}: {
  item: Terminology;
  setShowEdit: (value: boolean) => void;
  customerId: string;
  refetch: () => Promise<QueryObserverResult<Terminology[], AxiosError>>;
}) {
  const [newName, setNewName] = useState<string>(item.name);
  const { editTerminology, isFetchingEdit } = useEditTerminologyMutation();

  const handleUpdate = async () => {
    const payload: TerminologyPayload = { name: newName };
    await editTerminology({
      customerId: customerId,
      terminologyId: item.id,
      payload,
    });
    refetch();
    setShowEdit(false);
  };

  return (
    <div className="my-4 flex items-center justify-between rounded-md border p-3">
      <FloatingLabelInput
        type="text"
        label={t("mt.glossary.name")}
        className="mr-2 h-8"
        value={newName}
        onChange={(e) => {
          setNewName(e.target.value);
        }}
      />
      <div className="flex gap-1">
        <PortalTooltip text={t("common.save")}>
          <Button
            type="button"
            variant="outline"
            className="h-8 align-middle text-xs"
            size="sm"
            onClick={async () => handleUpdate()}
          >
            {isFetchingEdit ? <Loader2Icon className="size-4 animate-spin" /> : <Save className="size-4" />}
          </Button>
        </PortalTooltip>
        <PortalTooltip text={t("common.cancel")}>
          <Button
            type="button"
            variant="outline"
            className="h-8 align-middle text-xs"
            size="sm"
            onClick={() => setShowEdit(false)}
          >
            <Ban className="size-4" />
          </Button>
        </PortalTooltip>
      </div>
    </div>
  );
}
