import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Document } from "@tiptap/extension-document";
import { Italic } from "@tiptap/extension-italic";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Text } from "@tiptap/extension-text";
import { Underline } from "@tiptap/extension-underline";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import { BoldIcon, ItalicIcon, ListIcon, ListOrderedIcon, UnderlineIcon } from "lucide-react";

import { clsx } from "clsx";
import "../editor.css";
import { Button } from "./ui/button";

interface EditorProps {
  onChange: (content: string | undefined) => void;
  value: string | undefined;
  placeholder?: string;
}

interface ButtonConfig {
  command: "toggleBold" | "toggleItalic" | "toggleUnderline" | "toggleBulletList" | "toggleOrderedList";
  icon: React.ReactNode;
  activeClass: string;
  className: string;
}

const buttonConfig: ButtonConfig[] = [
  { command: "toggleBold", icon: <BoldIcon size={14} />, activeClass: "bold", className: "rounded-r-none" },
  { command: "toggleItalic", icon: <ItalicIcon size={14} />, activeClass: "italic", className: "rounded-none" },
  {
    command: "toggleUnderline",
    icon: <UnderlineIcon size={14} />,
    activeClass: "underline",
    className: "rounded-none",
  },
  { command: "toggleBulletList", icon: <ListIcon size={14} />, activeClass: "bulletList", className: "rounded-none" },
  {
    command: "toggleOrderedList",
    icon: <ListOrderedIcon size={14} />,
    activeClass: "orderedList",
    className: "rounded-l-none",
  },
];

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  if (!editor) return null;

  return (
    <div className="inline-flex" role="menu">
      {buttonConfig.map(({ command, icon: Icon, activeClass, className }) => (
        <Button
          autoFocus={false}
          tabIndex={-1}
          key={command}
          type="button"
          onClick={() => editor.chain().focus()[command]().run()}
          disabled={!editor.can().chain().focus()[command]().run()}
          className={clsx(
            "px-4 py-2 text-sm font-medium",
            { "dark:bg-text bg-neutral-200 dark:text-background": editor.isActive(activeClass) },
            className
          )}
          size="xs"
          variant="ghost"
        >
          {Icon}
        </Button>
      ))}
    </div>
  );
};

const TipTapEditor = ({ value: content, onChange: setContent, placeholder }: EditorProps) => {
  const editor = useEditor({
    editorProps: {
      attributes: {
        class: "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none min-h-20",
      },
    },
    extensions: [
      Text,
      Bold,
      Italic,
      Underline,
      Document,
      Paragraph,
      ListItem,
      OrderedList,
      BulletList,
      Placeholder.configure({ placeholder }),
    ],
    content,
    onUpdate: ({ editor }) => setContent(editor.getHTML()),
  });

  return (
    <div className="rounded border border-neutral-300 p-2 focus-within:border-2 focus-within:border-primary dark:border-border dark:focus-within:border-primary"
      data-test="tiptap-editor">
      <MenuBar editor={editor} />
      <EditorContent editor={editor} className="w-full cursor-text p-4" />
    </div>
  );
};

export default TipTapEditor;
