import { DataTable } from "@/components/datatable/DataTable";
import { EmptyDataPlaceholder } from "@/components/EmptyPlaceholder";
import { IdName } from "@/model/common";
import { useLanguagesQuery } from "@/query/request.query";
import { ColumnDef, ColumnSort } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SourceFile } from "./useNewRequestForm";

function useColumnConfiguration(): ColumnDef<SourceFile>[] {
  const { t } = useTranslation();
  const { languages } = useLanguagesQuery();

  const fileName: ColumnDef<SourceFile> = {
    id: "fileName",
    header: t("requests.table.documents"),
    accessorFn: (sourceFile) => {
      const group = sourceFile.groupName !== "default" ? ` (${sourceFile.groupName})` : "_";
      return `${group}${sourceFile.file.name}`;
    },
    cell: (props) => {
      const sourceFile = props.row.original;
      return (
        <div className="flex flex-col gap-1">
          <span>{sourceFile.file.name}</span>
          {sourceFile.groupName !== "default" && (
            <span className="text-xs text-muted-foreground">{sourceFile.groupName}</span>
          )}
        </div>
      );
    },
  };

  const sourceLanguage: ColumnDef<SourceFile> = {
    id: "sourceLanguage",
    header: t("requests.table.sourceLanguage"),
    accessorKey: "sourceLanguageCode",
    cell: (props) => {
      const lang = props.getValue() as string | undefined;
      if (!lang) return <EmptyDataPlaceholder />;
      return <span>{languages.find((x: IdName) => x.id === lang)?.name}</span>;
    },
  };

  const targetLanguages: ColumnDef<SourceFile> = {
    id: "targetLanguages",
    header: t("requests.table.targetLanguages"),
    accessorKey: "targetLanguageCodes",
    cell: (props) => {
      const langs = props.getValue() as string[] | undefined;
      if (!langs || langs.length === 0) return <EmptyDataPlaceholder />;
      return (
        <ul className="inline list-none">
          {langs.map((lang, index) => (
            <li key={index} className="after:content-[','] last:after:content-['']">
              {languages.find((x: IdName) => x.id === lang)?.name}
            </li>
          ))}
        </ul>
      );
    },
  };

  return [fileName, sourceLanguage, targetLanguages];
}

interface Props {
  data: SourceFile[];
}

export function NewFilesContent({ data }: Props) {
  const [search, setSearch] = useState<string>("");
  const [sortBy, setSortBy] = useState<ColumnSort[]>([{ id: "fileName", desc: false }]);
  const columns = useColumnConfiguration();

  if (data.length === 0) return null;

  return (
    <DataTable
      data-test="new-request-source-files"
      uniqueName="new-request-source-files"
      showViews={false}
      showSearch={false}
      defaultPageSize={10}
      columns={columns}
      data={data}
      searchTerm={search}
      onSearch={(searchTerm) => setSearch(searchTerm)}
      sortBy={sortBy}
      onSortBy={(sortBy) => setSortBy(sortBy)}
    />
  );
}
