import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { cn } from "@/utils/ui";
import { formatDate, isValid } from "date-fns";
import { useSidebar } from "./ui/sidebar";

const getBuildDate = () => {
  if (!import.meta.env.AP_BUILD_DATE) return "-";
  const date = new Date(import.meta.env.AP_BUILD_DATE);
  if (!isValid(date)) return "-";
  return formatDate(date, "yyyy-MM-dd HH:mm");
};
const AppVersionDetails = () => {
  const { state } = useSidebar();

  // don't show on Prod
  if (import.meta.env.AP_ENV === "prod") return null;
  return (
    <div className="ml-5 flex flex-col gap-2">
      <HoverCard>
        <HoverCardTrigger className={cn("mr-2 cursor-pointer text-sm font-medium", { hidden: state === "collapsed" })}>
          <div data-test="main-footer-version">Version: {import.meta.env.AP_VERSION}</div>
        </HoverCardTrigger>
        <HoverCardContent>
          <div>ID {import.meta.env.AP_BUILD_ID}</div>
          <div>Date {getBuildDate()}</div>
          <div>SHA {import.meta.env.AP_COMMIT_SHA}</div>
        </HoverCardContent>
      </HoverCard>
      <div
        data-test="appcues-placeholder-two"
        id="appcues-placeholder-two"
        className="inline-flex min-w-2 items-center gap-x-1"
      />
    </div>
  );
};

export default AppVersionDetails;
