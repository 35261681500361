import { Button } from "@/components/ui/button";
import { SourceTargetLanguage } from "@/model/request.typing";
import { t } from "i18next";
import { ArrowRightIcon } from "lucide-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const LANGUAGE_PAIRS_VISIBLE_ITEMS = 3;

interface Props {
  languages: SourceTargetLanguage[];
}

export function LanguagePairs({ languages }: Props) {
  const { t } = useTranslation();
  const { expandedSections, setExpanded, combinationsCount } = useLanguagePairs(languages);

  return (
    <div>
      <div className="grid gap-y-3">
        {languages.map((language) => (
          <div key={language.source.id}>
            <LanguagePairSection
              language={language}
              isExpanded={expandedSections.get(language.source.id) || false}
              setExpanded={setExpanded}
            />
          </div>
        ))}
      </div>
      {combinationsCount > 1 && (
        <div data-test="languagePairsCombinations" className="pt-2 text-muted-foreground opacity-80">
          {t("requests.details.fields.languagePairsCombinations", {
            count: combinationsCount,
          })}
        </div>
      )}
    </div>
  );
}

function LanguagePairSection({
  language,
  isExpanded,
  setExpanded,
}: {
  language: SourceTargetLanguage;
  isExpanded: boolean;
  setExpanded: (id: string, value: boolean) => void;
}) {
  const targetsToShow = isExpanded ? language.targets : language.targets.slice(0, LANGUAGE_PAIRS_VISIBLE_ITEMS);

  return (
    <>
      <div data-test={`language-pair-source-${language.source.id}`} className="flex items-center gap-2">
        <span className="text-secondary-foreground">
          {language.source.name} {language.source.id}
        </span>
      </div>
      <div className="pt-1">
        {targetsToShow.map((target, idx) => (
          <div
            key={idx}
            data-test={`language-pair-source-${language.source.id}-target-${target.id}`}
            className="flex items-center gap-x-1 py-1 pl-8 text-muted-foreground opacity-90"
          >
            <ArrowRightIcon size={16} className="shrink-0 text-secondary-foreground opacity-50" />
            {target.name} {target.id}
          </div>
        ))}
        {language.targets.length > LANGUAGE_PAIRS_VISIBLE_ITEMS && (
          <Button
            data-test={`language-pair-source-${language.source.id}-expand`}
            variant={"ghost"}
            size={"xs"}
            className="ml-8 text-xs text-muted-foreground underline opacity-90 hover:text-foreground"
            onClick={() => setExpanded(language.source.id, !isExpanded)}
          >
            {isExpanded ? t("requests.create.show-less") : t("requests.create.show-more")}
          </Button>
        )}
      </div>
    </>
  );
}

function useLanguagePairs(languages: SourceTargetLanguage[]) {
  const [expandedSections, setExpandedSections] = useState<Map<string, boolean>>(
    new Map(languages.map((x) => [x.source.id, false]))
  );
  const combinationsCount = useMemo(() => languages.map((x) => x.targets.length).reduce((a, b) => a + b), [languages]);

  const setExpanded = (id: string, value: boolean) => {
    setExpandedSections((prevSections) => {
      const newSections = new Map(prevSections);
      newSections.set(id, value);
      return newSections;
    });
  };

  return {
    expandedSections,
    combinationsCount,
    setExpanded,
  };
}
