import { cn } from "@/utils/ui";
import { ArrowRight, CheckIcon } from "lucide-react";
import { Children, createContext, forwardRef, isValidElement, ReactNode, Ref, useContext, useState } from "react";
import { Button } from "./button";

export interface StepItemType {
  stepCode: string;
  stepName: string;
  isCompleted: boolean;
  isInvalid: boolean;
}

interface StepperContextType {
  steps: StepItemType[];
  currentStepIndex: number;
  nextStep: (canGoToStep?: (currentStep: string, nextStep: string) => boolean) => void;
  prevStep: (canGoToStep?: (currentStep: string, nextStep: string) => boolean) => void;
  goToStep: (stepCode: string, canGoToStep?: (currentStep: string, nextStep: string) => boolean) => void;
}

const StepperContext = createContext<StepperContextType>({
  steps: [],
  currentStepIndex: 0,
  nextStep: () => {},
  prevStep: () => {},
  goToStep: () => true,
});

function useStepper() {
  const { currentStepIndex, steps, goToStep, nextStep, prevStep } = useContext(StepperContext);
  const isLastStep = currentStepIndex === steps.length - 1;
  const hasCompletedAllSteps = currentStepIndex === steps.length;
  const currentStep = steps[currentStepIndex];

  return {
    steps,
    goToStep,
    nextStep,
    prevStep,
    currentStepIndex,
    isLastStep,
    hasCompletedAllSteps,
    currentStep,
  };
}

interface StepperProps extends Pick<StepperContextType, "steps"> {
  children?: ReactNode;
  canSwitchToStep?: (currentStep: string, nextStep?: string) => boolean;
  onSwitchStep?: (currentStep: StepItemType, nextStep: StepItemType) => void;
  customNextStep?: (steps: { currentStep: StepItemType; nextStep: StepItemType }) => ReactNode;
}

const Stepper = forwardRef<HTMLDivElement, StepperProps>(
  ({ children, steps, canSwitchToStep, onSwitchStep, customNextStep }: StepperProps, ref: Ref<HTMLDivElement>) => {
    const [currentStepIndex, setActiveStepIndex] = useState<number>(0);

    const goToStep = (nextStepCode: string) => {
      const canSwitchToStepFn = canSwitchToStep ?? (() => true);
      const currentStep = steps[currentStepIndex];
      const nextStepIndex = steps.findIndex((step) => step.stepCode === nextStepCode);
      if (canSwitchToStepFn(currentStep.stepCode, nextStepCode)) {
        setActiveStepIndex(nextStepIndex);
        const nextStep = steps[nextStepIndex];
        onSwitchStep?.(currentStep, nextStep);
      }
    };

    const nextStep = () => {
      const stepCode = steps[currentStepIndex + 1]?.stepCode;
      if (stepCode) {
        goToStep(stepCode);
      }
    };

    const prevStep = () => {
      const stepCode = steps[currentStepIndex - 1]?.stepCode;
      if (stepCode) {
        goToStep(stepCode);
      }
    };

    return (
      <StepperContext.Provider value={{ steps, currentStepIndex, nextStep, prevStep, goToStep }}>
        <div className="flex size-full flex-col">
          <div className="m-4 sm:mx-auto sm:w-full sm:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl">
            <div ref={ref} data-testid="stepper" className="flex justify-between md:mx-32">
              {steps.map((step, index) => (
                <Step key={step.stepCode} data-test={`stepper-step-${step.stepCode}`} step={step} index={index} />
              ))}
            </div>
          </div>

          <div className="flex-1">
            <HorizontalContent>{children}</HorizontalContent>
          </div>

          <div className="absolute inset-x-0 bottom-0 border-t border-border backdrop-blur">
            <StepFooter customNextStep={customNextStep} />
          </div>
        </div>
      </StepperContext.Provider>
    );
  }
);

const Step = ({ step, index }: { step: StepItemType; index: number }) => {
  const { currentStepIndex, goToStep } = useStepper();

  const isActive = index === currentStepIndex;
  const isCompleted = step.isCompleted; // stepsCompleted.findIndex((step) => step === stepName) !== -1;
  const isInvalid = index !== currentStepIndex && step.isInvalid;

  return (
    <div
      className={cn(
        "md:mt-6",
        "relative flex items-center transition-all duration-200",
        "[&:not(:last-child)]:flex-1",
        "[&:not(:last-child)]:after:transition-all [&:not(:last-child)]:after:duration-200",
        "data-[completed=true]:[&:not(:last-child)]:after:h-[2px] data-[completed=true]:[&:not(:last-child)]:after:bg-foreground",
        "[&:not(:last-child)]:after:h-px [&:not(:last-child)]:after:flex-1 [&:not(:last-child)]:after:bg-neutral-300 [&:not(:last-child)]:after:content-[''] dark:[&:not(:last-child)]:after:bg-border"
      )}
      data-completed={isCompleted}
      data-invalid={isInvalid}
    >
      <div className={cn("group mt-1 flex flex-col items-center")}>
        <Button
          type="button"
          onClick={() => goToStep(step.stepCode)}
          variant={"none"}
          size={"none"}
          className={cn(
            "size-6 rounded-3xl border-2",
            "flex items-center justify-center border-neutral-400 transition-colors",
            "group-hover:border-foreground group-hover:bg-foreground group-hover:text-background",
            "data-[active=true]:border-foreground data-[active=true]:bg-background data-[active=true]:text-foreground",
            "data-[completed=true]:border-foreground data-[completed=true]:bg-foreground data-[completed=true]:text-background",
            "data-[invalid=true]:border-destructive data-[invalid=true]:bg-destructive/10 data-[invalid=true]:text-destructive",
            "group-hover:data-[invalid=true]:border-destructive group-hover:data-[invalid=true]:bg-destructive/20 group-hover:data-[invalid=true]:text-destructive"
          )}
          data-current={isActive}
          data-active={isCompleted || isInvalid ? false : isActive}
          data-completed={isCompleted}
          data-invalid={isInvalid}
        >
          <div
            data-active={isActive}
            data-completed={isCompleted}
            className={cn(
              "hidden md:block",
              "absolute -top-2/3 whitespace-nowrap text-sm font-medium leading-tight text-neutral-500 dark:text-muted-foreground",
              "group-hover:text-accent-foreground",
              "data-[active=true]:font-semibold data-[active=true]:text-foreground"
            )}
          >
            {step.stepName}
          </div>
          {isCompleted ? (
            <CheckIcon className="size-4 shrink-0" />
          ) : isInvalid ? (
            <span className="font-semibold">!</span>
          ) : (
            <span key="label" className="text-center text-xs font-semibold">
              {index + 1}
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

const HorizontalContent = ({ children }: { children: ReactNode }) => {
  const { currentStepIndex } = useStepper();
  const childArr = Children.toArray(children);

  if (currentStepIndex > childArr.length) {
    return null;
  }

  return (
    <div className="mb-10 pb-10">
      {Children.map(childArr[currentStepIndex], (node) => {
        if (!isValidElement(node)) {
          return null;
        }
        return Children.map(node.props.children, (childNode) => childNode);
      })}
    </div>
  );
};

const StepFooter = ({
  customNextStep,
}: {
  customNextStep?: (steps: { currentStep: StepItemType; nextStep: StepItemType }) => ReactNode;
}) => {
  const { nextStep, prevStep, currentStepIndex, steps, currentStep } = useStepper();
  const nextStepItem = steps[currentStepIndex + 1];
  const prevStepItem = steps[currentStepIndex - 1];

  const renderNextStep = customNextStep?.({ currentStep, nextStep: nextStepItem }) ?? null;

  return (
    <div className={"ml-4 mr-8 flex flex-wrap justify-between gap-y-2 px-6 py-4 pr-4"}>
      {prevStepItem ? (
        <Button type="button" variant="outline" onClick={() => prevStep()}>
          {prevStepItem.stepName}
        </Button>
      ) : (
        <div />
      )}
      {renderNextStep
        ? renderNextStep
        : nextStepItem && (
            <Button
              type="button"
              disabled={!nextStepItem}
              variant={currentStep.isCompleted ? "default" : "outline"}
              onClick={() => nextStep()}
            >
              {nextStepItem.stepName}
              {currentStep.isCompleted && <ArrowRight className="ml-2 size-4" />}
            </Button>
          )}
    </div>
  );
};

export { Stepper, useStepper };
