import { useOnMount } from "@/hooks/useOnMount";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { isDateInRange } from "@/utils/dates";
import { chain } from "lodash";
import type { ProjectCostFiltersType, ProjectCostPerLanguagesType, ProjectCostType } from "model/report.typing";
import { useEffect, useMemo } from "react";
import { useCostReportActions, useCostReportFilters } from "../reportStore";

export function usePerLanguagePairProjectCost(projectCosts: ProjectCostType[]) {
  return useMemo(() => {
    // map projectCosts to per language
    return chain(projectCosts)
      .flatMap((project) => {
        return project.languages?.map((language) => {
          return {
            ...project,
            languagePairCode: `${language.sourceLanguageCode} / ${language.targetLanguageCode}`.toLowerCase(),
            languagePairName: `${language.sourceLanguageName} / ${language.targetLanguageName}`.toLowerCase(),
            sourceLanguageCode: language.sourceLanguageCode,
            sourceLanguageName: language.sourceLanguageName,
            targetLanguageCode: language.targetLanguageCode,
            targetLanguageName: language.targetLanguageName,
            perLanguagePrice: language.price,
          } as ProjectCostPerLanguagesType;
        });
      })
      .orderBy("name")
      .value();
  }, [projectCosts]);
}

export function useFiltredPerLanguageProjectCost(projectCosts: ProjectCostType[], excludeDeliveryDate: boolean) {
  const filters = useCostReportFilters();
  const user = useAuthUserStore((store) => store.user);
  const showClient = user?.showClient ?? false;

  const perLanguagePairProjects = usePerLanguagePairProjectCost(projectCosts);

  const filteredProjectCostsPerLanguage = useMemo(() => {
    return chain(perLanguagePairProjects)
      .filter((item) => {
        if (!filterItem(filters, item, showClient, excludeDeliveryDate)) {
          return false;
        }
        if (filters.sourceLanguageIds.length > 0 || filters.targetLanguageIds.length > 0) {
          const sourceLanguageFilter = isArrayHasValue(filters.sourceLanguageIds)
            ? filters.sourceLanguageIds.includes(item.sourceLanguageCode)
            : true;
          const targetLanguageFilter = isArrayHasValue(filters.targetLanguageIds)
            ? filters.targetLanguageIds.includes(item.targetLanguageCode)
            : true;
          return sourceLanguageFilter && targetLanguageFilter;
        }
        return true;
      })
      .orderBy("name")
      .value();
  }, [perLanguagePairProjects, filters, showClient, excludeDeliveryDate]);

  return filteredProjectCostsPerLanguage;
}

export function useProjectCostsFilteredData(projectCosts: ProjectCostType[]) {
  const filters = useCostReportFilters();
  const user = useAuthUserStore((store) => store.user);
  const showClient = user?.showClient ?? false;

  const filteredProjectCosts = useMemo(() => {
    return projectCosts.filter((item) => {
      if (!filterItem(filters, item, showClient)) {
        return false;
      }
      if (filters.sourceLanguageIds.length > 0 || filters.targetLanguageIds.length > 0) {
        return item.languages.some((language) => {
          const sourceLanguageMatch = isArrayHasValue(filters.sourceLanguageIds)
            ? filters.sourceLanguageIds.includes(language.sourceLanguageCode)
            : true;
          const targetLanguageMatch = isArrayHasValue(filters.targetLanguageIds)
            ? filters.targetLanguageIds.includes(language.targetLanguageCode)
            : true;
          return sourceLanguageMatch && targetLanguageMatch;
        });
      }
      return true;
    });
  }, [projectCosts, filters, showClient]);

  const filteredProjectCostsPerLanguage = useFiltredPerLanguageProjectCost(projectCosts, false);

  return {
    showClient,
    filteredProjectCosts,
    filteredProjectCostsPerLanguage,
  };
}

export function useProjectCostsFilter(projectCosts: ProjectCostType[], hasSearchParams: boolean) {
  const user = useAuthUserStore((store) => store.user);
  const filters = useCostReportFilters();
  const { updateFilters } = useCostReportActions();
  const showClient = useMemo(() => user?.showClient ?? false, [user?.showClient]);
  const perLanguagePairProjects = usePerLanguagePairProjectCost(projectCosts);

  const options = useMemo(() => {
    const uniqByProjectId = chain(perLanguagePairProjects).uniqBy("projectId");

    const startDates = uniqByProjectId
      .filter((item) => filterItemForOptions(filters, item, showClient, "startDate"))
      .map(({ startDate }) => new Date(startDate).getTime())
      .value();
    const startDateMinMax = {
      from: new Date(Math.min(...startDates)),
      to: new Date(Math.max(...startDates)),
    };

    const deliveryDates = uniqByProjectId
      .filter((item) => filterItemForOptions(filters, item, showClient, "deliveryDate"))
      .filter((item) => !!item.deliveryDate)
      .map(({ deliveryDate }) => new Date(deliveryDate as Date).getTime())
      .value();
    const deliveryDateMinMax = {
      from: new Date(Math.min(...deliveryDates)),
      to: new Date(Math.max(...deliveryDates)),
    };

    return {
      startDateMinMax,
      deliveryDateMinMax,
      clientNames: uniqByProjectId
        .filter((item) => filterItemForOptions(filters, item, showClient, "clientNameIds"))
        .groupBy("clientId")
        .map((items) => ({
          id: items[0].clientId.toString(),
          name: items[0].clientName,
          count: items.length,
        }))
        .value(),
      owners: uniqByProjectId
        .filter(
          (item) =>
            !!item.ownerFirstName &&
            !!item.ownerLastName &&
            filterItemForOptions(filters, item, showClient, "ownersIds")
        )
        .groupBy((item) => mapToOwnerFullName(item))
        .map((items) => ({
          id: mapToOwnerFullName(items[0]),
          name: mapToOwnerFullName(items[0]),
          count: items.length,
        }))
        .orderBy(["count", "name"], ["desc", "asc"])
        .value(),
      currencies: uniqByProjectId
        .filter((item) => filterItemForOptions(filters, item, showClient, "currencyId"))
        .groupBy("currencyCode")
        .map((items) => ({
          id: items[0].currencyCode,
          name: items[0].currencyName,
          count: items.length,
        }))
        .orderBy("count", "desc")
        .value(),
      sourceLanguages: chain(perLanguagePairProjects)
        .filter((item) => filterItemForOptions(filters, item, showClient, "sourceLanguageIds", true))
        .groupBy("sourceLanguageCode")
        .map((items) => {
          return {
            id: items[0].sourceLanguageCode,
            name: items[0].sourceLanguageName,
            count: items.length,
          };
        })
        .orderBy("count", "desc")
        .value(),
      targetLanguages: chain(perLanguagePairProjects)
        .filter((item) => filterItemForOptions(filters, item, showClient, "targetLanguageIds", true))
        .groupBy("targetLanguageCode")
        .map((items) => {
          return {
            id: items[0].targetLanguageCode,
            name: items[0].targetLanguageName,
            count: items.length,
          };
        })
        .orderBy("count", "desc")
        .value(),
      divisions: chain(perLanguagePairProjects)
        .filter((item) => filterItemForOptions(filters, item, showClient, "divisionNames"))
        .groupBy("ecosystemName")
        .map((items) => ({
          id: items[0].ecosystemName,
          name: items[0].ecosystemName,
          count: items.length,
        }))
        .orderBy("count", "desc")
        .value(),
    };
  }, [perLanguagePairProjects, filters, showClient]);

  const hasActiveFilters = useMemo(() => {
    return (
      !!filters.startDate ||
      !!filters.deliveryDate ||
      Object.values(filters).filter(isArrayHasValue).length > 0 ||
      filters.searchText?.length > 0
    );
  }, [filters]);

  const onReset = () => {
    updateFilters({
      clientNameIds: [],
      ownersIds: [],
      sourceLanguageIds: [],
      targetLanguageIds: [],
      startDate: undefined,
      deliveryDate: undefined,
      divisionNames: [],
      searchText: "",
    });
  };

  useOnMount(() => {
    if (hasSearchParams) {
      onReset();
    }
  });

  useEffect(() => {
    if (options.currencies.length === 0) return;
    const exists = options.currencies.find((option) => option.id === filters.currencyId);
    if (!exists || !filters.currencyId) {
      updateFilters({ currencyId: options.currencies[0].id });
    }
  }, [filters.currencyId, options.currencies, updateFilters]);

  return { filters, hasActiveFilters, options, onReset };
}

function filterItemForOptions(
  filters: ProjectCostFiltersType,
  item: ProjectCostPerLanguagesType | ProjectCostType,
  showClient: boolean,
  filterToExclude: keyof ProjectCostFiltersType,
  forLanguages = false
) {
  const isExcluded = (filter?: keyof ProjectCostFiltersType) => filter === filterToExclude;

  if (
    showClient &&
    !isExcluded("clientNameIds") &&
    isArrayHasValue(filters.clientNameIds) &&
    !filters.clientNameIds.includes(item.clientId.toString())
  ) {
    return false;
  }
  if (
    !isExcluded("ownersIds") &&
    isArrayHasValue(filters.ownersIds) &&
    !filters.ownersIds.includes(mapToOwnerFullName(item))
  ) {
    return false;
  }

  if (forLanguages) {
    const shouldFilterSourceLanguage =
      !isExcluded("sourceLanguageIds") && isArrayHasValue(filters.sourceLanguageIds) && "sourceLanguageCode" in item;

    const shouldFilterTargetLanguage =
      !isExcluded("targetLanguageIds") && isArrayHasValue(filters.targetLanguageIds) && "targetLanguageCode" in item;

    if (shouldFilterSourceLanguage || shouldFilterTargetLanguage) {
      if (
        !filters.sourceLanguageIds.includes(item.sourceLanguageCode) &&
        !filters.targetLanguageIds.includes(item.targetLanguageCode)
      ) {
        return false;
      }
    }
  } else {
    if (
      !isExcluded("sourceLanguageIds") &&
      isArrayHasValue(filters.sourceLanguageIds) &&
      "sourceLanguageCode" in item &&
      !filters.sourceLanguageIds.includes(item.sourceLanguageCode)
    ) {
      return false;
    }
    if (
      !isExcluded("targetLanguageIds") &&
      isArrayHasValue(filters.targetLanguageIds) &&
      "targetLanguageCode" in item &&
      !filters.targetLanguageIds.includes(item.targetLanguageCode)
    ) {
      return false;
    }
  }

  if (!isExcluded("currencyId") && !!filters.currencyId && filters.currencyId !== item.currencyCode) {
    return false;
  }
  if (!isExcluded("startDate") && !isDateInRange(new Date(item.startDate), filters.startDate)) {
    return false;
  }
  if (!isExcluded("deliveryDate") && !isDateInRange(new Date(item.deliveryDate as Date), filters.deliveryDate)) {
    return false;
  }
  if (
    !isExcluded("divisionNames") &&
    isArrayHasValue(filters.divisionNames) &&
    !filters.divisionNames.includes(item.ecosystemName)
  ) {
    return false;
  }
  if (!filters.includeOpenProjects && !item.deliveryDate) {
    return false;
  }
  return true;
}

function filterItem(
  filters: ProjectCostFiltersType,
  item: ProjectCostType | ProjectCostPerLanguagesType,
  showClient: boolean,
  excludeDeliveryDate = false
) {
  if (filters.searchText?.length > 0) {
    const trimmedSearchText = filters.searchText.toLowerCase().trim();
    if (
      !item.projectName?.toLowerCase().toString().includes(trimmedSearchText) &&
      !item.projectId?.toLowerCase().toString().includes(trimmedSearchText) &&
      !item.portalProjectId?.toString().includes(trimmedSearchText)
    )
      return false;
  }
  if (
    showClient &&
    isArrayHasValue(filters.clientNameIds) &&
    !filters.clientNameIds.includes(item.clientId.toString())
  ) {
    return false;
  }
  if (isArrayHasValue(filters.ownersIds) && !filters.ownersIds.includes(mapToOwnerFullName(item))) {
    return false;
  }
  if (!!filters.currencyId && filters.currencyId !== item.currencyCode) {
    return false;
  }
  if (!isDateInRange(new Date(item.startDate), filters.startDate)) {
    return false;
  }
  if (
    (!!filters.deliveryDate?.from || !!filters.deliveryDate?.to) &&
    !excludeDeliveryDate &&
    !isDateInRange(new Date(item.deliveryDate as Date), filters.deliveryDate)
  ) {
    return false;
  }
  if (!filters.includeOpenProjects && !item.deliveryDate) {
    return false;
  }
  if (isArrayHasValue(filters.divisionNames) && !filters.divisionNames.includes(item.ecosystemName)) {
    return false;
  }
  return true;
}

function isArrayHasValue<T>(value: T | T[]): value is T[] {
  return Array.isArray(value) && value.length > 0;
}

function mapToOwnerFullName<T extends Partial<ProjectCostType>>(item: T): string {
  return `${item.ownerFirstName} ${item.ownerLastName}`;
}
