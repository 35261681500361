import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useDateFormatter } from "@/hooks/useDate";
import type { RequestFinancialType, RequestListType } from "@/model/request.typing";
import { canAcceptOrRejectQuote } from "@/pages/requests/common/financials-tab/request.utils";
import { useAcceptQuoteMutation } from "@/query/request.query";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { getFilenameFromUrl } from "@/utils/file";
import { CircleCheckIcon, CircleXIcon, FileClockIcon, Loader2Icon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DownloadLinkIcon } from "../components/DownloadLink";
import { HistoryDialog } from "./HistoryDialog";
import { RejectDialog } from "./RejectDialog";

export function FinancialsTabContent({
  financialData,
  projectId,
  entityType,
  requestType,
  requestStatus,
}: {
  financialData: RequestFinancialType[];
  projectId: string;
  entityType: string;
  requestType: RequestListType | undefined;
  requestStatus: string | null;
}) {
  const [historyData, setHistoryData] = useState<RequestFinancialType | null>(null); // used to show history dialog
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState<boolean>(false);
  const { mutate: acceptQuote, isPending } = useAcceptQuoteMutation(projectId);

  if (!financialData) {
    return null;
  }

  return (
    <div className="p-6 pt-4">
      {financialData.map((item) => (
        <QuoteItem
          key={item.id}
          item={item}
          projectId={projectId}
          entityType={entityType}
          requestType={requestType}
          requestStatus={requestStatus}
          isPending={isPending}
          openQuoteHistory={() => setHistoryData(item)}
          rejectQuote={() => setIsRejectDialogOpen(true)}
          acceptQuote={acceptQuote}
        />
      ))}
      <HistoryDialog
        open={!!historyData}
        data={historyData}
        projectId={projectId}
        entityType={entityType}
        onClose={() => setHistoryData(null)}
      />
      <RejectDialog projectId={projectId} open={isRejectDialogOpen} setOpen={setIsRejectDialogOpen} />
    </div>
  );
}

interface QuoteItemProps {
  item: RequestFinancialType;
  projectId: string;
  entityType: string;
  requestType: RequestListType | undefined;
  requestStatus: string | null;
  isPending: boolean;
  openQuoteHistory: () => void;
  rejectQuote: () => void;
  acceptQuote: () => void;
}

function QuoteItem({
  item,
  projectId,
  entityType,
  requestType,
  requestStatus,
  isPending,
  openQuoteHistory,
  rejectQuote,
  acceptQuote,
}: QuoteItemProps) {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.financials" });
  const formatNumber = useAuthUserStore((store) => store.formatNumber);
  const { formatDate } = useDateFormatter();

  const showAction = canAcceptOrRejectQuote(item, requestType, requestStatus);

  return (
    <div className="flex rounded-2xl bg-card px-6 py-4 shadow">
      <div className="flex max-w-64 flex-1 flex-col justify-center">
        <div className="font-semibold" data-test="quote-type">
          {t(item.quoteType)} #{item.quoteVersion}
        </div>
        <div className="text-sm font-medium text-muted-foreground">{formatDate(item.eventDateTime)}</div>
      </div>
      <div className="flex flex-col justify-center">
        <div className="font-medium" data-test="total">
          {typeof item.total === "number" && formatNumber(item.total, item.currencyCode)}
        </div>
      </div>
      <div className="flex flex-1 flex-row items-center justify-end gap-4">
        {showAction && (
          <div className="flex gap-2" data-test="accept-reject-buttons">
            <Button disabled={isPending} variant="outline" onClick={rejectQuote}>
              <CircleXIcon className="mr-2 size-4" />
              {t("reject")}
            </Button>
            <Button disabled={isPending} onClick={acceptQuote}>
              {isPending ? (
                <Loader2Icon className="mr-2 size-4 animate-spin" />
              ) : (
                <CircleCheckIcon className="mr-2 size-4" />
              )}
              {t("accept")}
            </Button>
          </div>
        )}
        <div className="flex items-center gap-2">
          {item.downloadUrl && (
            <DownloadLinkIcon id={projectId} entityType={entityType} fileName={getFilenameFromUrl(item.downloadUrl)} />
          )}
          {item.history.length > 0 && <ShowQuoteHistoryButton onClick={openQuoteHistory} />}
        </div>
      </div>
    </div>
  );
}

function ShowQuoteHistoryButton({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation("translation", { keyPrefix: "requests.details.financials" });

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant="outline" size="icon" onClick={onClick} data-test="show-history-button">
            <FileClockIcon className="size-5" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t("historyDialog.title")}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
