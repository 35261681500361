import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { User } from "@/model/user.typing";
import { getBaseUrl } from "@/utils/url";

interface Props {
  user: User;
  className?: string;
}

export function UserAvatar({ user, className }: Props) {
  if (!user) {
    return null;
  }
  const firstNameChar = (user.firstName ?? "").trim()[0] ?? "";
  const lastNameChar = (user.lastName ?? "").trim()[0] ?? "";
  const portalServerUrl = getBaseUrl();

  const isDefaultAvatar = user.avatarUrl.endsWith("default.png");

  return (
    <Avatar data-test="user-avatar" className={className}>
      <AvatarImage
        data-test="user-avatar-image"
        src={isDefaultAvatar ? undefined : `${portalServerUrl}${user.avatarUrl}`}
      />
      <AvatarFallback className="bg-sidebar-primary font-semibold text-sidebar-primary-foreground">
        {firstNameChar}
        {lastNameChar}
      </AvatarFallback>
    </Avatar>
  );
}
