import { ButtonIcon } from "@/components/button/ButtonIcon";
import { TextareaAutoSize } from "@/components/input/TextArea";
import { FormControl, FormField, FormItem, FormMessage } from "components/ui/form";
import { t } from "i18next";
import { XCircle } from "lucide-react";
import { LanguageOption } from "model/mt.typing";
import { UseFormReturn } from "react-hook-form";
import { LanguageSelector } from "../LanguageSelector";
import { TooltipProvider } from "@/components/ui/tooltip";
import PortalTooltip from "@/components/PortalTooltip";
import { TextMachineTranslationFormType } from "../../useTextMachineTranslationForm";

interface TextSourceControlsProps {
  sourceLanguages: LanguageOption[] | null;
  form: UseFormReturn<TextMachineTranslationFormType>;
  setTargets: (source: LanguageOption | null) => void;
  clearText: (areaType: string) => void;
  setTextChanged: (value: boolean) => void;
}

export const TextSourceControls = ({
  form,
  sourceLanguages,
  setTargets,
  clearText,
  setTextChanged,
}: TextSourceControlsProps) => {
  return (
    <div className="min-h-80 w-full">
      <FormField
        control={form.control}
        data-test="mt-text-source-languages"
        name="sourceLanguage"
        render={({ field }) => (
          <FormItem className="flex w-full flex-col">
            <LanguageSelector
              languages={sourceLanguages}
              placeholder={t("mt.detectLanguage")}
              field={field}
              onSelect={(language: LanguageOption) => {
                if (language?.code != form.getValues("sourceLanguage")?.code) {
                  form.setValue("sourceLanguage", language);
                  setTargets(language);
                } else {
                  // Clear the form element and reset the targets
                  form.setValue("sourceLanguage", undefined);
                  setTargets(null);
                }
              }}
            />
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="sourceText"
        render={({ field }) => (
          <FormItem className="flex w-full flex-col pt-4">
            <FormControl>
              <div className="relative">
                {field.value && field.value.length > 0 ? (
                  <TooltipProvider delayDuration={0}>
                    <PortalTooltip text={t("mt.clearSourceText")} side="left">
                      <ButtonIcon
                        icon={XCircle}
                        onClick={() => clearText("sourceText")}
                        className="absolute right-2 top-2 animate-in fade-in slide-in-from-right-2 slide-in-from-top-2"
                      />
                    </PortalTooltip>
                  </TooltipProvider>
                ) : null}
                <TextareaAutoSize
                  {...field}
                  dir="auto"
                  placeholder={t("mt.enterText")}
                  data-test="mt-source-text"
                  autoFocus={true}
                  maxLength={4800}
                  onChange={(e) => {
                    setTextChanged(true);
                    form.setValue("sourceText", e.target.value);
                  }}
                />
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
