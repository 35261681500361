import { useAuthUserStore } from "@/store/useAuthUserStore";
import useTheme from "hooks/useTheme";
import { PropsWithChildren, useEffect } from "react";

export function ThemeBranding({ children }: PropsWithChildren) {
  const user = useAuthUserStore((store) => store.user);
  const { updateTheme } = useTheme();

  useEffect(() => {
    if (!user) return;
    const isDefaultSite = user?.siteTag === "default";
    // Toggle the class on the body element
    if (!isDefaultSite) {
      /** TODO : Uncomment the lines bellow to re-activate the branding feature once the color scheme has been reworked */
      // const themeClass = `theme-${user.siteTag}`;
      // document.getElementById("root")?.classList.add(themeClass);

      updateTheme("light");

      // Set the title and favicon correctly
      if (user?.portalName) document.title = user.portalName;
      if (user?.favIconUrl) {
        const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        link.href = user.favIconUrl;
      }
    }
  }, [updateTheme, user]);

  return children;
}
