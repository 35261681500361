import { LanguageOption, MachineTranslationConfig } from "model/mt.typing";
import { t } from "i18next";
import { useCallback } from "react";
import { UseFormReturn } from "react-hook-form";
import { toast } from "sonner";
import { OcrMachineTranslationFormType } from "./useOcrMachineTranslationForm";

function useOcrMachineTranslation(
  form: UseFormReturn<OcrMachineTranslationFormType>,
  configuration: MachineTranslationConfig[] | undefined,
  setTargetLanguages: (text: LanguageOption[]) => void,
  setCollectionId: (text: string) => void,
  setMtProviderType: (mtProviderType: number) => void
) {
  const setTargets = useCallback(
    (source: LanguageOption | null) => {
      const targets: LanguageOption[] = [];
      if (configuration && source) {
        setCollectionId(configuration[0].collectionId);
        setMtProviderType(configuration[0].mtProviderType);
        const targetLanguages = configuration[0].languages.find(
          (x) => x.sourceLanguage.languageCode === source.code
        )?.targetLanguages;
        targetLanguages?.map((l) =>
          targets.push({
            label: l.languageName,
            code: l.languageCode,
            isDefaultDialect: l.isDefaultDialect,
            mappedCode: l.mappedLanguageCode,
            supportsTerminology: l.supportsTerminology,
          })
        );
      }
      setTargetLanguages(targets);
      const lastItem = localStorage.getItem("mtLastTarget");
      if (targets?.length === 1) form.setValue("targetLanguage", targets[0]);
      else if (lastItem) {
        // Attempt to set to last selected target
        const targetOption: LanguageOption = JSON.parse(lastItem);
        if (
          targetOption.code != form.getValues("targetLanguage")?.code &&
          targets.some((t) => t.code == targetOption.code)
        ) {
          form.setValue("targetLanguage", targetOption, { shouldValidate: true });
          toast.success(t("mt.targetLastSelection"));
        }
      } else form.resetField("targetLanguage");
    },
    [configuration, form, setCollectionId, setTargetLanguages, setMtProviderType]
  );

  const swapLanguages = useCallback(
    (source: LanguageOption | undefined, target: LanguageOption | undefined) => {
      if (configuration && source && target) {
        if (configuration[0].languages.find((x) => x.sourceLanguage.languageCode === target.code) !== undefined) {
          const targets: LanguageOption[] = [];
          const targetLanguages = configuration[0].languages.find(
            (x) => x.sourceLanguage.languageCode === target.code
          )?.targetLanguages;
          targetLanguages?.map((l) =>
            targets.push({
              label: l.languageName,
              code: l.languageCode,
              isDefaultDialect: l.isDefaultDialect,
              mappedCode: l.mappedLanguageCode,
              supportsTerminology: l.supportsTerminology,
            })
          );
          setTargetLanguages(targets);

          if (targets.find((x) => x.code === source.code) !== undefined)
            form.setValue("targetLanguage", source, { shouldValidate: true });
          else form.resetField("targetLanguage");

          form.setValue("sourceLanguage", target, { shouldValidate: true });
        } else {
          form.resetField("sourceLanguage");
          form.resetField("targetLanguage");
        }
      }
    },
    [configuration, form, setTargetLanguages]
  );

  return { setTargets, swapLanguages };
}

export default useOcrMachineTranslation;
