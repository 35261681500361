import type { User } from "@/model/user.typing";
import { convertToTimeZoneInfo } from "@/utils/dates";
import { formatDate as formatDateUtil, formatNumber, formatPercentage } from "@/utils/format";
import type { Locale } from "date-fns";
import { da, de, enUS, es, fi, fr, it, ja, ko, nb, nl, ptBR, ru, sv, th, vi, zhCN, zhTW } from "date-fns/locale";
import { changeLanguage } from "i18next";
import { create } from "zustand";

type DateTimeFormatSettings = {
  dateFormat?: string;
  timeFormat?: string | { includeTime: boolean; includeTimeZone?: "auto" | "show" | "hide" };
};

interface UserCulture {
  language: string;
  dateFormat: string;
  timeFormat: string;
  numberLocale: string;
  currencyPosition: number;
  currencyDecimalDigits: number;
  dateLocale: Locale;
  timeZoneInfo: {
    shortName: string;
    minutes: number;
  };
}

interface UserStoreState {
  user: User | null;
  userCulture: UserCulture;
  setUser: (user: User) => void;
  formatNumber: (number: number, currency?: string) => string;
  formatDate: (date: Date | null | undefined | string, settings?: DateTimeFormatSettings) => string;
  formatPercentage: (number: number, showDecimals: boolean) => string;
}

// all supported locales
const DEFAULT_LOCALES = {
  "da-DK": da,
  "de-DE": de,
  "en-US": enUS,
  "es-ES": es,
  "fr-FR": fr,
  "it-IT": it,
  "nl-NL": nl,
  "nb-NO": nb,
  "pt-BR": ptBR,
  "fi-FI": fi,
  "sv-SE": sv,
  "vi-VN": vi,
  "ru-RU": ru,
  "th-TH": th,
  "ko-KR": ko,
  "zh-Hans": zhCN,
  "zh-Hant": zhTW,
  "ja-JP": ja,
};

export const useAuthUserStore = create<UserStoreState>((set, get) => ({
  user: null,
  userCulture: {
    language: "en-US",
    dateFormat: "P",
    timeFormat: "p",
    numberLocale: "en-US",
    currencyPosition: 0,
    currencyDecimalDigits: 2,
    dateLocale: DEFAULT_LOCALES["en-US"],
    timezoneOffset: "00:00:00",
    timeZoneInfo: {
      shortName: "UTC",
      minutes: 0,
    },
  },
  setUser: (user) => {
    const language = user.cultureCode ?? "en-US"; // default to en-US as a safe guard
    const userCulture: UserCulture = {
      language,
      dateFormat: user.cultureDate ?? "P",
      timeFormat: user.cultureTime ?? "p",
      numberLocale: user.cultureNumber ?? language,
      currencyPosition: user.currencyPosition ?? 0,
      currencyDecimalDigits: user.currencyDecimalDigits ?? 2,
      dateLocale: DEFAULT_LOCALES[language as keyof typeof DEFAULT_LOCALES],
      timeZoneInfo: convertToTimeZoneInfo(user.baseUTCOffset) ?? {
        shortName: "UTC",
        minutes: 0,
      },
    };

    // save user and userCulture to the store
    set({ user, userCulture });
    // update i18n language
    changeLanguage(language).then();
  },
  formatNumber: (number: number, currency?: string) => {
    const userCulture = get().userCulture;
    return formatNumber(number, {
      language: userCulture.language,
      numberLocale: userCulture.numberLocale,
      currency,
      currencyPosition: userCulture.currencyPosition,
      currencyDecimalDigits: userCulture.currencyDecimalDigits,
    });
  },
  formatPercentage: (number: number, showDecimals = true) => {
    const userCulture = get().userCulture;
    return formatPercentage(number, {
      language: userCulture.language,
      numberLocale: userCulture.numberLocale,
      fractionDigits: showDecimals ? 2 : 0,
    });
  },
  formatDate: (date: Date | null | undefined | string, settings?: DateTimeFormatSettings) => {
    const locale = get().userCulture.dateLocale;
    const dateFormat = settings?.dateFormat ?? get().userCulture.dateFormat;
    const timeZoneShortName = get().userCulture.timeZoneInfo.shortName;
    const defaultTimeFormat = get().userCulture.timeFormat;
    const timeFormat = typeof settings?.timeFormat === "string" ? settings.timeFormat : null;
    const includeTime =
      typeof settings?.timeFormat === "object" &&
      "includeTime" in settings.timeFormat &&
      settings.timeFormat.includeTime;
    const includeTimeZone =
      typeof settings?.timeFormat === "object" &&
        "includeTimeZone" in settings.timeFormat &&
        !!settings.timeFormat.includeTimeZone
        ? settings.timeFormat.includeTimeZone
        : "auto";

    return formatDateUtil(date, {
      locale,
      dateFormat,
      timeSettings: {
        defaultTimeFormat,
        timeZoneShortName,
        timeFormat,
        includeTime,
        includeTimeZone,
      },
    });
  },
}));
