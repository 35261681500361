import { FaIcon, MENU_ICONS } from "@/components/Icons";
import { cn } from "@/utils/ui";
import { Loader2, LucideIcon } from "lucide-react";
import { Link, NavLink } from "react-router-dom";
import { VNEXT_BASE_PATH, getBaseUrl, getClassicPath, isVnextPath, sanitizePath } from "utils/url";
import { Card, CardContent } from "./ui/card";

interface LinkProps {
  title: string;
  icon: LucideIcon | string;
  link: string;
  description?: string;
}

export function MenuItemLink({ title, icon, link }: Omit<LinkProps, "description">) {
  const { isVnext, path } = getLinkInfo(link);
  const Icon = getIcon({ isVnext, link, icon }, "lg:size-4 shrink-0", "sidebar-item-icon");
  const dataTestValuePath = path.split("/").pop() ?? "";
  return (
    <NavLink
      data-test={"sidebar-item"}
      data-test-value={dataTestValuePath}
      to={path}
      className={"truncate"}
      relative={"route"}
      reloadDocument={!isVnext}
      end={path === "/client"}
    >
      {({ isActive }) => {
        let stateOverride = false;
        if (link.includes("/client/requests") && location.pathname.includes("/client/requests/create"))
          stateOverride = true;
        return (
          <span
            className={cn(
              "group flex flex-nowrap items-center gap-2 rounded-md px-3 py-2 text-sm font-medium hover:dark:text-slate-900",
              isActive && !stateOverride
                ? "bg-ribbon text-white hover:dark:text-white"
                : "text-gray-300 hover:bg-secondary-foreground"
            )}
            title={title}
          >
            {Icon}
            <span data-test="sidebar-item-title" className="hidden truncate lg:block">
              {title}
            </span>
          </span>
        );
      }}
    </NavLink>
  );
}

export function RequestsMenuItemLink({ title, icon, link }: Omit<LinkProps, "description">) {
  const { isVnext, path } = getLinkInfo(link);
  const Icon = getIcon({ isVnext, link, icon }, "size-4 shrink-0", "sidebar-item-icon");
  const dataTestValuePath = path.split("/").pop() ?? "";
  return (
    <NavLink
      data-test={"sidebar-item"}
      data-test-value={dataTestValuePath}
      to={path}
      className={"truncate"}
      relative={"route"}
      reloadDocument={!isVnext}
      end={path === "/client"}
    >
      {({ isActive, isPending }) => (
        <span
          className={cn(
            "group flex flex-nowrap items-center gap-2 rounded-md px-3 py-2 text-sm font-medium",
            isActive ? "bg-ribbon text-white" : "text-gray-300 hover:bg-secondary-foreground"
          )}
          title={title}
        >
          {isPending ? <Loader2 className="size-4 shrink-0 animate-spin" /> : Icon}
          <span data-test="sidebar-item-title" className="hidden truncate lg:block">
            {title}
          </span>
        </span>
      )}
    </NavLink>
  );
}

export function CardLink({ title, icon, link, description }: LinkProps) {
  const { isVnext, path } = getLinkInfo(link);
  const Icon = getIcon({ isVnext, link, icon }, "z-0 size-12", "card-icon");
  const dataTestValuePath = path.split("/").pop() ?? "";
  return (
    <Link data-test="card-item" data-test-value={dataTestValuePath} to={path} reloadDocument={!isVnext}>
      <Card className="group bg-secondary transition-colors duration-300 ease-in-out hover:bg-ribbon hover:text-white">
        <CardContent className="m-auto flex h-28 w-full flex-1 flex-col justify-center p-4">
          <div className="flex cursor-pointer flex-col justify-between">
            <div className="relative">
              <div className="absolute right-5 top-1/2 text-slate-300 dark:text-slate-600">{Icon}</div>
            </div>
            <h3 className="z-10 text-xl font-semibold" data-test="card-navigate-title">
              {title}
            </h3>
            {description ? (
              <p
                data-test="card-navigate-description"
                className="z-10 text-sm text-muted-foreground transition-colors duration-300 ease-in-out group-hover:text-slate-200"
              >
                {description}
              </p>
            ) : null}
          </div>
        </CardContent>
      </Card>
    </Link>
  );
}

export function getIcon(
  { icon, isVnext, link }: { isVnext: boolean; icon: string | LucideIcon; link: string },
  className: string = "",
  dataTestName: string = ""
) {
  if (isVnext && typeof icon === "string") {
    const iconKey = Object.keys(MENU_ICONS).find((key) => link.includes(key));
    const Icon = MENU_ICONS[(iconKey ?? "home") as keyof typeof MENU_ICONS];
    return <Icon data-test={dataTestName ?? ""} className={className} />;
  }

  if (!isVnext && typeof icon === "string") {
    return <FaIcon data-test={dataTestName ?? ""} icon={icon} className={className} />;
  }

  const Icon = icon as LucideIcon;
  return <Icon data-test={dataTestName ?? ""} className={className} />;
}

function getLinkInfo(pathTo: string) {
  const path = sanitizePath(pathTo);
  const isVnext = isVnextPath(path);
  const portalServerUrl = getBaseUrl();
  return {
    isVnext: isVnext,
    path: isVnext ? path.replace(VNEXT_BASE_PATH, "") : getClassicPath({ portalServerUrl, path }),
  };
}
