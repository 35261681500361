import { createCustomFieldsRecordSchema } from "@/components/customFields/common/customFieldFormSchema";
import { IdName } from "@/model/common";
import { CustomField } from "@/model/request.typing";
import { TZDate } from "@date-fns/tz";
import { zodResolver } from "@hookform/resolvers/zod";
import { isAfter, isBefore, isEqual } from "date-fns";
import { useForm } from "react-hook-form";
import { z } from "zod";

const RequestFileSchema = z.object({
  id: z.string(),
  key: z.string(),
  file: z.instanceof(File),
  uploaded: z.boolean(),
  progression: z.number(),
  abortController: z.instanceof(AbortController).optional(),
  remainingTime: z.string().optional(),
  status: z.enum(["waiting", "planned", "uploading", "uploaded", "failed", "canceled"]),
});

const SourceFileSchema = RequestFileSchema.extend({
  key: z.string(),
  groupName: z.string().nullable(),
  sourceLanguageCode: z
    .string()
    .nullable()
    .superRefine((data, ctx) => {
      if (!data) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "sourceLanguageCode",
        });
      }
    }),
  targetLanguageCodes: z.array(z.string()).min(1, "targetLanguageCodes"),
}).superRefine((data, ctx) => {
  if (
    data.sourceLanguageCode &&
    data.targetLanguageCodes &&
    data.targetLanguageCodes.length > 0 &&
    data.targetLanguageCodes.includes(data.sourceLanguageCode)
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["sourceLanguageCode"],
      message: "differentLanguages",
    });
  }
});

export const createNewRequestFormSchema = (timezone: string, customFields: CustomField[]) => {
  return z
    .object({
      division: z.object({
        id: z.string(),
        name: z.string(),
      }),
      service: z
        .object({
          id: z.string(),
          name: z.string(),
        })
        .nullable()
        .superRefine((data, ctx) => {
          if (!data || !data.id) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "service",
            });
          }
        }),
      quoteDueDate: z.date().optional(),
      name: z.string().max(100, "nameMaxLength").optional(),
      instructions: z.string().optional(),
      invoicingReference: z.string().max(256, "invoicingReferenceMaxLength").optional(),
      deadline: z.date().optional(),
      requestIdentifier: z.string(),
      requestQuote: z.boolean(),
      sourceFiles: z.array(SourceFileSchema).min(1, "sourceFiles"),
      referenceFiles: z.array(RequestFileSchema),
      customFields: createCustomFieldsRecordSchema(customFields),
      contacts: z.array(z.object({
        id: z.string(),
        name: z.string(),
      })).default([]),
    })
    .superRefine((data, ctx) => {
      const nowInUserTimeZone = new TZDate(new Date(), timezone);
      const deadline = data.deadline ? data.deadline : null;
      if (deadline && isBefore(deadline, nowInUserTimeZone)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["deadline"],
          message: "deadlineBeforeNow",
        });
      }

      const quoteDueDate = data.quoteDueDate ? data.quoteDueDate : null;
      if (quoteDueDate && isBefore(quoteDueDate, nowInUserTimeZone)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["quoteDueDate"],
          message: "quoteDueDateBeforeNow",
        });
      }
      if (
        data.quoteDueDate &&
        data.deadline &&
        (isAfter(data.quoteDueDate, data.deadline) || isEqual(data.quoteDueDate, data.deadline))
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["quoteDueDate"],
          message: "quoteDueDateBeforeDeadline",
        });
      }
      const failedFiles = (data.sourceFiles ?? []).filter((file) => file.status === "failed");
      if (failedFiles.length > 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["sourceFiles"],
          message: "sourceFilesNotUploaded",
        });
      }
    });
};

export type NewRequestFormType = z.infer<ReturnType<typeof createNewRequestFormSchema>>;
export type SourceFile = z.infer<typeof SourceFileSchema>;
export type RequestFile = z.infer<typeof RequestFileSchema>;
export type ReferenceFile = RequestFile;

export const useNewRequestForm = (division: IdName, requestIdentifier: string, timezone: string, customFields: CustomField[]) => {
  const schema = createNewRequestFormSchema(timezone, customFields);
  return useForm<NewRequestFormType>({
    mode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: {
      requestIdentifier,
      division: division,
      service: null,
      requestQuote: false,
      sourceFiles: [],
      referenceFiles: [],
      customFields: {},
      contacts: [],
    },
  });
};
