// noinspection AllyPlainJsInspection
import { useCallback } from "react";
import { getLocalStorageValue, setLocalStorageValue } from "utils/storage";
import { create } from "zustand";

export type ThemeType = "dark" | "light" | "system";

interface ThemeStoreState {
  theme: ThemeType;
  setTheme: (value: ThemeType) => void;
}

const useThemeStore = create<ThemeStoreState>((set) => ({
  theme: "system",
  setTheme: (value: ThemeType) => {
    set(() => ({ theme: value }));
  },
}));

const useTheme = () => {
  const { theme, setTheme } = useThemeStore();

  const getTheme = (): ThemeType => {
    const savedTheme = getLocalStorageValue<ThemeType>("pp-theme");
    return savedTheme ?? "system";
  };

  const detectTheme = () => {
    const theme = getTheme();
    updateTheme(theme);
  };

  const updateTheme = useCallback(
    (theme: ThemeType) => {
      setTheme(theme);

      setLocalStorageValue("pp-theme", theme);
      let themeToUpdate = theme;
      if (theme === "system") {
        themeToUpdate = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
      }
      if (themeToUpdate === "dark") {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    },
    [setTheme]
  );

  return { theme, updateTheme, detectTheme };
};

export default useTheme;
