import { LanguageOption, TermPayload, Terminology, TerminologyItem } from "@/model/mt.typing";
import { LanguageSelector } from "../../../LanguageSelector";
import useGlossary from "../useGlossary";
import { Form } from "react-hook-form";
import { FormControl, FormField, FormItem } from "@/components/ui/form";
import { t } from "i18next";
import { ArrowLeftRight, BanIcon, SaveIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useAddTerminologyItemMutation, useEditTerminologyItemMutation } from "@/query/mt/machinetranslation.query";
import Loader from "@/components/Loader";
import { ButtonIcon } from "@/components/button/ButtonIcon";
import { mapTerminologyItemLanguages } from "@/utils/mapping";
import { toast } from "sonner";
import { useTextMachineTranslationForm } from "@/pages/machinetranslation/useTextMachineTranslationForm";
import { FloatingLabelInput } from "@/components/input/FloatingInput";

export const TermDetails = () => {
  const {
    terminologyConfiguration,
    sourceLanguage,
    updateSourceLanguage,
    targetLanguage,
    updateTargetLanguage,
    setView,
    setTargets,
    currentTerminology,
    setCurrentTerminology,
    swapLanguages,
    currentTerm,
    setCurrentTerm,
    terminologyStoredSettings,
    updateTerminologyStoredSettings,
  } = useGlossary();

  const { addNewTerminologyItem, isFetchingAddItem } = useAddTerminologyItemMutation();
  const { editTerminologyItem, isFetchingEditItem } = useEditTerminologyItemMutation();

  const defaultFormValues = {
    sourceLanguage: sourceLanguage,
    targetLanguage: targetLanguage,
    sourceText: currentTerm?.source ? currentTerm.source : "",
    targetText: currentTerm?.target ? currentTerm.target : "",
  };

  const form = useTextMachineTranslationForm(defaultFormValues);

  if (isFetchingAddItem || isFetchingEditItem) return <Loader isShown={true} variant="md" className="my-2" />;

  const handleSubmit = async () => {
    const sourceValue = form.getValues("sourceText");
    const targetValue = form.getValues("targetText");
    if (sourceValue && targetValue && currentTerminology) {
      const payload: TermPayload = {
        source: sourceValue,
        target: targetValue,
        sourceLanguageCode: form.getValues("sourceLanguage.code"),
        targetLanguageCode: form.getValues("targetLanguage.code"),
      };

      if (checkForDuplicates(currentTerminology, payload, currentTerm)) {
        let result: Terminology;
        if (currentTerm) {
          result = await editTerminologyItem({
            customerId: terminologyConfiguration.customerId,
            instanceId: terminologyConfiguration.instanceId,
            terminologyId: currentTerminology.id,
            termId: currentTerm.id,
            payload,
          });
        } else {
          result = await addNewTerminologyItem({
            customerId: terminologyConfiguration.customerId,
            instanceId: terminologyConfiguration.instanceId,
            terminologyId: currentTerminology.id,
            payload,
          });
        }

        result = mapTerminologyItemLanguages(terminologyConfiguration.mappedLanguages, result);
        setCurrentTerminology(result);
        setCurrentTerm(null);
        form.setValue("sourceText", "");
        form.setValue("targetText", "");
        updateTerminologyStoredSettings({ ...terminologyStoredSettings, terminologyChanged: true });
      } else {
        toast.warning(t("mt.glossary.duplicateWarning", { term: payload.source }));
      }
    }
  };

  const handleCancel = () => {
    setCurrentTerm(null);
    setView("list");
  };

  const sourceLanguageField = form.watch("sourceLanguage");
  const targetLanguageField = form.watch("targetLanguage");
  const sourceTextField = form.watch("sourceText");
  const targetTextField = form.watch("targetText");

  return (
    <div className="mb-1 rounded-sm border p-2">
      <Form {...form}>
        <div className="mt-2">
          <div className="grid grid-cols-11">
            <FormField
              control={form.control}
              data-test="mt-terminology-source-language"
              name="sourceLanguage"
              render={({ field }) => (
                <FormItem className="col-span-5 flex w-full flex-col">
                  <LanguageSelector
                    languages={terminologyConfiguration.sourceLanguages}
                    placeholder={t("mt.glossary.sourceLanguage")}
                    data-test={`mt-glossary-term-source-language-list`}
                    field={field}
                    onSelect={(language: LanguageOption) => {
                      if (language?.code != form.getValues("sourceLanguage")?.code) {
                        form.setValue("sourceLanguage", language);
                        updateSourceLanguage(language);
                        setTargets(language, undefined, form);
                      } else {
                        form.resetField("sourceLanguage");
                        setTargets(undefined, undefined, form);
                      }
                    }}
                  />
                </FormItem>
              )}
            />
            <div className="col-span-1 my-2 text-center">
              <ButtonIcon
                icon={ArrowLeftRight}
                className="size-4"
                data-test="mt-glossary-term-language-swap"
                disabled={!sourceLanguageField || !targetLanguageField}
                onClick={() => swapLanguages(sourceLanguageField, targetLanguageField, form)}
              />
            </div>
            <FormField
              control={form.control}
              data-test="mt-terminology-target-language"
              name="targetLanguage"
              render={({ field }) => (
                <FormItem className="col-span-5 flex w-full flex-col">
                  <LanguageSelector
                    languages={terminologyConfiguration.targetLanguages}
                    placeholder={t("mt.glossary.targetLanguage")}
                    data-test={`mt-glossary-term-target-language-list`}
                    field={field}
                    onSelect={(language: LanguageOption) => {
                      if (language?.code != form.getValues("targetLanguage")?.code) {
                        form.setValue("targetLanguage", language);
                        updateTargetLanguage(language);
                      } else {
                        form.resetField("targetLanguage");
                      }
                    }}
                  />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-4 gap-2">
            <FormField
              control={form.control}
              name="sourceText"
              render={({ field }) => (
                <FormItem className="col-span-2 flex w-full flex-col pt-4">
                  <FormControl>
                    <div className="relative">
                      <FloatingLabelInput
                        {...field}
                        label={t("mt.glossary.enterTerm")}
                        data-test="mt-terminology-source-text"
                        autoFocus={true}
                        maxLength={256}
                        onChange={(e) => {
                          form.setValue("sourceText", e.target.value);
                        }}
                      />
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="targetText"
              render={({ field }) => (
                <FormItem className="col-span-2 flex w-full flex-col pt-4">
                  <FormControl>
                    <div className="relative">
                      <FloatingLabelInput
                        {...field}
                        label={t("mt.glossary.enterTranslation")}
                        data-test="mt-terminology-target-text"
                        maxLength={256}
                        onChange={(e) => {
                          form.setValue("targetText", e.target.value);
                        }}
                      />
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
      </Form>
      <div className="my-4 flex justify-center gap-2">
        <Button
          className="w-full"
          variant={"outline"}
          onClick={() => handleCancel()}
          data-test="mt-terminology-item-cancel-button"
        >
          <BanIcon className="mr-2 size-4 opacity-50" />
          {t("common.cancel")}
        </Button>
        <Button
          className="w-full"
          data-test="mt-terminology-item-save-button"
          disabled={
            sourceLanguageField === undefined ||
            targetLanguageField === undefined ||
            sourceTextField === undefined ||
            sourceTextField === "" ||
            targetTextField === undefined ||
            targetTextField === ""
          }
          variant={"outline"}
          onClick={async () => await handleSubmit()}
        >
          <SaveIcon className="mr-2 flex size-4 opacity-50" />
          {t("common.save")}
        </Button>
      </div>
    </div>
  );
};

function checkForDuplicates(terminology: Terminology, newItem: TermPayload, currentItem: TerminologyItem | null) {
  const duplicates = terminology.terminologyItems.filter(
    (x) =>
      x.source === newItem.source &&
      x.sourceLanguageCode === newItem.sourceLanguageCode &&
      x.targetLanguageCode === newItem.targetLanguageCode
  );

  if (currentItem !== null && duplicates.find((x) => x.id === currentItem.id) !== undefined) return true;

  return duplicates.length <= 0;
}
