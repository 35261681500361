import { DatatableCellWithHighlighting } from "@/components/datatable/components/DatatableCellWithHighlighting";
import { EmptyDataPlaceholder } from "@/components/EmptyPlaceholder";
import { Badge } from "@/components/ui/badge";
import { FormatDateFn, FormatDateTimeFn, useDateFormatter, useDateOperations } from "@/hooks/useDate";
import {
  BaseRequestType,
  DeliveredRequestType,
  InProgressRequestType,
  NewRequestType,
  RequestListType,
} from "@/model/request.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { filterStingInclude } from "@/utils/datatable";
import { isDateInRange } from "@/utils/dates";
import { ColumnDef } from "@tanstack/react-table";
import { TZDate } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { ServiceDescriptor } from "../../common/components/ServiceDescriptor";

export function useRequestListColumns<T>(type: RequestListType): ColumnDef<T>[] {
  const { t } = useTranslation();
  const { formatDateTime, formatDate } = useDateFormatter();
  const formatNumber = useAuthUserStore((store) => store.formatNumber);
  const { convertDate } = useDateOperations();
  const columnFactoryProps = { formatDate, formatDateTime, formatNumber, t, convertDate } satisfies ColumnFactoryProps;

  switch (type) {
    case "new":
      return [
        createNameColumn(columnFactoryProps),
        createCustomerContactNameColumn(columnFactoryProps),
        createProjectCodeColumn(columnFactoryProps),
        createCustomerNameColumn(columnFactoryProps),
        createServicesNameColumn(columnFactoryProps),
        createInvoicingReferenceColumn(columnFactoryProps),
        createStatusColumn(columnFactoryProps),
        createCreatedOnColumn(columnFactoryProps),
        createDeadlineColumn(columnFactoryProps),
        createTotalColumn(columnFactoryProps),
      ];
    case "in-progress":
      return [
        createNameColumn(columnFactoryProps),
        createCustomerContactNameColumn(columnFactoryProps),
        createProjectCodeColumn(columnFactoryProps),
        createCustomerNameColumn(columnFactoryProps),
        createServicesNameColumn(columnFactoryProps),
        createInvoicingReferenceColumn(columnFactoryProps),
        createStatusColumn(columnFactoryProps),
        createCreatedOnColumn(columnFactoryProps),
        createDeadlineColumn(columnFactoryProps),
        createTotalColumn(columnFactoryProps),
      ];
    case "delivered":
      return [
        createNameColumn(columnFactoryProps),
        createCustomerContactNameColumn(columnFactoryProps),
        createProjectCodeColumn(columnFactoryProps),
        createCustomerNameColumn(columnFactoryProps),
        createServicesNameColumn(columnFactoryProps),
        createInvoicingReferenceColumn(columnFactoryProps),
        createStatusColumn(columnFactoryProps),
        createCreatedOnColumn(columnFactoryProps),
        createDeliveredOnColumn(columnFactoryProps),
        createTotalColumn(columnFactoryProps),
      ];
    case "all":
      return [
        createNameColumn(columnFactoryProps),
        createCustomerContactNameColumn(columnFactoryProps),
        createProjectCodeColumn(columnFactoryProps),
        createCustomerNameColumn(columnFactoryProps),
        createServicesNameColumn(columnFactoryProps),
        createInvoicingReferenceColumn(columnFactoryProps),
        createStatusColumn(columnFactoryProps),
        createCreatedOnColumn(columnFactoryProps),
        createDeadlineColumn(columnFactoryProps),
        createTotalColumn(columnFactoryProps),
      ];
    default:
      return [];
  }
}

interface ColumnFactoryProps {
  formatDate: FormatDateFn;
  formatDateTime: FormatDateTimeFn;
  formatNumber: (number: number, currency?: string) => string;
  t: (key: string) => string;
  convertDate: (date: Date) => TZDate;
}

function createProjectCodeColumn<T>({ t }: ColumnFactoryProps): ColumnDef<T> {
  return {
    id: "projectCode",
    header: t("requests.table.projectCode"),
    accessorFn: (row) => (row as BaseRequestType).projectCode ?? "",
    enableGlobalFilter: true,
    meta: { renderForExport: (props) => (props.getValue() ? props.getValue() : "-") },
    cell(props) {
      const projectCode = props.getValue() as string;
      if (typeof projectCode !== "string" || projectCode.trim().length === 0) return <EmptyDataPlaceholder />;
      return <DatatableCellWithHighlighting value={projectCode ?? "-"} cell={props} />;
    },
  };
}

function createNameColumn<T>({ t }: ColumnFactoryProps): ColumnDef<T> {
  return {
    id: "name",
    header: t("requests.table.name"),
    accessorKey: "name",
    enableGlobalFilter: true,
    cell(props) {
      const name = props.getValue();
      if (typeof name !== "string") return <EmptyDataPlaceholder />;
      return (
        <div className="max-w-xs" title={name}>
          <p className="truncate">
            <DatatableCellWithHighlighting value={name} cell={props} />
          </p>
        </div>
      );
    },
  };
}

function createCustomerNameColumn<T>({ t }: ColumnFactoryProps): ColumnDef<T> {
  return {
    id: "customerName",
    header: t("requests.table.customerName"),
    accessorKey: "customerName",
    enableSorting: true,
    enableGlobalFilter: true,
    filterFn: filterStingInclude,
    cell(props) {
      return <DatatableCellWithHighlighting value={props.getValue() as string} cell={props} />;
    },
  };
}

function createCustomerContactNameColumn<T>({ t }: ColumnFactoryProps): ColumnDef<T> {
  return {
    id: "customerContactName",
    header: t("requests.table.requester"),
    accessorKey: "customerContactName",
    enableSorting: true,
    enableGlobalFilter: true,
    filterFn: filterStingInclude,
    cell(props) {
      return <DatatableCellWithHighlighting value={props.getValue() as string} cell={props} />;
    },
  };
}

function createServicesNameColumn<T>({ t }: ColumnFactoryProps): ColumnDef<T> {
  return {
    id: "services",
    header: t("requests.table.services"),
    accessorFn: (row) => (row as BaseRequestType).service?.name,
    enableSorting: true,
    enableGlobalFilter: false,
    filterFn: filterStingInclude,
    meta: { renderForExport: (props) => (props.getValue() ? props.getValue() : "-") },
    cell(props) {
      const service = (props.row.original as BaseRequestType).service;
      return <ServiceDescriptor service={service} />;
    },
  };
}

function createStatusColumn<T>({ t }: ColumnFactoryProps): ColumnDef<T> {
  return {
    id: "status",
    header: t("requests.table.status"),
    accessorKey: "status",
    enableSorting: true,
    enableGlobalFilter: false,
    filterFn: filterStingInclude,
    meta: { renderForExport: (props) => (props.getValue() ? t(`requests.statuses.${props.getValue()}`) : "-") },
    cell(props) {
      const name = props.getValue();
      if (typeof name !== "string") return <EmptyDataPlaceholder />;
      return (
        <Badge variant={"blue"} className="whitespace-nowrap rounded-sm px-2 py-1">
          {t(`requests.statuses.${name}`)}
        </Badge>
      );
    },
  };
}

function createCreatedOnColumn<T>({ formatDateTime, t, convertDate }: ColumnFactoryProps): ColumnDef<T> {
  return {
    ...getDefaultDateColumnOptions(formatDateTime, convertDate),
    id: "createdOn",
    accessorKey: "createdOn",
    header: t("requests.table.createdOn"),
  };
}

function createDeadlineColumn<T>({ formatDateTime, t, convertDate }: ColumnFactoryProps): ColumnDef<T> {
  return {
    ...getDefaultDateColumnOptions(formatDateTime, convertDate),
    id: "deadline",
    header: t("requests.table.deadline"),
    accessorKey: "deadline",
  };
}

function createTotalColumn<T>({ formatNumber, t }: ColumnFactoryProps): ColumnDef<T> {
  return {
    id: "total",
    header: t("requests.table.total"),
    accessorKey: "total",
    enableColumnFilter: false,
    enableGlobalFilter: false,
    meta: {
      renderForExport: (props) => {
        const value = props.getValue();
        const currency = (props.row.original as NewRequestType | InProgressRequestType | DeliveredRequestType)
          ?.currency;
        if (typeof value !== "number" || !currency) return "-";
        return formatNumber(value);
      },
    },
    cell(props) {
      const value = props.getValue();
      const currency = (props.row.original as NewRequestType | InProgressRequestType | DeliveredRequestType)?.currency;
      if (typeof value !== "number" || !currency) return <EmptyDataPlaceholder />;
      return <span className="float-right whitespace-nowrap">{formatNumber(value, currency)}</span>;
    },
  };
}

function createInvoicingReferenceColumn<T>({ t }: ColumnFactoryProps): ColumnDef<T> {
  return {
    id: "invoicingReference",
    header: t("requests.table.invoicingReference"),
    accessorKey: "invoicingReference",
    enableGlobalFilter: true,
    cell(props) {
      const invoicingReference = props.getValue();
      if (typeof invoicingReference !== "string" || invoicingReference.length === 0) return <EmptyDataPlaceholder />;
      return (
        <div className="max-w-xs" title={invoicingReference}>
          <p className="truncate">
            <DatatableCellWithHighlighting value={invoicingReference} cell={props} />
          </p>
        </div>
      );
    },
  };
}

function createDeliveredOnColumn<T>({ formatDateTime, t, convertDate }: ColumnFactoryProps): ColumnDef<T> {
  return {
    ...getDefaultDateColumnOptions(formatDateTime, convertDate),
    id: "deliveredOn",
    header: t("requests.table.deliveredOn"),
    accessorKey: "deliveredOn",
  };
}

function getDefaultDateColumnOptions<T>(
  formatDateTime: FormatDateTimeFn,
  convertDate: (date: Date) => TZDate
): Partial<ColumnDef<T>> {
  return {
    enableGlobalFilter: false,
    filterFn: (row, columnId, range) => {
      let value: Date | string | null | undefined = row.getValue(columnId);
      if (value) {
        value = convertDate(row.getValue(columnId));
      }
      return isDateInRange(row.getValue(columnId), range);
    },
    meta: {
      renderForExport: (props) => (props.getValue() ? formatDateTime(props.getValue()) : "-"),
    },
    cell(props) {
      const date = props.getValue();
      if (!date) return <EmptyDataPlaceholder />;
      return formatDateTime(date as Date);
    },
  } satisfies Partial<ColumnDef<T>>;
}
