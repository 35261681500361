import { useAuthUserStore } from "@/store/useAuthUserStore";
import { arraysAreEqual } from "@/utils/arrays";
import { objectsAreEqual } from "@/utils/objects";
import { ColumnDef, ColumnOrderState, Updater, VisibilityState } from "@tanstack/react-table";
import { useState } from "react";
import { getLocalStorageValue, setLocalStorageValue } from "utils/storage";

interface DatatableColumnSettingsState {
  order: ColumnOrderState;
  visibility: VisibilityState;
}

interface DatatableColumnSettings {
  order?: ColumnOrderState;
  visibility?: VisibilityState;
}

export function useDataTableColumnSettings<TData>(
  datatableName: string,
  columns: ColumnDef<TData>[],
  defaultColumnSettings?: DatatableColumnSettings
) {
  const { user } = useAuthUserStore();
  const localStorageId = `${user?.id}-${datatableName}`;

  const [columnSettings, setColumnSettings] = useState<DatatableColumnSettingsState>(() => {
    const savedValue = getLocalStorageValue<DatatableColumnSettingsState>(localStorageId);
    return {
      order: getColumnOrder(savedValue),
      visibility: getColumnVisibility(savedValue),
    };
  });

  function getColumnOrder(savedValue: DatatableColumnSettings | null): ColumnOrderState {
    if (!savedValue?.order) {
      return defaultColumnSettings?.order ?? columns.map((c) => c.id!);
    }
    if (savedValue.order.length !== columns.map((c) => c.id!).length) {
      return columns.map((c) => c.id!);
    }
    return savedValue.order;
  }

  function getColumnVisibility(savedValue: DatatableColumnSettings | null): VisibilityState {
    if (!savedValue?.visibility) {
      return defaultColumnSettings?.visibility ?? defaultColumnSettings?.visibility ?? {};
    }
    return savedValue.visibility;
  }

  function setColumnVisibility(updaterOrValue: Updater<VisibilityState>) {
    const visibility = getUpdaterValue(updaterOrValue, columnSettings.visibility);
    setLocalStorageValue(localStorageId, { ...columnSettings, visibility });
    setColumnSettings({ order: columnSettings.order, visibility });
  }

  function setColumnOrder(updaterOrValue: Updater<ColumnOrderState>) {
    const order = getUpdaterValue(updaterOrValue, columnSettings.order);
    setLocalStorageValue(localStorageId, { ...columnSettings, order });
    setColumnSettings({ ...columnSettings, order });
  }

  function resetColumnSettings() {
    const defaultSettings = {
      order: defaultColumnSettings?.order ?? columns.map((c) => c.id!),
      visibility: defaultColumnSettings?.visibility ?? {},
    };
    setColumnSettings(defaultSettings);
    setLocalStorageValue(localStorageId, defaultSettings);
  }

  function isDefaultSettings() {
    const isDefaultColumnOrder = arraysAreEqual(
      columnSettings.order,
      defaultColumnSettings?.order ?? columns.map((c) => c.id!)
    );
    const isDefaultColumnVisibility = objectsAreEqual(
      columnSettings.visibility,
      defaultColumnSettings?.visibility ?? {}
    );
    return isDefaultColumnOrder && isDefaultColumnVisibility;
  }

  return {
    columnVisibility: columnSettings.visibility,
    columnOrder: columnSettings.order,
    isDefaultSettings: isDefaultSettings(),
    setColumnVisibility,
    setColumnOrder,
    resetColumnSettings,
  };
}

function getUpdaterValue<T>(updaterOrValue: Updater<T>, state: T) {
  if (updaterOrValue instanceof Function) {
    return updaterOrValue(state);
  } else {
    return updaterOrValue;
  }
}
