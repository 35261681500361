import { cn } from "@/utils/ui";
import { XIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Badge, BadgeProps } from "../ui/badge";

interface DismissibleBadgeProps extends BadgeProps {
  onDismiss?: () => void;
  testId?: string;
}

export function DismissibleBadge({
  children,
  onDismiss,
  variant = "muted",
  className,
  testId,
  ...props
}: DismissibleBadgeProps) {
  const { t } = useTranslation();

  return (
    <Badge
      variant={variant}
      className={cn("flex items-center gap-1.5 rounded-sm px-2 py-0.5 pr-1 font-normal hover:bg-muted/80", className)}
      data-test={testId}
      {...props}
    >
      <span>{children}</span>
      {onDismiss && (
        <button
          type="button"
          onClick={onDismiss}
          className="rounded-sm p-0.5 hover:bg-muted-foreground/10"
          aria-label={t("common.delete")}
        >
          <XIcon className="size-3 text-muted-foreground" />
        </button>
      )}
    </Badge>
  );
}
