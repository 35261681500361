import { EmptyDataPlaceholder } from "@/components/EmptyPlaceholder";
import type { CustomField, CustomFieldValue } from "@/model/request.typing";
import { useAuthUserStore } from "@/store/useAuthUserStore";
import { formatDate } from "date-fns";
import { orderBy } from "lodash";
import { useTranslation } from "react-i18next";
import { Badge } from "../../ui/badge";
import { Tooltip, TooltipArrow, TooltipContent, TooltipProvider, TooltipTrigger } from "../../ui/tooltip";

export function CustomFieldDisplayValue({ field }: { field: CustomField }) {
  if (field.currentValue === null || field.currentValue === undefined) {
    return <EmptyDataPlaceholder iconSize={20} />;
  }

  switch (field.fieldType) {
    case "Boolean":
      return <CustomFieldViewBoolean value={field.currentValue} />;
    case "SingleSelect":
      return <CustomFieldViewSelect field={field} />;
    case "MultiSelect":
      return <CustomFieldViewMultiSelect field={field} />;
    case "Number":
      return <CustomFieldViewNumber field={field} />;
    case "String":
      return (
        <div>
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="max-w-64 truncate sm:max-w-sm lg:max-w-md">{field.currentValue.toString()}</div>
              </TooltipTrigger>
              <TooltipContent side="bottom" className="max-w-sm">
                <div className="whitespace-pre-wrap break-words text-sm">{field.currentValue.toString()}</div>
                <TooltipArrow />
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      );
    case "Date":
      return <CustomFieldViewDate value={field.currentValue} />;
    default:
      return <EmptyDataPlaceholder iconSize={20} />;
  }
}

function CustomFieldViewBoolean({ value }: { value?: CustomFieldValue }) {
  const { t } = useTranslation();
  if (value === true) {
    return <span>{t("common.yes")}</span>;
  }
  if (value === false) {
    return <span>{t("common.no")}</span>;
  }
  return <EmptyDataPlaceholder iconSize={20} />;
}

function CustomFieldViewSelect({ field }: { field: CustomField }) {
  const option = field.options.find((option) => option.id.toString() === field.currentValue?.toString());
  if (!option) {
    return <EmptyDataPlaceholder iconSize={20} />;
  }
  return (
    <div className="flex items-center gap-2">
      <Badge
        data-test={`customField-${field.customFieldId}-badge`}
        variant="muted"
        className="rounded-sm px-1 font-normal"
      >
        {option.optionText}
      </Badge>
    </div>
  );
}

function CustomFieldViewMultiSelect({ field }: { field: CustomField }) {
  if (
    !field.currentValue ||
    !Array.isArray(field.currentValue) ||
    field.currentValue.length === 0 ||
    field.options.length === 0
  ) {
    return <EmptyDataPlaceholder iconSize={20} />;
  }

  const selectedOptions = orderBy(
    field.currentValue
      .map((optionId) => {
        return field.options.find((option) => option.id.toString() === optionId.toString());
      })
      .filter((option) => !!option),
    "displayOrder",
    "asc"
  );

  if (selectedOptions.length === 0) {
    return <EmptyDataPlaceholder iconSize={20} />;
  }

  return (
    <div className="flex flex-wrap gap-2">
      {selectedOptions.map((option) => {
        return (
          <Badge
            key={option.id}
            data-test={`customField-${field.customFieldId}-badge`}
            variant="muted"
            className={"rounded-sm px-1 font-normal"}
          >
            {option.optionText}
          </Badge>
        );
      })}
    </div>
  );
}

function CustomFieldViewNumber({ field }: { field: CustomField }) {
  const userCulture = useAuthUserStore((store) => store.userCulture);
  const valueNumber = typeof field.currentValue === "number" ? field.currentValue : Number(field.currentValue);
  if (isNaN(valueNumber)) {
    return <EmptyDataPlaceholder iconSize={20} />;
  }
  const numberFormatter = new Intl.NumberFormat(userCulture.numberLocale, {
    minimumFractionDigits: field.precision ?? 0,
  });
  return <span>{numberFormatter.format(valueNumber)}</span>;
}

function CustomFieldViewDate({ value }: { value?: CustomFieldValue }) {
  const { dateFormat, dateLocale } = useAuthUserStore((store) => store.userCulture);

  if (typeof value === "string" || typeof value === "number" || value instanceof Date) {
    const formattedDate = formatDate(value, dateFormat, { locale: dateLocale });
    return <span>{formattedDate}</span>;
  }

  return <EmptyDataPlaceholder iconSize={20} />;
}
